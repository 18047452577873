import { useEffect } from 'react'
import constants from 'configs/constants'
import useHotjar from 'react-use-hotjar'
import { useUser } from 'features/shared/state/user/userContext'

const useInitHotjar = () => {
  const { user } = useUser()
  const { initHotjar, identifyHotjar, readyState } = useHotjar()
  const { HOTJAR_KEY, HOTJAR_SV } = constants
  useEffect(() => {
    initHotjar(parseInt(HOTJAR_KEY), parseInt(HOTJAR_SV))
  }, [HOTJAR_KEY, HOTJAR_SV, initHotjar])
  useEffect(() => {
    if (readyState && user) {
      identifyHotjar(user.id as string, {
        id: user.id as string,
        name: user.name as string,
        email: user.email as string,
        tenant: user.tenant as string,
        role: user.role as string,
        status: user.status as string,
      })
    }
  }, [readyState, user, identifyHotjar])
}

export default useInitHotjar
