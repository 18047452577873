import LemonLoader from 'components/LemonLoader'
import useAuth from 'features/shared/hooks/useAuth'
import getCurrentUser from 'features/shared/services/getCurrentUser'
import { userType } from 'features/shared/types/userType'
import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

type UserContextType = {
  user: userType
}

const UserContext = createContext<UserContextType | undefined>(undefined)
export const currentUserKey = 'current_user'

export const UserProvider: React.FC = ({ children }) => {
  const { token, logout } = useAuth()
  const { isLoading, data: user } = useQuery(currentUserKey, getCurrentUser, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!token,
    onError: () => logout(),
  })

  if (isLoading) {
    return <LemonLoader />
  }

  return (
    <UserContext.Provider value={{ user: user as userType }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext) as UserContextType
}
