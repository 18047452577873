import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import savePoliciesFromRole from '../services/savePoliciesFromRole'
import { policiesRoleQueryKey } from './usePoliciesFromRole'

function useSavePoliciesFromRole() {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(savePoliciesFromRole, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        [policiesRoleQueryKey, variables.role],
        variables.policies
      )

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('roles.success_save_policies'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useSavePoliciesFromRole
