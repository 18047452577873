import { Box, Button } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import useFunnels from '../hooks/useFunnels'
import useCreateProject from '../hooks/useCreateProject'
import getCategories from 'features/shared/services/getCategories'
import getAreas from 'features/shared/services/getAreas'
import FormControl from 'components/FormControl'
import Avatar from 'components/Avatar'
import SelectIcon from 'components/SelectIcon'
import SelectClients from 'features/shared/components/SelectClient'

import { Modal } from 'components/Modal'
import { projectType } from '../types/projectType'
import { createProjectProps } from '../services/createProject'
import { areaType } from 'features/shared/types/areaType'
import { areasQueryKey } from 'features/areas/views/Areas'
import { categoriesQueryKey } from 'features/categories/views/Categories'
import { categoryType } from 'features/shared/types/categoryType'
import { funnelType } from '../types/funnelType'
import Input from 'components/Input'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'
import { useLocation } from 'react-router-dom'

export const projectsQueryKey = 'projects'

const defaultProject = {
  id: '',
  tenant: '',
  active: true,
  name: '',
  client_id: '',
  pipeline_id: '',
  area_id: '',
  category_id: '',
}

interface CreateProjectProps {
  isOpen?: boolean
  onOpen: () => void
  onClose: () => void
  onError?: (error?: any) => void
  currentFunnel?: any
  currentClient?: any
  funnels?: funnelType[]
}

const CreateProject: React.FC<CreateProjectProps> = ({
  isOpen = false,
  onOpen,
  onClose,
  onError,
  currentFunnel,
  currentClient,
  funnels,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { refetch } = useFunnels()
  const useCreate = useCreateProject()
  const [isSubmited, setIsSubmited] = useState(false)
  const [project, setProject] = useState<projectType>(defaultProject)
  const { policyValidation } = usePolicyValidation()
  const { track } = useTrackEvent()

  const { data: areas } = useQuery<areaType[]>(areasQueryKey, getAreas, {
    enabled: policyValidation('areas.read'),
  })

  const { data: categories } = useQuery<categoryType[]>(
    categoriesQueryKey,
    getCategories,
    {
      enabled: policyValidation('categories.read'),
    }
  )

  const projectDataParsed = (project: projectType) => {
    const { id, tenant, ...rest } = project
    return rest as createProjectProps
  }

  const handleCreateProject = async () => {
    try {
      await useCreate.mutateAsync(projectDataParsed(project))

      track({
        eventName: 'projects - create - done',
        metadata: {
          triggerPath: location.pathname,
        },
      })

      refetch()
      onClose()
      setProject(defaultProject)
    } catch {
      onError?.(new Error(t('projects.generic_error')))
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setIsSubmited(true)
    const isValid = [
      project.name,
      project.pipeline_id,
      project.client_id,
      project.area_id,
      project.category_id,
    ].every((prop) => prop !== '')
    if (isValid) {
      handleCreateProject()
    }
  }

  const handleName = (name: string) => {
    setProject({
      ...project,
      name,
    })
  }

  const handleProjectPoperty = (key: string, value: string) =>
    setProject({ ...project, [key]: value })

  useEffect(() => {
    if (currentFunnel?.id) {
      handleProjectPoperty('pipeline_id', currentFunnel.id)
    }

    if (currentClient?.id) {
      handleProjectPoperty('client_id', currentClient.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFunnel, currentClient])

  useEffect(() => {
    if (isOpen) {
      track({
        eventName: 'projects - create',
        metadata: {
          triggerPath: location.pathname,
        },
      })
    }

    // eslint-disable-next-line
  }, [isOpen])

  return (
    <Box className="flex">
      <Modal
        onClose={() => {
          track({
            eventName: 'projects - create - cancel',
            metadata: {
              triggerPath: location.pathname,
            },
          })

          onClose()
        }}
        isOpen={isOpen}
        size="sm"
      >
        <Modal.Overlay />
        <Modal.Content as="form" onSubmit={handleSubmit}>
          <Modal.Header>{t('clients.create_project.title')}</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <Box className="space-y-3">
              <FormControl isRequired>
                <FormControl.Label>
                  {t('clients.create_project.name')}
                </FormControl.Label>

                <Input
                  size="md"
                  autoComplete="off"
                  name="name"
                  placeholder={t('clients.create_project.name')}
                  value={project.name || ''}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    handleName((e.target as HTMLInputElement).value)
                  }
                />

                <FormControl.ErrorMessage>
                  {t('field_required')}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={isSubmited && project.pipeline_id === ''}
              >
                <FormControl.Label>
                  {t('clients.create_project.funnel')}
                </FormControl.Label>

                <SelectIcon
                  isClearable
                  {...(currentFunnel && {
                    defaultValue: {
                      label: currentFunnel?.name,
                      value: currentFunnel?.id,
                    },
                  })}
                  onChange={(newValue: any) => {
                    handleProjectPoperty('pipeline_id', newValue.value)
                  }}
                  options={funnels
                    ?.filter((funn) => funn.active)
                    ?.map((funn) => ({
                      value: funn.id,
                      label: funn.name,
                      icon: <Avatar id={funn.id} name={funn.name} size={5} />,
                      iconSelected: (
                        <Avatar id={funn.id} name={funn.name} size={7} />
                      ),
                    }))}
                  size="md"
                />

                <FormControl.ErrorMessage>
                  {t('field_required')}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={isSubmited && project.client_id === ''}
              >
                <FormControl.Label>
                  {t('clients.create_project.client')}
                </FormControl.Label>

                <SelectClients
                  {...(currentClient && {
                    defaultValue: {
                      label: currentClient?.name,
                      value: currentClient?.id,
                    },
                  })}
                  onChange={(newValue: any) => {
                    handleProjectPoperty('client_id', newValue.value)
                  }}
                  size="md"
                  isClearable
                />

                <FormControl.ErrorMessage>
                  {t('field_required')}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={isSubmited && project.area_id === ''}
              >
                <FormControl.Label>
                  {t('clients.create_project.area')}
                </FormControl.Label>

                <SelectIcon
                  isClearable
                  onChange={(newValue: any) => {
                    handleProjectPoperty('area_id', newValue.value)
                  }}
                  options={areas?.map((area) => ({
                    value: area.id,
                    label: area.name,
                    icon: <Avatar id={area.id} name={area.name} size={5} />,
                    iconSelected: (
                      <Avatar id={area.id} name={area.name} size={7} />
                    ),
                  }))}
                  size="md"
                />

                <FormControl.ErrorMessage>
                  {t('field_required')}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={isSubmited && project.category_id === ''}
              >
                <FormControl.Label>
                  {t('clients.create_project.category')}
                </FormControl.Label>

                <SelectIcon
                  isClearable
                  onChange={(newValue: any) => {
                    handleProjectPoperty('category_id', newValue.value)
                  }}
                  options={categories?.map((cat) => ({
                    value: cat.id,
                    label: cat.name,
                    icon: <Avatar id={cat.id} name={cat.name} size={5} />,
                    iconSelected: (
                      <Avatar id={cat.id} name={cat.name} size={7} />
                    ),
                  }))}
                  size="md"
                />

                <FormControl.ErrorMessage>
                  {t('field_required')}
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">{t('save')}</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  )
}

export default CreateProject
