import { fetcherWithFile } from 'utils/fetcher'

async function getFunnelByFunnelStatusReport(
  year: string,
  month: string,
  funnel: string,
  currency: string
) {
  return await fetcherWithFile.get<any>(
    `/reporter/reports/funnel_status_by_funnel/${year}/${month}/${funnel}/${currency}`
  )
}

export default getFunnelByFunnelStatusReport
