import { fetcherWithFile } from 'utils/fetcher'

async function getFunnelByUserStatusReport(
  year: string,
  month: string,
  usrEmail: string,
  currency: string
) {
  return await fetcherWithFile.get<any>(
    `/reporter/reports/funnel_status_by_user/${year}/${month}/${usrEmail}/${currency}`
  )
}

export default getFunnelByUserStatusReport
