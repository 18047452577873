import { fetcherWithFile } from 'utils/fetcher'

type DownloadFileById = {
  projectId: string
  fileId: string
}

async function downloadFileById({ projectId, fileId }: DownloadFileById) {
  const file = await fetcherWithFile.get(
    `/crm-clients/projects/${projectId}/files/${fileId}`
  )

  return file
}

export default downloadFileById
