import { Box, Icon } from 'lemon-system'
import { Link, LinkProps, useResolvedPath, useMatch } from 'react-router-dom'

interface ListButtonProps extends LinkProps {
  icon: React.ElementType
}

const ListButton: React.FC<ListButtonProps> = ({
  children,
  icon,
  to,
  ...rest
}) => {
  const resolved = useResolvedPath(to);
  const isActive = useMatch({ path: resolved.pathname, end: true });
  return (
    <Box
      {...rest}
      to={(to as string).replace('/*', '')}
      as={Link}
      className={`text-neutral-01 rounded-md flex items-center p-2 hover:bg-neutral-05 ${
        isActive ? 'bg-neutral-05' : ''
      }`}
    >
      <Icon className="mr-2" as={icon} />
      {children}
    </Box>
  )
}

export default ListButton
