import { projectType } from 'features/shared/types/projectType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import moveMilestone from '../services/moveMilestone'
import { projectQueryKey } from './useProject'

function useMoveMilestone(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(moveMilestone, {
    onMutate: async ({ milestone: milestoneId, position: newPosition }) => {
      await queryClient.cancelQueries([projectQueryKey, projectId])

      const previousProject = queryClient.getQueryData([
        projectQueryKey,
        projectId,
      ])

      queryClient.setQueryData([projectQueryKey, projectId], (oldProject) => {
        const project = oldProject as projectType

        const milestones = [...project.milestones]

        const milestonePreviousIndex = milestones.findIndex(
          (milestone) => milestone.id === milestoneId
        )

        const milestone = milestones[milestonePreviousIndex]

        milestones.splice(milestonePreviousIndex, 1)
        milestones.splice(newPosition, 0, milestone)

        return { ...project, milestones }
      })

      return { previousProject }
    },
    onSuccess: (updatedProject: projectType) => {
      queryClient.setQueryData([projectQueryKey, projectId], updatedProject)
    },

    onError: (_err, _, context: any) => {
      queryClient.setQueryData(
        [projectQueryKey, projectId],
        context.previousProject
      )

      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useMoveMilestone
