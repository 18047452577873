import { fetcher } from 'utils/fetcher'

export type updateClientProps = {
  id: string
  name: string
  holding_id: string
}

async function updateClient(inputUpdateClient: updateClientProps) {
  return await fetcher.put(`/crm-clients/clients/${inputUpdateClient.id}`, inputUpdateClient)
}

export default updateClient
