import IconFile from 'components/IconFile'
import { Box } from 'lemon-system'
import { ReactNode } from 'react'

interface FileCardProps {
  name?: string
  ext?: string | null
  size?: string
  date?: string
  actions?: ReactNode
}

const FileCard: React.FC<FileCardProps> = ({
  name,
  ext,
  size,
  date,
  actions,
}) => {
  return (
    <Box className="border border-neutral-02 rounded-md py-4 pl-4 flex flex-col relative pr-12">
      <Box className="flex items-center">
        <IconFile size={32} className="mr-2" ext={ext} />

        <Box
          as="span"
          className="text-sm font-semibold text-neutral-07 flex overflow-hidden"
        >
          <Box
            as="span"
            className="overflow-ellipsis overflow-hidden whitespace-nowrap flex-1"
          >
            {name}
          </Box>
          <Box as="span" className="flex-0">
            {ext}
          </Box>
        </Box>
      </Box>
      <Box className="text-xs font-semibold text-neutral-08 ml-1 mt-1">
        {date} - {size}
      </Box>

      {actions && <Box className="absolute top-2 right-2">{actions}</Box>}
    </Box>
  )
}

export default FileCard
