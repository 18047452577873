import queryClient from 'configs/reactQuery/queryClient'
import { workTemplates } from 'features/shared/types/workTemplates'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import updateWorkTemplates from '../services/updateWorkTemplates'
import { workTemplatesKey } from './useWorkTemplates'

const useUpdateWorkTemplates = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(updateWorkTemplates, {
    onSuccess: (workTemplate: workTemplates) => {
      const workTemplates = queryClient.getQueryData(
        workTemplatesKey
      ) as workTemplates[]
      const updated = workTemplates.map((w) => {
        if (w?.id === workTemplate?.id) {
          return workTemplate
        } else {
          return w
        }
      })

      queryClient.setQueryData(workTemplatesKey, updated)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('work_templates.update_success'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateWorkTemplates
