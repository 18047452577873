import Avatar from 'components/Avatar'
import CancelButton from 'components/CancelButton'
import ConfirmButton from 'components/ConfirmButton'
import { Modal } from 'components/Modal'
import PlaceholderLoader from 'components/PlaceholderLoader'
import { userType } from 'features/shared/types/userType'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Icon, useTheme } from 'lemon-system'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCloudUpload } from 'react-icons/md'
import { TailSpin } from 'react-loader-spinner'
import readInputFile from 'utils/readInputFile'
import usePredefinedUserAvatars from '../hooks/usePredefinedUserAvatars'
import useUpdatePredefinedUserAvatar from '../hooks/useUpdatePredefinedUserAvatar'
import useUpdateUserAvatar from '../hooks/useUpdateUserAvatar'

interface UserAvatarEditProps {
  isOpen: boolean
  onClose: () => void
  user: userType
}

type currentAvatarType = {
  file: string | undefined
  name: string | null
  isCustom: boolean
}

const supportedFileTypes = ['image/jpeg', 'image/png', 'image/gif']

const UserAvatarEdit: React.FC<UserAvatarEditProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  const { t } = useTranslation()
  const { isLoading: isAvatarsLoading, data: avatars } =
    usePredefinedUserAvatars()
  const [currentAvatar, setCurrentAvatar] = useState<currentAvatarType>({
    file: user.avatar,
    name: null,
    isCustom: false,
  })
  const [isCustomAvatarError, setIsCustomAvatarError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const updateUserAvatar = useUpdatePredefinedUserAvatar()
  const updateCustomUserAvatar = useUpdateUserAvatar()
  const inputFileRef = useRef<HTMLInputElement>(null)

  const { getThemeProp: theme } = useTheme()

  const { track } = useTrackEvent()

  const handleAvatarSave = async () => {
    const { name, file, isCustom } = currentAvatar

    if (!name || !file) {
      onClose()
      return
    }

    try {
      setIsLoading(true)
      isCustom
        ? await updateCustomUserAvatar.mutateAsync({
            filename: name,
            file: file?.split(',')[1] || null,
          })
        : await updateUserAvatar.mutateAsync(name || null)

      track({
        eventName: 'profile - update avatar - done',
        metadata: {
          user,
        },
      })
      onClose()
    } catch {
      onClose()
    }
  }

  const onCancel = () => {
    track({
      eventName: 'profile - update avatar - cancel',
      metadata: {
        user,
      },
    })

    onClose()
  }

  return (
    <Box className="flex">
      <Modal isOpen={isOpen} onClose={onCancel}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Body>
            <Box className="flex justify-center mb-4">
              <CancelButton className="self-center mr-4" onClick={onCancel} />
              <Box className="flex relative self-center">
                <Avatar
                  src={currentAvatar.file}
                  size={60}
                  className={`${isLoading ? 'opacity-20' : ''}`}
                />

                {isLoading && (
                  <Box className="absolute flex h-full w-full justify-center items-center">
                    <TailSpin
                      width={60}
                      height={60}
                      color={theme('colors.primary.01')}
                    />
                  </Box>
                )}
              </Box>
              <ConfirmButton
                className="self-center ml-4"
                onClick={handleAvatarSave}
              />
            </Box>

            <Box className="flex w-full h-72">
              <Box className="flex flex-col h-full w-1/2">
                <Box
                  as="span"
                  className="flex text-bold text-xl justify-center mb-2"
                >
                  {t('user_profile.predefined_avatars_title')}
                </Box>

                <Box className="flex flex-wrap justify-center overflow-y-auto">
                  {isAvatarsLoading ? (
                    <>
                      <Box className="rounded-md">
                        <PlaceholderLoader className="m-4 h-16 w-16 rounded-full" />
                      </Box>
                      <Box className="rounded-md">
                        <PlaceholderLoader className="m-4 h-16 w-16 rounded-full" />
                      </Box>
                      <Box className="rounded-md">
                        <PlaceholderLoader className="m-4 h-16 w-16 rounded-full" />
                      </Box>
                    </>
                  ) : (
                    Object.keys(avatars || {}).map((avatarName) => (
                      <Box
                        className={`${
                          !currentAvatar.isCustom &&
                          currentAvatar.name === avatarName
                            ? 'bg-support-11'
                            : ''
                        } rounded-md hover:bg-support-12 cursor-pointer`}
                        key={`Avatar-${avatarName}`}
                        onClick={() => {
                          if (inputFileRef?.current?.value)
                            inputFileRef.current.value = ''

                          setCurrentAvatar({
                            file: avatars?.[avatarName],
                            name: avatarName,
                            isCustom: false,
                          })
                        }}
                      >
                        <Avatar
                          size={16}
                          src={avatars?.[avatarName]}
                          className="m-4"
                        />
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
              <Box className="flex flex-col w-1/2 h-full">
                <Box
                  as="span"
                  className="flex text-bold text-xl justify-center mb-2"
                >
                  {t('user_profile.custom_avatar_title')}
                </Box>

                <Box
                  as="label"
                  className="flex relative h-full justify-center cursor-pointer"
                >
                  <Box className="flex flex-col flex-wrap cursor-pointer absolute justify-center w-full h-full self-center">
                    <Box className="w-full flex justify-center">
                      <Icon as={MdCloudUpload} size={40} />
                    </Box>
                    <Box as="span" className="w-full flex justify-center">
                      {t('user_profile.upload_custom_avatar')}
                    </Box>

                    {currentAvatar.isCustom && isCustomAvatarError && (
                      <Box
                        as="span"
                        className="flex justify-center w-full text-sm font-semibold text-error-01 break-all px-2"
                      >
                        {t('user_profile.invalid_format')}
                      </Box>
                    )}

                    {currentAvatar.isCustom && currentAvatar.name && (
                      <Box
                        as="span"
                        className="flex justify-center w-full text-xs italic text-neutral-06 break-all px-2"
                      >
                        {currentAvatar.name}
                      </Box>
                    )}
                  </Box>

                  <Box
                    ref={inputFileRef}
                    as="input"
                    type="file"
                    accept="image/png, .jpeg, .jpg, image/gif"
                    onChange={async (e: any) => {
                      const file = e.target.files?.[0]

                      if (!supportedFileTypes.includes(file.type)) {
                        setCurrentAvatar({ ...currentAvatar, isCustom: true })
                        setIsCustomAvatarError(true)
                        return
                      }

                      if (file) {
                        setIsCustomAvatarError(false)
                        setIsLoading(true)
                        const base64File = await readInputFile(file)
                        setIsLoading(false)

                        setCurrentAvatar({
                          file: base64File as string,
                          name: file.name,
                          isCustom: true,
                        })
                      }
                    }}
                    className="cursor-pointer absolute w-full h-full opacity-0"
                  />
                </Box>
              </Box>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Box>
  )
}

export default UserAvatarEdit
