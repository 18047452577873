import { Box, Icon } from 'lemon-system'
import { useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'

export interface TableHeaderProps {
  title?: string
  className?: string
  sortable?: boolean
  fieldName?: string
  sortAsc?: boolean
  hidden?: boolean
  onClick?: () => void
}

const TableHeader: React.FC<TableHeaderProps> = ({
  title,
  className,
  sortable,
  sortAsc = false,
  hidden = false,
  onClick,
}) => {
  const [showIcon, setShowIcon] = useState<boolean>(false)

  return (
    <Box
      as="th"
      className={`${className} cursor-pointer`}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      onClick={onClick}
    >
      {title}
      {sortable && (
        <Icon
          as={sortAsc || hidden ? MdExpandMore : MdExpandLess}
          size={25}
          className={`ml-2 ${showIcon || !hidden ? 'opacity-1' : 'opacity-0'}`}
        />
      )}
    </Box>
  )
}

export default TableHeader
