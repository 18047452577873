import { ReactNode } from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import TaskDragButton from '../TaskDragButton'
import TaskRow from '../TaskRow'
import TaskTable from '../TaskTable'

interface DraggableMilestonesProps {
  id: string
  index: number
  isDisabledDroppable: boolean
  rowCells: ReactNode
  milestoneActions: ReactNode
  isDisabled: boolean
}

const DraggableMilestones: React.FC<DraggableMilestonesProps> = ({
  children,
  id,
  index,
  isDisabledDroppable,
  rowCells,
  milestoneActions,
  isDisabled,
}) => {
  const firstIndex = 0

  const customTaskTableDragStyles = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot
  ) => {
    if (!snapshot.isDropAnimating || !isDisabledDroppable) {
      return style
    }

    return {
      ...style,
      transform: `translate(0px, 0px)`,
    }
  }

  const isDragDisabled = index === firstIndex

  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled || isDisabled}
    >
      {(
        draggableProvided: DraggableProvided,
        draggableSnapshot: DraggableStateSnapshot
      ) => (
        <TaskTable
          className={`mb-6 ${draggableSnapshot.isDragging ? 'shadow-md' : ''}`}
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
          style={customTaskTableDragStyles(
            draggableProvided.draggableProps.style,
            draggableSnapshot
          )}
          actions={milestoneActions}
        >
          <TaskRow>
            {!isDisabled && (
              <TaskDragButton
                isDisabled={isDragDisabled}
                {...draggableProvided.dragHandleProps}
              />
            )}
            {rowCells}
          </TaskRow>

          {children}
        </TaskTable>
      )}
    </Draggable>
  )
}

export default DraggableMilestones
