import { userType } from 'features/shared/types/userType'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Icon } from 'lemon-system'
import { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'

interface UserDashboardSettingProps {
  user: userType['dashboard']
  onChange: (dashboardSettings: userType['dashboard']) => void
}

const UserDashboardSetting: React.FC<UserDashboardSettingProps> = ({
  user,
  onChange,
}) => {
  const [dashboardSettings, setDashboardSettings] = useState<
    userType['dashboard']
  >(user.dashboard)
  const { t } = useTranslation()

  const { track } = useTrackEvent()

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result
    const isOutsideTheList = !destination
    const isSamePlace =
      source.index === destination?.index &&
      source.droppableId === destination?.droppableId

    let newDashboardSettings

    if (isOutsideTheList || isSamePlace) {
      return
    }

    if (destination.droppableId === source.droppableId) {
      // Drag to same column
      const newList = dashboardSettings[destination.droppableId].filter(
        (_: any, idx: number) => source.index !== idx
      )
      newList.splice(
        destination.index,
        0,
        dashboardSettings[destination.droppableId][source.index]
      )
      dashboardSettings[destination.droppableId] = newList

      newDashboardSettings = {
        ...dashboardSettings,
        [destination.droppableId]: newList,
      }
    } else {
      // Drag between inactive <-> active
      const sourceList = dashboardSettings[source.droppableId].filter(
        (_: any, idx: number) => source.index !== idx
      )
      const destinationList = [...dashboardSettings[destination.droppableId]]
      destinationList.splice(
        destination.index,
        0,
        dashboardSettings[source.droppableId][source.index]
      )

      newDashboardSettings = {
        [source.droppableId]: sourceList,
        [destination.droppableId]: destinationList,
      }
    }

    setDashboardSettings(newDashboardSettings)

    track({
      eventName: 'profile - drag dashboard items',
      metadata: {
        user,
        dashboardSettings: newDashboardSettings,
      },
    })
  }

  useEffect(() => {
    onChange(dashboardSettings)
  }, [dashboardSettings, onChange])

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box className="flex overflow-x-auto flex-grow-1 flex-shrink-1 ml-4 mt-8 pb-4 items-start">
        <Box className="flex flex-col h-full w-72">
          <Box
            as="span"
            className="flex text-md font-semibold text-primary-01 text-sm py-1 p-3 justify-center w-full"
          >
            {t('user_profile.dashboard_settings.active_title')}
          </Box>

          <Box className="w-full h-full border border-dashed rounded-md flex flex-col justify-start bg-02">
            <Droppable droppableId="active">
              {(
                droppableProvided: DroppableProvided,
                droppableSnapshot: DroppableStateSnapshot
              ) => (
                <Box
                  className={`flex flex-col p-3 h-full rounded-b-md ${
                    droppableSnapshot.isDraggingOver ? 'bg-01' : ''
                  }`}
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {dashboardSettings.active.map(
                    (dashboardSection: string, sectionIdx: number) => (
                      <Draggable
                        key={`${dashboardSection}-${sectionIdx}`}
                        draggableId={`${dashboardSection}-${sectionIdx}`}
                        index={sectionIdx}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          draggableSnapshot: DraggableStateSnapshot
                        ) => (
                          <Box
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            className="flex bg-neutral-01 justify-center p-2 m-2 rounded-md border border-neutral-02"
                          >
                            {t(`dashboard.${dashboardSection}.title`)}
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                </Box>
              )}
            </Droppable>
          </Box>
        </Box>

        <Box className="flex flex-col self-center mx-2">
          <Icon as={MdArrowForward} size={32} />
          <Icon as={MdArrowBack} size={32} />
        </Box>

        <Box className="flex flex-col h-full w-72">
          <Box
            as="span"
            className="flex text-md font-semibold text-primary-01 text-sm py-1 p-3 justify-center w-full"
          >
            {t('user_profile.dashboard_settings.inactive_title')}
          </Box>

          <Box className="w-full h-full border border-dashed rounded-md flex flex-col justify-start bg-02">
            <Droppable droppableId="inactive">
              {(
                droppableProvided: DroppableProvided,
                droppableSnapshot: DroppableStateSnapshot
              ) => (
                <Box
                  className={`flex flex-col p-3 h-full rounded-b-md ${
                    droppableSnapshot.isDraggingOver ? 'bg-01' : ''
                  }`}
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {dashboardSettings.inactive.map(
                    (dashboardSection: string, sectionIdx: number) => (
                      <Draggable
                        key={`${dashboardSection}-${sectionIdx}`}
                        draggableId={`${dashboardSection}-${sectionIdx}`}
                        index={sectionIdx}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          draggableSnapshot: DraggableStateSnapshot
                        ) => (
                          <Box
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            className="flex bg-neutral-01 justify-center p-2 m-2 rounded-md border border-neutral-02"
                          >
                            {t(`dashboard.${dashboardSection}.title`)}
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                </Box>
              )}
            </Droppable>
          </Box>
        </Box>
      </Box>
    </DragDropContext>
  )
}

export default UserDashboardSetting
