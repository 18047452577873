import { clientsType } from 'features/shared/types/clientsType'
import { fetcher } from 'utils/fetcher'

export type createClientProps = Omit<
  clientsType,
  'id' | 'tenant' | 'holding_id' | 'code' | 'integration_code'
>

async function createClient(clientData: createClientProps) {
  return await fetcher.post('/crm-clients/clients', clientData)
}

export default createClient
