import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import getProjectById from '../services/getProjectById'

export const projectQueryKey = 'project'

function useProject(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useQuery(
    [projectQueryKey, projectId],
    () => getProjectById(projectId),
    {
      onError: () =>
        snackbar.add({
          duration: 3000,
          content: t('unexpected_error'),
          status: 'error',
          actionContent: defaultActionContent,
        }),
    }
  )
}

export default useProject
