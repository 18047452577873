import { areaType } from 'features/shared/types/areaType'
import { Box } from 'lemon-system'
import { useTranslation } from 'react-i18next'

interface ProjectAreaLabelProps {
  area?: areaType
  className?: string
}

const ProjectAreaLabel: React.FC<ProjectAreaLabelProps> = ({
  area,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <Box
      className={`${className} text-xs bg-info-02 text-info-01 font-bold rounded-sm p-2`}
    >
      {area?.name ? area?.name : t('projects.no_area')}
    </Box>
  )
}

export default ProjectAreaLabel
