import { fetcher } from 'utils/fetcher'

export type updateFavouriteContactprops = {
  clientId: string
  contactId: string
}

async function updateFavouriteContact(params: updateFavouriteContactprops) {
  return await fetcher.patch(`/crm-clients/clients/${params.clientId}/contacts/${params.contactId}/favourite`)
}

export default updateFavouriteContact
