import { clientsType } from 'features/shared/types/clientsType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import createClient from '../services/createClient'
import { clientsQueryKey } from '../views/Clients'

const useCreateClient = () => {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(createClient, {
    onSuccess: (newClient: clientsType) => {
      const updatedClients = [
        newClient,
        ...(queryClient.getQueryData([clientsQueryKey]) as clientsType[]),
      ]
      queryClient.setQueryData(clientsQueryKey, updatedClients)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('clients.success_create'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useCreateClient
