import { Modal } from 'components/Modal'
import PlaceholderLoader from 'components/PlaceholderLoader'
import { workTemplates } from 'features/shared/types/workTemplates'
import useWorkTemplates from 'features/workTemplates/hooks/useWorkTemplates'
import { Box, Button } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface LoadWorkTemplateModalProps {
  onSave: (workTemplateId: string) => void
  onClose: () => void
  isOpen: boolean
  isLoading: boolean
}

const LoadWorkTemplateModal: React.FC<LoadWorkTemplateModalProps> = ({
  onSave,
  onClose,
  isOpen,
  isLoading,
}) => {
  const { t } = useTranslation()
  const [wTSelected, setWTSelected] = useState<workTemplates>(null)
  const { handleSubmit } = useForm()
  const { isLoading: isWTLoading, data: workTemplates } = useWorkTemplates()

  const onSumbit = handleSubmit(async () => {
    if (!wTSelected?.id) return

    onSave(wTSelected?.id)
  })

  useEffect(() => {
    setWTSelected(null)
  }, [isOpen])

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="sm"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <Modal.Overlay />
      <Modal.Content as="form" onSubmit={onSumbit}>
        <Modal.Header>{t('projects.work_template_select_modal')}</Modal.Header>

        <Modal.Body>
          <Box className="space-y-3">
            {isWTLoading ? (
              <>
                <PlaceholderLoader className="h-14 mb-2 rounded-md" />
                <PlaceholderLoader className="h-14 mb-2 rounded-md" />
                <PlaceholderLoader className="h-14 mb-2 rounded-md" />
              </>
            ) : (
              <>
                {workTemplates?.map((workTemplate: workTemplates) => {
                  if (!workTemplate) return null

                  return (
                    <Box
                      className={`flex relative cursor-pointer rounded-md border border-neutral-02 font-semibold text-sm justify-center items-center p-4 mb-2 ${
                        wTSelected?.id === workTemplate.id
                          ? 'bg-primary-01 text-neutral-01'
                          : 'text-neutral-07 hover:bg-neutral-03'
                      }`}
                      key={`workTemplate-${workTemplate.id}`}
                      onClick={() => setWTSelected(workTemplate)}
                    >
                      {workTemplate.name}
                    </Box>
                  )
                })}
              </>
            )}
          </Box>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outlined"
            className="mr-4"
            onClick={onClose}
            isDisabled={isLoading}
          >
            {t('cancel')}
          </Button>
          <Button type="submit" isDisabled={isLoading}>
            {t('add')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default LoadWorkTemplateModal
