import { useQuery } from 'react-query'
import getCurrentTenant from '../services/getCurrentTenant'
import { tenantType } from '../types/tenantType'

export const tenantQueryKey = 'currentTenant'

function useCurrentTenant() {
  const result = useQuery<tenantType>(tenantQueryKey, getCurrentTenant, {
    refetchOnMount: false,
  })

  return { ...result }
}

export { useCurrentTenant }
