import { Placement } from '@popperjs/core'
import MotionBox from 'components/MotionBox'
import { AnimatePresence } from 'framer-motion'
import { useDisclosure } from 'hooks/useDisclosure'
import { Box, BoxProps } from 'lemon-system'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { usePopper } from 'react-popper'

export type customPlacement =
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'

export const animationOrigins: { [key in customPlacement]: string } = {
  bottom: 'origin-top',
  'bottom-start': 'origin-top-left',
  'bottom-end': 'origin-top-right',
  top: 'origin-bottom',
  'top-start': 'origin-bottom-left',
  'top-end': 'origin-bottom-right',
  right: 'origin-left',
  left: 'origin-right',
}

interface MenuProps {
  placement?: customPlacement
  children?: ReactNode
}

interface MenuContextProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
  referenceElement: HTMLElement | null
  setReferenceElement: (element: HTMLElement | null) => void
  popperElement: HTMLElement | null
  setPopperElement: (element: HTMLElement | null) => void
  placement?: customPlacement
  popper: {
    styles: {
      [key: string]: React.CSSProperties
    }
    attributes: {
      [key: string]:
        | {
            [key: string]: string
          }
        | undefined
    }
  }
}

interface MenuItemProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon?: ReactNode
  className?: string
  iconClassName?: string
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

function useMenu() {
  return useContext(MenuContext) as MenuContextProps
}

export const Menu = ({ placement = 'bottom-end', children }: MenuProps) => {
  const { isOpen, onClose, ...disclosure } = useDisclosure()
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const popper = usePopper(referenceElement, popperElement, {
    placement: placement as Placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperElement &&
        referenceElement &&
        !referenceElement.contains(event.target as HTMLElement) &&
        !popperElement.contains(event.target as HTMLElement)
      )
        onClose()
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popperElement, referenceElement, isOpen, onClose])

  return (
    <MenuContext.Provider
      value={{
        ...disclosure,
        isOpen,
        onClose,
        referenceElement,
        setReferenceElement,
        popperElement,
        setPopperElement,
        popper,
        placement,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

const MenuButton: <E extends React.ElementType = 'button'>(
  props: BoxProps<E>
) => React.ReactElement | null = ({
  children,
  as: element = 'button',
  onClick,
  ...props
}) => {
  const { setReferenceElement, onToggle } = useMenu()

  return (
    <Box
      as={element as React.ElementType}
      {...props}
      ref={setReferenceElement}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(e)
        onToggle()
      }}
    >
      {children}
    </Box>
  )
}

const MenuList: React.FC = ({ children }) => {
  const { isOpen, setPopperElement, popper, placement } = useMenu()

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          ref={setPopperElement}
          style={popper.styles.popper}
          className="z-dropdown"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.1,
          }}
          {...popper.attributes.popper}
        >
          <MotionBox
            className={`bg-neutral-01 shadow-sm rounded-md min-w-44 py-2 ${
              animationOrigins[placement as customPlacement]
            }`}
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            transition={{
              duration: 0.1,
            }}
          >
            {children}
          </MotionBox>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  className,
  iconClassName,
  onClick,
  icon,
}) => {
  const { onClose } = useMenu()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e)
    onClose()
  }

  return (
    <Box
      as="button"
      className={`${className} w-full flex items-center text-secondary-01 px-3 py-2.5 text-sm font-semibold hover:bg-02 focus:outline-none`}
      onClick={handleClick}
    >
      {icon && (
        <Box className={`${iconClassName} mr-2 text-info-01 flex items-center`}>
          {icon}
        </Box>
      )}
      {children}
    </Box>
  )
}

Menu.List = MenuList
Menu.Item = MenuItem
Menu.Button = MenuButton
