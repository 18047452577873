import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import softDeleteFunnel from '../services/softDeleteFunnel'

function useSoftDeleteFunnel() {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(softDeleteFunnel, {
    onSuccess: (_, variables) => {
      snackbar.add({
        duration: 5000,
        status: 'success',
        content: 'Funnel desactivado correctamente',
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useSoftDeleteFunnel
