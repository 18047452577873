import PlaceholderLoader from 'components/PlaceholderLoader'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button, useTheme } from 'lemon-system'
import { Fragment, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import usePolicies from '../hooks/usePolicies'
import usePoliciesFromRole from '../hooks/usePoliciesFromRole'
import useSavePoliciesFromRole from '../hooks/useSavePoliciesFromRole'
import parsePolicies from '../utils/parsePolicies'
import policyStatus from '../utils/policyStatus'

type keyValueObj = { [key: string]: string }

const defaultRoles = { admin: 'admin', default: 'default' }

const RoleDetail: React.FC = () => {
  const { roleName = defaultRoles.admin } = useParams()
  const policies = usePolicies()
  const rolePolicies = usePoliciesFromRole(roleName)
  const savePolicies = useSavePoliciesFromRole()
  const { register, handleSubmit, reset, formState, control } = useForm()
  const fields = useWatch({ control })
  const { t } = useTranslation()
  const { policyValidation } = usePolicyValidation()
  const canWrite = useMemo(
    () => policyValidation('roles.write'),
    // eslint-disable-next-line
    []
  )

  const { getThemeProp: theme } = useTheme()

  const { track } = useTrackEvent()

  const getPolicyStatus = (policy: string) => {
    const hasFullAccess = rolePolicies.data?.includes('*.*')
    const hasPolicyFullAccess = rolePolicies.data?.includes(`${policy}.*`)

    if (hasFullAccess || hasPolicyFullAccess) return policyStatus.write.number

    const statusNumber = rolePolicies.data?.filter((rolePolicy) =>
      rolePolicy.includes(`${policy}.`)
    ).length

    return statusNumber?.toString()
  }

  const onSubmit = handleSubmit((data) => {
    savePolicies.mutate({
      role: roleName,
      policies: parsePolicies(data),
    })

    track({
      eventName: 'roles - edit - done',
      metadata: {
        roleName,
      },
    })
  })

  useEffect(() => {
    if (formState.isDirty) {
      track({
        eventName: 'roles - edit',
        metadata: {
          roleName,
        },
      })
    }
    // eslint-disable-next-line
  }, [formState.isDirty])

  useEffect(() => {
    track({
      eventName: 'roles',
      metadata: {
        roleName,
      },
    })
    // eslint-disable-next-line
  }, [roleName])

  useEffect(() => {
    if (policies.data && rolePolicies.data) {
      const defaultValues = policies.data.reduce(
        (initFormData, policyGroup) => {
          const [, policies] = Object.entries(policyGroup)[0]

          const policiesStatus = policies.reduce((initPoliciesData, policy) => {
            return {
              ...initPoliciesData,
              [policy]: getPolicyStatus(policy) as string,
            }
          }, {} as keyValueObj)

          return { ...initFormData, ...policiesStatus }
        },
        {} as keyValueObj
      )

      reset(defaultValues)
    }

    // eslint-disable-next-line
  }, [roleName, policies.data, rolePolicies.data])

  if (policies.isLoading || rolePolicies.isLoading) {
    return (
      <Box className="py-6">
        <PlaceholderLoader className="h-6 mb-2 w-64" />
        <PlaceholderLoader className="h-6 mb-2 w-64" />
        <PlaceholderLoader className="h-6 mb-2 w-64" />
        <PlaceholderLoader className="h-6 mb-2 w-64" />
        <PlaceholderLoader className="h-6 mb-2 w-64" />
        <PlaceholderLoader className="h-6 mb-2 w-64" />
        <PlaceholderLoader className="h-6 mb-2 w-64" />
      </Box>
    )
  }

  const isEmptyPolicies = !Object.values(fields).some(
    (field) =>
      field === policyStatus.read.number || field === policyStatus.write.number
  )
  const isOneOfDefaultRoles = Object.keys(defaultRoles).includes(roleName)

  return (
    <Box className="py-6" as="form" onSubmit={onSubmit}>
      {policies.data?.map((policyGroup) => {
        const [policyGroupName, policies] = Object.entries(policyGroup)[0]

        return (
          <Box
            key={`policyGroup-${policyGroupName}`}
            className="mb-6 grid grid-cols-4 items-center max-w-lg gap-x-4 gap-y-2 mb-10"
          >
            <Box as="h2" className="font-semibold capitalize leading-3">
              {t(`roles.groups.${policyGroupName}`)}
            </Box>
            <Box
              as="span"
              className="font-semibold capitalize justify-self-start text-sm"
            >
              {t('roles.status.none')}
            </Box>
            <Box
              as="span"
              className="font-semibold capitalize justify-self-center text-sm"
            >
              {t('roles.status.read')}
            </Box>
            <Box
              as="span"
              className="font-semibold capitalize justify-self-end text-sm"
            >
              {t('roles.status.write')}
            </Box>

            {policies.map((policy) => (
              <Fragment key={`policyGroup-${policyGroupName}-policy-${policy}`}>
                <Box as="label">{t(`roles.policies.${policy}`)}</Box>
                <Box
                  {...register(policy)}
                  as="input"
                  type="range"
                  step="1"
                  min="0"
                  max="2"
                  disabled={isOneOfDefaultRoles || !canWrite}
                  className="col-span-3"
                />
              </Fragment>
            ))}
          </Box>
        )
      })}

      {canWrite && (
        <>
          {savePolicies.isLoading ? (
            <Button isDisabled>
              <TailSpin
                width={20}
                height={20}
                color={theme('colors.secondary.01')}
              />
            </Button>
          ) : (
            <Button
              type="submit"
              isDisabled={
                isOneOfDefaultRoles || !formState.isDirty || isEmptyPolicies
              }
            >
              {t('save')}
            </Button>
          )}
        </>
      )}
    </Box>
  )
}

export default RoleDetail
