import { categoryType } from 'features/shared/types/categoryType'
import { fetcher } from 'utils/fetcher'

export type updateCategoryProps = Omit<categoryType, 'tenant'>

async function updateCategory(updateCategoryData: updateCategoryProps) {
  return await fetcher.put(`/crm-clients/categories/${updateCategoryData.id}`, updateCategoryData)
}

export default updateCategory
