import { useInfiniteQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Box, Button } from 'lemon-system'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'

import getProjectComments from 'features/shared/services/getProjectComments'
import useCreateComment, { commentsQueryKey } from '../hooks/useCreateComment'
import CommentCard from 'features/projects/components/CommentCard'

import { useUsers } from 'features/shared/hooks/useUsers'
import { projectCommentsType } from 'features/shared/types/projectCommentsType'
import { createCommentProps } from '../services/createComment'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import PlaceholderLoader from 'components/PlaceholderLoader'
import AutoResizeTextArea from '../components/AutoResizeTextArea'
import { useTranslation } from 'react-i18next'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import useTrackEvent from 'hooks/useTrackEvent'

const ProjectComments: React.FC = () => {
  const { projectId } = useParams()
  const {
    isLoading: isCommentsLoading,
    data,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<projectCommentsType[]>(
    [commentsQueryKey, projectId],
    ({ pageParam }) => getProjectComments(projectId, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage?.[0]?.created_at,
      select: (data) => ({
        pages: [...data.pages].reverse(),
        pageParams: [...data.pageParams].reverse(),
      }),
    }
  )

  const { isLoading: isUsersLoading } = useUsers()
  const useCreate = useCreateComment(projectId as string)
  const [newComment, setNewComment] = useState<string>('')
  const [, setIsCreatingNewComment] = useState<boolean>(false)
  const { policyValidation } = usePolicyValidation()
  const { t } = useTranslation()

  const { track, debouncedTrack } = useTrackEvent({
    trackOnMount: {
      eventName: 'projects - comments',
      metadata: {
        projectId,
      },
    },
  })

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: () => {
      track({
        eventName: 'projects - comments - previous page',
        metadata: {
          projectId,
        },
      })
      fetchNextPage()
    },
    rootMargin: '400px 0px 0px 0px',
  })
  const scrollableRootRef = useRef<HTMLDivElement | null>(null)
  const lastScrollDistanceToBottomRef = useRef<number>()

  const rootRefSetter = useCallback(
    (node: HTMLDivElement) => {
      rootRef(node)
      scrollableRootRef.current = node
    },
    [rootRef]
  )

  const handleRootScroll = React.useCallback(() => {
    const rootNode = scrollableRootRef.current
    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom
    }
  }, [])

  const createnewComment = async (comment: string) => {
    try {
      setNewComment('')
      setIsCreatingNewComment(true)
      await useCreate.mutateAsync({ projectId, comment } as createCommentProps)
      await refetch()

      track({
        eventName: 'projects - comments - send message',
        metadata: {
          comment,
          projectId,
        },
      })

      setIsCreatingNewComment(false)
    } catch {
      setIsCreatingNewComment(false)
    }
  }

  const handleSubmitSaveNewComment: React.FormEventHandler<
    HTMLFormElement
  > = async (e) => {
    e.preventDefault()
    const isValid = !!newComment
    if (!isValid) {
      return
    }
    return createnewComment(newComment)
  }

  useLayoutEffect(() => {
    const scrollableRoot = scrollableRootRef.current
    const lastScrollDistanceToBottom =
      lastScrollDistanceToBottomRef.current ?? 0

    if (scrollableRoot) {
      scrollableRoot.scrollTop =
        scrollableRoot.scrollHeight - lastScrollDistanceToBottom
    }
  }, [data?.pages, rootRef])

  return (
    <Box className="bg-neutral-03 h-full flex flex-col overflow-hidden -mx-6 -mb-6">
      <Box
        className="flex-grow-1 flex-shrink-1 overflow-y-auto px-10 py-6 flex flex-col items-start"
        ref={rootRefSetter}
        onScroll={handleRootScroll}
      >
        {(isUsersLoading ||
          isCommentsLoading ||
          isFetchingNextPage ||
          hasNextPage) && (
          <Box className="space-y-9" ref={sentryRef}>
            <PlaceholderLoader className="h-16 w-56 rounded-md shadow-xs" />
            <PlaceholderLoader className="h-16 w-56 rounded-md shadow-xs" />
            <PlaceholderLoader className="h-16 w-56 rounded-md shadow-xs" />
          </Box>
        )}

        {!isUsersLoading &&
          !isCommentsLoading &&
          data?.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group?.map((comment) => (
                <CommentCard
                  email={comment.author}
                  time={comment.created_at}
                  key={comment.id}
                  className="my-3"
                >
                  {comment.message}
                </CommentCard>
              ))}
            </React.Fragment>
          ))}
      </Box>

      {policyValidation('projects_comments.write') && (
        <Box
          as="form"
          onSubmit={handleSubmitSaveNewComment}
          className="flex items-end bg-neutral-01 p-6 shadow-md flex-grow-0 flex-shrink-0"
        >
          <AutoResizeTextArea
            value={newComment}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
              const comment = (e.target as HTMLTextAreaElement).value

              debouncedTrack({
                eventName: 'projects - comments - write',
                metadata: {
                  comment,
                  projectId,
                },
              })
              setNewComment(comment)
            }}
            placeholder={t('projects.comments.new_comment')}
          />
          <Button type="submit">{t('send')}</Button>
        </Box>
      )}
    </Box>
  )
}

export default ProjectComments
