import { holdingType } from 'features/shared/types/holdingType'
import { fetcher } from 'utils/fetcher'

export type createHoldingProps = Omit<holdingType, 'id' | 'tenant' | 'code'>

async function createHolding(holdingData: createHoldingProps) {
  return await fetcher.post('/crm-clients/holdings', holdingData)
}

export default createHolding
