import { Box, Icon } from 'lemon-system'
import { MdDragIndicator } from 'react-icons/md'

interface TaskDragButtonProps {
  isDisabled?: boolean
}

const TaskDragButton: React.FC<TaskDragButtonProps> = ({
  isDisabled,
  children,
  ...props
}) => {
  return (
    <Box className="flex-0 h-full flex items-center">
      <Box
        className={`pl-4 pr-2 -mr-4 relative rounded-md text-neutral-08 flex h-full items-center justify-center ${
          isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        {...props}
      >
        <Icon as={MdDragIndicator} size={25} />
      </Box>
    </Box>
  )
}

export default TaskDragButton
