import { useCallback, useState } from 'react'

export function useDisclosure() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = useCallback(() => {
    setIsOpen(true)

    // eslint-disable-next-line
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)

    // eslint-disable-next-line
  }, [])

  const onToggle = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen)

    // eslint-disable-next-line
  }, [])

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}
