import { userType } from 'features/shared/types/userType'
import { fetcher } from 'utils/fetcher'

export type createUserProps = Pick<userType, 'name' | 'email' | 'role'>

async function createUser(userData: createUserProps) {
  return await fetcher.post('/landlord/users', userData)
}

export default createUser
