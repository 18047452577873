import { fetcher } from 'utils/fetcher'
import { projectHistoryType } from '../types/projectHistoryType'

async function getProjectHistory(projectId?: string, lastDate?: string) {
  return await fetcher.get<projectHistoryType[]>(
    `/bookkeeper/projects/${projectId}${lastDate ? '/' + lastDate : ''}`
  )
}

export default getProjectHistory
