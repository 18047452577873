import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { policiesType } from 'features/shared/types/policiesType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IndexRouteProps,
  LayoutRouteProps,
  Navigate,
  Outlet,
  PathRouteProps,
} from 'react-router-dom'

type ReactRouteProps = PathRouteProps | LayoutRouteProps | IndexRouteProps
type ProtectedRouteProps = ReactRouteProps & {
  redirect?: string
  allowedPolicy: policiesType
  showSnackbarMsg?: boolean
  children: any
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirect = '/',
  allowedPolicy,
  showSnackbarMsg = true,
  children,
}) => {
  const { policyValidation } = usePolicyValidation()
  const { t } = useTranslation()
  const validated = policyValidation(allowedPolicy)
  const snackbar = useSnackbar()

  useEffect(() => {
    if (!validated && showSnackbarMsg) {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('module_not_allowed'),
        actionContent: defaultActionContent,
      })
    }
  }, [snackbar, t, validated, showSnackbarMsg])

  if (validated) return children ? children : <Outlet />

  return <Navigate to={redirect} replace />
}

export default ProtectedRoute
