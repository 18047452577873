import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { downloadFileFromBinary } from 'utils/downloadFileFromBinary'
import downloadFileById from '../services/downloadFileById'

function useDownloadFile() {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(downloadFileById, {
    onSuccess: (res) => {
      const fileName = res.headers['content-disposition'].split('=')[1]

      downloadFileFromBinary(res.data, fileName)
    },

    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useDownloadFile
