import { format, isValid, parseISO } from 'date-fns'

const parseProjectHistoryChanges = (change: any) => {
  const parsedDate = parseISO(change)

  if (isValid(parsedDate)) return format(parsedDate, 'dd/MM/yyyy - HH:mm:ss')

  if (change === null) return 'null'

  return change.toString()
}

export default parseProjectHistoryChanges
