import { fetcher } from 'utils/fetcher'

export type createMilestoneProps = {
  projectId: string
  name: string
  deadline: Date | null
  assignees: string[] | null
}

async function createMilestone({
  projectId,
  name,
  deadline,
  assignees,
}: createMilestoneProps) {
  const result = await fetcher.post(
    `/crm-clients/projects/${projectId}/milestones`,
    {
      name,
      deadline,
      assignees,
    }
  )

  return result
}

export default createMilestone
