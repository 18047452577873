import { contactType } from './../types/contactType'
import { fetcher } from 'utils/fetcher'

async function getContactsFromClientId(id: string) {
  const contacts = await fetcher.get<contactType[]>(
    `/crm-clients/clients/${id}/contacts`
  )

  return contacts
}

export default getContactsFromClientId
