import useDeleteMilestone from 'features/projects/hooks/useDeleteMilestone'
import { useDisclosure } from 'hooks/useDisclosure'
import useTrackEvent from 'hooks/useTrackEvent'
import { Button, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdDeleteOutline } from 'react-icons/md'
import DeleteResource from '../DeleteResource'

interface DeleteMilestoneProps {
  projectId: string
  milestoneId: string
}

const DeleteMilestone: React.FC<DeleteMilestoneProps> = ({
  projectId,
  milestoneId,
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const { mutateAsync, isLoading } = useDeleteMilestone(projectId)
  const { t } = useTranslation()
  const { track } = useTrackEvent()

  const handleClickConfirm = async () => {
    await mutateAsync({
      projectId,
      milestoneId,
    })

    track({
      eventName: 'projects - work - milestones - delete - done',
      metadata: {
        projectId,
      },
    })

    onClose()
  }

  return (
    <>
      <Button
        size="xs"
        variant="outlined"
        style={{
          minWidth: 'fit-content',
        }}
        onClick={() => {
          track({
            eventName: 'projects - work - milestones - delete',
            metadata: {
              projectId,
            },
          })
          onOpen()
        }}
      >
        <Icon as={MdDeleteOutline} />
      </Button>

      <DeleteResource
        title={t('projects.delete_milestone')}
        onClose={() => {
          track({
            eventName: 'projects - work - milestones - delete - cancel',
            metadata: {
              projectId,
            },
          })
          onClose()
        }}
        onConfirm={handleClickConfirm}
        isLoading={isLoading}
        isOpen={isOpen}
      />
    </>
  )
}

export default DeleteMilestone
