import { Box } from 'lemon-system'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

interface TabProps {
  isActive?: boolean
  onClick?: () => void
  to: string
}

interface TabsProps {
  className?: string
}

const Tabs: React.FC<TabsProps> = ({ children, className = '' }) => {
  return (
    <Box className={`border-b-2 flex border-secondary-01 ${className}`}>
      {children}
    </Box>
  )
}

export const Tab: React.FC<TabProps> = ({
  to,
  children,
  onClick,
  isActive,
}) => {
  const resolved = useResolvedPath(to)
  const matched = useMatch({ path: resolved.pathname, end: true })
  const isTabActive = isActive === undefined ? matched : isActive

  const isActiveClassName = isTabActive
    ? 'text-primary-01 border-primary'
    : 'text-secondary-01 border-secondary-01'

  return (
    <Box
      as={Link}
      to={to}
      className={`flex items-center justify-center font-semibold text-sm h-9 px-4 min-w-22 flex-grow-0 rounded-t-sm flex-shrink-0 border-b-2 -mb-0.5 hover:bg-02 focus:outline-none ${isActiveClassName}`}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export default Tabs
