import { Confirm } from 'components/Dialog'
import useSavePoliciesFromRole from 'features/roles/hooks/useSavePoliciesFromRole'
import { rolesQueryKey } from 'features/shared/hooks/useRoles'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

const DeleteRole: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { roleName } = useParams()
  const savePolicies = useSavePoliciesFromRole()
  const isDisableDelete = !roleName || roleName === 'default'

  const handleDelete = async () => {
    if (isDisableDelete) return

    await savePolicies.mutateAsync({
      role: roleName,
      policies: [],
    })

    await queryClient.invalidateQueries(rolesQueryKey)

    track({
      eventName: 'roles - delete - done',
      metadata: {
        roleName,
      },
    })

    navigate(`./`)
  }

  const { track } = useTrackEvent()

  if (isDisableDelete) return null

  return (
    <Confirm
      title={t('roles.delete_role.title')}
      onConfirm={handleDelete}
      onCancel={() => {
        track({
          eventName: 'roles - delete - cancel',
          metadata: {
            roleName,
          },
        })
      }}
      onOpen={() => {
        track({
          eventName: 'roles - delete',
          metadata: {
            roleName,
          },
        })
      }}
      isLoading={savePolicies.isLoading}
      button={<Button isDisabled={isDisableDelete}>{t('delete')}</Button>}
      body={
        <Box
          as="span"
          dangerouslySetInnerHTML={{
            __html: t('roles.delete_role.confirm', {
              role: roleName,
            }),
          }}
        />
      }
    />
  )
}

export default DeleteRole
