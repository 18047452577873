import { Box, Button, Icon } from 'lemon-system'
import { useState } from 'react'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'

export interface PaginationProps {
  pageCount: number
  onPagination: (newIndex: number) => void
}

const Projects: React.FC<PaginationProps> = ({ pageCount, onPagination }) => {
  const [currentPaginationIndex, setCurrentPaginationIndex] = useState(1)

  return (
    <Box className="w-full">
      <Box className="flex mt-6 mb-4 justify-center">
        <Box className="justify-center flex ml-1">
          <Box
            as={Button}
            color="primary"
            size="xs"
            variant="outlined"
            onClick={async () => {
              const minLeft = 1
              const humanNewCurrentPage = currentPaginationIndex - 1

              if (currentPaginationIndex > minLeft) {
                await onPagination(humanNewCurrentPage)
                setCurrentPaginationIndex(humanNewCurrentPage)
              }
            }}
          >
            <Icon as={MdArrowBackIos}></Icon>
          </Box>
        </Box>

        {[...Array(pageCount)].map((x, index) => (
          <Box key={index + 1} className="justify-center flex ml-1">
            <Box
              as={Button}
              color="primary"
              size="xs"
              {...(currentPaginationIndex !== index + 1 && {
                variant: 'outlined',
              })}
              onClick={() => {
                setCurrentPaginationIndex(index + 1)
                onPagination(index + 1)
              }}
            >
              {index + 1}
            </Box>
          </Box>
        ))}

        <Box className="justify-center flex ml-1">
          <Box
            as={Button}
            color="primary"
            size="xs"
            variant="outlined"
            onClick={async () => {
              const maxRight = pageCount
              const humanNewCurrentPage = currentPaginationIndex + 1

              if (currentPaginationIndex < maxRight) {
                await onPagination(humanNewCurrentPage)
                setCurrentPaginationIndex(humanNewCurrentPage)
              }
            }}
          >
            <Icon as={MdArrowForwardIos}></Icon>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Projects
