import { funnelType } from './../types/funnelType'
import { useOutletContext } from 'react-router-dom'
import useProjects from './useProjects'
import { useEffect, useMemo, useState } from 'react'
import { projectType } from 'features/shared/types/projectType'
import { usePrevious } from 'hooks/usePrevious'

type funnelContextType = {
  funnel: funnelType
  animateFunnelCards?: boolean
  onCardDragged: () => void
  stepToScroll: number
  refetchFunnel: () => void
  includeInactives: boolean
}

type stepWithProjectType = {
  name: string
  rate?: number | null
  projects: projectType[]
}

type funnelWithProjectsType = Omit<funnelType, 'steps'> & {
  steps: stepWithProjectType[]
}

type projectsType = {
  [index: string]: projectType
}

export type funnelFiltersType = {
  clients: string[]
  owners: string[]
  areas: string[]
  categories: string[]
}

function useFunnel() {
  const [filters, setFilters] = useState<funnelFiltersType>({
    clients: [],
    owners: [],
    areas: [],
    categories: [],
  })
  const funnelContext = useOutletContext<funnelContextType>()
  const {
    data: projects,
    isLoading,
    refetch: refetchProjects,
  } = useProjects(funnelContext.funnel?.id)

  const projectsList = useMemo(
    () =>
      projects?.reduce(
        (objProjects, project) => ({ ...objProjects, [project.id]: project }),
        {} as projectsType
      ),
    [projects]
  )

  const areFiltersEmpty =
    !filters.clients.length &&
    !filters.areas.length &&
    !filters.categories.length &&
    !filters.owners.length

  // Memo projects IDS from the current funnel.
  const projectIDS = useMemo(() => {
    return funnelContext.funnel?.steps
      .map((step) => step.projects)
      .reduce((acum, p) => [...acum, ...p], [])
  }, [funnelContext.funnel])
  const prevProjectIDS = usePrevious(projectIDS)

  useEffect(() => {
    if (prevProjectIDS) {
      refetchProjects()
    }
  }, [projectIDS, refetchProjects, prevProjectIDS])

  const parseProjects = (projectsIds: string[]) =>
    projectsIds.map((projectId) => projectsList?.[projectId]) as projectType[]

  const filterProjects = (projectsToFilter: projectType[]) =>
    projectsToFilter.filter((project) => {
      const isClientInFilters = filters.clients.includes(project.client_id)
      const isOwnerInFilters =
        project?.owner && filters.owners.includes(project.owner)
      const isAreaInFilters =
        project?.area?.id && filters.areas.includes(project.area.id)
      const isCategoryInFilters =
        project?.category?.id &&
        filters.categories.includes(project.category.id)

      return (
        !project ||
        isClientInFilters ||
        isOwnerInFilters ||
        isAreaInFilters ||
        isCategoryInFilters
      )
    })

  const funnelData = useMemo(() => {
    const funnel = funnelContext.funnel

    if (!projectsList)
      return {
        ...funnel,
        steps: [] as stepWithProjectType[],
      }

    const steps = funnel?.steps.map((step) => ({
      ...step,
      projects: areFiltersEmpty
        ? parseProjects(step.projects)
        : filterProjects(parseProjects(step.projects)),
    }))

    return {
      ...funnel,
      steps,
    }

    // eslint-disable-next-line
  }, [projectsList, funnelContext.funnel, filters, areFiltersEmpty])

  const getProjectRelativePosition = (position: number, step: number) => {
    if (areFiltersEmpty) return position

    const currentProjectId = funnelData.steps[step]?.projects[position]?.id
    const prevProjectId = funnelData.steps[step]?.projects[position - 1]?.id

    console.log({ currentProjectId, prevProjectId })

    if (!prevProjectId && !currentProjectId) return 0

    const currentPosition = funnelContext.funnel.steps[
      step
    ]?.projects?.findIndex((projectId) => projectId === currentProjectId)

    const prevPosition = funnelContext.funnel.steps[step]?.projects?.findIndex(
      (projectId) => projectId === prevProjectId
    )

    console.log({ currentPosition, prevPosition })

    if (currentProjectId) return currentPosition

    return prevPosition + 1
  }

  return {
    ...funnelContext,
    isLoading: isLoading || !funnelData,
    funnel: funnelData as funnelWithProjectsType,
    handleFilter: setFilters,
    getProjectRelativePosition,
    projects,
  }
}

export default useFunnel
