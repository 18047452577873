import MotionBox from 'components/MotionBox'
import { AnimatePresence } from 'framer-motion'
import { Box, BoxProps } from 'lemon-system'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { createPortal } from 'react-dom'

type modalSizesType = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface ModalManagerProps {
  onClose: () => void
  isOpen: boolean
  size?: modalSizesType
  isCentered?: boolean
  closeOnOverlayClick?: boolean
  closeOnEsc?: boolean
}

const modalSizes: { [size in modalSizesType]: string } = {
  xs: '310px',
  sm: '500px',
  md: '700px',
  lg: '900px',
  xl: '1000px',
}

const ModalManagerContext = createContext<ModalManagerProps | undefined>(
  undefined
)

export function useModalManager() {
  return useContext(ModalManagerContext) as ModalManagerProps
}

export const ModalManager: React.FC<ModalManagerProps> = ({
  isOpen,
  onClose,
  children,
  size,
  isCentered = false,
  closeOnOverlayClick = true,
  closeOnEsc = true,
}) => {
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (closeOnEsc && isOpen)
      document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [closeOnEsc, isOpen, handleKeyDown])

  useEffect(() => {
    document
      .querySelector('body')
      ?.setAttribute('style', isOpen ? 'overflow: hidden' : 'overflow: visible')

    return () => document.querySelector('body')?.removeAttribute('style')
  }, [isOpen])

  return createPortal(
    <ModalManagerContext.Provider
      value={{
        isOpen,
        onClose,
        size,
        isCentered,
        closeOnOverlayClick,
        closeOnEsc,
      }}
    >
      <AnimatePresence>{isOpen && <div>{children}</div>}</AnimatePresence>
    </ModalManagerContext.Provider>,
    document.querySelector('body') as HTMLElement
  )
}

export const ModalManagerOverlay: React.FC = () => (
  <MotionBox
    key="overlay"
    initial={{ opacity: 0 }}
    animate={{ opacity: 0.6 }}
    exit={{ opacity: 0 }}
    className="fixed left-0 top-0 w-screen h-screen z-modal bg-neutral-09 opacity-60"
  />
)

export const ModalManagerContent: <E extends React.ElementType = 'div'>(
  props: BoxProps<E>
) => React.ReactElement | null = ({ children, as, ...props }) => {
  const {
    size = 'md',
    isCentered,
    onClose,
    closeOnOverlayClick,
  } = useModalManager()

  const mouseDownTarget = useRef<EventTarget | null>(null)
  const overlayRef = useRef<HTMLDivElement>(null)

  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    mouseDownTarget.current = e.target
  }

  const handleClickOverlay: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    const isDraggingToOutside = mouseDownTarget.current !== e.target
    const isNotOverlayElement = e.target !== overlayRef.current

    if (!closeOnOverlayClick || isDraggingToOutside || isNotOverlayElement)
      return

    onClose()
  }

  return (
    <Box
      className={`flex w-screen h-screen fixed left-0 top-0 justify-center ${
        isCentered ? 'items-center' : 'items-start'
      } overflow-auto z-modal`}
      onClick={handleClickOverlay}
      onMouseDown={onMouseDown}
      ref={overlayRef}
    >
      <MotionBox
        key="modal"
        initial={{ translateY: -20, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        exit={{ translateY: -20, opacity: 0 }}
        className={`w-full relative z-modal shadow-lg bg-neutral-01 flex-0 rounded-md w-full max-w-[${modalSizes[size]}] my-9 mx-12`}
        as={as}
        {...props}
      >
        {children}
      </MotionBox>
    </Box>
  )
}
