import { ThemeProvider } from 'lemon-system'
import ReactQueryProvider from './reactQuery/ReactQueryProvider'
import './i18n'
import { mixInit } from './mixPanel'

mixInit()

const ConfigsWrapper: React.FC = ({ children }) => {
  return (
    <ReactQueryProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </ReactQueryProvider>
  )
}

export default ConfigsWrapper
