import RSSelectType from 'react-select/dist/declarations/src/Select'
import { SelectProps, SelectSizes } from 'components/Select'
import SelectIcon from 'components/SelectIcon'
import { forwardRef } from 'react'
import { GroupBase } from 'react-select'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { areaType } from 'features/shared/types/areaType'
import { useQuery } from 'react-query'
import PlaceholderLoader from 'components/PlaceholderLoader'
import Avatar from 'components/Avatar'
import { categoriesQueryKey } from 'features/categories/views/Categories'
import getCategories from 'features/shared/services/getCategories'

const sizeStyles: { [key in SelectSizes]: string } = {
  xs: 'h-8',
  sm: 'h-9',
  md: 'h-10',
  lg: 'h-12',
}

const SelectCategory = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  Omit<SelectProps, 'options'>
>(({ size = 'sm', ...props }, ref) => {
  const { policyValidation } = usePolicyValidation()
  const { isLoading, data: categories } = useQuery<areaType[]>(
    categoriesQueryKey,
    getCategories,
    { enabled: policyValidation('categories.read') }
  )

  if (isLoading)
    return (
      <PlaceholderLoader className={`w-full rounded-sm ${sizeStyles[size]}`} />
    )

  const options = categories?.map((area) => ({
    label: area.name,
    value: area.id,
    icon: <Avatar id={area.id} name={area.name} size={5} />,
    iconSelected: <Avatar id={area.id} name={area.name} size={5} />,
  }))

  return <SelectIcon size={size} ref={ref} options={options} {...props} />
})

export default SelectCategory

