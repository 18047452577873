import { Box, Icon } from 'lemon-system'
import { ElementType } from 'react'

interface TaskCellHeaderProps {
  icon: ElementType<any>
}

const TaskCellHeader: React.FC<TaskCellHeaderProps> = ({ icon, children }) => {
  return (
    <Box className="font-semibold text-md text-neutral-08 text-center mb-2">
      <Icon as={icon} className="mr-1" />
      {children}
    </Box>
  )
}

export default TaskCellHeader
