import FloatingButton from 'components/FloatingButton'
import { Modal } from 'components/Modal'
import { useDisclosure } from 'hooks/useDisclosure'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdNoteAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import readInputFile from 'utils/readInputFile'
import { uniqId } from 'utils/uniqId'
import UploadFile from '../UploadFile'

type fileType = {
  file: string
  fileName: string
  id: string
}

const AddFile: React.FC = () => {
  const { t } = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [isDragOver, setIsDragOver] = useState(false)
  const [files, setFiles] = useState<fileType[]>([])
  const { projectId } = useParams()

  const { track } = useTrackEvent()

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const file = e.target.files?.[0] as File
    const base64File = (await readInputFile(file)) as string

    setFiles((prevFiles) => [
      ...prevFiles,
      {
        id: uniqId(),
        fileName: file.name,
        file: base64File?.split(',')[1],
      },
    ])

    setIsDragOver(false)
    e.target.value = ''
  }

  useEffect(() => {
    setFiles([])
  }, [isOpen])

  return (
    <>
      <FloatingButton
        size={18}
        icon={MdNoteAdd}
        className="bg-primary-01"
        onClick={() => {
          track({
            eventName: 'projects - documents - upload',
            metadata: {
              projectId,
            },
          })
          onOpen()
        }}
      />

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        size="sm"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>{t('projects.documents.upload_file')}</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <Box as="label" className="relative block cursor-pointer">
              <Box
                className={`relative rounded-md text-sm text-center text-neutral-08 font-semibold border-[1.5px] border-dashed px-4 py-6 ${
                  isDragOver
                    ? 'bg-info-02 border-info-01'
                    : 'bg-02 border-neutral-08'
                }`}
              >
                <Box className="flex flex-col pointer-events-none">
                  <Box>{t('projects.documents.drag_and_drop_here')}</Box>
                  <Box className="my-1">{t('projects.documents.or')}</Box>
                  <Box
                    className={isDragOver ? 'text-info-01' : 'text-neutral-07'}
                  >
                    {t('projects.documents.browse_here')}
                  </Box>
                </Box>
              </Box>
              <Box
                onDragEnter={() => setIsDragOver(true)}
                onDragLeave={() => setIsDragOver(false)}
                onChange={handleChange}
                as="input"
                type="file"
                className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
              />
            </Box>

            {!!files.length && (
              <>
                <Box
                  as="h3"
                  className="text-sm text-neutral-07 font-semibold mt-6"
                >
                  {t('projects.documents.uploaded_files')}
                </Box>
                <Box className="my-2 space-y-2">
                  {files.map((file) => (
                    <UploadFile
                      key={file.id}
                      projectId={projectId as string}
                      file={file.file}
                      fileName={file.fileName}
                    />
                  ))}
                </Box>
              </>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  )
}
export default AddFile
