import { Dialog } from 'components/Modal'
import { Button } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from 'hooks/useDisclosure'
import { ReactNode } from 'react'

interface ConfirmProps {
  isLoading?: boolean
  onConfirm: () => void
  onCancel?: () => void
  onOpen?: () => void
  title: string
  body?: ReactNode
  button?: JSX.Element | null
}
const Confirm: React.FC<ConfirmProps> = ({
  isLoading = false,
  onConfirm,
  onCancel,
  onOpen,
  title,
  body,
  button,
}) => {
  const { t } = useTranslation()
  const { onClose, onOpen: _onOpen, isOpen } = useDisclosure()

  const conditionalOnclose = () => {
    if (isLoading) return

    onCancel?.()
    onClose()
  }

  return (
    <>
      {button && (
        <button.type
          {...button.props}
          onClick={() => {
            onOpen?.()
            _onOpen()
          }}
        />
      )}

      <Dialog isOpen={isOpen} onClose={conditionalOnclose} status="warning">
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Header>{title}</Dialog.Header>
          <Dialog.CloseButton />
          <Dialog.Body>{body}</Dialog.Body>
          <Dialog.Footer>
            <Button
              onClick={conditionalOnclose}
              variant="text"
              color="secondary"
              className="mr-2"
              isDisabled={isLoading}
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={async () => {
                await onConfirm()
                onClose()
              }}
              isDisabled={isLoading}
            >
              {t('confirm')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  )
}

export default Confirm
