import DatePicker from 'components/DatePicker'
import FormControl from 'components/FormControl'
import PlaceholderLoader from 'components/PlaceholderLoader'
import SelectCurrency from 'features/shared/components/SelectCurrency'
import SelectUser from 'features/shared/components/SelectUser'
import { useCurrentTenant } from 'features/shared/hooks/useCurrentTenant'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button, useTheme } from 'lemon-system'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import { downloadFileFromBinary } from 'utils/downloadFileFromBinary'
import getFunnelByUserStatusReport from '../services/getFunnelByUserStatusReport'

const FunnelByUserStatusReport: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation()
  const [isDownloading, setIsDownloading] = useState(false)
  const { isLoading: isLoadingTenantData, data: currentTenant } =
    useCurrentTenant()

  const { getThemeProp: theme } = useTheme()

  const { track } = useTrackEvent({
    trackOnMount: {
      eventName: 'reports - user status',
    },
  })

  const onSubmit = handleSubmit(async ({ year, userEmail, currency }) => {
    setIsDownloading(true)
    const fullYear = year.getFullYear()
    const month = year.getMonth() + 1
    const res = await getFunnelByUserStatusReport(
      fullYear,
      month,
      userEmail,
      currency
    )
    const fileName = res.headers['content-disposition'].split('=')[1]
    setIsDownloading(false)
    await downloadFileFromBinary(res.data, fileName)

    track({
      eventName: 'reports - user status - download',
      metadata: {
        fullYear,
        month,
        userEmail,
        currency,
      },
    })
  })

  return (
    <Box className="flex h-full place-content-center place-items-center">
      <Box as="form" onSubmit={onSubmit} className="flex flex-col w-80">
        <Box className="flex w-full justify-center mb-6">
          <FormControl isInvalid={!!errors.year} className="w-full">
            <FormControl.Label>
              {t('reports.funnel_by_user_status.period')}
            </FormControl.Label>

            <Controller
              name="year"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  className="h-12"
                  selected={field.value}
                  dateFormat="MM/yyyy"
                  size="md"
                  showMonthYearPicker
                />
              )}
            />

            <Box className="absolute">
              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="flex w-full justify-center mb-6">
          <FormControl isInvalid={!!errors.userEmail} className="w-full">
            <FormControl.Label>
              {t('reports.funnel_by_user_status.users')}
            </FormControl.Label>

            <Controller
              name="userEmail"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectUser
                  {...field}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.value)
                  }}
                  size="lg"
                  isClearable
                />
              )}
            />

            <Box className="absolute">
              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="flex w-full justify-center">
          <FormControl isInvalid={!!errors.currency} className="w-full">
            <FormControl.Label>
              {t('reports.funnel_general_status.currency')}
            </FormControl.Label>

            {isLoadingTenantData ? (
              <PlaceholderLoader className="w-full rounded-sm h-12" />
            ) : (
              <Controller
                name="currency"
                control={control}
                rules={{ required: true }}
                defaultValue={currentTenant?.currency}
                render={({ field }) => (
                  <SelectCurrency
                    {...field}
                    onChange={(newValue: any) => field.onChange(newValue.value)}
                    defaultValue={currentTenant?.currency}
                    size="lg"
                  />
                )}
              />
            )}

            <Box className="absolute">
              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="flex justify-center mt-4">
          <Button
            type="submit"
            className="h-12 relative"
            isDisabled={isDownloading}
          >
            {t('download')}
            {isDownloading && (
              <Box className="absolute right-0 -mr-10">
                <TailSpin color={theme('colors.primary.01')} width={30} />
              </Box>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default FunnelByUserStatusReport
