import { projectMilestoneType } from 'features/shared/types/projectType'
import { workTemplates } from 'features/shared/types/workTemplates'
import { fetcher } from 'utils/fetcher'

type createWorkTemplatesProps = {
  name: string | null
  milestones: projectMilestoneType[]
}

async function createWorkTemplates({
  name,
  milestones,
}: createWorkTemplatesProps) {
  return await fetcher.post<workTemplates>(`/work-templates/`, {
    name,
    milestones,
  })
}

export default createWorkTemplates
