import createPersistedState from 'use-persisted-state'
import { resetHeaders, setHeaders } from 'utils/fetcher'

const useToken = createPersistedState('app.auth.token')

function useAuth() {
  const [token, setToken] = useToken(null)

  const onSetToken = (token: string) => {
    setHeaders(token)
    setToken(token)
  }

  const logout = () => {
    resetHeaders()
    setToken(null)
    window.location.reload()
  }

  return {
    token,
    setToken: onSetToken,
    logout,
  }
}

export default useAuth
