import { Route, Routes } from 'react-router-dom'
import routes from 'routes/routing'
import ReportViewHandler from './views/ReportViewHandler'
import Reports from './views/Reports'

const ReportsWrapper: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Reports />}>
        <Route path={routes.reports_detail} element={<ReportViewHandler />} />
      </Route>
    </Routes>
  )
}

export default ReportsWrapper
