import PageLoader from 'components/PageLoader'
import getProjectHistory from 'features/shared/services/getProjectHistory'
import { projectHistoryType } from 'features/shared/types/projectHistoryType'
import { useUsers } from 'features/shared/hooks/useUsers'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import HistoryTable from '../components/HistoryTable'
import { useRef, useState } from 'react'
import useTrackEvent from 'hooks/useTrackEvent'

const ProjectHistory: React.FC = () => {
  const { projectId } = useParams()
  const { isLoading: isProjectHistoryLoading, data: projectHistory } = useQuery<
    projectHistoryType[]
  >(`project-history-${projectId}`, () => getProjectHistory(projectId))
  const { isLoading: isUsersLoading, data: users } = useUsers()
  const [fetchingMore, setFetchingMore] = useState(false)
  const fetchMoreData = useRef(true)

  const { track } = useTrackEvent({
    trackOnMount: {
      eventName: 'projects - history',
      metadata: {
        projectId,
      },
    },
  })

  const handleOnBottom = async () => {
    if (!fetchMoreData.current) return

    setFetchingMore(true)
    const last = projectHistory?.[projectHistory.length - 1]
    const data = await getProjectHistory(projectId, last?.date)

    track({
      eventName: 'projects - history - previous page',
      metadata: {
        projectId,
      },
    })

    if (data.length !== 0) projectHistory?.push(...data)
    else fetchMoreData.current = false

    setFetchingMore(false)
  }

  if (isProjectHistoryLoading || isUsersLoading) return <PageLoader />

  return (
    <HistoryTable
      projectHistory={projectHistory}
      users={users}
      handleOnBottom={handleOnBottom}
      fetchingMore={fetchingMore}
    />
  )
}

export default ProjectHistory
