const filterRowBuilder = (findOnAll: boolean = true, searchProps: [string] = ['name']) => (source: any, search: string) => {
  let filtered = source

  if (!search) return filtered

  const regex = new RegExp(`${search}`, 'gi')
  const properties = findOnAll ? Object.keys((filtered && filtered[0]) || {}) : searchProps
  filtered = filtered?.filter((f: any) => properties.some((prop : string) => regex.test(f[prop] || '')))

  return filtered
}

export default filterRowBuilder
