import PageLoader from 'components/PageLoader'
import Tabs, { Tab } from 'components/Tabs'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { policiesType } from 'features/shared/types/policiesType'
import { Box, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdArrowBack } from 'react-icons/md'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import routes from 'routes/routing'
import useProject from '../hooks/useProject'

type routesType = {
  title: string
  path: string
  allowedPolicy: policiesType
}

export const projectsRoutes: routesType[] = [
  {
    title: 'projects.tabs.tasks',
    path: './',
    allowedPolicy: 'projects_tasks.read',
  },
  {
    title: 'projects.tabs.comments',
    path: routes.project_comments,
    allowedPolicy: 'projects_comments.read',
  },
  {
    title: 'projects.tabs.documents',
    path: routes.projects_documents,
    allowedPolicy: 'projects_documents.read',
  },
  {
    title: 'projects.tabs.history',
    path: routes.project_history,
    allowedPolicy: 'projects_history.read',
  },
  {
    title: 'projects.tabs.properties',
    path: routes.project_properties,
    allowedPolicy: 'projects_properties.read',
  },
]

const ProjectsDetail: React.FC = () => {
  const { t } = useTranslation()
  const { policyValidation } = usePolicyValidation()
  const { projectId } = useParams()
  const { isLoading, data: project } = useProject(projectId as string)
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  if (isLoading) return <PageLoader />

  return (
    <Box className="p-6 flex flex-col h-full">
      <Box className="pb-8 flex items-center">
        <Box
          as="button"
          onClick={goBack}
          className="block mr-2 focus:outline-none text-brand-03"
        >
          <Icon as={MdArrowBack} size={24} />
        </Box>

        <Box
          as="h1"
          className="text-secondary-01 font-semibold text-lg flex items-center"
        >
          {project?.name}
        </Box>
      </Box>

      <Tabs>
        {projectsRoutes
          .filter((route) => policyValidation(route.allowedPolicy))
          .map((route) => (
            <Tab key={route.title} to={route.path}>
              {t(route.title)}
            </Tab>
          ))}
      </Tabs>

      <Outlet context={project} />
    </Box>
  )
}

export default ProjectsDetail
