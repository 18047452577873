import { QueryClient } from 'react-query'
import constants from '../constants'

const retry = 3

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: constants.IS_PRODUCTION && retry,
    },
  },
})

export default queryClient
