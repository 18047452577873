import { useQuery } from 'react-query'
import getAreas from '../services/getAreas'
import { areaType } from '../types/areaType'

const useAreas = (options: any = {}) => {
  const { isLoading, data: areas } = useQuery<areaType[]>(
    'areas',
    getAreas,
    options
  )
  return { isLoading, areas }
}

export default useAreas
