import { contactType } from 'features/clients/types/contactType'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'lemon-system'
import { useMutation, useQueryClient } from 'react-query'
import updateContact from '../services/updateContact'
import { clientContactsQueryKey } from './useClientContacts'
import { defaultActionContent } from 'features/shared/utils'

function useUpdateContact() {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(updateContact, {
    onSuccess: (updatedContact: contactType, variables) => {
      const previousContacts = queryClient.getQueryData([
        clientContactsQueryKey,
        variables.clientId,
      ])

      const updatedContacts = (previousContacts as contactType[]).map(
        (contact) =>
          contact.id === updatedContact.id ? updatedContact : contact
      )

      queryClient.setQueryData(
        [clientContactsQueryKey, variables.clientId],
        updatedContacts
      )

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('clients.success_contact_update'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateContact
