import IconFile from 'components/IconFile'
import MotionBox from 'components/MotionBox'
import useUploadFile from 'features/projects/hooks/useUploadFile'
import splitFileName from 'features/projects/utils/splitFileName'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box } from 'lemon-system'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface UploadFileProps {
  projectId: string
  file: string
  fileName: string
}

const UploadFile: React.FC<UploadFileProps> = ({
  projectId,
  file,
  fileName,
}) => {
  const { t } = useTranslation()
  const { isIdle, isLoading, isSuccess, isError, mutate } =
    useUploadFile(projectId)

  const { track } = useTrackEvent()

  const splittedFile = useMemo(() => {
    return splitFileName(fileName)
  }, [fileName])

  const upload = () => {
    mutate({
      projectId,
      file,
      fileName,
    })

    track({
      eventName: 'projects - documents - upload - done',
      metadata: {
        projectId,
      },
    })
  }

  const handleRetry = () => {
    upload()
  }

  useEffect(() => {
    upload()

    // eslint-disable-next-line
  }, [])

  return (
    <Box className="border border-neutral-02 rounded-md py-2 pl-2 pr-4 flex flex-col relative">
      <Box className="flex">
        <IconFile size={32} className="mr-2" ext={splittedFile.fileExt} />

        <Box className="flex flex-col flex-grow-1 flex-shrink-1 py-1 overflow-hidden items-start">
          <Box className="text-sm font-semibold text-neutral-07 overflow-hidden inline-flex max-w-full">
            <Box className="overflow-ellipsis overflow-hidden whitespace-nowrap flex-1">
              {splittedFile.fileName}
            </Box>
            <Box as="span" className="flex-0">
              {splittedFile.fileExt}
            </Box>
          </Box>
          <Box className="mt-auto flex items-end w-full">
            {isError && (
              <Box className="leading-[18px] text-error-01 text-sm">
                {t('projects.documents.upload_file_error')}
                <Box
                  as="button"
                  className="ml-2 font-bold focus:outline-none"
                  onClick={handleRetry}
                >
                  {t('projects.documents.retry')}
                </Box>
              </Box>
            )}

            {(isLoading || isIdle) && (
              <Box className="rounded-full bg-02 h-2 overflow-hidden relative flex-grow-1 flex-shrink-1">
                <MotionBox
                  className={`absolute -left-40 w-40 h-full top-0 bg-gradient-to-r from-transparent via-primary-01 to-transparent`}
                  initial={{ left: '-40%' }}
                  animate={{ left: '100%' }}
                  transition={{
                    repeat: Infinity,
                    duration: 1,
                  }}
                  exit={{ left: 0 }}
                  key="loading"
                />
              </Box>
            )}
            {isSuccess && (
              <Box className="rounded-full bg-02 h-2 overflow-hidden relative flex-grow-1 flex-shrink-1">
                <MotionBox
                  key="success"
                  className={`absolute left-0 h-full top-0 bg-tertiary-01`}
                  initial={{ width: 0, left: 0 }}
                  animate={{ width: '100%', left: 0 }}
                  exit={{ width: '100%', left: 0 }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UploadFile
