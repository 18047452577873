import { Box, useSnackbar, useTheme } from 'lemon-system'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'

import { defaultActionContent } from 'features/shared/utils'

export interface TableRowProps {
  rowClassName?: string
  isDisabled?: (resource: any) => boolean
  isValid?: (resource: any) => boolean
  resource: any
  resourceName: string
  RowComponent: React.FC<any>
  isNew?: boolean
  onTableEditing: (val: boolean) => void
  onCreate?: (resource: any) => void
  onCancel?: () => void
  onRowClick?: (rowData: any) => void
  dataSource?: any[]
}

const defaultNameProperty = 'name'

const TableRow: React.FC<TableRowProps> = ({
  rowClassName,
  isDisabled,
  isValid,
  resourceName,
  resource,
  RowComponent,
  onTableEditing,
  isNew = false,
  onCreate,
  onCancel,
  onRowClick,
  dataSource,
  ...other
}) => {
  const [isEditing, setIsEditing] = useState(isNew)
  const [isLoading, setIsLoading] = useState(false)
  const firstInputRef = useRef<HTMLInputElement>(null)

  const { getThemeProp: theme } = useTheme()
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  const handleCreate = async (resource: any) => {
    const dataRow =
      isNew &&
      dataSource?.find(
        (row) =>
          row?.[defaultNameProperty]?.toLowerCase() ===
          resource?.[defaultNameProperty]?.toLowerCase()
      )
    if (!!dataRow) {
      return snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('resource_exist_error', {
          name: dataRow.name,
        }),
        actionContent: defaultActionContent,
      })
    }
    setIsLoading(true)
    await onCreate?.(resource)
    setIsLoading(false)
    onTableEditing(false)
    setIsEditing(false)
  }

  const handleCancel = () => {
    onCancel?.()
    onTableEditing(false)
    setIsEditing(false)
  }

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        isNew && onCancel?.()
        onTableEditing(false)
        setIsEditing(false)
        break
      default:
        break
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isEditing) {
      firstInputRef.current?.focus()
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      setIsLoading(false)
    }

    // eslint-disable-next-line
  }, [isEditing])

  return (
    <Box
      as="tr"
      className={`${isEditing ? 'z-[2000]' : 'hover:bg-neutral-02'} ${
        onRowClick ? 'cursor-pointer' : ''
      } relative odd:bg-neutral-01 even:bg-03 text-secondary-01 items-center ${rowClassName}`}
      onClick={() => onRowClick?.(resource)}
    >
      {isLoading && (
        <Box
          as="td"
          colSpan={6}
          className="flex justify-center p-2 absolute w-full h-full opacity-80 bg-neutral-02 z-[1000] top-0 left-0"
        >
          <TailSpin width={44} height={44} color={theme('colors.primary.01')} />
        </Box>
      )}

      {
        <RowComponent
          isEditable={isEditing}
          isDisabled={isDisabled?.(resource)}
          isValid={isValid}
          onCreate={handleCreate}
          onCancel={handleCancel}
          firstInputRef={firstInputRef}
          onEditable={() => {
            onTableEditing(true)
            setIsEditing(true)
          }}
          onLoading={(val: boolean) => setIsLoading(val)}
          {...{ [resourceName]: resource }}
          {...other}
        />
      }
    </Box>
  )
}

export default TableRow
