import { Box, Button, Icon } from 'lemon-system'
import {
  MdCheck,
  MdClose,
  MdDeleteOutline,
  MdOutlineEdit,
} from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { RefObject, useEffect, useState } from 'react'

import Avatar from 'components/Avatar'

import { holdingType } from 'features/shared/types/holdingType'
import { Confirm } from 'components/Dialog'
import useTrackEvent from 'hooks/useTrackEvent'

interface HoldingRowProps {
  holding: holdingType
  isNew: boolean
  isEditable: boolean
  onEdit?: (key: string, value: string) => void
  onCreate?: (holding: holdingType) => void
  onEditable: () => void
  onCancelEdit?: () => void
  onCancel?: () => void
  isDisabled?: boolean
  onDeleteHolding?: (holding: holdingType) => void
  newIsValid?: boolean
  firstInputRef?: RefObject<HTMLInputElement> | null
  isLoading?: boolean
  canUpdateDelete: boolean
}

const HoldingRow: React.FC<HoldingRowProps> = ({
  holding,
  isEditable = false,
  onCreate,
  onEditable,
  onEdit,
  onCancel,
  isDisabled = false,
  onDeleteHolding,
  firstInputRef,
  isLoading,
  canUpdateDelete,
}) => {
  const { t } = useTranslation()
  const [holdingEditable, setHoldingEditable] = useState<holdingType>(holding)
  const [isValid, setIsValid] = useState(false)

  const handleHoldingEditable = (key: string, value: string) =>
    setHoldingEditable({ ...holdingEditable, [key]: value })

  const { track } = useTrackEvent()

  useEffect(() => {
    setHoldingEditable(holding)

    // eslint-disable-next-line
  }, [isEditable])

  useEffect(() => {
    setIsValid(!!(holdingEditable.name?.trim() || '').length)
  }, [holdingEditable.name])

  return (
    <>
      <Box as="td" className="w-1/3 flex justify-center">
        <Avatar
          size={7}
          id={holding.id}
          name={holdingEditable.name}
          className={'inline-flex'}
        />
      </Box>
      <Box as="td" className={`text-left w-1/3`}>
        {isEditable ? (
          <Box
            as="input"
            value={holdingEditable.name || ''}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleHoldingEditable?.(
                'name',
                (e.target as HTMLInputElement).value
              )
            }
            placeholder={t('holdings.edit_fields.name')}
            className="border-b border-transparent focus:(border-b outline-none) w-full"
            ref={firstInputRef}
            disabled={isLoading}
            required
          />
        ) : (
          holding.name
        )}
      </Box>
      <Box as="td" className="w-1/3">
        {isEditable ? (
          <Box className="text-center">
            <Button
              size="xs"
              className="mr-2"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                onCancel?.()
                track({
                  eventName: `holdings - ${
                    holding.id ? 'edit' : 'create'
                  } - cancel`,
                  ...(holding.id && {
                    metadata: {
                      holding: holdingEditable,
                    },
                  }),
                })
              }}
              variant="outlined"
            >
              <Icon as={MdClose} />
            </Button>

            <Button
              size="xs"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                onCreate?.(holdingEditable)
                track({
                  eventName: `holdings - ${
                    holding.id ? 'edit' : 'create'
                  } - done`,
                  ...(holding.id && {
                    metadata: {
                      holding: holdingEditable,
                    },
                  }),
                })
              }}
              variant="outlined"
              isDisabled={!isValid}
              type="submit"
            >
              <Icon as={MdCheck} />
            </Button>
          </Box>
        ) : (
          <Box as="td" className="w-3/12">
            <Box className="flex justify-center">
              {canUpdateDelete && (
                <>
                  <Button
                    size="xs"
                    style={{
                      minWidth: 'fit-content',
                    }}
                    onClick={() => {
                      onEditable()
                      track({
                        eventName: 'holdings - edit',
                        metadata: {
                          holding,
                        },
                      })
                    }}
                    variant="outlined"
                    isDisabled={isDisabled}
                  >
                    <Icon as={MdOutlineEdit} />
                  </Button>
                  <Confirm
                    title={`Eliminar Holding`}
                    onConfirm={async () => {
                      await onDeleteHolding?.(holding)
                      track({
                        eventName: 'holdings - delete - done',
                        metadata: {
                          holding,
                        },
                      })
                    }}
                    isLoading={isLoading}
                    onOpen={() => {
                      track({
                        eventName: 'holdings - delete',
                        metadata: {
                          holding,
                        },
                      })
                    }}
                    onCancel={() => {
                      track({
                        eventName: 'holdings - delete - cancel',
                        metadata: {
                          holding,
                        },
                      })
                    }}
                    button={
                      <Button
                        size="xs"
                        className="ml-2"
                        color="primary"
                        style={{
                          minWidth: 'fit-content',
                          opacity: 100,
                        }}
                        variant="outlined"
                      >
                        <Icon as={MdDeleteOutline} />
                      </Button>
                    }
                    body={t('generic_short_confirm_message')}
                  />
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default HoldingRow
