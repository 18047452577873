import { Box } from 'lemon-system'
import { projectSearchResultType } from '../types/projectSearchResultType'
import Avatar from 'components/Avatar'
import { format, parseISO } from 'date-fns'
import { userType } from 'features/shared/types/userType'
import { clientsType } from 'features/shared/types/clientsType'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ProjectAreaLabel from 'components/ProjectAreaLabel'
import ProjectCategoryLabel from 'components/ProjectCategoryLabel'

export interface ProjectViewResultProps {
  usersData: userType[]
  clients: clientsType[]
  projects: projectSearchResultType[]
}

const ProjectsViewResult: React.FC<ProjectViewResultProps> = ({
  usersData,
  clients,
  projects,
  ...other
}) => {
  const { t } = useTranslation()

  return (
    <>
      {projects?.map((pro) => {
        const currentUser = usersData?.find((u) => u.email === pro.owner)
        const currenClient = clients?.find((cli) => cli.id === pro.client_id)
        return (
          <Box
            key={pro.id}
            as={Link}
            to={`/project/${pro.id}`}
            className="flex flex-col border-1 rounded-md w-full mt-6"
          >
            <Box className="w-full bg-neutral-01 rounded-md shadow-xs p-4 mr-4 flex items-center">
              {/* project highlights */}
              <Box className="flex flex-wrap w-1/5 text-info-01 items-center text-lg ml-2">
                {pro.search?.highlights?.texts?.map((chunk) =>
                  chunk.type === 'hit' ? (
                    <Box
                      as="span"
                      className="bg-warning-02 font-semibold p-1 mx-1 rounded-sm"
                    >
                      {chunk?.value}
                    </Box>
                  ) : (
                    chunk?.value
                  )
                )}
                {!pro.search && pro?.name}
              </Box>

              {/* Client */}
              <Box className="flex items-center text-sm font-bold w-1/5">
                <Avatar
                  size={9}
                  name={currenClient?.name}
                  id={currenClient?.id}
                />
                <Box className="ml-2">{currenClient?.name}</Box>
              </Box>

              {/* Area and Categories */}
              <Box className="flex flex-col mx-2 w-1/5">
                <ProjectAreaLabel area={pro.area} className="mb-2" />
                <ProjectCategoryLabel category={pro.category} />
              </Box>

              {/* Dates */}
              <Box className="flex flex-col w-1/5">
                <Box className="bg-02 rounded-sm w-full px-2">
                  <Box className="flex w-full text-xs font-bold p-2">
                    <Box as="span" className="w-1/3 flex">
                      {t('projects.results.created_at')}:
                    </Box>
                    <Box as="span" className="w-2/3 flex justify-center">
                      {pro.created_at
                        ? format(parseISO(pro.created_at || ''), 'dd/MM/yyyy')
                        : '--/--/----'}
                    </Box>
                  </Box>

                  <Box className="bg-neutral-05 w-full h-px" />

                  <Box className="flex w-full text-xs font-bold p-2">
                    <Box as="span" className="w-1/3 flex">
                      {t('projects.results.pipeline_completed_at')}:
                    </Box>
                    <Box as="span" className="w-2/3 flex justify-center">
                      {pro.pipeline?.completed_at
                        ? format(
                            parseISO(pro.pipeline?.completed_at || ''),
                            'dd/MM/yyyy'
                          )
                        : '--/--/----'}
                    </Box>
                  </Box>

                  <Box className="bg-neutral-05 w-full h-px" />

                  <Box className="flex w-full text-xs font-bold p-2">
                    <Box as="span" className="w-1/3 flex">
                      {t('projects.results.completed_at')}:
                    </Box>
                    <Box as="span" className="w-2/3 flex justify-center">
                      {pro.completed_at
                        ? format(parseISO(pro.completed_at || ''), 'dd/MM/yyyy')
                        : '--/--/----'}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Owner */}
              <Box className="flex justify-center w-1/5">
                {currentUser && (
                  <>
                    <Avatar
                      src={currentUser?.avatar}
                      size={9}
                      name={currentUser?.name}
                      id={currentUser?.id}
                    />

                    <Box className="flex ml-2 items-center font-bold text-xs">
                      {currentUser?.name}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )
      })}
    </>
  )
}

export default ProjectsViewResult
