import { clientsType } from 'features/shared/types/clientsType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import updateFavouriteContact from '../services/updateFavouriteContact'

const useUpdateFavouriteContact = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(updateFavouriteContact, {
    onSuccess: (result: clientsType, updatedClient) => {
      const isAddFavourite = result.favourite !== null;

      if (isAddFavourite) {
        snackbar.add({
          duration: 5000,
          status: 'success',
          content: t('clients.success_add_contact_favourite'),
          actionContent: defaultActionContent,
        })
      }
      else {
        snackbar.add({
          duration: 5000,
          status: 'success',
          content: t('clients.success_delete_contact_favourite'),
          actionContent: defaultActionContent,
        })
      }

    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateFavouriteContact
