import queryClient from 'configs/reactQuery/queryClient'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import getFilterededProjects from '../services/getFilterededProjects'
import { projectSearchResponseType } from '../types/projectSearchResultType'
export const projectsResultQueryKey = 'projectsFilteredResult'

function useFilteredProjects() {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(getFilterededProjects, {
    onSuccess: (inputResponse: projectSearchResponseType) => {
      queryClient.setQueryData(projectsResultQueryKey, inputResponse)
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })

}

export default useFilteredProjects
