import MotionBox from 'components/MotionBox'
import Tooltip from 'components/Tooltip'
import { AnimatePresence } from 'framer-motion'
import { Box, Icon } from 'lemon-system'
import { useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'

interface TooltipErrorsProps {
  error: string | undefined
}

const TooltipErrors: React.FC<TooltipErrorsProps> = ({ error }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )

  return (
    <AnimatePresence>
      {error && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
          }}
        >
          <Box
            ref={setReferenceElement as (element: HTMLElement | null) => void}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className="flex"
          >
            <Icon as={MdErrorOutline} className="text-error-01" size={18} />
          </Box>
          <Tooltip
            classname="bg-error-02"
            isOpen={showTooltip}
            referenceElement={referenceElement}
          >
            <Box
              as="span"
              className="flex text-sm justify-center text-error-01"
            >
              {error}
            </Box>
          </Tooltip>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}

export default TooltipErrors
