import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import removeContact from '../services/removeContact'
import { contactType } from '../types/contactType'
import { clientContactsQueryKey } from './useClientContacts'

function useRemoveContact() {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(removeContact, {
    onSuccess: (_, variables) => {
      const previousContacts = queryClient.getQueryData([
        clientContactsQueryKey,
        variables.clientId,
      ])

      const updatedContacts = (previousContacts as contactType[]).filter(
        (contact) => contact.id !== variables.contactId
      )

      queryClient.setQueryData(
        [clientContactsQueryKey, variables.clientId],
        updatedContacts
      )
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useRemoveContact
