import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import updateMilestone from '../services/updateMilestone'
import { projectQueryKey } from './useProject'

function useUpdateMilestone(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(updateMilestone, {
    onSuccess: () => {
      queryClient.invalidateQueries([projectQueryKey, projectId])
    },

    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateMilestone
