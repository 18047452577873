import { Box, useTheme } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'

const HistoryFooter: React.FC = () => {
  const { t } = useTranslation()
  const { getThemeProp: theme } = useTheme()

  return (
    <Box className="w-full bg-neutral-06">
      <Box className="flex justify-center items-center">
        <Box
          as="span"
          className="flex justify-center items-center mr-4 my-4 text-neutral-01 font-bold"
        >
          {t('loading')}
        </Box>
        <TailSpin width={44} height={44} color={theme('colors.neutral.01')} />
      </Box>
    </Box>
  )
}

export default HistoryFooter
