import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'lemon-system'
import { useQuery } from 'react-query'
import { policiesGroupType } from '../types/policiesGroupType'
import getPolicies from '../services/getPolicies'
import { defaultActionContent } from 'features/shared/utils'

const policiesQueryKey = 'policies'

function usePolicies() {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useQuery<policiesGroupType[]>(policiesQueryKey, getPolicies, {
    onError: () =>
      snackbar.add({
        duration: 3000,
        content: t('unexpected_error'),
        status: 'error',
        actionContent: defaultActionContent,
      }),
  })
}

export default usePolicies
