import { Box } from 'lemon-system'
import TableRow from 'components/TableRow'
import Overlay from 'components/Overlay'
import { useEffect, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import MotionBox from 'components/MotionBox'
import TableHeader, { TableHeaderProps } from 'components/TableHeader'

export interface TableProps {
  headers: TableHeaderProps[]
  sortItemsBy?: { field: string; asc: boolean }
  rowClassName?: string
  rowComponent: React.FC<any>
  FooterComponent?: React.FC<any>
  EmptyComponent?: any // with React.FC<any> throws error.
  tableClassName?: string
  newResource?: any
  resources: any[]
  isCreating: boolean
  resourceName: string
  onBottom?: () => void
  [x: string]: any
}

const Table: React.FC<TableProps> = ({
  headers,
  sortItemsBy,
  rowClassName,
  tableClassName,
  resources,
  isCreating,
  rowComponent,
  FooterComponent,
  EmptyComponent,
  newResource,
  resourceName,
  onBottom,
  ...other
}) => {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
  }

  const handleScroll = () => {
    if (bodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = bodyRef.current

      if (scrollTop + clientHeight >= scrollHeight - 1) {
        onBottom?.()
      }
    }
  }

  const getNestedValue = (field: string, data: object) =>
    (field.split('.').reduce((p: any, c: any) => p?.[c], data) as string) || ''

  const sort = (field: string, isAsc: boolean) => {
    if (isAsc)
      return (a: any, b: any) => {
        const itemA = getNestedValue(field, a)?.toLowerCase()
        const itemB = getNestedValue(field, b)?.toLowerCase()

        return itemA > itemB ? 1 : -1
      }

    return (a: any, b: any) => {
      const itemA = getNestedValue(field, a)?.toLowerCase()
      const itemB = getNestedValue(field, b)?.toLowerCase()

      return itemA > itemB ? -1 : 1
    }
  }

  const handleHeaderSort = (field?: string) => {
    if (!field) return

    const sortDirection = field !== sortBy.field ? true : !sortBy.asc
    const sortered = items.sort(sort(field, sortDirection))

    setSortBy({ field, asc: sortDirection })
    setItems([...sortered])
  }

  useEffect(() => setItems(resources), [resources])

  const [items, setItems] = useState(resources)
  const [sortBy, setSortBy] = useState(sortItemsBy || { field: '', asc: true })
  const [isTableEditing, setIsTableEditing] = useState(false)
  const bodyRef = useRef(null)

  return (
    <>
      <Overlay isOpen={isCreating || isTableEditing} />

      <Box as="form" onSubmit={handleSubmit}>
        <Box
          as="table"
          className={`table-fixed w-full max-w-screen-lg max-w-screen-xl ${tableClassName}`}
        >
          <Box as="thead" className="bg-neutral-05 w-full">
            <Box as="tr" className="w-full">
              {headers.map((h, idx) => (
                <TableHeader
                  {...h}
                  key={`TableHeader-${idx}`}
                  sortAsc={h.fieldName === sortBy.field && sortBy.asc}
                  hidden={h.fieldName !== sortBy.field}
                  onClick={() => handleHeaderSort(h.fieldName)}
                />
              ))}
            </Box>
          </Box>

          <Box as="tbody">
            <Box as="tr">
              <Box as="td" colSpan={headers.length}>
                <Box
                  className="overflow-y-scroll"
                  style={{ maxHeight: '50vh' }}
                  ref={bodyRef}
                  onScroll={handleScroll}
                >
                  <Box as="table" className="w-full">
                    <Box as="tbody">
                      {isCreating && (
                        <TableRow
                          isNew
                          onTableEditing={(val) => setIsTableEditing(val)}
                          RowComponent={rowComponent}
                          resource={newResource}
                          resourceName={resourceName}
                          rowClassName={rowClassName}
                          dataSource={items}
                          {...other}
                        />
                      )}
                      {items.length !== 0
                        ? items.map((r, idx) => (
                          <TableRow
                            onTableEditing={(val) => setIsTableEditing(val)}
                            RowComponent={rowComponent}
                            resource={r}
                            resourceName={resourceName}
                            key={r.id}
                            rowClassName={rowClassName}
                            dataSource={items}
                            {...other}
                          />
                        ))
                        : EmptyComponent && <EmptyComponent />}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <AnimatePresence>
          {FooterComponent && (
            <MotionBox
              key="footer"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Box className="w-full flex max-w-screen-lg max-w-screen-xl">
                {<FooterComponent />}
              </Box>
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>
    </>
  )
}

export default Table
