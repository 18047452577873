import { Box } from 'lemon-system'
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'

interface DroppableMilestonesProps {
  isDisabledDroppable: boolean
  placeholderProps: {
    [index: string]: number
  } | null
  className?: string
}

const DroppableMilestones: React.FC<DroppableMilestonesProps> = ({
  children,
  placeholderProps,
  isDisabledDroppable,
  className,
}) => {
  return (
    <Droppable droppableId="milestones" type="milestones">
      {(
        droppableProvided: DroppableProvided,
        droppableProvidedSnapshot: DroppableStateSnapshot
      ) => (
        <Box
          className={`mt-10 relative ${className}`}
          {...droppableProvided.droppableProps}
          ref={droppableProvided.innerRef}
        >
          {children}
          {droppableProvided.placeholder}
          {placeholderProps && droppableProvidedSnapshot.isDraggingOver && (
            <Box
              className={`border-dotted border-2 rounded-md m-0.5 absolute ${
                isDisabledDroppable
                  ? 'bg-error-02 border-error-01'
                  : 'bg-info-02 border-info-01'
              }`}
              style={{
                top: placeholderProps.clientY,
                left: placeholderProps.clientX,
                height: placeholderProps.clientHeight,
                width: placeholderProps.clientWidth,
              }}
            />
          )}
        </Box>
      )}
    </Droppable>
  )
}

export default DroppableMilestones
