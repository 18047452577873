import i18n from 'i18next'

import { useState } from 'react'

import ClientRow from './ClientRow'
import useCreateClient from '../hooks/useCreateClient'
import useHoldings from 'features/shared/hooks/useHoldings'
import Table from 'components/Table'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useActivateClient from '../hooks/useActivateClient'
import useInactivateClient from '../hooks/useInactivateClient'
import useUpdateClient from '../hooks/useUpdateClient'

import { clientsType } from 'features/shared/types/clientsType'
import { createClientProps } from '../services/createClient'
import { updateClientProps } from '../services/updateClient'

interface ClientsTableProps {
  isCreating: boolean
  clients: clientsType[] | undefined
  onCancel: () => void
  onCreated: () => void
}

const defaultClient = {
  id: null,
  tenant: null,
  name: null,
  holding: null,
  active: true,
}

const headers = [
  { className: 'w-1/12' },
  {
    title: i18n.t('clients.name'),
    className: 'py-2 text-neutral-01 text-left w-3/12',
    sortable: true,
    fieldName: 'name',
  },
  {
    title: i18n.t('clients.holding'),
    className: 'py-2 text-neutral-01 text-left w-3/12',
    sortable: true,
    fieldName: 'holding.name',
  },
  {
    title: i18n.t('clients.contact'),
    className: 'py-2 text-neutral-01 text-left w-3/12',
    sortable: true,
    fieldName: 'favourite.name',
  },
  { className: 'py-2 text-neutral-01 text-left w-5/12' },
]

const ClientsTable: React.FC<ClientsTableProps> = ({
  isCreating,
  clients = [],
  onCancel,
  onCreated,
}) => {
  const [newClient, setNewClient] = useState<clientsType>(defaultClient)
  const { isLoading, mutateAsync } = useCreateClient()
  const { mutateAsync: updateMutateAsync } = useUpdateClient()
  const { mutateAsync: activateMutateAsync } = useActivateClient()
  const { mutateAsync: inactivateMutateAsync } = useInactivateClient()
  const { policyValidation } = usePolicyValidation()
  const { isLoading: isHoldingsLoading, holdings } = useHoldings({
    refetchOnMount: false,
    enabled: policyValidation('holdings.read'),
  })
  const clientDataParsed = (client: clientsType) => {
    const { id, tenant, holding, ...rest } = client
    return { ...rest, ...(client.holding && { holding_id: client.holding.id }) } as createClientProps
  }

  const handleIsDisabled = (client: clientsType) => !client.active

  const handleCreateUpdateClient = async (client: clientsType) => {
    try {
      client.id
        ? await updateMutateAsync({ id: client.id, name: client.name, holding_id: client.holding?.id } as updateClientProps)
        : await mutateAsync(clientDataParsed(client))
      onCreated()
      setNewClient(defaultClient)
    } catch {
      onCancel()
    }
  }

  const handleClientInactivation = async (active: boolean, client: clientsType) =>
    active ? await activateMutateAsync(client) : await inactivateMutateAsync(client)

  return (
    <Table
      headers={headers}
      sortItemsBy={{ field: 'name', asc: true }}
      isCreating={isCreating}
      newResource={newClient}
      resourceName="client"
      resources={clients}
      rowComponent={ClientRow}
      rowClassName="py-2 flex"
      isDisabled={handleIsDisabled}
      holdings={holdings}
      isHoldingsLoading={isHoldingsLoading}
      isLoading={isLoading}
      onCancel={onCancel}
      onCreate={handleCreateUpdateClient}
      onActivateClient={handleClientInactivation}
    />
  )
}

export default ClientsTable
