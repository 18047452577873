import { useTranslation } from 'react-i18next'
import useAuth from 'features/shared/hooks/useAuth'
import { useSnackbar } from 'lemon-system'
import { useMutation } from 'react-query'
import login from '../services/login'
import { useState } from 'react'
import { defaultActionContent } from 'features/shared/utils'

function useLogin() {
  const { setToken } = useAuth()
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const [mustUpdatePass, setMustUpdatePass] = useState(false)

  return {
    ...useMutation(login, {
      onSuccess: (data) => {
        data.http_status === 204
          ? setMustUpdatePass(true)
          : setToken(data.token)
      },
      onError: () => {
        snackbar.add({
          content: t('signin_error'),
          status: 'error',
          duration: 3000,
          actionContent: defaultActionContent,
        })
      },
    }),
    mustUpdatePass,
  }
}

export default useLogin
