import Avatar from 'components/Avatar'
import PlaceholderLoader from 'components/PlaceholderLoader'
import { SelectProps, SelectSizes } from 'components/Select'
import SelectIcon from 'components/SelectIcon'
import { clientsQueryKey } from 'features/clients/views/Clients'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import getClients from 'features/shared/services/getClients'
import { clientsType } from 'features/shared/types/clientsType'
import { forwardRef } from 'react'
import { useQuery } from 'react-query'
import { GroupBase } from 'react-select'
import RSSelectType from 'react-select/dist/declarations/src/Select'

const sizeStyles: { [key in SelectSizes]: string } = {
  xs: 'h-8',
  sm: 'h-9',
  md: 'h-10',
  lg: 'h-12',
}

const SelectClient = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  Omit<SelectProps, 'options'>
>(({ size = 'sm', ...props }, ref) => {
  const { policyValidation } = usePolicyValidation()
  const { isLoading, data: clients } = useQuery<clientsType[]>(
    clientsQueryKey,
    getClients,
    { enabled: policyValidation('clients.read') }
  )

  if (isLoading)
    return (
      <PlaceholderLoader className={`w-full rounded-sm ${sizeStyles[size]}`} />
    )

  const options = clients?.map((client) => ({
    label: client.name,
    value: client.id,
    icon: <Avatar id={client.id} name={client.name} size={5} />,
    iconSelected: <Avatar id={client.id} name={client.name} size={7} />,
  }))

  return <SelectIcon size={size} {...props} ref={ref} options={options} />
})

export default SelectClient
