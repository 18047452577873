import { Route, Routes } from 'react-router-dom'
import routes from 'routes/routing'
import Funnels from './views/Funnels'
import FunnelDetail from './views/FunnelDetail'

const FunnelsWrapper: React.FC = () => {
  return (
    <Routes>
      <Route element={<Funnels />}>
        <Route index element={<FunnelDetail />} />
        <Route path={routes.funnels_detail} element={<FunnelDetail />} />
      </Route>
    </Routes>
  )
}

export default FunnelsWrapper
