import { workTemplates } from 'features/shared/types/workTemplates'
import { fetcher } from 'utils/fetcher'

async function deleteWorkTemplates(workTemplates: workTemplates) {
  return await fetcher.delete<workTemplates>(
    `/work-templates/${workTemplates?.id}`
  )
}

export default deleteWorkTemplates
