import MotionBox from 'components/MotionBox'
import { funnelStepType } from 'features/funnels/types/funnelType'
import { useAnimation } from 'framer-motion'
import { usePrevious } from 'hooks/usePrevious'
import { Box } from 'lemon-system'
import { Link } from 'react-router-dom'
import { deleteColorTransition } from 'utils/deleteColorTransition'
import { newUpdateColorTransition } from 'utils/newUpdateColorTransition'

interface ProjectCountProps {
  step: funnelStepType
  stepIdx: number
  funnelId: string
}

const ProjectCount: React.FC<ProjectCountProps> = ({
  step,
  stepIdx,
  funnelId,
}) => {
  const currentCount = step.projects.length
  const prevCount = usePrevious(currentCount)
  const countControl = useAnimation()
  const cardControl = useAnimation()

  if (prevCount !== undefined && prevCount !== currentCount) {
    countControl.start({ scale: [1, 2, 1] })
    cardControl.start({
      backgroundColor:
        currentCount < prevCount
          ? deleteColorTransition
          : newUpdateColorTransition(),
    })
  }

  return (
    <MotionBox
      className="mr-4 w-72 flex-grow-0 flex-shrink-0 bg-neutral-01 rounded-md border border-neutral-02 flex flex-col justify-start"
      animate={cardControl}
    >
      <Box
        as={Link}
        to={`funnels/${funnelId}?step=${stepIdx}`}
        className="text-neutral-07"
      >
        <Box className="font-semibold text-neutral-07 text-sm px-3 pt-3">
          {step.name}
        </Box>
        <Box className="flex justify-center p-4">
          <MotionBox
            anitial={{ scale: 1 }}
            animate={countControl}
            exit={{ scale: 1 }}
          >
            <Box as="span" className="text-4xl">
              {currentCount}
            </Box>
          </MotionBox>
        </Box>
      </Box>
    </MotionBox>
  )
}

export default ProjectCount
