const routes = {
  dashboard: '/',
  clients: 'clients/*',
  client_detail: ':clientId',
  client_detail_contacts: 'contacts',
  roles: 'roles/*',
  role_detail: ':roleName',
  users: 'users',
  funnels: 'funnels/*',
  funnels_detail: ':funnelId',
  holdings: 'holdings/*',
  projects: '/projects',
  project_detail: 'project/*',
  projects_documents: 'documents',
  project_history: 'history',
  project_properties: 'properties',
  areas: 'areas/*',
  categories: 'categories/*',
  user_profile: 'profile',
  reports: 'reports/*',
  reports_detail: ':reportName',
  project_comments: 'comments',
  work_templates: 'work_templates',
}

export default routes
