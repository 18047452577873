import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import getRoles from '../services/getRoles'
import { roleType } from '../types/roleType'
import { defaultActionContent } from '../utils'

export const rolesQueryKey = 'roles'

const useRoles = (options: any = {}) => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  const { data: roles, ...rest } = useQuery<roleType>(rolesQueryKey, getRoles, {
    ...options,
    onError: () =>
      snackbar.add({
        duration: 3000,
        content: t('unexpected_error'),
        status: 'error',
        actionContent: defaultActionContent,
      }),
  })
  return { roles, ...rest }
}

export default useRoles
