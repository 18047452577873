import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import updateArea from '../services/updateArea'

import { areaType } from 'features/shared/types/areaType'
import { defaultActionContent } from '../../shared/utils'

const useUpdateArea = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(updateArea, {
    onSuccess: (updatedArea: areaType) => {
      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('areas.success_update'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateArea
