import { policiesGroupType } from '../types/policiesGroupType'
import { fetcher } from 'utils/fetcher'

async function getPolicies() {
  const policies = await fetcher.get<policiesGroupType[]>('/landlord/policies')

  return policies
}

export default getPolicies
