import { areaType } from 'features/shared/types/areaType'
import { fetcher } from 'utils/fetcher'

export type updateAreaProps = Omit<areaType, 'tenant'>

async function updateArea(areaData: updateAreaProps) {
  return await fetcher.put(`/crm-clients/areas/${areaData.id}`, areaData)
}

export default updateArea
