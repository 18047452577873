import { Box, useTheme } from 'lemon-system'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
  DropResult,
  DragStart,
} from 'react-beautiful-dnd'
import routes from 'routes/routing'
import FunnelCard from 'features/shared/components/FunnelCard'
import useFunnel from '../hooks/useFunnel'
import useReorderProjects from '../hooks/useReorderProjects'
import PlaceholderLoader from 'components/PlaceholderLoader'
import { projectType } from '../../shared/types/projectType'
import { AnimatePresence, useAnimation } from 'framer-motion'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'
import { Link } from 'react-router-dom'
import { useLinkCleaner } from 'hooks/useLinkCleaner'
import { useTranslation } from 'react-i18next'
import FunnelFilters from '../components/FunnelFilters'
import { useQuery } from 'react-query'
import { clientsType } from 'features/shared/types/clientsType'
import { clientsQueryKey } from 'features/clients/views/Clients'
import getClients from 'features/shared/services/getClients'
import MotionBox from 'components/MotionBox'
import { newUpdateColorTransition } from 'utils/newUpdateColorTransition'

type clientType = {
  [index: string]: clientsType
}

const FunnelDetail: React.FC = () => {
  const { t } = useTranslation()
  const {
    funnel,
    animateFunnelCards,
    onCardDragged,
    isLoading,
    handleFilter,
    getProjectRelativePosition,
    stepToScroll,
    projects,
    refetchFunnel,
    includeInactives,
  } = useFunnel()
  const [isLastColumnItemDragged, setIsLastColumnItemDragged] = useState(false)
  const stepsRef = useRef<Array<HTMLDivElement | null>>([])
  const stepScrolledControl = useAnimation()
  const { policyValidation } = usePolicyValidation()
  const { getThemeProp: theme } = useTheme()

  const { data: clients } = useQuery<clientsType[]>(
    clientsQueryKey,
    getClients,
    { enabled: policyValidation('clients.read') }
  )
  const reorderProjects = useReorderProjects(includeInactives)
  const [useAnimations, setUseAnimations] = useState(animateFunnelCards)
  const canFunnelWrite = useMemo(
    () => policyValidation('projects.write'),

    // eslint-disable-next-line
    []
  )
  const projectLink = useLinkCleaner(
    `${routes.projects}?funnel_id=${funnel?.id}&sort_by=graduated_at&sort_order=desc&funnel_status=true`
  )

  const { track } = useTrackEvent()

  const projectsClient = useMemo(
    () =>
      projects?.reduce((objProjects: any, project: projectType) => {
        return {
          ...objProjects,
          [project.id]: clients?.find((c) => c.id === project.client_id),
        }
      }, {} as clientType),
    [projects, clients]
  )

  useEffect(() => {
    setUseAnimations(animateFunnelCards)
  }, [animateFunnelCards])

  useEffect(() => {
    stepsRef.current = stepsRef.current.slice(0, funnel?.steps.length)
  }, [funnel?.steps.length])

  useEffect(() => {
    const lastDiv = stepsRef.current[stepToScroll]
    lastDiv?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    stepScrolledControl.start({
      backgroundColor: newUpdateColorTransition('#f0f2f3'), // bg-02,
    })
  }, [stepToScroll, stepScrolledControl, isLoading])

  const handleDragEnd = (result: DropResult) => {
    setIsLastColumnItemDragged(false)

    const { source, destination } = result
    const isOutsideTheList = !destination
    const isSamePlace =
      source.index === destination?.index &&
      source.droppableId === destination?.droppableId

    if (isOutsideTheList || isSamePlace) {
      track({
        eventName: 'funnels - drag - cancel',
        metadata: {
          funnelId: funnel.id,
        },
      })
      return
    }

    const prevStep = +source.droppableId
    const prevPosition = source.index

    const newStep = +destination.droppableId
    const newPosition = destination.index

    const projectId = funnel.steps[prevStep].projects[prevPosition].id

    reorderProjects.mutate({
      funnelId: funnel.id,
      projectId,
      step: newStep,
      position: getProjectRelativePosition(newPosition, newStep),
    })

    track({
      eventName: 'funnels - drag - done',
      metadata: {
        funnelId: funnel.id,
      },
    })
    onCardDragged()
  }
  const lastStepIndex = funnel?.steps.length - 1

  const handleDragStart = (result: DragStart) => {
    track({
      eventName: 'funnels - drag',
      metadata: {
        funnelId: funnel.id,
      },
    })
    setIsLastColumnItemDragged(+result.source.droppableId === lastStepIndex)
  }

  const handleProjectDeleted = (updatedProject: projectType) => refetchFunnel()

  if (isLoading)
    return (
      <Box className="flex overflow-x-auto flex-grow-1 flex-shrink-1 mt-8 pb-4 items-start">
        <PlaceholderLoader className="mx-2 w-72 flex-grow-0 flex-shrink-0 rounded-md h-60" />
        <PlaceholderLoader className="mx-2 w-72 flex-grow-0 flex-shrink-0 rounded-md h-60" />
        <PlaceholderLoader className="mx-2 w-72 flex-grow-0 flex-shrink-0 rounded-md h-60" />
      </Box>
    )

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <FunnelFilters onChange={handleFilter} />
      <Box className="flex overflow-auto flex-grow-1 flex-shrink-1 mt-8 items-start">
        <Box className="pb-4 flex">
          {funnel?.steps.map((step, stepIndex) => {
            const control =
              stepToScroll === stepIndex ? stepScrolledControl : null

            return (
              <MotionBox
                className="mx-2 w-72 flex-grow-0 flex-shrink-0 bg-02 rounded-md flex flex-col justify-start"
                key={`funnel-${funnel.id}-step-${stepIndex}-${step.name}`}
                ref={(el: HTMLDivElement) => (stepsRef.current[stepIndex] = el)}
                animate={control}
                transition={{
                  duration: 1,
                  repeat: 1,
                }}
              >
                <MotionBox
                  animate={control}
                  transition={{
                    duration: 1,
                    repeat: 1,
                  }}
                  className="font-semibold text-neutral-07 text-sm py-1 p-3 bg-02 rounded-t-md top-0 sticky z-docked"
                >
                  {step.name}
                </MotionBox>
                <Droppable
                  droppableId={`${stepIndex}`}
                  isDropDisabled={
                    stepIndex !== lastStepIndex && isLastColumnItemDragged
                  }
                >
                  {(
                    droppableProvided: DroppableProvided,
                    droppableSnapshot: DroppableStateSnapshot
                  ) => (
                    <Box
                      className={`flex flex-col p-3 flex-grow-1 rounded-b-md ${
                        droppableSnapshot.isDraggingOver ? 'bg-01' : ''
                      }`}
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                      style={{
                        minHeight: theme('spacing.20'),
                      }}
                      data-testid={`step-${stepIndex}`}
                    >
                      <AnimatePresence initial={false}>
                        {step.projects.map((project, index) => {
                          if (!project) return null

                          return (
                            <Draggable
                              key={`${step.name}-${project.id}`}
                              draggableId={`${step.name}-${project.id}`}
                              index={index}
                              isDragDisabled={!canFunnelWrite}
                            >
                              {(
                                draggableProvided: DraggableProvided,
                                draggableSnapshot: DraggableStateSnapshot
                              ) => (
                                <FunnelCard
                                  isDragging={draggableSnapshot.isDragging}
                                  data-testid={project.id}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  ref={draggableProvided.innerRef}
                                  useAnimations={useAnimations}
                                  client={projectsClient?.[project.id]}
                                  project={project}
                                  onUpdate={handleProjectDeleted}
                                />
                              )}
                            </Draggable>
                          )
                        })}
                      </AnimatePresence>

                      {droppableProvided.placeholder}

                      {stepIndex === funnel?.steps.length - 1 && (
                        <Box
                          as={Link}
                          to={projectLink}
                          className="flex justify-center"
                        >
                          {t('funnels.see_more_projects')}
                        </Box>
                      )}
                    </Box>
                  )}
                </Droppable>
              </MotionBox>
            )
          })}
        </Box>
      </Box>
    </DragDropContext>
  )
}

export default FunnelDetail
