import { fetcher } from 'utils/fetcher'

type reorderProjectsByFunnelIdProps = {
  projectId: string
  step: number
  position: number
  funnelId: string
}

async function reorderProjectsByFunnelId({
  projectId,
  step,
  funnelId,
  position,
}: reorderProjectsByFunnelIdProps) {
  const response = await fetcher.put(
    `/crm-clients/funnels/${funnelId}/projects`,
    {
      project: projectId,
      position,
      step,
    }
  )

  return response
}

export default reorderProjectsByFunnelId
