import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import routes from './routing'
import NotFound from './NotFound'
import Dashboard from 'features/dashboard'
import ClientWrapper from 'features/clients'
import Layout from './components/Layout'
import Login from 'features/login'
import RolesWrapper from 'features/roles'
import Users from 'features/users'
import FunnelsWrapper from 'features/funnels'
import HoldingsWrapper from 'features/holdings'
import ProjectsWrapper from 'features/projects'
import AreasWrapper from 'features/areas'
import CategoriesWrapper from 'features/categories'
import { useUser } from 'features/shared/state/user/userContext'
import UserProfile from 'features/userProfile'
import ReportsWrapper from 'features/reports'
import ProtectedRoute from 'features/shared/components/ProtectedRoute'
import Projects from 'features/projects/views/Projects'
import WorkTemplatesWrapper from 'features/workTemplates'
import useInitHotjar from 'hooks/useInitHotjar'
import useInitAppcues from 'hooks/useInitAppcues'

const PrivateApp: React.FC = () => {
  useInitHotjar()
  useInitAppcues()
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route
          path={routes.clients}
          element={
            <ProtectedRoute allowedPolicy="clients.read">
              <ClientWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.roles}
          element={
            <ProtectedRoute allowedPolicy="roles.read">
              <RolesWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.users}
          element={
            <ProtectedRoute allowedPolicy="users.read">
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.funnels}
          element={
            <ProtectedRoute allowedPolicy="funnels.read">
              <FunnelsWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.holdings}
          element={
            <ProtectedRoute allowedPolicy="holdings.read">
              <HoldingsWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.projects}
          element={
            <ProtectedRoute allowedPolicy="projects.read">
              <Projects />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.areas}
          element={
            <ProtectedRoute allowedPolicy="areas.read">
              <AreasWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.categories}
          element={
            <ProtectedRoute allowedPolicy="categories.read">
              <CategoriesWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.project_detail}
          element={
            <ProtectedRoute allowedPolicy="projects.read">
              <ProjectsWrapper />
            </ProtectedRoute>
          }
        />
        <Route path={routes.user_profile} element={<UserProfile />} />
        <Route path={routes.reports} element={<ReportsWrapper />} />
        <Route
          path={routes.work_templates}
          element={<WorkTemplatesWrapper />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

const PublicApp: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
    </Routes>
  )
}

const AppRoutes: React.FC = () => {
  const { user } = useUser()

  return (
    <Router>
      <Routes>
        <Route path="*" element={user ? <PrivateApp /> : <PublicApp />} />
      </Routes>
    </Router>
  )
}

export default AppRoutes
