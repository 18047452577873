import FunnelCard from 'features/shared/components/FunnelCard'
import MotionBox from 'components/MotionBox'
import PlaceholderLoader from 'components/PlaceholderLoader'
import getRecentProjects from 'features/shared/services/getRecentProjects'
import { projectType } from 'features/shared/types/projectType'
import { Box } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { clientsType } from 'features/shared/types/clientsType'
import { clientsQueryKey } from 'features/clients/views/Clients'
import getClients from 'features/shared/services/getClients'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useMemo } from 'react'
import queryClient from 'configs/reactQuery/queryClient'

type clientType = {
  [index: string]: clientsType
}

const projectRecentKey = 'project_recent'

const ProjectRecent: React.FC = () => {
  const { policyValidation } = usePolicyValidation()
  const { t } = useTranslation()
  const { isLoading, data: projects } = useQuery<projectType[]>(
    projectRecentKey,
    getRecentProjects
  )
  const { data: clients } = useQuery<clientsType[]>(
    clientsQueryKey,
    getClients,
    { enabled: policyValidation('clients.read') }
  )

  const projectsClient = useMemo(
    () =>
      projects?.reduce((objProjects: any, project: projectType) => {
        return {
          ...objProjects,
          [project.id]: clients?.find((c) => c.id === project.client_id),
        }
      }, {} as clientType),
    [projects, clients]
  )

  const handleProjectUpdate = (updatedProject: projectType) => {
    const previousProjects = queryClient.getQueryData([
      projectRecentKey,
    ]) as projectType[]

    const updatedProjects = previousProjects.map((project) =>
      project.id === updatedProject.id ? updatedProject : project
    )

    queryClient.setQueryData(projectRecentKey, updatedProjects)
  }

  return (
    <Box className="w-full mb-6">
      <Box as="h1" className="text-secondary-01 font-semibold text-lg">
        {t('dashboard.project_recent.title')}
      </Box>

      <Box className="flex flex-wrap mt-6 overflow-x-auto flex-grow-1 flex-shrink-1">
        {isLoading ? (
          <MotionBox
            key="placeholder-loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box className="flex mr-4 w-72 h-36">
              <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
              <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
              <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
              <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
              <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
            </Box>
          </MotionBox>
        ) : (
          projects?.map((p, i) => (
            <MotionBox
              custom={i}
              animate="visible"
              variants={{
                visible: (i: any) => ({
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: i * 0.15,
                  },
                }),
              }}
              className="flex"
              style={{ x: -30, opacity: 0 }}
              key={`FunnelCard-${p.id}`}
            >
              <Box className="flex mr-4 w-72">
                <FunnelCard
                  className="w-full"
                  client={projectsClient?.[p.id]}
                  project={p}
                  onUpdate={handleProjectUpdate}
                />
              </Box>
            </MotionBox>
          ))
        )}
      </Box>
    </Box>
  )
}

export default ProjectRecent
