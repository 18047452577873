import { fetcher } from 'utils/fetcher'

export type softDeleteFunnelProps = {
  funnelId: string
}

async function softDelete({ funnelId }: softDeleteFunnelProps) {
  const response = await fetcher.delete(
    `/crm-clients/funnels/${funnelId}`
  )

  return response
}

export default softDelete
