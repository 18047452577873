import { holdingType } from 'features/shared/types/holdingType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import createHolding from '../services/createHolding'
import { holdingsQueryKey } from '../views/Holdings'

const useCreateHolding = () => {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(createHolding, {
    onSuccess: (newHolding: holdingType) => {
      const updatedHolding = [
        newHolding,
        ...(queryClient.getQueryData([holdingsQueryKey]) as holdingType[]),
      ]
      queryClient.setQueryData(holdingsQueryKey, updatedHolding)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('holdings.success_create'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useCreateHolding
