import { areaType } from 'features/shared/types/areaType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import createArea from '../services/createArea'
import { areasQueryKey } from '../views/Areas'

const useCreateArea = () => {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(createArea, {
    onSuccess: (newArea: areaType) => {
      const updatedArea = [
        newArea,
        ...(queryClient.getQueryData([areasQueryKey]) as areaType[]),
      ]
      queryClient.setQueryData(areasQueryKey, updatedArea)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('areas.success_create'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useCreateArea
