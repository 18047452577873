import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import createMilestone, {
  createMilestoneProps,
} from '../services/createMilestone'
import { projectQueryKey } from './useProject'

function useCreateMilestone(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const mutation = useMutation(createMilestone, {
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })

  const refetchProject = async () => {
    await queryClient.invalidateQueries([projectQueryKey, projectId])
  }

  const mutateAsync = async (props: createMilestoneProps) => {
    await mutation.mutateAsync(props)
    await refetchProject()
  }

  return { ...mutation, mutateAsync }
}

export default useCreateMilestone
