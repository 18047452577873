import { fetcher } from 'utils/fetcher'
import { projectCommentsType } from '../types/projectCommentsType'

async function getProjectComments(projectId?: string, before?: string) {
  const results = await fetcher.get<projectCommentsType[]>(
    `/crm-clients/projects/${projectId}/comments${before ? `/${before}` : ''}`
  )

  return [...results].reverse()
}

export default getProjectComments
