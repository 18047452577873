import i18n from 'i18next'

import { useState } from 'react'

import HoldingRow from './HoldingRow'
import useHoldings from 'features/shared/hooks/useHoldings'
import Table from 'components/Table'
import useCreateHolding from '../hooks/useCreateHolding'
import useDeleteHolding from '../hooks/useDeleteHolding'
import useUpdateHolding from '../hooks/useUpdateHolding'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'

import { createHoldingProps } from '../services/createHolding'
import { holdingType } from 'features/shared/types/holdingType'
import { deleteHoldingProps } from '../services/deleteHolding'
import { updateHoldingProps } from '../services/updateHolding'

interface HoldingTableProps {
  isCreating: boolean
  holdings: holdingType[] | undefined
  onCancel: () => void
  onCreated: () => void
}

const defaultHolding = {
  id: null,
  tenant: null,
  name: null,
}

const headersDefinition = [
  { className: 'w-1/3' },
  {
    title: i18n.t('holdings.name'),
    className: 'py-2 text-neutral-01 text-left w-1/3',
    sortable: true,
    fieldName: 'name',
  },
  { className: 'py-2 text-neutral-01 text-left w-1/3' },
]

const HoldingsTable: React.FC<HoldingTableProps> = ({
  isCreating,
  holdings = [],
  onCancel,
  onCreated,
}) => {
  useHoldings({ refetchOnMount: false })
  const [newHolding, setNewHolding] = useState<holdingType>(defaultHolding)
  const { isLoading, mutateAsync } = useCreateHolding()
  const { mutateAsync: mutateUpdateAsync } = useUpdateHolding()
  const { mutateAsync: mutateDeleteAsync } = useDeleteHolding()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const { policyValidation } = usePolicyValidation()

  const holdingDataParsed = (holding: holdingType) => {
    const { id, tenant, ...rest } = holding
    return rest as createHoldingProps
  }

  const holdingUpdateDataParsed = (holding: holdingType) => {
    const { tenant, ...rest } = holding
    return rest as updateHoldingProps
  }

  const holdingDataDeleteParsed = (holding: holdingType) => {
    const { tenant, ...rest } = holding
    return rest as deleteHoldingProps
  }

  const handleEditNewHolding = (key: string, value: string) =>
    setNewHolding({ ...newHolding, [key]: value })

  const handleCreateHolding = async (holding: holdingType) => {
    try {
      holding.id
        ? await mutateUpdateAsync(holdingUpdateDataParsed(holding))
        : await mutateAsync(holdingDataParsed(holding))
      onCreated()
      setNewHolding(defaultHolding)
    } catch {
      onCancel()
    }
  }

  const handleDeleteHolding = async (input: holdingType) => {
    try {
      setIsDeleteLoading(true)
      await mutateDeleteAsync(holdingDataDeleteParsed(input))
      onCreated()
      setIsDeleteLoading(false)
    } catch {
      onCancel()
      setIsDeleteLoading(false)
    }
  }

  return (
    <Table
      headers={headersDefinition}
      sortItemsBy={{ field: 'name', asc: true }}
      isCreating={isCreating}
      newResource={newHolding}
      resourceName="holding"
      resources={holdings}
      rowComponent={HoldingRow}
      rowClassName="py-2 flex"
      holdings={holdings}
      isLoading={isLoading || isDeleteLoading}
      onEdit={handleEditNewHolding}
      onCancel={onCancel}
      onCreate={handleCreateHolding}
      onDeleteHolding={handleDeleteHolding}
      canUpdateDelete={policyValidation('holdings.write')}
    />
  )
}

export default HoldingsTable
