import { categoryType } from 'features/shared/types/categoryType'
import { fetcher } from 'utils/fetcher'

export type createCategoryProps = Omit<categoryType, 'id' | 'tenant'>

async function createCategory(categoryData: createCategoryProps) {
  return await fetcher.post('/crm-clients/categories', categoryData)
}

export default createCategory
