import { fetcher } from 'utils/fetcher'
import { clientType } from 'features/shared/types/clientType'

async function getClient(clientId?: string) {
  const client = await fetcher.get<clientType>(
    `crm-clients/clients/${clientId}`
  )
  return client
}

export default getClient
