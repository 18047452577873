import { projectTaskType } from 'features/shared/types/projectType'
import { fetcher } from 'utils/fetcher'

type updateTaskStatusProps = {
  status: boolean
  projectId: string
  milestoneId: string
  taskId: string
}

async function updateTaskStatus({
  projectId,
  status,
  milestoneId,
  taskId,
}: updateTaskStatusProps) {
  const project = await fetcher.patch<projectTaskType>(
    `/crm-clients/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}`,
    {
      status,
    }
  )

  return project
}

export default updateTaskStatus
