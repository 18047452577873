import { fetcher } from 'utils/fetcher'

export type createProjectProps = {
  name: string
  active?: boolean,
  client_id: string,
  pipeline_id: string,
  area_id: string,
  category_id: string
  project_id: string
}


async function createProject(projectData: createProjectProps) {
  const response = await fetcher.post(`/crm-clients/projects`, projectData)

  return response
}

export default createProject
