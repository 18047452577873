import { projectType } from 'features/shared/types/projectType'
import { fetcher } from 'utils/fetcher'

type moveTaskProps = {
  projectId: string
  milestoneIdFrom: string
  milestoneIdTo: string
  taskId: string
  position: number
}

async function moveTask({
  projectId,
  milestoneIdFrom,
  milestoneIdTo,
  taskId,
  position,
}: moveTaskProps) {
  const project = await fetcher.put<projectType>(
    `/crm-clients/projects/${projectId}/tasks`,
    {
      milestone_from: milestoneIdFrom,
      milestone_to: milestoneIdTo,
      task: taskId,
      position: position,
    }
  )

  return project
}

export default moveTask
