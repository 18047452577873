import RSSelectType from 'react-select/dist/declarations/src/Select'
import { SelectProps } from 'components/Select'
import SelectIcon from 'components/SelectIcon'
import { GroupBase } from 'react-select'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

interface SelectSortByProps extends Omit<SelectProps, 'options'> {
  sortingDefault?: string
}

const SelectSortBy = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  SelectSortByProps
>(({ size = 'sm', sortingDefault = '', value, ...props }, ref) => {
  const { t } = useTranslation()
  const defaultValueItems = []
  const valueItems = []
  const options = [
    { label: t('projects.sorting.name'), value: 'name' },
    { label: t('projects.sorting.owner'), value: 'owner' },
    { label: t('projects.sorting.created_at'), value: 'created_at' },
    { label: t('projects.sorting.graduated_at'), value: 'graduated_at' },
    { label: t('projects.sorting.completed_at'), value: 'completed_at' },
    { label: t('projects.sorting.score'), value: 'score' },
  ]

  if (sortingDefault) {
    const found = options.find((o) => o.value === sortingDefault)
    if (found) defaultValueItems.push(found)
  }

  if (value) {
    const found = options.find((o) => o.value === value)
    if (found) valueItems.push(found)
  }

  return (
    <SelectIcon
      size={size}
      ref={ref}
      options={options}
      defaultValue={defaultValueItems}
      value={valueItems}
      {...props}
    />
  )
})

export default SelectSortBy
