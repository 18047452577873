import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import getWorkTemplates from '../services/getWorkTemplates'

export const workTemplatesKey = 'work_templates'

function useWorkTemplates() {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useQuery([workTemplatesKey], getWorkTemplates, {
    onError: () =>
      snackbar.add({
        duration: 3000,
        content: t('unexpected_error'),
        status: 'error',
        actionContent: defaultActionContent,
      }),
  })
}

export default useWorkTemplates
