import { useDisclosure } from 'hooks/useDisclosure'
import { Box, Button, Icon } from 'lemon-system'
import { useEffect } from 'react'
import { MdMenu } from 'react-icons/md'

const MenuButton: React.FC = ({ children }) => {
  const size = 20
  const { onToggle, isOpen } = useDisclosure()

  useEffect(() => {
    const menuElementsIds = ['menu-create-button', 'menu-main', 'menu-main-svg']
    const handleClickOutside = (event: MouseEvent) => {
      const isMenuClick = menuElementsIds.includes((event.target as HTMLElement).id)
      if (!isMenuClick && isOpen) {
        onToggle()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [isOpen])

  return (
    <Box
      className="fixed right-6 bottom-5"
    >
      <Button
        id='menu-main'
        className={`bg-primary-01 override:(rounded-full min-w-0 w-${size} h-${size})`}
        onClick={() => {
          onToggle()
        }}
      >
        <Icon as={MdMenu} size={size * 1.5} id='menu-main-svg' />
      </Button>

      <Box
        className={`absolute bottom-full right-0 flex flex-col items-end space-y-5 mb-5 ${!isOpen ? 'hidden' : ''
          }`}
      >
        {children}
      </Box>
    </Box>
  )
}

export default MenuButton
