import { fetcher } from 'utils/fetcher'

export type serverProjectData = {
  name?: string
  id: string
  active: boolean
  status: boolean
  client_id: string
  area_id: string
  category_id: string
  owner?: string
  worth?: {
    currency?: string | null
    amount?: number | null
    multiplier?: number | null
  }
}

async function updateProject(projectData: serverProjectData) {
  return await fetcher.put(`/crm-clients/projects/${projectData.id}`, projectData)
}

export default updateProject
