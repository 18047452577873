import { Route, Routes } from 'react-router-dom'
import routes from 'routes/routing'
import RoleDetail from './views/RoleDetail'
import Roles from './views/Roles'

const RolesWrapper: React.FC = () => {
  return (
    <Routes>
      <Route element={<Roles />}>
        <Route index element={<RoleDetail />} />
        <Route path={routes.role_detail} element={<RoleDetail />} />
      </Route>
    </Routes>
  )
}

export default RolesWrapper
