import { Confirm } from 'components/Dialog'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button, Icon } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'

interface MenuFunnelButtonProps {
  size: number
  icon: IconType
  onDeleteFunnel?: () => void
  onActivateFunnel?: () => void
  onCreateFunnel?: () => void
  onEditFunnel?: () => void
  onCreateProject?: () => void
  isActiveFunnel?: boolean
  funnelId?: string
}

const MenuFunnelButton: React.FC<MenuFunnelButtonProps> = ({
  size,
  icon,
  onDeleteFunnel,
  onActivateFunnel,
  onCreateFunnel,
  onEditFunnel,
  onCreateProject,
  isActiveFunnel,
  funnelId,
}) => {
  const { policyValidation } = usePolicyValidation()
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [isDisableLoading, setIsDisableLoading] = useState(false)

  const { track } = useTrackEvent()

  const onClick = () => {
    setShow(!show)
  }

  useEffect(() => {
    const menuIdPattern = 'menu-funnels'
    const handleClickOutside = (event: MouseEvent) => {
      const isMenuClick = (event.target as HTMLElement).id.includes(
        menuIdPattern
      )
      if (!isMenuClick && show) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [show])

  return (
    <Box className="fixed right-6 bottom-5">
      <Button
        id="menu-funnels-create-button"
        className={`bg-primary-01 override:(rounded-full min-w-0 w-${size} h-${size})`}
        onClick={onClick}
      >
        <Icon as={icon} size={size * 1.5} id="menu-funnels-svg-create-button" />
      </Button>

      <Box
        className={`absolute bottom-full right-0 flex flex-col items-end space-y-5 mb-5 ${
          !show ? 'hidden' : ''
        }`}
      >
        {policyValidation('funnels.write') && (
          <>
            <Button
              id="menu-funnels-new-button"
              title={t('new')}
              onClick={() => {
                setShow(false)
                onCreateFunnel?.()
              }}
            >
              <Box className="text-xs pointer-events-none">
                {t('funnels.actions_buttons.new')}
              </Box>
            </Button>
            <Button
              id="menu-funnels-edit-button"
              title={t('edit')}
              onClick={() => {
                setShow(false)
                onEditFunnel?.()
              }}
            >
              <Box className="text-xs pointer-events-none">
                {t('funnels.actions_buttons.edit')}
              </Box>
            </Button>
          </>
        )}

        {policyValidation('projects.write') && (
          <Button
            id="menu-funnels-create-new-button"
            title={t('clients.create_project.title')}
            isDisabled={!isActiveFunnel}
            className="w-max"
            onClick={() => {
              setShow(false)
              onCreateProject?.()
            }}
          >
            <Box className="text-xs pointer-events-none">
              {t('clients.create_project.title')}
            </Box>
          </Button>
        )}

        {policyValidation('funnels.write') && (
          <>
            {isActiveFunnel ? (
              <Confirm
                title={t('funnels.disable_funnel')}
                onConfirm={async () => {
                  track({
                    eventName: 'funnels - disable - done',
                    metadata: {
                      funnelId,
                    },
                  })
                  setIsDisableLoading(true)
                  setShow(false)
                  onDeleteFunnel?.()
                  setIsDisableLoading(false)
                }}
                onOpen={() => {
                  track({
                    eventName: 'funnels - disable',
                    metadata: {
                      funnelId,
                    },
                  })
                }}
                onCancel={() => {
                  track({
                    eventName: 'funnels - disable - cancel',
                    metadata: {
                      funnelId,
                    },
                  })
                }}
                isLoading={isDisableLoading}
                button={
                  <Button id="menu-funnels-disable-button" title="Desactivar">
                    <Box className="text-xs pointer-events-none">
                      {t('funnels.actions_buttons.inactivate')}
                    </Box>
                  </Button>
                }
                body={t('generic_short_confirm_message')}
              />
            ) : (
              <Confirm
                title={t('funnels.enable_funnel')}
                onConfirm={async () => {
                  track({
                    eventName: 'funnels - enable - done',
                    metadata: {
                      funnelId,
                    },
                  })
                  setIsDisableLoading(true)
                  setShow(false)
                  onActivateFunnel?.()
                  setIsDisableLoading(false)
                }}
                onOpen={() => {
                  track({
                    eventName: 'funnels - enable',
                    metadata: {
                      funnelId,
                    },
                  })
                }}
                onCancel={() => {
                  track({
                    eventName: 'funnels - enable - cancel',
                    metadata: {
                      funnelId,
                    },
                  })
                }}
                isLoading={isDisableLoading}
                button={
                  <Button id="menu-funnels-enable-button" title="Activar">
                    <Box className="flex text-xs pointer-events-none">
                      {t('funnels.actions_buttons.activate')}
                    </Box>
                  </Button>
                }
                body={t('generic_short_confirm_message')}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default MenuFunnelButton
