import { useUser } from 'features/shared/state/user/userContext'
import constants from 'configs/constants'
import { createContext, useContext, useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'

interface SocketContextProps {
  socket: Socket | null
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined)

export function useSocket() {
  const { socket } = useContext(SocketContext) as SocketContextProps

  return socket
}

export function useSocketEvent(event: string) {
  const { socket } = useContext(SocketContext) as SocketContextProps
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const eventListener = (eventData: any) => {
      setData(eventData)
    }

    socket?.on(event, eventListener)

    return () => {
      socket?.off(event, eventListener)
    }

    // eslint-disable-next-line
  }, [socket])

  return data
}

export const SocketProvider: React.FC = ({ children }) => {
  const environment = 'sandbox'
  const { user } = useUser()
  const [socket, setSocket] = useState<SocketContextProps['socket']>(null)

  useEffect(() => {
    let newSocket: Socket

    if (user) {
      const joinUserCommandName = 'join_user-room'
      newSocket = io(`${constants.API_URL}/${user.tenant}_crm_${environment}`)

      newSocket.emit(joinUserCommandName, user.id)
      setSocket(newSocket)
    }

    return () => {
      if (newSocket) newSocket.disconnect()
    }

    // eslint-disable-next-line
  }, [user])

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  )
}
