import ConfigsWrapper from 'configs/ConfigsWrapper'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import Routes from 'routes'
import AppProvider from 'state'
import constants from 'configs/constants'

const [subdomain] = window.location.hostname.split('.')

Sentry.init({
  dsn: constants.SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
})
Sentry.setTag('tenant', subdomain)

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <ConfigsWrapper>
        <AppProvider>
          <Routes />
        </AppProvider>
      </ConfigsWrapper>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
