import { useTranslation } from 'react-i18next'
import { Box } from 'lemon-system'
import ProjectRecent from '../components/ProjectRecent'
import WorkPending from '../components/WorkPending'
import WorkRecent from '../components/WorkRecent'
import FunnelsSummary from '../components/FunnelsSummary'
import { useUser } from 'features/shared/state/user/userContext'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'

const sections = {
  funnel_summary: { component: FunnelsSummary, allowedPolicy: 'funnels.read' },
  work_recent: { component: WorkRecent, allowedPolicy: 'projects_tasks.read' },
  project_recent: { component: ProjectRecent, allowedPolicy: 'projects.read' },
  work_pending: {
    component: WorkPending,
    allowedPolicy: 'projects_tasks.read',
  },
}

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const {
    user: {
      dashboard: { active },
    },
  } = useUser()
  const { policyValidation } = usePolicyValidation()

  useTrackEvent({
    trackOnMount: {
      eventName: 'dashboard',
    },
  })

  return (
    <Box className="p-6">
      <Box
        as="h1"
        className="text-secondary-01 font-semibold text-lg pb-8"
        data-testid="dashboardTitle"
      >
        {t('dashboard.title')}
      </Box>

      {active.map((a: string) => {
        const { component: Component, allowedPolicy } = (sections as any)[a]

        return policyValidation(allowedPolicy) ? <Component key={a} /> : null
      })}
    </Box>
  )
}

export default Dashboard
