import { holdingType } from 'features/shared/types/holdingType'
import { fetcher } from 'utils/fetcher'

export type deleteHoldingProps = Omit<holdingType, 'tenant' | 'code'>

async function deleteHolding(holdingData: deleteHoldingProps) {
  return await fetcher.delete(`/crm-clients/holdings/${holdingData.id}`)
}

export default deleteHolding
