import queryClient from 'configs/reactQuery/queryClient'
import { workTemplates } from 'features/shared/types/workTemplates'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import createWorkTemplates from '../services/createWorkTemplates'
import { workTemplatesKey } from './useWorkTemplates'

const useCreateWorkTemplates = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(createWorkTemplates, {
    onSuccess: (workTemplate: workTemplates) => {
      const workTemplates = (queryClient.getQueryData(workTemplatesKey) ||
        []) as workTemplates[]

      queryClient.setQueryData(workTemplatesKey, [
        ...workTemplates,
        workTemplate,
      ])

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('work_templates.create_success'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useCreateWorkTemplates
