import { fetcher } from 'utils/fetcher'
import { funnelType } from '../types/funnelType'

async function getFunnels(includeInactives: boolean = false) {
  const funnels = await fetcher.get<funnelType[]>('/crm-clients/funnels', {
    params: { includeInactives },
  })

  return funnels
}

export default getFunnels
