import { parseISO } from 'date-fns'
import useUpdateMilestone from 'features/projects/hooks/useUpdateMilestone'
import { projectMilestoneType } from 'features/shared/types/projectType'
import { useDisclosure } from 'hooks/useDisclosure'
import useTrackEvent from 'hooks/useTrackEvent'
import { Button, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdOutlineEdit } from 'react-icons/md'
import SaveResourceModal, { saveProps } from '../SaveResourceModal'

interface EditMilestoneProps {
  milestone: projectMilestoneType
  projectId: string
}

const EditMilestone: React.FC<EditMilestoneProps> = ({
  milestone,
  projectId,
}) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { mutateAsync, isLoading } = useUpdateMilestone(projectId)
  const { t } = useTranslation()
  const { track } = useTrackEvent()

  const handleSave = async (values: saveProps) => {
    await mutateAsync({
      projectId,
      milestoneId: milestone.id,
      ...values,
    })

    track({
      eventName: 'projects - work - milestones - edit - done',
      metadata: {
        projectId,
      },
    })

    onClose()
  }

  return (
    <>
      <Button
        size="xs"
        variant="outlined"
        style={{
          minWidth: 'fit-content',
        }}
        onClick={() => {
          track({
            eventName: 'projects - work - milestones - edit',
            metadata: {
              projectId,
            },
          })
          onOpen()
        }}
      >
        <Icon as={MdOutlineEdit} />
      </Button>

      <SaveResourceModal
        onClose={() => {
          track({
            eventName: 'projects - work - milestones - edit - cancel',
            metadata: {
              projectId,
            },
          })
          onClose()
        }}
        isOpen={isOpen}
        isLoading={isLoading}
        onSave={handleSave}
        title={t('projects.edit_task')}
        defaultValues={{
          assignees: milestone.assignees,
          deadline: milestone.deadline ? parseISO(milestone.deadline) : null,
          name: milestone.name,
        }}
      />
    </>
  )
}

export default EditMilestone
