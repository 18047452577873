import { Box } from 'lemon-system'
import { forwardRef } from 'react'

interface TaskRowProps extends React.ComponentPropsWithoutRef<'div'> {
  isNested?: boolean
  className?: string
}

const TaskRow = forwardRef<HTMLDivElement, TaskRowProps>(
  ({ isNested, children, className = '', ...props }, ref) => {
    return (
      <Box
        className={`flex items-center border-b border-neutral-02 group ${className} ${
          isNested ? 'pl-8 bg-03 h-18' : 'h-20 bg-neutral-01'
        }`}
        ref={ref}
        {...props}
      >
        {children}
      </Box>
    )
  }
)

export default TaskRow
