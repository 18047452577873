import { useDisclosure } from 'hooks/useDisclosure'
import { Button, Icon } from 'lemon-system'

import { useTranslation } from 'react-i18next'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import useCreateTask from 'features/projects/hooks/useCreateTask'
import SaveResourceModal, { saveProps } from '../SaveResourceModal'
import useTrackEvent from 'hooks/useTrackEvent'

interface AddTaskProps {
  projectId: string
  milestoneId: string
}

const AddTask: React.FC<AddTaskProps> = ({ projectId, milestoneId }) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { t } = useTranslation()
  const { mutateAsync, isLoading } = useCreateTask(projectId)
  const { track } = useTrackEvent()

  const handleSave = async (values: saveProps) => {
    await mutateAsync({
      projectId,
      milestoneId,
      ...values,
    })

    track({
      eventName: 'projects - work - tasks - create - done',
      metadata: {
        projectId,
      },
    })

    onClose()
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          track({
            eventName: 'projects - work - tasks - create',
            metadata: {
              projectId,
            },
          })
          onOpen()
        }}
      >
        <Icon as={MdOutlineAddCircleOutline} className="mr-2" />{' '}
        {t('projects.add_task')}
      </Button>

      <SaveResourceModal
        onClose={() => {
          track({
            eventName: 'projects - work - tasks - create - cancel',
            metadata: {
              projectId,
            },
          })
          onClose()
        }}
        isOpen={isOpen}
        isLoading={isLoading}
        onSave={handleSave}
        title={t('projects.add_task')}
      />
    </>
  )
}

export default AddTask
