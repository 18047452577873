import { Box } from 'lemon-system'
import { forwardRef, ReactNode } from 'react'

interface TaskTableProps extends React.ComponentPropsWithoutRef<'div'> {
  actions?: ReactNode
  className?: string
}

const TaskTable = forwardRef<HTMLDivElement, TaskTableProps>(
  ({ className = '', actions, children, ...props }, ref) => {
    return (
      <Box
        className={`flex border border-neutral-02 rounded-md flex-col overflow-hidden ${className}`}
        {...props}
        ref={ref}
      >
        <Box className="flex-grow-1 flex-shrink-1">{children}</Box>
        <Box className="flex flex-grow-1 flex-shrink-1 pl-12 bg-03 py-4">
          {actions}
        </Box>
      </Box>
    )
  }
)

export default TaskTable
