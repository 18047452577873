import { parseISO } from 'date-fns'
import { projectTaskType } from 'features/shared/types/projectType'
import { useDisclosure } from 'hooks/useDisclosure'
import { Button, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdOutlineEdit } from 'react-icons/md'
import SaveResourceModal, {
  saveProps,
} from 'features/projects/components/SaveResourceModal'

interface EditTaskProps {
  onSave: (values: saveProps) => void
  task: projectTaskType
}

const EditTask: React.FC<EditTaskProps> = ({ onSave, task }) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { t } = useTranslation()

  const handleSave = async (values: saveProps) => {
    onClose()
    onSave(values)
  }

  return (
    <>
      <Button
        size="xs"
        variant="outlined"
        style={{
          minWidth: 'fit-content',
        }}
        onClick={() => onOpen()}
      >
        <Icon as={MdOutlineEdit} />
      </Button>

      <SaveResourceModal
        onClose={() => {
          onClose()
        }}
        isOpen={isOpen}
        isLoading={false}
        onSave={handleSave}
        title={t('projects.edit_task')}
        defaultValues={{
          assignees: task.assignees,
          deadline: task.deadline ? parseISO(task.deadline) : null,
          name: task.name,
        }}
        showDeadline={false}
      />
    </>
  )
}

export default EditTask
