import { Icon } from 'lemon-system'
import { MdInsertDriveFile } from 'react-icons/md'

interface IconFileProps {
  size?: number | 'xs' | 'sm'
  className?: string
  ext?: string | null
}

const fileColors: { [inndex: string]: string } = {
  '.pdf': '#ea5455',
  '.doc': '#3e7fd8',
  '.docx': '#3e7fd8',
  '.ppt': '#db603a',
  '.pptx': '#db603a',
  '.xls': '#5bab6b',
  '.xlsx': '#5bab6b',
}

const IconFile: React.FC<IconFileProps> = ({
  size = 'sm',
  className = '',
  ext,
}) => {
  return (
    <Icon
      size={size}
      as={MdInsertDriveFile}
      className={`text-neutral-05 ${className}`}
      style={{ color: ext ? fileColors[ext] : undefined }}
    />
  )
}

export default IconFile
