import { policiesType } from './../types/policiesType'
import { useUser } from 'features/shared/state/user/userContext'

function usePolicyValidation() {
  const { user } = useUser()

  const policyValidation = (policy: policiesType) => {
    if ((user.policies as string[]).includes('*.*')) return true

    return user.policies.includes(policy)
  }

  return { policyValidation }
}

export default usePolicyValidation
