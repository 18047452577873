import { Box, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdOutlineElderly } from 'react-icons/md'

const HistoryNoDataRow: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Box as="tr" className="py-6">
      <Box
        as="td"
        colSpan={4}
        className="flex justify-center items-center py-6"
      >
        <Icon as={MdOutlineElderly} size={28} className="flex justify-center" />
        <Box as="span" className="flex justify-center text-lg ml-2">
          {t('projects.history.no_data')}
        </Box>
      </Box>
    </Box>
  )
}

export default HistoryNoDataRow
