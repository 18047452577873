import { Box, Button, Icon } from 'lemon-system'
import { ReactNode } from 'react'
import { MdClose } from 'react-icons/md'
import {
  ModalManager,
  ModalManagerContent,
  ModalManagerOverlay,
  ModalManagerProps,
  useModalManager,
} from './components/ModalManager'

export interface ModalProps extends ModalManagerProps {
  children: ReactNode
}

export const Modal = ({ children, ...props }: ModalProps) => {
  return <ModalManager {...props}>{children}</ModalManager>
}

const ModalHeader: React.FC = ({ children }) => {
  return (
    <Box
      as="header"
      className="font-semibold text-neutral-04 text-xl py-5 px-8 border-b-2 border-neutral-02"
    >
      {children}
    </Box>
  )
}

const ModalCloseButton: React.FC = () => {
  const { onClose } = useModalManager()

  return (
    <Button
      color="secondary"
      variant="text"
      className="absolute override:(min-w-max) top-5 right-4"
      onClick={onClose}
    >
      <Icon as={MdClose} />
    </Button>
  )
}

const ModalBody: React.FC = ({ children }) => {
  return <Box className="px-8 pt-6 pb-3 last:pb-6">{children}</Box>
}

const ModalFooter: React.FC = ({ children }) => {
  return <Box className="px-8 pt-3 pb-6 flex justify-end">{children}</Box>
}

Modal.Overlay = ModalManagerOverlay
Modal.Content = ModalManagerContent
Modal.Header = ModalHeader
Modal.CloseButton = ModalCloseButton
Modal.Body = ModalBody
Modal.Footer = ModalFooter
