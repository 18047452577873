import { Box } from 'lemon-system'
import { createContext, ReactNode, useContext, useRef } from 'react'
import routes from 'routes/routing'
import { Link } from 'react-router-dom'

interface LayoutProps {
  logo?: string
  header?: ReactNode
  sidebar?: ReactNode
}

interface LayoutContextProps {
  scrollToBottom: () => void
}

const LayoutContext = createContext<LayoutContextProps | undefined>(undefined)

export function useLayout() {
  return useContext(LayoutContext) as LayoutContextProps
}

const Layout: React.FC<LayoutProps> = ({ children, logo, header, sidebar }) => {
  const layoutRef = useRef<HTMLElement>(null)

  const scrollToBottom = () => {
    layoutRef.current?.scroll({
      top: layoutRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  return (
    <Box className="flex w-full h-screen">
      <Box
        as="aside"
        className="w-64 bg-secondary-02 flex-grow-0 flex-shrink-0"
      >
        <Box as="header" className="bg-primary-02 h-16 flex items-center">
          <Box as={Link} to={routes.dashboard} className="h-full">
            <Box as="img" src={logo} className="h-full ml-4" />
          </Box>
        </Box>
        <Box>{sidebar}</Box>
      </Box>
      <Box className="flex flex-col flex-1 h-screen overflow-hidden">
        <Box
          as="header"
          className="border-b border-neutral-02 h-16 flex items-center justify-end p-4 flex-grow-0 flex-shrink-0"
        >
          {header}
        </Box>
        <LayoutContext.Provider value={{ scrollToBottom }}>
          <Box
            as="main"
            ref={layoutRef}
            className="overflow-auto flex-grow-1 flex-shrink-1"
          >
            {children}
          </Box>
        </LayoutContext.Provider>
      </Box>
    </Box>
  )
}

export default Layout
