import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'lemon-system'
import { useQuery } from 'react-query'
import getContactsFromClientId from '../services/getContactsFromClientId'
import { contactType } from '../types/contactType'
import { defaultActionContent } from 'features/shared/utils'

export const clientContactsQueryKey = 'client_contacts'

function useClientContacts(id: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useQuery<contactType[]>(
    [clientContactsQueryKey, id],
    () => getContactsFromClientId(id),
    {
      onError: () =>
        snackbar.add({
          duration: 3000,
          content: t('unexpected_error'),
          status: 'error',
          actionContent: defaultActionContent,
        }),
    }
  )
}

export default useClientContacts
