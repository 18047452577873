import { areaType } from 'features/shared/types/areaType'
import { fetcher } from 'utils/fetcher'

export type createAreaProps = Omit<areaType, 'id' | 'tenant'>

async function createArea(areaData: createAreaProps) {
  return await fetcher.post('/crm-clients/areas', areaData)
}

export default createArea
