import i18n from 'i18next'

import { useState } from 'react'

import CategoryRow from './CategoryRow'
import useCategories from 'features/shared/hooks/useCategories'
import Table from 'components/Table'
import useCreateCategory from '../hooks/useCreateCategory'
import useDeleteCategory from '../hooks/useDeleteCategory'
import useUpdateCategory from '../hooks/useUpdateCategory'

import { createCategoryProps } from '../services/createCategory'
import { categoryType } from 'features/shared/types/categoryType'
import { deleteCategoryProps } from '../services/deleteCategory'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { updateCategoryProps } from '../services/updateCategory'

interface CategoryTableProps {
  isCreating: boolean
  categories: categoryType[] | undefined
  onCancel: () => void
  onCreated: () => void
}

const defaultCategory = {
  id: null,
  tenant: null,
  name: null,
}

const headersDefinition = [
  { className: 'w-1/3' },
  {
    title: i18n.t('areas.name'),
    className: 'py-2 text-neutral-01 text-left w-1/3',
    sortable: true,
    fieldName: 'name',
  },
  { className: 'py-2 text-neutral-01 text-left w-1/3' },
]

const CategoriesTable: React.FC<CategoryTableProps> = ({
  isCreating,
  categories = [],
  onCancel,
  onCreated,
}) => {
  useCategories({ refetchOnMount: false })
  const [newCategory, setNewCategory] = useState<categoryType>(defaultCategory)
  const { isLoading, mutateAsync } = useCreateCategory()
  const { mutateAsync: mutateUpdateAsync } = useUpdateCategory()
  const { mutateAsync: mutateDeleteAsync } = useDeleteCategory()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const { policyValidation } = usePolicyValidation()

  const categoryDataParsed = (category: categoryType) => {
    const { id, tenant, ...rest } = category
    return rest as createCategoryProps
  }

  const categoryUpdateDataParsed = (category: categoryType) => {
    const { tenant, ...rest } = category
    return rest as updateCategoryProps
  }

  const categoryDataDeleteParsed = (category: categoryType) => {
    const { tenant, ...rest } = category
    return rest as deleteCategoryProps
  }

  const handleEditNewCategory = (key: string, value: string) =>
    setNewCategory({ ...newCategory, [key]: value })

  const handleCreateCategory = async (category: categoryType) => {
    try {
      category.id
        ? await mutateUpdateAsync(categoryUpdateDataParsed(category))
        : await mutateAsync(categoryDataParsed(category))
      onCreated()
      setNewCategory(defaultCategory)
    } catch {
      onCancel()
    }
  }

  const handleDeleteCategory = async (category: categoryType) => {
    try {
      setIsDeleteLoading(true)
      await mutateDeleteAsync(categoryDataDeleteParsed(category))
      onCreated()
      setIsDeleteLoading(false)
    } catch {
      onCancel()
      setIsDeleteLoading(false)
    }
  }

  return (
    <Table
      headers={headersDefinition}
      sortItemsBy={{ field: 'name', asc: true }}
      isCreating={isCreating}
      newResource={newCategory}
      resourceName="category"
      resources={categories}
      rowComponent={CategoryRow}
      rowClassName="py-2 flex"
      isLoading={isLoading || isDeleteLoading}
      onEdit={handleEditNewCategory}
      onCancel={onCancel}
      onCreate={handleCreateCategory}
      onDeleteCategory={handleDeleteCategory}
      canUpdateDelete={policyValidation('categories.write')}
    />
  )
}

export default CategoriesTable
