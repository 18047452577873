import { Box } from 'lemon-system'
import lemon from 'assets/logo.svg'
import MotionBox from 'components/MotionBox'

const LemonLoader: React.FC = () => {
  return (
    <Box className="flex justify-center items-center w-screen h-screen">
      <Box className="relative">
        <MotionBox
          as="img"
          src={lemon}
          className="w-20"
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          transition={{
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 0.8,
          }}
          exit={{ opacity: 0.5 }}
        />
      </Box>
    </Box>
  )
}

export default LemonLoader
