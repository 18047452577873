import MotionBox from 'components/MotionBox'
import PlaceholderLoader from 'components/PlaceholderLoader'
import Tabs, { Tab } from 'components/Tabs'
import useFunnels from 'features/funnels/hooks/useFunnels'
import { funnelType } from 'features/funnels/types/funnelType'
import { funnelsQueryKey } from 'features/funnels/views/Funnels'
import { useUser } from 'features/shared/state/user/userContext'
import { defaultActionContent } from 'features/shared/utils'
import { Box, useSnackbar } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSocketEvent } from 'state/socket/socketContext'
import ProjectCount from '../ProjectCount'

const FunnelsSummary: React.FC = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { isLoading, data: funnels } = useFunnels()
  const [funnelSelected, setFunnelSelected] = useState<funnelType>()
  const { user } = useUser()
  const notification = useSocketEvent('notification')

  useEffect(() => {
    if (funnels && !funnelSelected) {
      setFunnelSelected(funnels[0])
    }
  }, [funnels, funnelSelected])

  useEffect(() => {
    if (notification) {
      const { author, type, resource, data: incomingFunnel } = notification
      const { email: currentAuthor } = user
      const isCurrentAuthor = currentAuthor === author

      if (!isCurrentAuthor) {
        const isUpdateFunnelAction = type === 'update' && resource === 'funnel'

        if (isUpdateFunnelAction) {
          updateFunnels(incomingFunnel)
          snackbar.add({
            duration: 5000,
            status: 'info',
            content: t('funnels.funnel_update_by_user', { author }),
            actionContent: defaultActionContent,
          })
        }
      }
    }
    // eslint-disable-next-line
  }, [notification])

  const updateFunnels = (incomingFunnel: funnelType) => {
    const updatedFunnels = funnels?.map((f) =>
      f.id === incomingFunnel.id ? incomingFunnel : f
    )
    queryClient.setQueryData([funnelsQueryKey], updatedFunnels)

    if (incomingFunnel.id === funnelSelected?.id)
      setFunnelSelected(incomingFunnel)
  }

  const handleFunnelChange = (funnel: funnelType) => setFunnelSelected(funnel)

  return (
    <Box className="w-full mb-6">
      <Box as="h1" className="text-secondary-01 font-semibold text-lg mb-2">
        {t('dashboard.funnel_summary.title')}
      </Box>

      {isLoading ? (
        <MotionBox
          key="placeholder-loader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Box className="flex flex-col mb-4">
            <PlaceholderLoader className="mr-4 h-6 mb-4 rounded-md" />
          </Box>
        </MotionBox>
      ) : (
        <Tabs>
          {funnels?.map(
            (funnel) =>
              funnel.active && (
                <Tab
                  key={`funnelTab-${funnel.id}`}
                  to={''}
                  isActive={funnel.id === funnelSelected?.id}
                  onClick={() => handleFunnelChange(funnel)}
                >
                  {funnel.name}
                </Tab>
              )
          )}
        </Tabs>
      )}

      <Box className="flex overflow-x-auto flex-grow-1 flex-shrink-1 mt-8 pb-4 items-start">
        {isLoading ? (
          <MotionBox
            key="placeholder-loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box className="flex flex-wrap mb-4">
              <PlaceholderLoader className="mr-4 h-32 w-72 mb-4 rounded-md" />
              <PlaceholderLoader className="mr-4 h-32 w-72 mb-4 rounded-md" />
              <PlaceholderLoader className="mr-4 h-32 w-72 mb-4 rounded-md" />
            </Box>
          </MotionBox>
        ) : (
          funnelSelected?.steps.map((step, i) => (
            <MotionBox
              custom={i}
              animate="visible"
              variants={{
                visible: (i: any) => ({
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: i * 0.15,
                  },
                }),
              }}
              className="flex"
              style={{ x: -30, opacity: 0 }}
              key={`${funnelSelected?.id}-${i}-${step.name}`}
            >
              <ProjectCount
                step={step}
                stepIdx={i}
                funnelId={funnelSelected.id}
                key={step.name}
              />
            </MotionBox>
          ))
        )}
      </Box>
    </Box>
  )
}

export default FunnelsSummary
