import { Button, Icon } from 'lemon-system'
import { ButtonProps } from 'lemon-system/types/src/components/Button/Button'
import { IconType } from 'react-icons'

interface FloatingButtonProps extends Omit<ButtonProps, 'size'> {
  size: number
  icon: IconType
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  size,
  icon,
  className,
  onClick,
  ...rest
}) => {
  return (
    <Button
      className={`${className} absolute right-6 bottom-6
        override:(rounded-full min-w-0 w-${size} h-${size})`}
      onClick={onClick}
      {...rest}
    >
      <Icon as={icon} size={size * 1.5} />
    </Button>
  )
}

export default FloatingButton
