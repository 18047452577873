import DatePicker from 'components/DatePicker'
import FormControl from 'components/FormControl'
import Input from 'components/Input'
import { Modal } from 'components/Modal'
import { addHours } from 'date-fns'
import SelectUser from 'features/shared/components/SelectUser'
import { Box, Button } from 'lemon-system'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type saveProps = {
  assignees: string[] | null
  name: string
  deadline: Date | null
}

interface SaveResourceModalProps {
  onSave: (props: saveProps) => void
  onClose: () => void
  isOpen: boolean
  isLoading: boolean
  title: string
  defaultValues?: saveProps
  showDeadline?: boolean
}

type selectType = {
  label: string
  value: string
}

const SaveResourceModal: React.FC<SaveResourceModalProps> = ({
  isOpen,
  onClose,
  isLoading,
  onSave,
  title,
  defaultValues,
  showDeadline = true,
}) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<saveProps>({
    defaultValues,
  })

  const parseSelection = (selectedItems: string[] | null) =>
    selectedItems?.map((selectedItem) => ({ value: selectedItem }))

  const parseAssignees = (assignees: selectType[] | null) =>
    assignees?.map((asiggnee) => asiggnee.value) || null

  const onSumbit = handleSubmit(async (values) => {
    onSave({
      assignees: values.assignees,
      name: values.name,
      deadline: values.deadline ? addHours(values.deadline, 10) : null,
    })
  })

  useEffect(() => {
    if (defaultValues) reset(defaultValues)

    // eslint-disable-next-line
  }, [defaultValues])

  useEffect(() => {
    reset()

    // eslint-disable-next-line
  }, [isOpen])

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="sm"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <Modal.Overlay />
      <Modal.Content as="form" onSubmit={onSumbit}>
        <Modal.Header>{title}</Modal.Header>

        <Modal.Body>
          <Box className="space-y-3">
            <FormControl isInvalid={!!errors.name}>
              <FormControl.Label>{t('projects.name')}</FormControl.Label>

              <Input
                size="md"
                {...register('name', { required: true })}
                autoComplete="off"
              />

              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </FormControl>

            {showDeadline && (
              <FormControl as="div">
                <FormControl.Label>
                  {t('projects.deadline_date')}
                </FormControl.Label>

                <Controller
                  name="deadline"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={undefined}
                      dateFormat="dd/MM/yyyy"
                      selected={field.value}
                      size="md"
                      autoComplete="off"
                    />
                  )}
                />
              </FormControl>
            )}

            <FormControl>
              <FormControl.Label>{t('projects.assignees')}</FormControl.Label>

              <Controller
                name="assignees"
                control={control}
                render={({ field }) => (
                  <SelectUser
                    {...field}
                    value={parseSelection(field.value)}
                    onChange={(values) =>
                      field.onChange(parseAssignees(values as selectType[]))
                    }
                    size="md"
                    isClearable={false}
                    isMultiGrouped
                  />
                )}
              />

              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </FormControl>
          </Box>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outlined"
            className="mr-4"
            onClick={onClose}
            isDisabled={isLoading}
          >
            {t('cancel')}
          </Button>
          <Button type="submit" isDisabled={isLoading}>
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default SaveResourceModal
