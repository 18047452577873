import { Box } from 'lemon-system'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface AvatarProps {
  size?: number
  id?: string | null
  name?: string | null
  className?: string
  src?: string
}

interface AvatarGroupProps {
  className?: string
  size: number
  max: number
}

interface AvatarContextProps {
  size: number
}

const AvatarContext = createContext<AvatarContextProps | undefined>(undefined)

const Avatar = ({
  size = 3,
  id = '',
  name = '',
  className = '',
  src,
}: AvatarProps) => {
  const context = useContext(AvatarContext)
  const conditionalSize = context?.size || size
  const [hasImageError, setHasImageError] = useState(false)

  const bgColor = useMemo(() => {
    if (!id) return 'bg-disabled-02'

    const firstColor = 1
    const lastColor = 29
    const idAsNumber = id
      .split('')
      .map((x) => x.charCodeAt(0))
      .reduce((x, y) => x + y)

    const colorBasedOnId = firstColor + (idAsNumber % lastColor)
    const twoDigitsColor = colorBasedOnId.toString().padStart(2, '0')

    return `bg-support-${twoDigitsColor}`
  }, [id])

  const getInitials = () => {
    if (!name) return ''

    const words = name.replace(/\s+/g, ' ').trim().split(' ')
    switch (words.length) {
      case 1:
        return words[0].substring(0, 2).toUpperCase()
      case 2:
        return `${words[0][0] + words[1][0]}`.toUpperCase()
      default:
        return `${words[0][0] + words[words.length - 2][0]}`.toUpperCase()
    }
  }

  const unitRem = 0.25

  useEffect(() => {
    if (src) setHasImageError(false)
  }, [src])

  if (!hasImageError && src)
    return (
      <Box
        as="img"
        className={`${className} rounded-full object-cover bg-disabled-02 w-${conditionalSize} h-${conditionalSize}`}
        src={src}
        onError={() => setHasImageError(true)}
        alt={getInitials()}
      />
    )

  return (
    <Box
      className={`${className} flex place-content-center items-center
        rounded-full object-cover w-${conditionalSize} h-${conditionalSize} ${bgColor}`}
    >
      <Box
        as="span"
        className={`text-md font-semibold text-neutral-01`}
        style={{
          fontSize: `calc(${conditionalSize * unitRem}rem / 2.4)`,
        }}
      >
        {getInitials()}
      </Box>
    </Box>
  )
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({
  children,
  className,
  size,
  max,
}) => {
  if (!Array.isArray(children)) return null

  const start = 0
  const end = max
  const remainingItems = children.length - max
  const hasOneItemRemaining = remainingItems > 1
  const avatars = hasOneItemRemaining
    ? [...children].slice(start, end)
    : [...children]
  const unitRem = 0.25
  const stackMarginSize = unitRem * (size / 3)

  const reversedAvatars = [...avatars]?.reverse()

  return (
    <AvatarContext.Provider value={{ size }}>
      <Box className={`${className} flex flex-row-reverse`}>
        {hasOneItemRemaining && (
          <Box
            className={`border-2 rounded-full border-neutral-01 -mr-[${stackMarginSize}rem]`}
          >
            <Avatar name={`+${remainingItems}`} />
          </Box>
        )}

        {reversedAvatars?.map((avatar: JSX.Element, index) => {
          return (
            <Box
              key={`avatar-${avatar?.props?.name}-${index}`}
              className={`border-2 rounded-full border-neutral-01 -mr-[${stackMarginSize}rem]`}
            >
              {avatar}
            </Box>
          )
        })}
      </Box>
    </AvatarContext.Provider>
  )
}

Avatar.Group = AvatarGroup

export default Avatar
