import Avatar from 'components/Avatar'
import { Modal } from 'components/Modal'
import PlaceholderLoader from 'components/PlaceholderLoader'
import { useUsers } from 'features/shared/hooks/useUsers'
import { userType } from 'features/shared/types/userType'
import { useDisclosure } from 'hooks/useDisclosure'
import { Box } from 'lemon-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface UsersListProps {
  userEmails: string[]
}

const UsersList: React.FC<UsersListProps> = ({ userEmails }) => {
  const { isLoading, data, isIdle } = useUsers()
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { t } = useTranslation()

  const usersData = useMemo(() => {
    if (!data) return null

    const filteredUsers: [string, userType][] = data
      .filter((user) => userEmails.includes(user.email || ''))
      .map((user) => [user.email as string, user])

    return new Map<string, userType>(filteredUsers)
  }, [userEmails, data])

  if (isIdle) return null

  if (isLoading || !usersData) {
    return (
      <Box className="flex">
        <PlaceholderLoader className="h-8 w-8 rounded-full -mr-2" />
        <PlaceholderLoader className="h-8 w-8 rounded-full -mr-2" />
        <PlaceholderLoader className="h-8 w-8 rounded-full -mr-2" />
      </Box>
    )
  }

  return (
    <Box className="h-8 cursor-pointer" onClick={onOpen}>
      <Avatar.Group size={7} max={2}>
        {userEmails.map((email) => {
          const user = usersData?.get(email)

          return (
            <Avatar
              key={`initial-list-${user?.id}`}
              id={user?.id}
              name={user?.name}
              src={user?.avatar}
            />
          )
        })}
      </Avatar.Group>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="sm">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>{t('projects.assignees')}</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            {userEmails.map((email) => {
              const user = usersData?.get(email)

              return (
                <Box
                  key={`initial-modal-${user?.id}`}
                  className="flex items-center mb-8 last:mb-0"
                >
                  <Avatar
                    size={12}
                    id={user?.id}
                    name={user?.name}
                    src={user?.avatar}
                  />

                  <Box className="flex flex-col ml-4">
                    <Box className="font-semibold text-neutral-09 text-md">
                      {user?.name}
                    </Box>
                    <Box className="text-neutral-09 text-md">{user?.email}</Box>
                  </Box>
                </Box>
              )
            })}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Box>
  )
}

export default UsersList
