import MotionBox from 'components/MotionBox'
import { Box, Button, Icon, useTheme } from 'lemon-system'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  MdCheck,
  MdClose,
  MdDeleteOutline,
  MdOutlineEdit,
  MdOutlineStar,
  MdPhoneAndroid,
} from 'react-icons/md'
import { AnimatePresence } from 'framer-motion'
import { uniqId } from 'utils/uniqId'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import Avatar from 'components/Avatar'
import Overlay from 'components/Overlay'
import ConfirmButton from 'components/ConfirmButton'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useForm } from 'react-hook-form'
import TooltipErrors from '../TooltipErrors'
import { isEmail } from 'utils/isEmail'

export interface ContactCardProps {
  name?: string
  email?: string
  phone?: string
  onEdit?: (params: { name: string; email: string; phone: string }) => void
  onFavourite?: (params: { contactId: string }) => void
  onRemove?: () => void
  isLoading?: boolean
  className?: string
  id?: string
  onEditing?: (flag: boolean) => void
  onRemoving?: (flag: boolean) => void
  isEditing?: boolean
  isRemoving?: boolean
  isFavourite?: boolean | null
  readOnly?: boolean | null
}

const ContactCard: React.FC<ContactCardProps> = ({
  name = '',
  email = '',
  phone = '',
  onEdit,
  onFavourite,
  onRemove,
  isLoading,
  className,
  onEditing,
  onRemoving,
  isEditing,
  isRemoving,
  id,
  isFavourite,
  readOnly = false,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const confirmEditButtonId = useMemo(() => uniqId(), [])
  const layoutId = useMemo(() => id || uniqId(), []) // eslint-disable-line
  const { t } = useTranslation()
  const { policyValidation } = usePolicyValidation()
  const userCanEditDelete = policyValidation('contacts.write')
  const {
    register,
    reset,
    watch,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { name, email, phone },
    mode: 'all',
  })
  const watchAll = watch()
  const { getThemeProp: theme } = useTheme()

  const handleClickStartEdit = () => {
    onEditing?.(true)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const handleClickStar = () => {
    onFavourite?.({
      contactId: id || '',
    })
  }

  const handleClickCancelEdit = () => {
    reset()
    onEditing?.(false)
  }

  const onSubmit = handleSubmit(async (values) => {
    onEdit?.(values)
  })

  const handleClickCancelDelete = () => onRemoving?.(false)

  const handleClickConfirmDelete = () => onRemove?.()

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onEditing?.(false)
    }

    // eslint-disable-next-line
  }, [])

  const showActions = !readOnly

  const isEditConfirmEnabled =
    (name !== watchAll.name ||
      email !== watchAll.email ||
      phone !== watchAll.phone) &&
    isValid

  useEffect(() => {
    if (isEditing) {
      setFocus('name')
    }
  }, [setFocus, isEditing])

  useEffect(() => {
    if (isEditing) document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isEditing, handleKeyDown])

  return (
    <MotionBox
      key={layoutId}
      className="relative flex"
      layout
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transition={{
        type: 'spring',
        damping: 25,
        stiffness: 120,
      }}
    >
      <Overlay isOpen={isEditing} />

      <Box
        as="form"
        className={`flex flex-col relative bg-neutral-01 border border-neutral-02 rounded-md p-6 justify-center items-center min-w-80 ${
          isEditing ? 'z-popover' : ''
        } ${className}`}
        onSubmit={onSubmit}
      >
        {showActions && isFavourite && (
          <Button
            variant="outlined"
            className="flex rounded-full p-1 hover:bg-02 focus:outline-none ml-60"
            style={{
              minWidth: 'fit-content',
            }}
            onClick={handleClickStar}
          >
            <Icon
              size="sm"
              color="primary"
              as={MdOutlineStar}
              className="text-neutral-05"
            />
          </Button>
        )}

        {showActions && !isFavourite && (
          <Button
            variant="outlined"
            style={{
              minWidth: 'fit-content',
            }}
            className={`flex rounded-full p-1 hover:bg-02 focus:outline-none ml-60 ${
              isHovered ? 'opacity-100' : 'opacity-0'
            }`}
            onClick={handleClickStar}
          >
            <Icon
              size="sm"
              color="primary"
              as={MdOutlineStar}
              className="text-neutral-05"
            />
          </Button>
        )}

        <Box className="flex items-center">
          <Box
            className={`w-28 flex justify-end transition-opacity ${
              isEditing || isHovered ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {showActions && !isEditing && onRemoving && userCanEditDelete && (
              <Button
                size="xs"
                style={{
                  minWidth: 'fit-content',
                }}
                variant="outlined"
                onClick={() => onRemoving?.(true)}
              >
                <Icon as={MdDeleteOutline} />
              </Button>
            )}
          </Box>

          <Box className="mx-4 flex-shrink-0">
            <Avatar id={id} name={watchAll.name} size={24} />
          </Box>

          <Box
            className={`flex flex-shrink-0 w-28 transition-opacity ${
              isEditing || isHovered ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {showActions && !isEditing && userCanEditDelete && (
              <Button
                size="xs"
                style={{
                  minWidth: 'fit-content',
                }}
                variant="outlined"
                onClick={handleClickStartEdit}
              >
                <Icon as={MdOutlineEdit} />
              </Button>
            )}

            {isEditing && (
              <Button
                size="xs"
                className="mr-2"
                style={{
                  minWidth: 'fit-content',
                }}
                onClick={handleClickCancelEdit}
                variant="outlined"
              >
                <Icon as={MdClose} />
              </Button>
            )}

            <AnimatePresence>
              {isEditing && (
                <MotionBox
                  className="opacity-0"
                  key={`confirmEdit-${confirmEditButtonId}`}
                  initial={{ x: -20, opacity: 1 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -20, opacity: 0 }}
                >
                  <ConfirmButton isDisabled={!isEditConfirmEnabled} />
                </MotionBox>
              )}
            </AnimatePresence>
          </Box>
        </Box>

        {isEditing ? (
          <>
            <Box className="flex items-center mt-4">
              <TooltipErrors error={errors.name?.message} />

              <Box
                as="input"
                {...register('name', {
                  required: {
                    value: true,
                    message: t('field_required'),
                  },
                })}
                className="font-bold text-secondary-01 text-lg text-center border-b border-transparent outline-none focus:border-secondary-01 min-w-56"
                placeholder={t('clients.edit_fields.name')}
                autoComplete="off"
              />
            </Box>

            <Box className="flex items-center">
              <TooltipErrors error={errors.email?.message} />

              <Box
                as="input"
                {...register('email', {
                  required: {
                    value: true,
                    message: t('field_required'),
                  },
                  validate: (value) =>
                    isEmail(value) ||
                    t('clients.contact_errors.invalid_email').toString(),
                })}
                className="flex items-center text-neutral-08 text-sm mt-1 text-center min-w-56 border-b border-transparent outline-none focus:border-neutral-08"
                placeholder={t('clients.edit_fields.email')}
                autoComplete="off"
              />
            </Box>

            <Box className="flex items-center mt-4 text-info-01 bg-info-02 rounded-md p-2 text-sm font-semibold opacity-80">
              <TooltipErrors error={errors.phone?.message} />
              <Icon size={15} as={MdPhoneAndroid} />

              <Box
                as="input"
                {...register('phone', {
                  required: {
                    value: true,
                    message: t('field_required'),
                  },
                  pattern: /\d+/g,
                  maxLength: {
                    value: 12,
                    message: t('clients.contact_errors.invalid_length_phone', {
                      length: 12,
                    }),
                  },
                })}
                className="pl-1 text-info-01 bg-info-02 text-sm font-semibold w-24 text-center outline-none border-b border-transparent focus:border-info-01"
                placeholder={t('clients.edit_fields.phone')}
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    return
                  }

                  if (!/\d+/g.test(e.key)) {
                    e.preventDefault()
                  }
                }}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              as="span"
              className="flex items-center font-bold text-secondary-01 mt-4 text-lg border-b border-transparent"
            >
              {name}
            </Box>
            {email && (
              <Box
                as="a"
                href={`mailto:${email}`}
                target="_blank"
                className="flex items-center text-neutral-08 text-sm mt-1 hover:text-primary-03 border-b border-transparent"
              >
                {email}
              </Box>
            )}

            {phone && (
              <Box
                as="a"
                href={`tel:${phone}`}
                target="_blank"
                className="flex items-center mt-4 text-info-01 bg-info-02 rounded-md p-2 text-sm font-semibold opacity-80 hover:opacity-100 border-b border-transparent"
              >
                <Icon size={15} as={MdPhoneAndroid} className="mr-1" />
                {phone}
              </Box>
            )}
          </>
        )}

        <AnimatePresence>
          {(isRemoving || isLoading) && (
            <MotionBox
              className="rounded-md m-px absolute -top-px -left-px -bottom-px -right-px bg-neutral-01 opacity-85"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.85 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {(isRemoving || isLoading) && (
            <MotionBox
              className="flex items-center justify-center absolute top-0 left-0 w-full h-full flex-col"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
            >
              {isRemoving && !isLoading && (
                <>
                  <Box as="span" className="mx-10 text-center mb-6 font-bold">
                    {t('clients.confirm_remove_contact_message')}
                  </Box>
                  <Box>
                    <Button
                      size="lg"
                      className="mr-4"
                      style={{
                        minWidth: 'fit-content',
                      }}
                      onClick={handleClickCancelDelete}
                    >
                      <Icon size={22} as={MdClose} />
                    </Button>
                    <Button
                      size="lg"
                      style={{
                        minWidth: 'fit-content',
                      }}
                      onClick={handleClickConfirmDelete}
                    >
                      <Icon size={22} as={MdCheck} />
                    </Button>
                  </Box>
                </>
              )}

              {isLoading && (
                <TailSpin
                  width={60}
                  height={60}
                  color={theme('colors.primary.01')}
                />
              )}
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>
    </MotionBox>
  )
}

export default ContactCard
