import Input, { InputSizes } from 'components/Input'
import { forwardRef } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

interface DatePickerProps extends ReactDatePickerProps {
  size?: InputSizes
  isInvalid?: boolean
  isDisabled?: boolean
  isRequired?: boolean
}

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  ({ size, isInvalid, isDisabled, isRequired, ...props }, ref) => {
    return (
      <ReactDatePicker
        disabled={isDisabled}
        required={isRequired}
        {...props}
        customInput={
          <Input
            size={size}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            isRequired={isRequired}
            ref={ref}
          />
        }
      />
    )
  }
)

export default DatePicker
