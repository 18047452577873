import RSSelectType from 'react-select/dist/declarations/src/Select'
import { SelectProps } from 'components/Select'
import { forwardRef } from 'react'
import { GroupBase } from 'react-select'
import SelectIcon from 'components/SelectIcon'
import { useTranslation } from 'react-i18next'

interface SelectFunnelStatusProps extends Omit<SelectProps, 'options'> {
  selectionDefault?: {
    funnel_status?: boolean | undefined | null
    status?: boolean | undefined
  }
}

const SelectFunnelStatus = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  SelectFunnelStatusProps
>(({ size = 'sm', selectionDefault = {}, ...props }, ref) => {
  const { t } = useTranslation()
  const defaultValue = []
  const options = [
    {
      label: t('projects.filters.on_funnel_status.on_creation'),
      value: { funnel_status: false, status: undefined },
    },
    {
      label: t('projects.filters.on_funnel_status.initiated'),
      value: { funnel_status: true, status: undefined },
    },
    {
      label: t('projects.filters.on_funnel_status.finished'),
      value: { funnel_status: undefined, status: true },
    },
  ]

  if (
    selectionDefault.funnel_status === true ||
    selectionDefault.funnel_status === false
  ) {
    const found = options.find(
      (o) => o.value.funnel_status === selectionDefault.funnel_status
    )
    if (found) defaultValue.push(found)
  }

  if (selectionDefault.status === true) {
    const found = options.find(
      (o) => o.value.status === selectionDefault.status
    )
    if (found) defaultValue.push(found)
  }

  return (
    <SelectIcon
      size={size}
      ref={ref}
      options={options}
      defaultValue={defaultValue}
      {...props}
    />
  )
})

export default SelectFunnelStatus
