import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import deleteFileById from '../services/deleteFileById'
import { filesQueryKey } from './useFiles'

function useDeleteFile(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(deleteFileById, {
    onSuccess: () => {
      queryClient.invalidateQueries([filesQueryKey, projectId])
    },

    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useDeleteFile
