import { usersQueryKey } from 'features/shared/hooks/useUsers'
import { userType } from 'features/shared/types/userType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import createUser from '../services/createUser'

const useCreateUser = () => {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(createUser, {
    onSuccess: (newUser: userType) => {
      const updatedUsers = [
        newUser,
        ...(queryClient.getQueryData([usersQueryKey]) as userType[]),
      ]
      queryClient.setQueryData(usersQueryKey, updatedUsers)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('users.success_create'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useCreateUser
