import getPredefinedUserAvatars from 'features/shared/services/getPredefinedUserAvatars'
import { predefinedUserAvatarType } from 'features/shared/types/predefinedUserAvatarType'
import { useQuery } from 'react-query'

const usePredefinedUserAvatars = () => {
  return useQuery<predefinedUserAvatarType>(
    'predefined_user_avatars',
    getPredefinedUserAvatars,
    { refetchOnMount: false }
  )
}

export default usePredefinedUserAvatars
