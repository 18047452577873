import { holdingType } from 'features/shared/types/holdingType'
import { fetcher } from 'utils/fetcher'

export type updateHoldingProps = Omit<holdingType, 'tenant' | 'code'>

async function updateHolding(updateHoldingData: updateHoldingProps) {
  return await fetcher.put(`/crm-clients/holdings/${updateHoldingData.id}`, updateHoldingData)
}

export default updateHolding
