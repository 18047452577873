import i18n from 'i18next'

import { useState } from 'react'

import AreaRow from './AreaRow'
import useAreas from 'features/shared/hooks/useAreas'
import Table from 'components/Table'
import useCreateArea from '../hooks/useCreateArea'
import useDeleteArea from '../hooks/useDeleteArea'
import useUpdateArea from '../hooks/useUpdateArea'

import { createAreaProps } from '../services/createArea'
import { areaType } from 'features/shared/types/areaType'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { updateAreaProps } from '../services/updateArea'
import { deleteAreaProps } from '../services/deleteArea'

interface AreaTableProps {
  isCreating: boolean
  areas: areaType[] | undefined
  onCancel: () => void
  onCreated: () => void
}

const defaultArea = {
  id: null,
  tenant: null,
  name: null,
}

const headersDefinition = [
  { className: 'w-1/3' },
  {
    title: i18n.t('areas.name'),
    className: 'py-2 text-neutral-01 text-left w-1/3',
    sortable: true,
    fieldName: 'name',
  },
  { className: 'py-2 text-neutral-01 text-left w-1/3' },
]

const AreasTable: React.FC<AreaTableProps> = ({
  isCreating,
  areas = [],
  onCancel,
  onCreated,
}) => {
  useAreas({ refetchOnMount: false })
  const [newArea, setNewArea] = useState<areaType>(defaultArea)
  const { isLoading, mutateAsync } = useCreateArea()
  const { mutateAsync: mutateUpdateAsync } = useUpdateArea()
  const { mutateAsync: mutateDeleteAsync } = useDeleteArea()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const { policyValidation } = usePolicyValidation()

  const areaDataParsed = (area: areaType) => {
    const { id, tenant, ...rest } = area
    return rest as createAreaProps
  }

  const areaUpdateDataParsed = (area: areaType) => {
    const { tenant, ...rest } = area
    return rest as updateAreaProps
  }

  const areaDataDeleteParsed = (area: areaType) => {
    const { tenant, ...rest } = area
    return rest as deleteAreaProps
  }

  const handleEditNewArea = (key: string, value: string) =>
    setNewArea({ ...newArea, [key]: value })

  const handleCreateArea = async (area: areaType) => {
    try {
      area.id
        ? await mutateUpdateAsync(areaUpdateDataParsed(area))
        : await mutateAsync(areaDataParsed(area))
      onCreated()
      setNewArea(defaultArea)
    } catch {
      onCancel()
    }
  }

  const handleDeleteArea = async (area: areaType) => {
    try {
      setIsDeleteLoading(true)
      await mutateDeleteAsync(areaDataDeleteParsed(area))
      onCreated()
      setIsDeleteLoading(false)
    } catch {
      onCancel()
      setIsDeleteLoading(false)
    }
  }

  return (
    <Table
      headers={headersDefinition}
      sortItemsBy={{ field: 'name', asc: true }}
      isCreating={isCreating}
      newResource={newArea}
      resourceName="area"
      resources={areas}
      rowComponent={AreaRow}
      rowClassName="py-2 flex"
      isLoading={isLoading || isDeleteLoading}
      onEdit={handleEditNewArea}
      onCancel={onCancel}
      onCreate={handleCreateArea}
      onDeleteArea={handleDeleteArea}
      canUpdateDelete={policyValidation('areas.write')}
    />
  )
}

export default AreasTable
