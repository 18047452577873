import { projectType } from '../../shared/types/projectType'
import { fetcher } from 'utils/fetcher'

async function getProjectsByFunnelId(funnelId: string) {
  const projects = await fetcher.get<projectType[]>(
    `/crm-clients/funnels/${funnelId}/projects`
  )

  return projects
}

export default getProjectsByFunnelId
