import { fetcher } from 'utils/fetcher'
import { fileType } from '../types/fileType'

async function getFilesByProjectId(projectId: string) {
  const files = await fetcher.get<fileType[]>(
    `/crm-clients/projects/${projectId}/files`
  )

  return files
}

export default getFilesByProjectId
