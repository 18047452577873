const blobType = 'application/octet-stream'

export const downloadFileFromBinary = async (blob: Blob, fileName: string) => {
  if (blob.type !== blobType) {
    const buffer = await blob.arrayBuffer()
    const fixedBlob = new Blob([buffer], { type: blobType })

    triggerDownload(fixedBlob, fileName)
  } else {
    triggerDownload(blob, fileName)
  }
}

const triggerDownload = (blob: Blob, fileName: string) => {
  // Workaround to give a custom name to the blob file and download it.
  const a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  a.click()
}
