import { Box, Icon } from 'lemon-system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdMenu, MdSearch } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import ProjectsViewResult from './ProjectsViewResult'
import Pagination from 'components/Pagination'
import getFilterededProjects from '../services/getFilterededProjects'
import ProjectFilters from '../components/ProjectFilters'
import SelectAscDesc from 'features/shared/components/SelectAscDesc'
import FormControl from 'components/FormControl'
import SelectSortBy from '../components/SelectSortBy'
import getClients from 'features/shared/services/getClients'
import MenuProjectButton from 'features/projects/components/MenuProjectButton'
import CreateProject from 'features/funnels/views/CreateProject'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useDisclosure } from 'hooks/useDisclosure'
import useFunnels from 'features/funnels/hooks/useFunnels'

import useFilteredProjects, {
  projectsResultQueryKey,
} from '../hooks/useFilteredProjects'
import { projectSearchResponseType } from '../types/projectSearchResultType'
import { projectSearchParams } from '../types/projectSearchParams'
import { useUsers } from 'features/shared/hooks/useUsers'
import { clientsType } from 'features/shared/types/clientsType'
import { clientsQueryKey } from 'features/clients/views/Clients'

const Projects: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState('')
  const [isCreatingProject, setIsCreatingProject] = useState(false)
  const { policyValidation } = usePolicyValidation()
  const { onClose, onOpen } = useDisclosure()
  const { data: funnels } = useFunnels()
  const { control, setValue, getValues } = useForm({
    defaultValues: {
      sort_order: searchParams.get('sort_order') || 'desc',
      sort_by: searchParams.get('sort_by') || 'score',
    },
  })

  const { data: usersData } = useUsers()

  const { data: clients } = useQuery<clientsType[]>(
    clientsQueryKey,
    getClients,
    { enabled: true }
  )

  const { data: projects } = useQuery<projectSearchResponseType>(
    projectsResultQueryKey,
    () => getFilterededProjects({ page: 1, sort_by: 'name', sort_order: 'asc' })
  )

  const { mutateAsync: mutateFilteredProjects, isLoading: isFetching } =
    useFilteredProjects()

  const handleChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value
    setSearch(value)
  }

  const move = async (newIndex: number) => {
    if (!getValues('sort_by')) {
      setValue('sort_by', 'score', { shouldDirty: true })
      setValue('sort_order', 'desc', { shouldDirty: true })
    }

    await mutateFilteredProjects({
      ...(search && { name: search }),
      page: newIndex,
      sort_by: getValues('sort_by') || null,
      sort_order: getValues('sort_order') || null,
    } as projectSearchParams)
  }

  const applyFilters = async (values: any) => {
    const copyValues = { ...values }
    const undefinedValues = Object.keys(values).filter((val) => !values[val])

    for (let index = 0; index < undefinedValues.length; index++) {
      const currentKey = undefinedValues[index]
      delete copyValues[currentKey]
    }

    if (!getValues('sort_by')) {
      setValue('sort_by', 'score', { shouldDirty: true })
      setValue('sort_order', 'desc', { shouldDirty: true })
    }

    await mutateFilteredProjects({
      ...copyValues,
      page: 1,
      sort_by: getValues('sort_by') || null,
      sort_order: getValues('sort_order') || null,
    } as projectSearchParams)
  }

  const handleOnCloseCreateProjectModal = async () => {
    setIsCreatingProject(false)
    onClose()
  }

  const handleResquestFilteredResult: React.FormEventHandler<
    HTMLFormElement
  > = async (e) => {
    e.preventDefault()

    if (!getValues('sort_by')) {
      setValue('sort_by', 'score', { shouldDirty: true })
      setValue('sort_order', 'desc', { shouldDirty: true })
    }

    await mutateFilteredProjects({
      ...(search && { name: search }),
      page: 1,
      sort_by: getValues('sort_by') || null,
      sort_order: getValues('sort_order') || null,
    } as projectSearchParams)
  }

  return (
    <Box className="overflow-x-hidden h-full relative">
      <ProjectFilters
        search={search}
        onApply={applyFilters}
        isDisabled={isFetching}
      />

      <Box as="form" onSubmit={handleResquestFilteredResult}>
        <Box className="p-6">
          <Box as="h1" className="text-secondary-01 font-semibold text-lg pb-8">
            {t('projects.title')}
          </Box>

          <Box as="h3" className="text-secondary-01 font-semibold text-sm pb-2">
            {t('projects.search_title')}
          </Box>

          <Box className="h-8 w-1/2 ring-1 ring-secondary-01 flex items-center pl-2 mb-4 rounded-sm">
            <Box
              as="input"
              type="text"
              placeholder={t('projects.search_placeholder')}
              className="h-full w-full placeholder-neutral-05 px-2 text-sm text-secondary-01 focus:outline-none"
              value={search}
              onChange={handleChangeSearch}
            />
            <Box as="button">
              <Icon
                className="mr-2"
                type="submit"
                as={MdSearch}
                onClick={async () => {}}
              />
            </Box>
          </Box>

          <Box className="flex mb-4">
            <FormControl className="w-52">
              <Controller
                name="sort_by"
                control={control}
                render={({ field }) => (
                  <SelectSortBy
                    {...field}
                    value={getValues('sort_by')}
                    onChange={(newValue: any) => {
                      setValue('sort_by', newValue?.value)
                      field.onChange(newValue?.value)
                    }}
                    sortingDefault={getValues('sort_by')}
                    isClearable
                  />
                )}
              />
            </FormControl>

            <FormControl className="w-40 ml-2">
              <Controller
                name="sort_order"
                control={control}
                render={({ field }) => (
                  <SelectAscDesc
                    {...field}
                    value={getValues('sort_order')}
                    onChange={({ value }: any) => {
                      setValue('sort_order', value)
                      field.onChange(value)
                    }}
                    sortingDefault={getValues('sort_order')}
                  />
                )}
              />
            </FormControl>
          </Box>

          {usersData && clients && projects && (
            <ProjectsViewResult
              usersData={usersData}
              clients={clients}
              projects={projects.result}
            />
          )}

          {usersData && clients && projects && (
            <Pagination
              pageCount={projects.pages}
              onPagination={(newIndex) => {
                move(newIndex)
              }}
            />
          )}
        </Box>
      </Box>

      {policyValidation('projects.write') && (
        <MenuProjectButton
          size={20}
          icon={MdMenu}
          onCreateProject={() => {
            setIsCreatingProject(true)
            onOpen()
          }}
        />
      )}
      {isCreatingProject && (
        <CreateProject
          isOpen={isCreatingProject}
          onOpen={onOpen}
          onClose={handleOnCloseCreateProjectModal}
          funnels={funnels}
        />
      )}
    </Box>
  )
}

export default Projects
