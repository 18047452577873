import { Box, useSnackbar, Icon, useTheme, Checkbox } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdMenu, MdToggleOn, MdToggleOff } from 'react-icons/md'
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'

import PageLoader from 'components/PageLoader'
import useFunnels from '../hooks/useFunnels'
import CreateProject from './CreateProject'
import useProjects from '../hooks/useProjects'
import EditFunnel from './EditFunnel'
import CreateFunnel from './CreateFunnel'
import MotionBox from 'components/MotionBox'
import useActivateFunnel from '../hooks/useActivateFunnel'
import useSoftDeleteFunnel from '../hooks/useSoftDeleteFunnel'
import MenuFunnelButton from 'features/funnels/components/MenuFunnelButton'
import Avatar from 'components/Avatar'

import Tabs, { Tab } from 'components/Tabs'
import { useUser } from 'features/shared/state/user/userContext'
import { useDisclosure } from 'hooks/useDisclosure'
import { useSocketEvent } from 'state/socket/socketContext'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useUsers } from 'features/shared/hooks/useUsers'
import useTrackEvent from 'hooks/useTrackEvent'

export const funnelsQueryKey = 'funnels'

const Funnels: React.FC = () => {
  const [includeInactives, setIncludeInactives] = useState<boolean>(false)
  const { policyValidation } = usePolicyValidation()
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const [urlParams] = useSearchParams()
  const step = urlParams.get('step')
  const stepToScroll = step ? parseInt(step) : null
  const { funnelId } = useParams()
  const navigate = useNavigate()
  const { isLoading, data: funnels, refetch } = useFunnels(includeInactives)
  const { refetch: projectRefetch } = useProjects(funnelId || '')
  const { user } = useUser()
  const useSoftDelete = useSoftDeleteFunnel()
  const useActivate = useActivateFunnel()
  const [isMenuActionLoading, setIsMenuActionLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isCreatingProject, setIsCreatingProject] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const { onClose, onOpen } = useDisclosure()
  const notification = useSocketEvent('notification')
  const [animateFunnelCards, setAnimateFunnelCards] = useState(false)
  const { data: users } = useUsers()

  const { getThemeProp: theme } = useTheme()

  const { track } = useTrackEvent()

  const handleSoftDelete = async () => {
    try {
      setIsMenuActionLoading(true)
      await useSoftDelete.mutateAsync({
        funnelId: funnelId as string,
      })
      refetch()
      setIsMenuActionLoading(false)
    } catch {
      setIsMenuActionLoading(false)
    }
  }

  const handleActivateFunnel = async () => {
    try {
      setIsMenuActionLoading(true)
      await useActivate.mutateAsync({
        funnelId: funnelId as string,
      })
      refetch()
      setIsMenuActionLoading(false)
    } catch {
      setIsMenuActionLoading(false)
    }
  }

  const handleOnCloseCreateModal = async () => {
    setIsCreating(false)
    onClose()
  }

  const handleOnCloseEditModal = async () => {
    setIsEditing(false)
    onClose()
  }

  const handleOnCloseCreateProjectModal = async () => {
    setIsCreatingProject(false)
    onClose()
    projectRefetch()
  }

  const currentFunnelData = funnels?.find((funnel) => funnel.id === funnelId)

  useEffect(() => {
    if (funnels) {
      navigate(
        `./${funnelId && currentFunnelData ? funnelId : funnels[0].id}${
          stepToScroll !== null ? `?step=${stepToScroll}` : ''
        }`
      )
    }

    // eslint-disable-next-line
  }, [funnelId, funnels, currentFunnelData])

  useEffect(() => {
    if (funnelId) {
      track({
        eventName: 'funnels - detail',
        metadata: {
          funnelId,
        },
      })
    }

    // eslint-disable-next-line
  }, [funnelId])

  useEffect(() => {
    if (notification) {
      const { author, type, resource, data: incomingFunnel } = notification
      const { email: currentAuthor } = user
      const isCurrentAuthor = currentAuthor === author

      if (!isCurrentAuthor) {
        const incomingUser = users?.find((u) => u.email === author)
        const isUpdateFunnelAction =
          type === 'update' &&
          resource === 'funnel' &&
          incomingFunnel?.id === funnelId
        if (isUpdateFunnelAction) {
          setAnimateFunnelCards(true)
          refetch()
          snackbar.add({
            duration: 5000,
            status: 'info',
            content: (
              <>
                <Box as={'span'} className="flex items-center text-neutral-09">
                  <Box className="mr-4">
                    <Avatar
                      src={incomingUser?.avatar || ''}
                      size={9}
                      name={incomingUser?.name || ''}
                    />
                  </Box>
                  <Box className="mr-4">
                    <Box className="font-bold text-sm">
                      {incomingUser?.name || ''}
                    </Box>
                    <Box className="text-xs">
                      {users?.find((u) => u.email === author)?.email || ''}
                    </Box>
                  </Box>
                  <Box className="text-sm bg-info-02 text-info-01 py-1 px-2 mx-1 font-semibold rounded-sm">
                    {t('funnels.update_board_msg_whithout_user')}
                  </Box>
                </Box>
              </>
            ),
          })
        }
      }
    }

    // eslint-disable-next-line
  }, [notification])

  if (isLoading) return <PageLoader />

  return (
    <Box className="p-6 h-full flex flex-col relative overflow-hidden">
      <Box className="flex-grow-0 flex-shrink-0">
        <Box
          as="h1"
          className="text-secondary-01 font-semibold text-lg flex items-center mb-8"
        >
          {t('funnels.title')}

          <Box className="flex ml-4">
            <Checkbox
              isChecked={includeInactives}
              onChange={() => setIncludeInactives(!includeInactives)}
            >
              <Box as="span" className="hover:cursor-pointer">
                {t('funnels.include_inactives')}
              </Box>
            </Checkbox>
          </Box>
        </Box>

        <Tabs className="overflow-x-auto overflow-y-hidden">
          {funnels
            ?.filter(
              (funnel) => policyValidation('funnels.write') || funnel.active
            )
            .map((funnel) => (
              <Tab key={`funnelTab-${funnel.id}`} to={`./${funnel.id}`}>
                {funnel.name}
                {funnel.active ? (
                  <Icon as={MdToggleOn} size={25} />
                ) : (
                  <Icon as={MdToggleOff} size={25} opacity={0.5} />
                )}
              </Tab>
            ))}
        </Tabs>
      </Box>

      <Outlet
        context={{
          funnel: currentFunnelData,
          animateFunnelCards: animateFunnelCards,
          onCardDragged: () => setAnimateFunnelCards(false),
          refetchFunnel: refetch,
          stepToScroll,
          includeInactives,
        }}
      />

      {(policyValidation('funnels.write') ||
        policyValidation('projects.write')) && (
        <MenuFunnelButton
          funnelId={funnelId}
          size={20}
          icon={MdMenu}
          isActiveFunnel={currentFunnelData?.active}
          onDeleteFunnel={() => {
            handleSoftDelete()
          }}
          onActivateFunnel={() => {
            handleActivateFunnel()
          }}
          onCreateFunnel={() => {
            setIsCreating(true)
            track({
              eventName: 'funnels - create',
            })
            onOpen()
          }}
          onEditFunnel={() => {
            setIsEditing(true)
            track({
              eventName: 'funnels - edit',
              metadata: {
                funnelId,
              },
            })
            onOpen()
          }}
          onCreateProject={() => {
            setIsCreatingProject(true)
            onOpen()
          }}
        />
      )}
      {isMenuActionLoading && (
        <MotionBox
          className="flex items-center justify-center absolute top-0 left-0 w-full h-full flex-col"
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 10, opacity: 0 }}
        >
          <TailSpin width={60} height={60} color={theme('colors.primary.01')} />
        </MotionBox>
      )}

      {isCreating && (
        <CreateFunnel
          isOpen={isCreating}
          onOpen={onOpen}
          onClose={handleOnCloseCreateModal}
          currentFunnel={currentFunnelData}
        />
      )}

      {isEditing && (
        <EditFunnel
          isOpen={isEditing}
          onOpen={onOpen}
          onClose={handleOnCloseEditModal}
          currentFunnel={currentFunnelData}
        />
      )}

      {isCreatingProject && (
        <CreateProject
          isOpen={isCreatingProject}
          onOpen={onOpen}
          onClose={handleOnCloseCreateProjectModal}
          currentFunnel={currentFunnelData}
          funnels={funnels}
        />
      )}
    </Box>
  )
}

export default Funnels
