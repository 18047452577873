import { contactType } from './../types/contactType'
import { fetcher } from 'utils/fetcher'

export type createContactProps = {
  clientId: string
  contactData: Omit<contactType, 'id'>
}

async function createContact({ clientId, contactData }: createContactProps) {
  const response = await fetcher.post(
    `/crm-clients/clients/${clientId}/contacts`,
    contactData
  )

  return response
}

export default createContact
