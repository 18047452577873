import { projectType } from 'features/shared/types/projectType'
import { fetcher } from 'utils/fetcher'

type moveMilestoneProps = {
  projectId: string
  milestone: string
  position: number
}

async function moveMilestone({
  projectId,
  milestone,
  position,
}: moveMilestoneProps) {
  const project = await fetcher.put<projectType>(
    `/crm-clients/projects/${projectId}/milestones`,
    { milestone, position }
  )

  return project
}

export default moveMilestone
