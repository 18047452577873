import { categoryType } from 'features/shared/types/categoryType'
import { fetcher } from 'utils/fetcher'

export type deleteCategoryProps = Omit<categoryType, 'tenant'>

async function deleteCategory(categoryData: deleteCategoryProps) {
  return await fetcher.delete(`/crm-clients/categories/${categoryData.id}`)
}

export default deleteCategory
