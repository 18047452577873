import { userType } from 'features/shared/types/userType'
import { fetcher } from 'utils/fetcher'

async function updateUserRole(user: userType) {
  return await fetcher.put(
    `/landlord/users/${user.email}/roles/${user.role || 'default'}`
  )
}

export default updateUserRole
