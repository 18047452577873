import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useQuery } from 'react-query'
import getUsers from '../services/getUsers'
import { userType } from '../types/userType'

export const usersQueryKey = 'users'

function useUsers() {
  const { policyValidation } = usePolicyValidation()
  const canRead = policyValidation('users.read')
  const result = useQuery<userType[]>(usersQueryKey, getUsers, {
    refetchOnMount: false,
    enabled: canRead,
  })

  function getUserByEmail(email?: string) {
    if (result.isLoading || !email) return null

    return result.data?.find((u) => u.email === email)
  }

  return { ...result, getUserByEmail }
}

export { useUsers }
