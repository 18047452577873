import Avatar from 'components/Avatar'
import Select, { SelectProps } from 'components/Select'
import Tooltip from 'components/Tooltip'
import { Box } from 'lemon-system'
import { ComponentType, forwardRef, Fragment, useState } from 'react'
import {
  GroupBase,
  components,
  OptionProps,
  SingleValueProps,
} from 'react-select'
import RSSelectType from 'react-select/dist/declarations/src/Select'

const { Option, SingleValue, MultiValue, ValueContainer } = components

type selectType = {
  label: string
  value: string
}

type valueType = selectType | selectType[] | undefined

const IconOption: ComponentType<
  OptionProps<any, boolean, GroupBase<unknown>>
> = (props) => {
  return (
    <Option {...props}>
      <Box className="flex items-center">
        <Box className="mr-2">{props.data.iconSelected || props.data.icon}</Box>
        <Box>{props.data.label}</Box>
      </Box>
    </Option>
  )
}

const IconSingleValue: ComponentType<
  SingleValueProps<any, boolean, GroupBase<unknown>>
> = (props) => {
  return (
    <SingleValue {...props}>
      <Box className="flex items-center">
        <Box className="mr-2">{props.data.icon}</Box>
        <Box>{props.data.label}</Box>
      </Box>
    </SingleValue>
  )
}

const IconMultiValue: any = (props: any) => {
  return (
    <MultiValue {...props}>
      <Box className="flex items-center">
        <Box className="mr-2">{props.data.icon}</Box>
        <Box>{props.data.label}</Box>
      </Box>
    </MultiValue>
  )
}

const Empty: any = (props: any) => {
  return null
}

const MultiValueContainerGrouped = ({ children, ...props }: any) => {
  const {
    selectProps: { value: items },
  } = props
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )

  return (
    <ValueContainer {...props}>
      <Box
        ref={setReferenceElement}
        className="bg-neutral-01"
        onMouseEnter={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Avatar.Group size={5} max={2} className="cursor-pointer">
          {items?.map((i: any) => (
            <Fragment key={`${i.value}`}>{i.icon}</Fragment>
          ))}
        </Avatar.Group>
      </Box>
      <Tooltip
        classname="bg-neutral-01"
        isOpen={showTooltip}
        referenceElement={referenceElement}
        placement="bottom-start"
      >
        {items?.map((i: any) => (
          <Box className="flex p-1 items-center" key={`${i.value}`}>
            {i.icon}
            <Box className="ml-2 text-sm text-secondary-01">{i.label}</Box>
          </Box>
        ))}
      </Tooltip>
      {children}
    </ValueContainer>
  )
}

const SelectIcon = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  SelectProps
>(({ value, defaultValue, isMultiGrouped, ...props }, ref) => {
  const options = props.options as any

  const parseValues = (rawValue: valueType) => {
    let valuesWithIcon

    if (Array.isArray(rawValue))
      valuesWithIcon = rawValue.map((selectData) =>
        options?.find((option: any) => option.value === selectData.value)
      )

    if (!Array.isArray(rawValue) && rawValue?.value)
      valuesWithIcon = options?.find(
        (option: any) => option.value === rawValue.value
      )

    return valuesWithIcon
  }

  return (
    <Select
      {...(isMultiGrouped && { isMulti: true })}
      {...props}
      ref={ref}
      value={parseValues(value as valueType)}
      defaultValue={parseValues(defaultValue as valueType)}
      components={{
        Option: IconOption,
        SingleValue: IconSingleValue,
        MultiValue: IconMultiValue,
        ...(isMultiGrouped && {
          ValueContainer: MultiValueContainerGrouped,
          MultiValueRemove: Empty,
          MultiValue: Empty,
        }),
      }}
    />
  )
})

export default SelectIcon
