import { fetcher } from 'utils/fetcher'

type updateMilestoneProps = {
  projectId: string
  milestoneId: string
  name: string
  deadline: Date | null
  assignees: string[] | null
}

async function updateMilestone({
  projectId,
  milestoneId,
  name,
  deadline,
  assignees,
}: updateMilestoneProps) {
  const result = await fetcher.put(
    `/crm-clients/projects/${projectId}/milestones/${milestoneId}`,
    { name, deadline, assignees }
  )
  return result
}

export default updateMilestone
