import { areaType } from 'features/shared/types/areaType'
import { fetcher } from 'utils/fetcher'

export type deleteAreaProps = Omit<areaType, 'tenant'>

async function createArea(areaData: deleteAreaProps) {
  return await fetcher.delete(`/crm-clients/areas/${areaData.id}`)
}

export default createArea
