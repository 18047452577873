import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import reorderProjectsByFunnelId from '../services/reorderProjectsByFunnelId'
import { funnelsQueryKey } from './useFunnels'
import { funnelType } from '../types/funnelType'
import { defaultActionContent } from 'features/shared/utils'

function useReorderProjects(includeInactives: boolean) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(reorderProjectsByFunnelId, {
    onMutate: async ({
      funnelId,
      position: newPosition,
      projectId,
      step: newStep,
    }) => {
      await queryClient.cancelQueries(funnelsQueryKey)

      const previousFunnels = queryClient.getQueryData([
        funnelsQueryKey,
        includeInactives,
      ])

      queryClient.setQueryData(
        [funnelsQueryKey, includeInactives],
        (oldFunnels) => {
          const newFunnels = (oldFunnels as funnelType[])?.map((funnel) => {
            if (funnel.id !== funnelId) return funnel

            const cleanSteps = funnel.steps.map((step) =>
              step.projects.includes(projectId)
                ? {
                    ...step,
                    projects: step.projects.filter(
                      (project) => project !== projectId
                    ),
                  }
                : step
            )

            const newSteps = cleanSteps.map((step, stepIndex) => {
              const newProjects = [...step.projects]

              if (newStep === stepIndex) {
                newProjects.splice(newPosition, 0, projectId)
              }

              return { ...step, projects: newProjects }
            })

            return {
              ...funnel,
              steps: newSteps,
            }
          })

          return newFunnels
        }
      )

      return { previousFunnels }
    },
    onSuccess: (updatedFunnel: funnelType, variables) => {
      queryClient.setQueryData(funnelsQueryKey, (oldFunnels) =>
        (oldFunnels as funnelType[])?.map((funnel) =>
          funnel.id === variables.funnelId ? updatedFunnel : funnel
        )
      )
    },
    onError: (_err, _, context: any) => {
      queryClient.setQueryData(funnelsQueryKey, context.previousFunnels)

      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useReorderProjects
