import { Box, useSnackbar, Icon, Button } from 'lemon-system'
import { useState } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import useFunnels from '../hooks/useFunnels'
import useCreateFunnel from '../hooks/useCreateFunnel'

import { funnelStepType, funnelType } from '../types/funnelType'
import { createFunnelProps } from '../services/createFunnel'
import { Modal } from 'components/Modal'
import FormControl from 'components/FormControl'
import Input from 'components/Input'
import { defaultActionContent } from 'features/shared/utils'
import useTrackEvent from 'hooks/useTrackEvent'

export const funnelsQueryKey = 'funnels'

const defaultFunnel = {
  id: '',
  tenant: '',
  active: true,
  name: '',
  steps: [] as funnelStepType[],
}

interface CreateFunnelsProps {
  isOpen?: boolean
  onOpen: () => void
  onClose: () => void
  onError?: (error?: any) => void
  currentFunnel?: any
}

const CreateFunnel: React.FC<CreateFunnelsProps> = ({
  isOpen = false,
  onOpen,
  onClose,
  onError,
  currentFunnel,
}) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { refetch } = useFunnels()
  const useCreate = useCreateFunnel()
  const [funnel, setFunnel] = useState<funnelType>(defaultFunnel)

  const { track } = useTrackEvent()

  const funnelDataParsed = (area: funnelType) => {
    const { id, tenant, ...rest } = area
    return rest as createFunnelProps
  }

  const handleCreateFunnel = async () => {
    try {
      await useCreate.mutateAsync(funnelDataParsed(funnel))
      track({
        eventName: 'funnels - create - done',
      })
      refetch()
      onClose()
      setFunnel(defaultFunnel)
    } catch {
      onError?.(new Error(t('funnels.generic_error')))
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const minStepLength = 2
    const steps = funnel.steps
    const stepsLength = steps.length
    const isValidLength = stepsLength >= minStepLength
    const isValidStepContent =
      isValidLength && steps.every((step) => !!step.name)
    const isValidName = !!funnel.name
    const isValidFunnel = isValidName && isValidLength && isValidStepContent
    if (!isValidFunnel) {
      const message = t('funnels.schema_error_validation')
      return snackbar.add({
        duration: 5000,
        status: 'error',
        content: message,
        actionContent: defaultActionContent,
      })
    }
    handleCreateFunnel()
  }

  const handleStep = (index: number, value: string) => {
    const newSteps = [...funnel.steps]
    newSteps[index].name = value
    setFunnel({
      ...funnel,
      steps: newSteps,
    })
  }

  const handleRate = (index: number, value: string) => {
    const newSteps = [...funnel.steps]
    newSteps[index].rate = Number(value)
    setFunnel({
      ...funnel,
      steps: newSteps,
    })
  }

  const handleName = (name: string) => {
    setFunnel({
      ...funnel,
      name,
    })
  }

  return (
    <Box className="flex">
      <Modal
        onClose={() => {
          track({
            eventName: 'funnels - create - cancel',
          })
          onClose()
        }}
        isOpen={isOpen}
        size="sm"
      >
        <Modal.Overlay />
        <Modal.Content as="form" onSubmit={handleSubmit}>
          <Modal.Header>{t('funnels.create_funnel')}</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <FormControl isRequired>
              <FormControl.Label>{t('funnels.name')}</FormControl.Label>

              <Input
                size="md"
                autoComplete="off"
                name="name"
                placeholder={t('funnels.name')}
                value={funnel.name || ''}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  handleName((e.target as HTMLInputElement).value)
                }
              />
            </FormControl>

            <Box className="mt-3 pt-5 pb-3 border-b-2 border-neutral-02 flex items-end justify-between">
              <Box
                as="span"
                className="text-secondary-01 font-semibold text-sm"
              >
                {t('funnels.steps')}
              </Box>

              <Button
                color="primary"
                size="xs"
                variant="outlined"
                onClick={() => {
                  const newSteps = [...funnel.steps]

                  newSteps.push({ name: '' } as funnelStepType)

                  setFunnel({
                    ...funnel,
                    steps: newSteps,
                  })
                }}
              >
                {t('funnels.add_step')}
              </Button>
            </Box>

            {funnel.steps?.map((step, index) => (
              <Box
                key={index}
                className="py-5 border-b-2 border-neutral-02 last:(pb-0 border-none)"
              >
                <Box className="flex items-end justify-between mb-1">
                  <Box
                    as="label"
                    htmlFor={`step${index}`}
                    className="text-secondary-01 font-semibold text-xs"
                  >
                    {`${t('funnels.step')} ${index + 1}`}
                  </Box>

                  <Button
                    size="xs"
                    variant="outlined"
                    style={{
                      minWidth: 'fit-content',
                    }}
                    onClick={() => {
                      const newSteps = [...funnel.steps]

                      newSteps.splice(index, 1)

                      setFunnel({
                        ...funnel,
                        steps: newSteps,
                      })
                    }}
                  >
                    <Icon as={MdOutlineCancel} />
                  </Button>
                </Box>

                <Box className="flex space-x-3">
                  <Input
                    size="md"
                    autoComplete="off"
                    isRequired
                    id={`step${index}`}
                    name={`step${index}`}
                    placeholder={t('funnels.name')}
                    minLength={2}
                    value={step.name || ''}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      handleStep(index, (e.target as HTMLInputElement).value)
                    }
                  />

                  <Input
                    size="md"
                    autoComplete="off"
                    isRequired
                    name={`rate${index}`}
                    placeholder={t('funnels.rate')}
                    type="number"
                    value={step.rate ?? ''}
                    min={0}
                    max={100}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      handleRate(index, (e.target as HTMLInputElement).value)
                    }
                  />
                </Box>
              </Box>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">{t('save')}</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  )
}

export default CreateFunnel
