import { useFormControl } from 'components/FormControl'
import { Box } from 'lemon-system'
import { forwardRef } from 'react'

export type InputSizes = 'xs' | 'sm' | 'md' | 'lg'

export interface InputProps
  extends Omit<
    React.ComponentPropsWithoutRef<'input'>,
    'size' | 'disabled' | 'required'
  > {
  size?: InputSizes
  isInvalid?: boolean
  isDisabled?: boolean
  isRequired?: boolean
}

const sizeStyles: { [key in InputSizes]: string } = {
  xs: 'h-8 px-3 text-sm',
  sm: 'h-9 px-4 text-sm',
  md: 'h-10 px-5 text-md',
  lg: 'h-12 px-6 text-md',
}

const baseStyles =
  'border-[1.5px] text-secondary-01 rounded-sm w-full placeholder-neutral-05'
const hoverStyles = 'hover:shadow-xs'
const focusStyles = 'focus:(outline-none border-brand-03)'
const disabledStyles = 'disabled:(text-disabled-04 border-disabled-01)'

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = 'sm',
      isInvalid = false,
      isDisabled = false,
      isRequired = false,
      className = '',
      ...props
    },
    ref
  ) => {
    const formControl = useFormControl()
    const conditionalInvalid = formControl?.isInvalid || isInvalid
    const conditionalRequired = formControl?.isRequired || isRequired

    const borderColor = conditionalInvalid ? 'error-01' : 'secondary-01'

    return (
      <Box
        {...props}
        disabled={isDisabled}
        required={conditionalRequired}
        ref={ref}
        className={`${baseStyles} border-${borderColor} ${
          isDisabled ? '' : hoverStyles
        } ${disabledStyles} ${focusStyles} ${sizeStyles[size]} ${className}`}
        as="input"
      />
    )
  }
)

export default Input
