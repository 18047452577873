import Avatar from 'components/Avatar'
import PlaceholderLoader from 'components/PlaceholderLoader'
import { SelectProps, SelectSizes } from 'components/Select'
import SelectIcon from 'components/SelectIcon'
import useMoneys from 'features/shared/hooks/useMoneys'
import { moneyType } from 'features/shared/types/moneyType'
import { forwardRef } from 'react'
import { GroupBase } from 'react-select'
import RSSelectType from 'react-select/dist/declarations/src/Select'

const sizeStyles: { [key in SelectSizes]: string } = {
  xs: 'h-8',
  sm: 'h-9',
  md: 'h-10',
  lg: 'h-12',
}

const SelectCurrency = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  Omit<SelectProps, 'options'>
>(({ size = 'sm', defaultValue = '', ...props }, ref) => {
  const { isLoading, data: moneys } = useMoneys()

  if (isLoading)
    return (
      <PlaceholderLoader className={`w-full rounded-sm ${sizeStyles[size]}`} />
    )

  return (
    <SelectIcon
      {...props}
      ref={ref}
      options={moneys?.map((money: moneyType) => ({
        value: money.Code,
        label: money.Name,
        icon: <Avatar id={money.Name} name={money.Name} size={5} />,
      }))}
      defaultValue={{
        label: moneys?.find((money) => money.Code === defaultValue)?.Name,
        value: defaultValue,
        icon: (
          <Avatar
            id={defaultValue as string}
            name={defaultValue as string}
            size={5}
          />
        ),
      }}
      size={size}
    />
  )
})

export default SelectCurrency
