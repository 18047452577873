import { Box, Icon } from 'lemon-system'
import { useQuery } from 'react-query'
import { Link, Outlet, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MdArrowBack } from 'react-icons/md'
import PageLoader from 'components/PageLoader'
import Avatar from 'components/Avatar'
import routes from 'routes/routing'
import getClient from 'features/shared/services/getClient'
import Tabs, { Tab } from 'components/Tabs'
import { clientType } from 'features/shared/types/clientType'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { policiesType } from 'features/shared/types/policiesType'

export const clientTabs = [
  { title: 'clients.projects', path: './', allowedPolicy: 'projects.read' },
  {
    title: 'clients.contacts',
    path: `./${routes.client_detail_contacts}`,
    allowedPolicy: 'contacts.read',
  },
]

const ClientDetail: React.FC = () => {
  const { t } = useTranslation()
  const { clientId } = useParams<{ clientId: string }>()
  const { isLoading, data: client } = useQuery<clientType>(
    `client-${clientId}`,
    () => getClient(clientId)
  )
  const { policyValidation: validator } = usePolicyValidation()
  const allowedTabs = clientTabs.filter((t) =>
    validator(t.allowedPolicy as policiesType)
  )

  if (isLoading) return <PageLoader />

  return (
    <Box className="p-6">
      <Box className="pb-8 flex items-center">
        <Box as={Link} to="../" className="block mr-2">
          <Icon as={MdArrowBack} size={24} />
        </Box>

        <Box
          as="h1"
          className="text-primary-01 font-semibold text-lg flex items-center"
        >
          <Avatar size={9} id={clientId} name={client?.name} />
          <Box className="ml-4">{client?.name}</Box>
        </Box>
      </Box>

      <Tabs>
        {allowedTabs.map((tab) => (
          <Tab to={tab.path} key={`TAB-${tab.title}`}>
            {t(tab.title)}
          </Tab>
        ))}
      </Tabs>

      <Outlet />
    </Box>
  )
}

export default ClientDetail
