import Avatar from 'components/Avatar'
import PlaceholderLoader from 'components/PlaceholderLoader'
import { SelectProps, SelectSizes } from 'components/Select'
import SelectIcon from 'components/SelectIcon'
import useFunnels from 'features/funnels/hooks/useFunnels'
import { funnelType } from 'features/funnels/types/funnelType'
import { forwardRef, useMemo } from 'react'
import { GroupBase } from 'react-select'
import RSSelectType from 'react-select/dist/declarations/src/Select'

const sizeStyles: { [key in SelectSizes]: string } = {
  xs: 'h-8',
  sm: 'h-9',
  md: 'h-10',
  lg: 'h-12',
}

const SelectFunnel = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  Omit<SelectProps, 'options'>
>(({ size = 'sm', defaultValue = '', ...props }, ref) => {
  const { isLoading, data: funnels } = useFunnels()
  const defaultItem = useMemo(() => {
    if (!defaultValue) return []

    const found = funnels?.find((funnel) => funnel.id === defaultValue)
    return found ? [found] : []
  }, [defaultValue, funnels])

  if (isLoading)
    return (
      <PlaceholderLoader className={`w-full rounded-sm ${sizeStyles[size]}`} />
    )

  return (
    <SelectIcon
      {...props}
      ref={ref}
      options={funnels?.map((funnel: funnelType) => ({
        value: funnel.id,
        label: funnel.name,
        icon: <Avatar id={funnel.name} name={funnel.name} size={5} />,
      }))}
      defaultValue={defaultItem.map((i) => ({
        label: i.name,
        value: i.id,
        icon: <Avatar id={i.id as string} name={i.name as string} size={5} />,
      }))}
      size={size}
    />
  )
})

export default SelectFunnel

