import { format, parseISO } from 'date-fns'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Checkbox } from 'lemon-system'
import { useMemo, useState } from 'react'
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { MdEvent, MdPeople } from 'react-icons/md'
import AddMilestone from '../components/AddMilestone'
import AddTask from '../components/AddTask'
import DeleteMilestone from '../components/DeleteMilestone'
import DeleteTask from '../components/DeleteTask'
import DraggableMilestones from '../components/DraggableMilestones'
import DraggableTasks from '../components/DraggableTasks'
import DroppableMilestones from '../components/DroppableMilestones'
import DroppableTasks from '../components/DroppableTasks'
import EditMilestone from '../components/EditMilestone'
import EditTask from '../components/EditTask'
import TaskCell from '../components/TaskCell'
import TaskCellHeader from '../components/TaskCellHeader'
import UsersList from '../components/UsersList'
import useCurrentProject from '../hooks/useCurrentProject'
import useMoveMilestone from '../hooks/useMoveMilestone'
import useMoveTask from '../hooks/useMoveTask'
import usePlaceholder from '../hooks/usePlaceholder'
import useUpdateMilestoneStatus from '../hooks/useUpdateMilestoneStatus'
import useUpdateTaskStatus from '../hooks/useUpdateTaskStatus'

const ProjectsTasks: React.FC = () => {
  const [isDisabledDroppable, setisDisabledDroppable] = useState(false)
  const project = useCurrentProject()
  const moveMilestone = useMoveMilestone(project.id)
  const moveTask = useMoveTask(project.id)
  const updateMilestoneStatus = useUpdateMilestoneStatus(project.id)
  const updateTaskStatus = useUpdateTaskStatus(project.id)
  const { t } = useTranslation()

  const { calculatePlaceholderProps, clearPlaceholder, placeholderProps } =
    usePlaceholder()

  const { policyValidation } = usePolicyValidation()
  const { canUsersRead, canTasksWrite } = useMemo(
    () => ({
      canUsersRead: policyValidation('users.read'),
      canTasksWrite: policyValidation('projects_tasks.write'),
    }),

    // eslint-disable-next-line
    []
  )

  const { track } = useTrackEvent({
    trackOnMount: {
      eventName: 'projects - work',
      metadata: {
        projectId: project.id,
      },
    },
  })

  const firstIndex = 0

  const handleDragStart = (event: DragStart) => {
    calculatePlaceholderProps({
      draggableId: event.draggableId,
      source: event.source,
    })

    track({
      eventName: `projects - work - ${event.type} - drag`,
      metadata: {
        projectId: project.id,
      },
    })
  }

  const handleDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return
    }

    setisDisabledDroppable(event.destination.index === firstIndex)

    calculatePlaceholderProps({
      draggableId: event.draggableId,
      source: event.source,
      destination: event.destination,
    })
  }

  const handleDragEnd = (result: DropResult) => {
    clearPlaceholder()
    setisDisabledDroppable(false)

    const { source, destination, type, draggableId } = result
    const isOutsideTheList = !destination
    const isSamePlace =
      source.index === destination?.index &&
      source.droppableId === destination?.droppableId

    if (isOutsideTheList || isSamePlace) {
      track({
        eventName: `projects - work - ${type} - drag - cancel`,
        metadata: {
          projectId: project.id,
        },
      })

      return
    }

    if (type === 'milestones') {
      if (destination?.index === firstIndex) return

      moveMilestone.mutate({
        projectId: project.id,
        milestone: draggableId,
        position: destination.index,
      })
    }

    if (type === 'tasks') {
      moveTask.mutate({
        milestoneIdFrom: source.droppableId,
        milestoneIdTo: destination.droppableId,
        position: destination.index,
        projectId: project.id,
        taskId: draggableId,
      })
    }

    track({
      eventName: `projects - work - ${type} - drag - done`,
      metadata: {
        projectId: project.id,
      },
    })
  }

  // TODO: Replace with src/utils/parseDate.ts
  const parseDate = (date: string | null) => {
    if (!date) return '--/--/----'

    return format(parseISO(date), 'dd/MM/yyyy')
  }

  const handleChangeMilestone =
    (milestoneId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      updateMilestoneStatus.mutate({
        milestoneId,
        projectId: project.id,
        status: e.target.checked,
      })

      track({
        eventName: `projects - work - milestones - ${
          e.target.checked ? 'complete' : 'uncomplete'
        }`,
        metadata: {
          projectId: project.id,
        },
      })
    }

  const handleChangeTask =
    (milestoneId: string, taskId: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateTaskStatus.mutate({
        milestoneId,
        taskId,
        projectId: project.id,
        status: e.target.checked,
      })

      track({
        eventName: `projects - work - tasks - ${
          e.target.checked ? 'complete' : 'uncomplete'
        }`,
        metadata: {
          projectId: project.id,
        },
      })
    }

  return (
    <Box>
      {canTasksWrite && <AddMilestone projectId={project.id} />}

      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
        <DroppableMilestones
          isDisabledDroppable={isDisabledDroppable}
          placeholderProps={placeholderProps}
        >
          {project?.milestones.map((milestone, milestoneIndex) => (
            <DraggableMilestones
              isDisabled={!canTasksWrite}
              key={milestone.id}
              id={milestone.id}
              index={milestoneIndex}
              isDisabledDroppable={isDisabledDroppable}
              milestoneActions={
                canTasksWrite && (
                  <AddTask projectId={project.id} milestoneId={milestone.id} />
                )
              }
              rowCells={
                <>
                  <TaskCell as="label" type="fluid">
                    <Box className="text-lg flex flex-row items-center">
                      {canTasksWrite && (
                        <Checkbox
                          onChange={handleChangeMilestone(milestone.id)}
                          isChecked={milestone.status}
                        />
                      )}
                      {milestone.name}
                    </Box>
                  </TaskCell>

                  <TaskCell
                    header={
                      <TaskCellHeader icon={MdEvent}>
                        {t('projects.completed_at')}
                      </TaskCellHeader>
                    }
                  >
                    {parseDate(milestone.completed_at)}
                  </TaskCell>
                  <TaskCell
                    header={
                      <TaskCellHeader icon={MdEvent}>
                        {t('projects.deadline')}
                      </TaskCellHeader>
                    }
                  >
                    {parseDate(milestone.deadline)}
                  </TaskCell>

                  {canUsersRead && (
                    <TaskCell
                      header={
                        <TaskCellHeader icon={MdPeople}>
                          {t('projects.assignees')}
                        </TaskCellHeader>
                      }
                    >
                      <UsersList userEmails={milestone.assignees} />
                    </TaskCell>
                  )}

                  {canTasksWrite && (
                    <TaskCell>
                      <Box className="space-x-2 flex">
                        <DeleteMilestone
                          milestoneId={milestone.id}
                          projectId={project.id}
                        />
                        <EditMilestone
                          milestone={milestone}
                          projectId={project.id}
                        />
                      </Box>
                    </TaskCell>
                  )}
                </>
              }
            >
              <DroppableTasks id={milestone.id}>
                {milestone.tasks.map((task, taskIndex) => (
                  <DraggableTasks
                    isDisabled={!canTasksWrite}
                    id={task.id}
                    index={taskIndex}
                    key={task.id}
                  >
                    <TaskCell as="label" type="fluid">
                      <Box className="text-lg flex flex-row items-center">
                        {canTasksWrite && (
                          <Checkbox
                            onChange={handleChangeTask(milestone.id, task.id)}
                            isChecked={task.status}
                          />
                        )}
                        {task.name}
                      </Box>
                    </TaskCell>

                    <TaskCell>{parseDate(task.completed_at)}</TaskCell>

                    <TaskCell>{parseDate(task.deadline)}</TaskCell>

                    {canUsersRead && (
                      <TaskCell>
                        <UsersList userEmails={task.assignees} />
                      </TaskCell>
                    )}

                    {canTasksWrite && (
                      <TaskCell>
                        <Box className="space-x-2 flex">
                          <DeleteTask
                            taskId={task.id}
                            projectId={project.id}
                            milestoneId={milestone.id}
                          />
                          <EditTask
                            task={task}
                            projectId={project.id}
                            milestoneId={milestone.id}
                          />
                        </Box>
                      </TaskCell>
                    )}
                  </DraggableTasks>
                ))}
              </DroppableTasks>
            </DraggableMilestones>
          ))}
        </DroppableMilestones>
      </DragDropContext>
    </Box>
  )
}

export default ProjectsTasks
