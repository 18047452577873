import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import deleteHolding from '../services/deleteHolding'

import { holdingType } from 'features/shared/types/holdingType'
import { defaultActionContent } from 'features/shared/utils'

const useDeleteHolding = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(deleteHolding, {
    onSuccess: (deletedHolding: holdingType) => {
      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('holdings.success_delete'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useDeleteHolding
