import { useDisclosure } from 'hooks/useDisclosure'
import { MdMenu } from 'react-icons/md'
import useCreateMilestone from 'features/projects/hooks/useCreateMilestone'
import { useLayout } from 'components/Layout'
import SaveResourceModal, { saveProps } from '../SaveResourceModal'
import { useTranslation } from 'react-i18next'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button, Icon } from 'lemon-system'
import { useState } from 'react'
import LoadWorkTemplateModal from '../LoadWorkTemplateModal'
import usePatchMilestonesFromWorkTemplate from 'features/projects/hooks/usePatchMilestonesFromWorkTemplate'

interface AddMilestoneProps {
  projectId: string
}

const AddMilestone: React.FC<AddMilestoneProps> = ({ projectId }) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const {
    onClose: onCloseWt,
    isOpen: isOpenWt,
    onOpen: onOpenWt,
  } = useDisclosure()
  const { t } = useTranslation()
  const { mutateAsync, isLoading } = useCreateMilestone(projectId)
  const { mutateAsync: mutateAsyncWt, isLoading: isLoadingWt } =
    usePatchMilestonesFromWorkTemplate()
  const layout = useLayout()
  const { track } = useTrackEvent()
  const [show, setShow] = useState(false)

  const handleSave = async (values: saveProps) => {
    await mutateAsync({
      projectId,
      ...values,
    })

    track({
      eventName: 'projects - work - milestones - create - done',
      metadata: {
        projectId,
      },
    })

    layout.scrollToBottom()

    onClose()
  }

  return (
    <>
      <Box className="fixed right-6 bottom-5 z-popover">
        <Box
          className={`bottom-full flex flex-col items-end space-y-5 mb-5 ${
            !show ? 'hidden' : ''
          }`}
        >
          <Button title={t('projects.add_milestone')} onClick={() => onOpen()}>
            {t('projects.add_milestone')}
          </Button>

          <Button
            title={t('projects.add_from_work_template')}
            onClick={() => onOpenWt()}
          >
            {t('projects.add_from_work_template')}
          </Button>
        </Box>

        <Box className="flex justify-end">
          <Button
            className="bg-primary-01 override:(rounded-full min-w-0 w-18 h-18)"
            onClick={() => setShow(!show)}
          >
            <Icon as={MdMenu} size={20 * 1.5} />
          </Button>
        </Box>
      </Box>

      <SaveResourceModal
        onClose={() => {
          track({
            eventName: 'projects - work - milestones - create - cancel',
            metadata: {
              projectId,
            },
          })
          onClose()
        }}
        isOpen={isOpen}
        isLoading={isLoading}
        onSave={handleSave}
        title={t('projects.add_milestone')}
      />

      <LoadWorkTemplateModal
        onSave={async (workTemplateId) => {
          // TODO: Add mixpanel track.
          await mutateAsyncWt({ projectId, workTemplateId })
          onCloseWt()
        }}
        onClose={() => onCloseWt()}
        isOpen={isOpenWt}
        isLoading={isLoadingWt}
      />
    </>
  )
}

export default AddMilestone
