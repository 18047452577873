import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import getFilesByProjectId from '../services/getFilesByProjectId'

export const filesQueryKey = 'project_documents'

function useFiles(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useQuery(
    [filesQueryKey, projectId],
    () => getFilesByProjectId(projectId),
    {
      onError: () =>
        snackbar.add({
          duration: 3000,
          content: t('unexpected_error'),
          status: 'error',
          actionContent: defaultActionContent,
        }),
    }
  )
}

export default useFiles
