import { fetcher } from 'utils/fetcher'

export type createCommentProps = {
  projectId: string
  comment: string
}

async function createComment({
  projectId,
  comment,
}: createCommentProps) {
  const result = await fetcher.post(
    `/crm-clients/projects/${projectId}/comments`,
    {
      message: comment,
    }
  )

  return result
}

export default createComment
