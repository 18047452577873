import { fetcher } from 'utils/fetcher'

type createTaskProps = {
  projectId: string
  milestoneId: string
  name: string
  deadline: Date | null
  assignees: string[] | null
}

async function createTask({
  projectId,
  milestoneId,
  name,
  deadline,
  assignees,
}: createTaskProps) {
  const result = await fetcher.post(
    `/crm-clients/projects/${projectId}/milestones/${milestoneId}/tasks`,
    {
      name,
      deadline,
      assignees,
    }
  )

  return result
}

export default createTask
