function splitFileName(fileName: string) {
  const ext = fileName.split('.').pop()
  const extWithDot = `.${ext}`

  return {
    fileName: fileName.replace(extWithDot, ''),
    fileExt: ext !== fileName ? extWithDot : null,
  }
}

export default splitFileName
