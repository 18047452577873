import queryClient from 'configs/reactQuery/queryClient'
import { workTemplates } from 'features/shared/types/workTemplates'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import deleteWorkTemplates from '../services/deleteWorkTemplates'
import { workTemplatesKey } from './useWorkTemplates'

const useDeleteWorkTemplates = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(deleteWorkTemplates, {
    onSuccess: (_: workTemplates, workTemplate: workTemplates) => {
      const workTemplates = queryClient.getQueryData(
        workTemplatesKey
      ) as workTemplates[]
      const filtered = workTemplates.filter((w) => w?.id !== workTemplate?.id)

      queryClient.setQueryData(workTemplatesKey, filtered)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('work_templates.delete_success'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useDeleteWorkTemplates
