import { Button, Icon } from 'lemon-system'
import { MdCheck } from 'react-icons/md'

interface ConfirmButtonProps {
  className?: string
  onClick?: () => void
  isDisabled?: boolean
  size?: any
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  className,
  onClick,
  isDisabled,
  size = 'xs',
}) => (
  <Button
    size={size}
    className={className}
    style={{
      minWidth: 'fit-content',
    }}
    onClick={onClick}
    variant="outlined"
    isDisabled={isDisabled}
    type="submit"
  >
    <Icon as={MdCheck} />
  </Button>
)

export default ConfirmButton
