import Avatar from 'components/Avatar'
import { Modal } from 'components/Modal'
import { format, parseISO } from 'date-fns'
import parseProjectHistoryChanges from 'features/projects/utils/parseProjectHistoryChanges'
import { projectHistoryType } from 'features/shared/types/projectHistoryType'
import { projectType } from 'features/shared/types/projectType'
import { userType } from 'features/shared/types/userType'
import { exists } from 'i18next'
import { Box } from 'lemon-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface ProjectHistoryDetailModalProps {
  project?: projectType
  projectHistoryItem?: projectHistoryType
  users: userType[]
  onClose: () => void
  isOpen: boolean
}

const ProjectHistoryDetailModal: React.FC<ProjectHistoryDetailModalProps> = ({
  project,
  projectHistoryItem,
  users,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation()
  const author = useMemo(
    () => users.find((u) => u.email === projectHistoryItem?.author),
    [users, projectHistoryItem?.author]
  )

  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnEsc closeOnOverlayClick>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Box className="flex pr-12">
            <Box as="span">{project?.name}</Box>
          </Box>
        </Modal.Header>
        <Modal.CloseButton />
        <Modal.Body>
          <Box className="space-y-2">
            <Box className="flex text-sm items-center pb-2">
              <Box className="text-neutral-08 font-semibold min-w-24">
                {t('projects.history.author')}
              </Box>
              <Box className="flex text-neutral-07 font-semibold items-center">
                <Box className="flex">
                  <Avatar size={7} id={author?.id} name={author?.name} />
                </Box>
                <Box as="span" className="ml-2">
                  {author?.name}
                </Box>
              </Box>
            </Box>

            <Box className="flex text-sm items-center py-2">
              <Box className="text-neutral-08 font-semibold min-w-24">
                {t('projects.history.date')}
              </Box>
              <Box className="flex text-neutral-07 font-semibold items-center">
                {projectHistoryItem?.date &&
                  format(
                    parseISO(projectHistoryItem?.date),
                    'dd/MM/yyyy - HH:mm:ss'
                  )}
              </Box>
            </Box>

            <Box className="flex text-sm items-center py-2">
              <Box className="text-neutral-08 font-semibold min-w-24">
                {t('projects.history.action')}
              </Box>
              <Box className="flex text-neutral-07 font-semibold items-center">
                {t(`projects.history.actions.${projectHistoryItem?.action}`)}{' '}
                {exists(
                  `projects.history.resource.${projectHistoryItem?.resource}`
                )
                  ? t(
                      `projects.history.resource.${projectHistoryItem?.resource}`
                    )
                  : projectHistoryItem?.resource}
                :
                <Box as="span" className="ml-2 font-bold">
                  {projectHistoryItem?.subject}
                </Box>
              </Box>
            </Box>

            <Box className="flex text-sm items-center py-2">
              <Box className="text-neutral-08 font-semibold min-w-24">
                {t('projects.history.before')}
              </Box>
              <Box className="text-neutral-07 font-semibold items-center">
                {Object.keys(projectHistoryItem?.summary || {}).map(
                  (key, idx) => (
                    <Box className="flex w-full" key={`sum-${idx}`}>
                      <Box as="span" className="font-bold">
                        {key}:
                      </Box>
                      <Box
                        as="span"
                        className="break-all text-error-01 line-through ml-2"
                      >
                        {parseProjectHistoryChanges(
                          projectHistoryItem?.summary[key].from
                        )}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>

            <Box className="flex text-sm items-center py-2">
              <Box className="text-neutral-08 font-semibold min-w-24">
                {t('projects.history.after')}
              </Box>
              <Box className="text-neutral-07 font-semibold items-center">
                {Object.keys(projectHistoryItem?.summary || {}).map(
                  (key, idx) => (
                    <Box className="flex w-full" key={`sum-${idx}`}>
                      <Box as="span" className="font-bold">
                        {key}:
                      </Box>
                      <Box
                        as="span"
                        className="break-all text-tertiary-01 ml-2"
                      >
                        {parseProjectHistoryChanges(
                          projectHistoryItem?.summary[key].to
                        )}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default ProjectHistoryDetailModal

