import { Dialog } from 'components/Modal'
import { Button } from 'lemon-system'
import { useTranslation } from 'react-i18next'

interface MarkAsFinishedResourceProps {
  onClose: () => void
  isOpen: boolean
  isLoading: boolean
  onConfirm: () => void
  title: string
}

const MarkAsFinishedResource: React.FC<MarkAsFinishedResourceProps> = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  title,
}) => {
  const { t } = useTranslation()

  const conditionalOnclose = () => {
    if (isLoading) return

    onClose()
  }

  return (
    <Dialog isOpen={isOpen} onClose={conditionalOnclose} status="warning">
      <Dialog.Overlay />
      <Dialog.Content>
        <Dialog.Header>{title}</Dialog.Header>
        <Dialog.CloseButton />
        <Dialog.Body>{t('generic_confirm_message')}</Dialog.Body>
        <Dialog.Footer>
          <Button
            onClick={conditionalOnclose}
            variant="text"
            color="secondary"
            className="mr-2"
            isDisabled={isLoading}
          >
            {t('cancel')}
          </Button>
          <Button onClick={onConfirm} isDisabled={isLoading}>
            {t('confirm')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default MarkAsFinishedResource
