import { Box, Button, Icon } from 'lemon-system'
import { createContext, ReactNode, useContext } from 'react'
import { IconType } from 'react-icons'
import {
  MdCheckCircle,
  MdClose,
  MdError,
  MdInfo,
  MdReportProblem,
} from 'react-icons/md'
import {
  ModalManager,
  ModalManagerContent,
  ModalManagerOverlay,
  ModalManagerProps,
  useModalManager,
} from './components/ModalManager'

type dialogStatusType = 'success' | 'warning' | 'error' | 'info'

export interface DialogProps extends ModalManagerProps {
  children: ReactNode
  status?: dialogStatusType
}

interface DialogContextProps {
  status?: dialogStatusType
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined)

function useDialog() {
  return useContext(DialogContext) as DialogContextProps
}

export const Dialog = ({
  children,
  size = 'xs',
  status,
  ...props
}: DialogProps) => {
  return (
    <DialogContext.Provider value={{ status }}>
      <ModalManager size={size} {...props}>
        {children}
      </ModalManager>
    </DialogContext.Provider>
  )
}

const DialogHeader: React.FC = ({ children }) => {
  const { status } = useDialog()

  const statusIcons: { [key in dialogStatusType]: IconType } = {
    success: MdCheckCircle,
    warning: MdReportProblem,
    error: MdError,
    info: MdInfo,
  }

  return (
    <>
      {status && (
        <Box
          className={`absolute top-0 left-0 right-0 h-2.5 bg-${status}-01 rounded-t-md`}
        />
      )}

      <Box
        as="header"
        className={`font-bold text-neutral-04 text-md pb-2.5 px-5 pt-${
          status ? '10' : '8'
        }`}
      >
        {status && <Icon as={statusIcons[status]} className="mr-1.5" />}
        {children}
      </Box>
    </>
  )
}

const DialogCloseButton: React.FC = () => {
  const { onClose } = useModalManager()
  const { status } = useDialog()

  return (
    <Button
      color="secondary"
      variant="text"
      className={`absolute override:(min-w-max) right-1 top-${
        status ? '3' : '1'
      }`}
      onClick={onClose}
    >
      <Icon as={MdClose} />
    </Button>
  )
}

const DialogBody: React.FC = ({ children }) => {
  return (
    <Box className="px-5 pb-3 last:pb-6 text-sm text-neutral-05">
      {children}
    </Box>
  )
}

const DialogFooter: React.FC = ({ children }) => {
  return <Box className="px-5 pt-3 pb-6 flex justify-end">{children}</Box>
}

Dialog.Overlay = ModalManagerOverlay
Dialog.Content = ModalManagerContent
Dialog.Header = DialogHeader
Dialog.CloseButton = DialogCloseButton
Dialog.Body = DialogBody
Dialog.Footer = DialogFooter
