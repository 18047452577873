import { categoryType } from 'features/shared/types/categoryType'
import { Box } from 'lemon-system'
import { useTranslation } from 'react-i18next'

interface ProjectCategoryLabelProps {
  category?: categoryType
  className?: string
}

const ProjectCategoryLabel: React.FC<ProjectCategoryLabelProps> = ({
  category,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <Box
      className={`${className} text-xs bg-success-02 text-success-01 font-bold rounded-sm p-2`}
    >
      {category?.name ? category?.name : t('projects.no_category')}
    </Box>
  )
}

export default ProjectCategoryLabel
