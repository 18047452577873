import { fetcher } from 'utils/fetcher'

type deleteTaskProps = {
  projectId: string
  milestoneId: string
  taskId: string
}

async function deleteTask({ projectId, milestoneId, taskId }: deleteTaskProps) {
  const result = await fetcher.delete(
    `/crm-clients/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}`
  )

  return result
}

export default deleteTask
