import { projectType } from 'features/shared/types/projectType'
import { fetcher } from 'utils/fetcher'

type patchMilestonesFromWorkTemplateProps = {
  projectId: string
  workTemplateId: string
}

async function patchMilestonesFromWorkTemplate({
  projectId,
  workTemplateId,
}: patchMilestonesFromWorkTemplateProps) {
  return await fetcher.patch<projectType>(
    `/crm-clients/projects/${projectId}/work_template/${workTemplateId}`
  )
}

export default patchMilestonesFromWorkTemplate
