import { fetcher } from 'utils/fetcher'

export type funnelStep = {
  name: string
}

export type editFunnelProps = {
  id: string
  name: string
  active?: boolean
  steps: funnelStep[]
}


async function editFunnel(editFunnelData: editFunnelProps) {
  const response = await fetcher.put(`/crm-clients/funnels/${editFunnelData.id}`, editFunnelData)

  return response
}

export default editFunnel
