import Table from 'components/Table'
import useProject from 'features/projects/hooks/useProject'
import { projectHistoryType } from 'features/shared/types/projectHistoryType'
import { userType } from 'features/shared/types/userType'
import { useDisclosure } from 'hooks/useDisclosure'
import i18n from 'i18next'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import HistoryFooter from '../HistoryFooter'
import HistoryNoDataRow from '../HistoryNoDataRow'
import HistoryRow from '../HistoryRow'
import ProjectHistoryDetailModal from '../ProjectHistoryDetailModal'

const headers = [
  {
    title: i18n.t('projects.history.author'),
    className: 'py-2 text-neutral-01 w-1/6',
  },
  {
    title: i18n.t('projects.history.date'),
    className: 'py-2 text-neutral-01 w-1/6',
  },
  {
    title: i18n.t('projects.history.action'),
    className: 'py-2 text-neutral-01 w-2/6',
  },
  {
    title: i18n.t('projects.history.changes'),
    className: 'py-2 text-neutral-01 w-2/6',
  },
]

interface HistoryTableProps {
  projectHistory: projectHistoryType[] | undefined
  users: userType[] | undefined
  handleOnBottom: () => void
  fetchingMore: boolean
}

const HistoryTable: React.FC<HistoryTableProps> = ({
  projectHistory,
  users,
  handleOnBottom,
  fetchingMore,
}) => {
  const { projectId } = useParams()
  const { data: project } = useProject(projectId as string)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [projectHistoryItem, setProjectHistoryItem] =
    useState<projectHistoryType>()
  const handleRowClick = (projectHistoryItem: projectHistoryType) => {
    setProjectHistoryItem(projectHistoryItem)
    onOpen()
  }

  return (
    <>
      <Table
        headers={headers}
        rowComponent={HistoryRow}
        FooterComponent={fetchingMore ? HistoryFooter : undefined}
        NoDataComponent={HistoryNoDataRow}
        tableClassName="mt-6"
        resources={projectHistory || []}
        isCreating={false}
        onBottom={handleOnBottom}
        resourceName={'project_history'}
        users={users || []}
        onRowClick={handleRowClick}
        rowClassName="cursor-pointer"
      />

      <ProjectHistoryDetailModal
        project={project}
        projectHistoryItem={projectHistoryItem}
        users={users || []}
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  )
}

export default HistoryTable
