import { projectType } from 'features/shared/types/projectType'
import { fetcher } from 'utils/fetcher'

async function getProjectById(projectId: string) {
  const project = await fetcher.get<projectType>(
    `/crm-clients/projects/${projectId}`
  )

  return project
}

export default getProjectById
