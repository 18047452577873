import FormControl from 'components/FormControl'
import MotionBox from 'components/MotionBox'
import SelectClient from 'features/shared/components/SelectClient'
import SelectUser from 'features/shared/components/SelectUser'
import { Box, Button, Icon } from 'lemon-system'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineFilterAlt,
} from 'react-icons/md'
import SelectArea from 'features/shared/components/SelectArea'
import SelectCategory from 'features/shared/components/SelectCategory'
import { useDisclosure } from 'hooks/useDisclosure'
import { useEffect } from 'react'
import { funnelFiltersType } from 'features/funnels/hooks/useFunnel'

type SelectType = {
  label: string
  value: string
}

interface FunnelFiltersProps {
  onChange?: (formValues: funnelFiltersType) => void
}

const FunnelFilters: React.FC<FunnelFiltersProps> = ({ onChange }) => {
  const { t } = useTranslation()
  const { control } = useForm({ mode: 'onChange' })
  const formValues = useWatch({ control })
  const { onToggle, isOpen } = useDisclosure()

  useEffect(() => {
    const parseStringArray = (fieldValue?: SelectType[]) => {
      if (!fieldValue) return []

      return fieldValue.map((item) => item.value)
    }

    const parseFormValues = (values: typeof formValues) => {
      return {
        clients: parseStringArray(values.clients),
        owners: parseStringArray(values.owners),
        areas: parseStringArray(values.areas),
        categories: parseStringArray(values.categories),
      }
    }

    if (formValues) onChange?.(parseFormValues(formValues))

    // eslint-disable-next-line
  }, [formValues])

  return (
    <MotionBox
      initial={{ translateX: '0%' }}
      animate={{ translateX: isOpen ? '0%' : '100%' }}
      className="flex absolute h-full right-0 top-0 z-sticky"
      as="form"
    >
      <Button
        size="md"
        className="flex absolute top-0 right-full"
        onClick={onToggle}
      >
        <Icon as={isOpen ? MdChevronRight : MdChevronLeft} size={28} />
        <Icon as={MdOutlineFilterAlt} size={28} />
      </Button>

      <Box className="w-56 h-full bg-01 overflow-y-scroll">
        <FormControl className="mx-2 mb-2 mt-4">
          <FormControl.Label>{t('projects.filters.clients')}</FormControl.Label>

          <Controller
            name="clients"
            control={control}
            render={({ field }) => <SelectClient {...field} isMultiGrouped />}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>{t('projects.filters.owners')}</FormControl.Label>

          <Controller
            name="owners"
            control={control}
            render={({ field }) => <SelectUser {...field} isMultiGrouped />}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>{t('projects.filters.areas')}</FormControl.Label>

          <Controller
            name="areas"
            control={control}
            render={({ field }) => <SelectArea {...field} isMultiGrouped />}
          />
        </FormControl>

        <FormControl className="mx-2 mb-4">
          <FormControl.Label>
            {t('projects.filters.categories')}
          </FormControl.Label>

          <Controller
            name="categories"
            control={control}
            render={({ field }) => <SelectCategory {...field} isMultiGrouped />}
          />
        </FormControl>
      </Box>
    </MotionBox>
  )
}

export default FunnelFilters
