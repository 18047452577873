import { Box, Button, Icon } from 'lemon-system'
import {
  MdCheck,
  MdClose,
  MdDeleteOutline,
  MdOutlineEdit,
} from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { RefObject, useEffect, useState } from 'react'

import Avatar from 'components/Avatar'

import { areaType } from 'features/shared/types/areaType'
import { Confirm } from 'components/Dialog'
import useTrackEvent from 'hooks/useTrackEvent'

interface AreaRowProps {
  area: areaType
  isNew: boolean
  isEditable: boolean
  onEdit?: (key: string, value: string) => void
  onCreate?: (area: areaType) => void
  onEditable: () => void
  onCancelEdit?: () => void
  onCancel?: () => void
  isDisabled?: boolean
  onDeleteArea?: (area: areaType) => void
  newIsValid?: boolean
  firstInputRef?: RefObject<HTMLInputElement> | null
  isLoading?: boolean
  canUpdateDelete: boolean
}

const AreaRow: React.FC<AreaRowProps> = ({
  area,
  isEditable = false,
  onCreate,
  onEditable,
  onCancel,
  isDisabled = false,
  onDeleteArea,
  firstInputRef,
  isLoading,
  canUpdateDelete,
}) => {
  const { t } = useTranslation()
  const [areaEditable, setAreaEditable] = useState<areaType>(area)
  const [isValid, setIsValid] = useState(false)

  const handleAreaEditable = (key: string, value: string) =>
    setAreaEditable({ ...areaEditable, [key]: value })

  const { track } = useTrackEvent()

  useEffect(() => {
    setAreaEditable(area)

    // eslint-disable-next-line
  }, [isEditable])

  useEffect(() => {
    setIsValid(!!(areaEditable.name?.trim() || '').length)
  }, [areaEditable.name])

  return (
    <>
      <Box as="td" className="w-1/3 flex justify-center">
        <Avatar
          size={7}
          id={area.id}
          name={areaEditable.name}
          className={'inline-flex'}
        />
      </Box>
      <Box as="td" className={`text-left w-1/3`}>
        {isEditable ? (
          <Box
            as="input"
            value={areaEditable.name || ''}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleAreaEditable?.('name', (e.target as HTMLInputElement).value)
            }}
            placeholder={t('areas.edit_fields.name')}
            className="border-b border-transparent focus:(border-b outline-none) w-full"
            ref={firstInputRef}
            disabled={isLoading}
            required
          />
        ) : (
          area.name
        )}
      </Box>
      <Box as="td" className="w-1/3">
        {isEditable ? (
          <Box className="text-center">
            <Button
              size="xs"
              className="mr-2"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                onCancel?.()
                track({
                  eventName: `areas - ${area.id ? 'edit' : 'create'} - cancel`,
                  ...(area.id && {
                    metadata: {
                      area: areaEditable,
                    },
                  }),
                })
              }}
              variant="outlined"
            >
              <Icon as={MdClose} />
            </Button>

            <Button
              size="xs"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                onCreate?.(areaEditable)
                track({
                  eventName: `areas - ${area.id ? 'edit' : 'create'} - done`,
                  ...(area.id && {
                    metadata: {
                      area: areaEditable,
                    },
                  }),
                })
              }}
              variant="outlined"
              isDisabled={!isValid}
              type="submit"
            >
              <Icon as={MdCheck} />
            </Button>
          </Box>
        ) : (
          <Box as="td" className="w-3/12">
            <Box className="flex justify-center">
              {canUpdateDelete && (
                <>
                  <Button
                    size="xs"
                    style={{
                      minWidth: 'fit-content',
                    }}
                    onClick={() => {
                      onEditable()
                      track({
                        eventName: 'areas - edit',
                        metadata: {
                          area,
                        },
                      })
                    }}
                    variant="outlined"
                    isDisabled={isDisabled}
                  >
                    <Icon as={MdOutlineEdit} />
                  </Button>

                  <Confirm
                    title={t('areas.delete_area_text')}
                    onConfirm={async () => {
                      await onDeleteArea?.(area)
                      track({
                        eventName: 'areas - delete - done',
                        metadata: {
                          area,
                        },
                      })
                    }}
                    isLoading={isLoading}
                    onOpen={() => {
                      track({
                        eventName: 'areas - delete',
                        metadata: {
                          area,
                        },
                      })
                    }}
                    onCancel={() => {
                      track({
                        eventName: 'areas - delete - cancel',
                        metadata: {
                          area,
                        },
                      })
                    }}
                    button={
                      <Button
                        size="xs"
                        className="ml-2"
                        color="primary"
                        style={{
                          minWidth: 'fit-content',
                          opacity: 100,
                        }}
                        variant="outlined"
                      >
                        <Icon as={MdDeleteOutline} />
                      </Button>
                    }
                    body={t('generic_short_confirm_message')}
                  />
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default AreaRow
