import constants from 'configs/constants'
import mixpanel from 'mixpanel-browser'

export type TrackEventProps = {
  eventName: string
  metadata?: { [index: string]: any }
}

const trackEvent = (
  eventName: TrackEventProps['eventName'],
  metadata?: TrackEventProps['metadata']
) => {
  if (!constants.MIXPANEL_TOKEN) return

  if (typeof (window as any || {}).Cypress !== 'undefined') return

  mixpanel.track(eventName, metadata)
}

export default trackEvent
