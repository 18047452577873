import Avatar from 'components/Avatar'
import { userType } from 'features/shared/types/userType'
import { Box } from 'lemon-system'
import { Link } from 'react-router-dom'
import routes from 'routes/routing'

interface UserAvatarProps extends Pick<userType, 'name' | 'avatar' | 'email'> {}

const UserAvatar: React.FC<UserAvatarProps> = ({ avatar, name, email }) => {
  return (
    <Box
      as={Link}
      to={routes.user_profile}
      className="flex items-center text-neutral-09"
    >
      <Box className="mr-4">
        <Avatar src={avatar} size={9} name={name} />
      </Box>
      <Box className="mr-4">
        <Box className="font-bold text-sm">{name}</Box>
        <Box className="text-xs">{email}</Box>
      </Box>
    </Box>
  )
}

export default UserAvatar
