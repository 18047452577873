import { Box, useTheme } from 'lemon-system'
import { TailSpin } from 'react-loader-spinner'

const PageLoader: React.FC = () => {
  const { getThemeProp } = useTheme()

  return (
    <Box className="flex justify-center items-center w-screen h-screen">
      <TailSpin color={getThemeProp('colors.primary.01')} />
    </Box>
  )
}

export default PageLoader
