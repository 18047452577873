const constants = {
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
  IS_DEV: process.env.NODE_ENV === 'development',
  API_URL: process.env.REACT_APP_API_URL || '',
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  HOTJAR_KEY: process.env.REACT_APP_HOTJAR_KEY || '',
  HOTJAR_SV: process.env.REACT_APP_HOTJAR_SV || '',
  APPCUES_KEY: process.env.REACT_APP_APPCUES_KEY || '',
}

export default constants
