import Avatar from 'components/Avatar'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import esLocale from 'date-fns/locale/es'
import { useUsers } from 'features/shared/hooks/useUsers'
import { useUser } from 'features/shared/state/user/userContext'
import { Box } from 'lemon-system'
import { useTranslation } from 'react-i18next'

interface CommentCardProps {
  time: string
  email: string
  className?: string
}

const CommentCard: React.FC<CommentCardProps> = ({
  email,
  time,
  children,
  className = '',
}) => {
  const { t } = useTranslation()
  const { getUserByEmail } = useUsers()
  const author = getUserByEmail(email)
  const { user: loggedUser } = useUser()
  const user = email === loggedUser.email ? loggedUser : author

  const isReversed = email === loggedUser.email

  const relativeDate = () => {
    const formatedDate = formatDistanceToNowStrict(parseISO(time), {
      locale: esLocale,
    })

    return t('time_ago', {
      time: formatedDate,
    })
  }

  return (
    <Box
      className={`flex items-start ${
        isReversed ? 'self-end flex-row-reverse' : ''
      } ${className}`}
    >
      <Avatar
        className={`mt-1 ${isReversed ? 'ml-4' : 'mr-4'}`}
        src={user?.avatar}
        size={9}
        name={user?.name || email}
        id={user?.id || email}
      />

      <Box className="flex flex-col">
        <Box
          className={`${
            isReversed ? 'bg-info-02' : 'bg-neutral-01 '
          } rounded-md shadow-xs p-4`}
        >
          <Box className="mr-4 flex items-center">
            <Box className="font-bold text-sm mr-2" title={email}>
              {user?.name || email}
            </Box>
            {user?.name && <Box className="text-xs">{email}</Box>}
          </Box>

          <Box className="relative whitespace-pre-wrap break-words text-neutral-09 mt-2">
            {children}
          </Box>
        </Box>
        <Box
          className={`text-sm mt-2 text-neutral-07 ${
            isReversed ? 'text-right' : ''
          }`}
        >
          {relativeDate()}
        </Box>
      </Box>
    </Box>
  )
}

export default CommentCard
