import Avatar from 'components/Avatar'
import { userType } from 'features/shared/types/userType'
import { Box } from 'lemon-system'

interface ProjectOwnerProps {
  user: userType
  className?: string
}

const ProjectOwner: React.FC<ProjectOwnerProps> = ({ className, user }) => {
  return (
    <Box className={`${className} flex items-center`}>
      <Box className="mr-4">
        <Avatar src={user.avatar} size={7} />
      </Box>
      <Box className="mr-4">
        <Box className="font-bold text-sm text-neutral-07">{user.name}</Box>
      </Box>
    </Box>
  )
}

export default ProjectOwner
