import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdNavigateNext, MdOutlineEdit } from 'react-icons/md'

import Avatar from 'components/Avatar'
import ConfirmButton from 'components/ConfirmButton'
import CancelButton from 'components/CancelButton'
import Confirm from 'components/Dialog/Confirm'
import useTrackEvent from 'hooks/useTrackEvent'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import ContactCardWrapper from '../components/ContactCardWrapper'

import { clientsType } from 'features/shared/types/clientsType'
import { holdingType } from 'features/shared/types/holdingType'
import Selector, { itemType } from 'components/Selector'
import { tableRowType } from 'features/shared/types/tableRowType'
import { Modal } from 'components/Modal'

interface ClientRowProps extends tableRowType {
  client: clientsType
  holdings?: holdingType[]
  onActivateClient: (active: boolean, client: clientsType) => void
  isHoldingsLoading?: boolean
}

const ClientRow: React.FC<ClientRowProps> = ({
  isEditable,
  client,
  onCancel,
  firstInputRef,
  onCreate,
  onEditable,
  onLoading,
  onActivateClient,
  isLoading,
  holdings,
  isHoldingsLoading,
}) => {
  const { t } = useTranslation()
  const history = useNavigate()
  const isDisabled = !client.active
  const [isValid, setIsValid] = useState(false)
  const [isEditLoading, setIsEditLoading] = useState(false)
  const [showContactModalInfo, setShowContactModalInfo] = useState(false)
  const [clientEditable, setClientEditable] = useState<clientsType>(client)
  const { policyValidation } = usePolicyValidation()
  const projectsRead = policyValidation('projects.read')
  const contactsRead = policyValidation('contacts.read')
  const showClientDetailButton = projectsRead || contactsRead

  const handleEditNewClient = (key: string, value: any) =>
    setClientEditable({ ...clientEditable, [key]: value })

  const { track } = useTrackEvent()

  useEffect(() => {
    setIsValid((clientEditable.name?.trim() || '').length !== 0)
  }, [clientEditable.name])

  return (
    <>
      <Box
        as="td"
        className="w-1/12 flex justify-center cursor-pointer"
        onClick={() => history(`./${client.id}`)}
      >
        <Avatar
          size={7}
          id={clientEditable.id}
          name={clientEditable.name}
          className="inline-flex"
        />
      </Box>
      <Box as="td" className="text-left w-3/12 pr-2">
        {isEditable ? (
          <Box
            as="input"
            value={clientEditable.name || ''}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleEditNewClient('name', (e.target as HTMLInputElement).value)
            }
            placeholder={t('clients.edit_fields.name')}
            className="border-b border-transparent focus:(border-b outline-none) w-full"
            ref={firstInputRef}
            disabled={isLoading}
            required
          />
        ) : (
          <Box
            className="cursor-pointer"
            onClick={() => history(`./${client.id}`)}
          >
            {client.name}
          </Box>
        )}
      </Box>
      <Box as="td" className="text-left w-3/12 pr-2">
        {isEditable ? (
          <Selector
            items={holdings}
            labelKey="name"
            value={
              clientEditable.holding && {
                value: clientEditable.holding.id,
                label: clientEditable.holding.name,
              }
            }
            placeholder={
              isHoldingsLoading
                ? t('loading')
                : t('clients.edit_fields.no_holdings')
            }
            onChange={(item: itemType) =>
              handleEditNewClient('holding', {
                id: item?.value,
                name: item?.label,
              })
            }
            isLoading={isHoldingsLoading}
            isDisabled={isHoldingsLoading}
            isSearchable={false}
            isClearable
            menuPosition="fixed"
          />
        ) : (
          client.holding?.name
        )}
      </Box>
      <Box
        as="td"
        {...(client.favourite?.name && {
          onClick: () => {
            setShowContactModalInfo(true)
          },
        })}
        className={`text-left w-3/12 pr-2 ${
          client.favourite?.name && 'cursor-pointer'
        }`}
      >
        {client.favourite?.name}
      </Box>
      <Box as="td" className="w-5/12">
        {isEditable && (
          <Box className="text-right mr-4">
            <CancelButton
              className="mr-2"
              onClick={() => {
                onCancel?.()
                track({
                  eventName: `clients - ${
                    client.id ? 'edit' : 'create'
                  } - cancel`,
                  ...(client.id && {
                    metadata: {
                      client: clientEditable,
                    },
                  }),
                })
              }}
            />
            <ConfirmButton
              onClick={() => {
                onCreate?.(clientEditable)
                track({
                  eventName: `clients - ${
                    client.id ? 'edit' : 'create'
                  } - cancel`,
                  ...(client.id && {
                    metadata: {
                      client: clientEditable,
                    },
                  }),
                })
              }}
              isDisabled={!isValid}
            />
          </Box>
        )}
        {!isEditable && showClientDetailButton && (
          <Box className="text-center mr-4 flex justify-center">
            <Button
              size="xs"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                onEditable()
                track({
                  eventName: 'clients - edit',
                  metadata: {
                    client,
                  },
                })
              }}
              variant="outlined"
              isDisabled={isDisabled}
            >
              <Icon as={MdOutlineEdit} />
            </Button>
            <Confirm
              title={`${
                client.active
                  ? t('users.edit_fields.inactivate_button')
                  : t('users.edit_fields.activate_button')
              } cliente`}
              onConfirm={async () => {
                onLoading(true)
                setIsEditLoading(true)
                await onActivateClient(!client.active, clientEditable)

                track({
                  eventName: !client.active
                    ? 'clients - disable - done'
                    : 'clients - enable - done',
                  metadata: {
                    client,
                  },
                })

                setIsEditLoading(false)
                onLoading(false)
              }}
              onCancel={() => {
                track({
                  eventName: client.active
                    ? 'clients - disable - cancel'
                    : 'clients - enable - cancel',
                  metadata: {
                    client,
                  },
                })
              }}
              onOpen={() => {
                track({
                  eventName: client.active
                    ? 'clients - disable'
                    : 'clients - enable',
                  metadata: {
                    client,
                  },
                })
              }}
              isLoading={isEditLoading}
              button={
                <Button
                  size="xs"
                  className="ml-2 mr-2"
                  color="primary"
                  style={{
                    minWidth: 'fit-content',
                    opacity: 100,
                  }}
                  variant="outlined"
                >
                  {client.active
                    ? t('users.edit_fields.inactivate_button')
                    : t('users.edit_fields.activate_button')}
                </Button>
              }
              body={t('generic_short_confirm_message')}
            />

            <Button
              size="xs"
              className="text-right"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                track({
                  eventName: 'clients - detail',
                  metadata: {
                    clientId: client.id as string,
                  },
                })
                history(`./${client.id}`)
              }}
              variant="outlined"
              isDisabled={isDisabled}
            >
              <Icon as={MdNavigateNext} />
            </Button>
          </Box>
        )}
        {showContactModalInfo && client.favourite && (
          <Modal
            size="sm"
            onClose={() => {
              setShowContactModalInfo(false)
            }}
            isOpen={showContactModalInfo}
            closeOnEsc
            closeOnOverlayClick
          >
            <Modal.Overlay />
            <Modal.Content>
              <Modal.Header>{t('clients.contact')}</Modal.Header>
              <Modal.CloseButton />
              <Modal.Body>
                <Box>
                  <ContactCardWrapper
                    key={client.favourite?.id}
                    clientId={client.id || ''}
                    id={client.favourite?.name || ''}
                    name={client.favourite?.name}
                    email={client.favourite?.email}
                    phone={client.favourite?.phone}
                    readOnly={true}
                  />
                </Box>
              </Modal.Body>
            </Modal.Content>
          </Modal>
        )}
      </Box>
    </>
  )
}

export default ClientRow
