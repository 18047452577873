import { currentUserKey } from 'features/shared/state/user/userContext'
import { userType } from 'features/shared/types/userType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import updateUser from '../services/updateUser'

const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  return useMutation(updateUser, {
    onSuccess: (updatedUser: userType) => {
      const currentUser = queryClient.getQueryData(currentUserKey) as userType
      queryClient.setQueryData(currentUserKey, {
        ...currentUser,
        ...updatedUser,
      })

      snackbar.add({
        duration: 3000,
        status: 'success',
        content: t('user_profile.user_updated_successfully'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateUser
