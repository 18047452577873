import CancelButton from 'components/CancelButton'
import ConfirmButton from 'components/ConfirmButton'
import Avatar from 'components/Avatar'
import Selector, { itemType } from 'components/Selector'
import { isEmail } from 'utils/isEmail'
import { roleType } from 'features/shared/types/roleType'
import { tableRowType } from 'features/shared/types/tableRowType'
import { userType } from 'features/shared/types/userType'
import { Box, Button, Icon } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineEdit } from 'react-icons/md'
import { Confirm } from '../../../components/Dialog'
import useTrackEvent from 'hooks/useTrackEvent'
import useResetUserPassword from '../hooks/useResetUserPassword'

interface UserRowProps extends tableRowType {
  user: userType
  roles: roleType
  isRolesLoading: boolean
  onActivateUser: (active: boolean, user: userType) => void
  canUpdateActivate: boolean
  currentUserIsAdmin: boolean
}

const UserRow: React.FC<UserRowProps> = ({
  isEditable,
  user,
  firstInputRef,
  isLoading,
  roles,
  isRolesLoading,
  onCreate,
  onEditable,
  onLoading,
  onActivateUser,
  isDisabled,
  onCancel,
  canUpdateActivate,
  currentUserIsAdmin,
}) => {
  const { t } = useTranslation()
  const [userEditable, setUserEditable] = useState<userType>(user)
  const [isValid, setIsValid] = useState(false)
  const [isEditLoading, setIsEditLoading] = useState(false)
  const canEdit = currentUserIsAdmin || userEditable.role !== 'admin'
  const resetPassword = useResetUserPassword()

  const { track } = useTrackEvent()

  useEffect(() => {
    setUserEditable(user)

    // eslint-disable-next-line
  }, [isEditable])

  useEffect(() => {
    setIsValid(
      (userEditable.name?.trim() || '').length !== 0 &&
        isEmail(userEditable.email?.trim() || '')
    )
  }, [userEditable.name, userEditable.email])

  const handleUserEditable = (key: string, value: string) =>
    setUserEditable({ ...userEditable, [key]: value })

  return (
    <>
      <Box
        as="td"
        className={`${
          isDisabled ? 'opacity-20' : ''
        } w-1/12 flex justify-center`}
      >
        {user.avatar ? (
          <Box
            as="img"
            className="rounded-full object-cover w-9 h-9"
            src={user.avatar}
          />
        ) : (
          <Avatar size={9} id={user.id} className="inline-flex" />
        )}
      </Box>
      <Box as="td" className={`${isDisabled ? 'opacity-20' : ''} w-3/12`}>
        {isEditable && !user.id ? (
          <Box
            as="input"
            value={userEditable.name || ''}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleUserEditable('name', (e.target as HTMLInputElement).value)
            }
            placeholder={t('users.edit_fields.name')}
            className="border-b border-transparent focus:(border-b outline-none) w-full"
            ref={firstInputRef}
            disabled={isLoading}
            required
          />
        ) : (
          user.name
        )}
      </Box>
      <Box as="td" className={`${isDisabled ? 'opacity-20' : ''} w-3/12`}>
        {isEditable && !user.id ? (
          <Box
            as="input"
            value={userEditable.email || ''}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleUserEditable('email', (e.target as HTMLInputElement).value)
            }
            className="border-b border-transparent focus:outline-none w-full"
            placeholder={t('users.edit_fields.email')}
            disabled={isLoading}
            required
          />
        ) : (
          user.email
        )}
      </Box>
      <Box as="td" className={`${isDisabled ? 'opacity-20' : ''} w-2/12`}>
        {isEditable ? (
          <Selector
            items={roles}
            valueKey="name"
            labelKey="name"
            placeholder={
              isRolesLoading ? t('loading') : t('users.edit_fields.no_role')
            }
            onChange={(item: itemType, event: any) => {
              const value = event.action === 'clear' ? 'default' : item?.value
              handleUserEditable('role', value)
            }}
            isLoading={isRolesLoading}
            isDisabled={isRolesLoading}
            isSearchable={false}
            value={
              userEditable.role && {
                value: userEditable.role,
                label: userEditable.role,
              }
            }
            isClearable
            menuPosition="fixed"
          />
        ) : (
          user.role
        )}
      </Box>
      <Box as="td" className="w-3/12">
        {isEditable ? (
          <Box className="text-center">
            <CancelButton
              className="mr-2"
              onClick={() => {
                track({
                  eventName: `users - ${user.id ? 'edit' : 'create'} - cancel`,
                  ...(user.id && {
                    metadata: {
                      user: userEditable,
                    },
                  }),
                })
                onCancel?.()
              }}
            />
            <ConfirmButton
              onClick={() => {
                track({
                  eventName: `users - ${user.id ? 'edit' : 'create'} - done`,
                  ...(user.id && {
                    metadata: {
                      user: userEditable,
                    },
                  }),
                })
                onCreate?.(userEditable)
              }}
              isDisabled={!isValid}
            />
          </Box>
        ) : (
          <Box className="flex justify-center">
            {user.active && canUpdateActivate && canEdit && (
              <Button
                size="xs"
                style={{
                  minWidth: 'fit-content',
                }}
                onClick={() => {
                  track({
                    eventName: 'users - edit',
                    metadata: {
                      user,
                    },
                  })
                  onEditable()
                }}
                variant="outlined"
                isDisabled={isDisabled}
              >
                <Icon as={MdOutlineEdit} />
              </Button>
            )}
            {canUpdateActivate && (
              <Confirm
                title={`${
                  user.active
                    ? t('users.edit_fields.inactivate_button')
                    : t('users.edit_fields.activate_button')
                } usuario`}
                onConfirm={async () => {
                  onLoading(true)
                  setIsEditLoading(true)
                  await onActivateUser(!user.active, userEditable)
                  track({
                    eventName: !user.active
                      ? 'users - disable - done'
                      : 'users - enable - done',
                    metadata: {
                      user,
                    },
                  })
                  setIsEditLoading(false)
                  onLoading(false)
                }}
                onCancel={() => {
                  track({
                    eventName: user.active
                      ? 'users - disable - cancel'
                      : 'users - enable - cancel',
                    metadata: {
                      user,
                    },
                  })
                }}
                onOpen={() => {
                  track({
                    eventName: user.active
                      ? 'users - disable'
                      : 'users - enable',
                    metadata: {
                      user,
                    },
                  })
                }}
                isLoading={isEditLoading}
                button={
                  <Button
                    size="xs"
                    className="ml-2"
                    color="primary"
                    style={{
                      minWidth: 'fit-content',
                      opacity: 100,
                    }}
                    variant="outlined"
                  >
                    {user.active
                      ? t('users.edit_fields.inactivate_button')
                      : t('users.edit_fields.activate_button')}
                  </Button>
                }
                body={t('generic_short_confirm_message')}
              />
            )}

            {user?.status === 'change.password' && (
              <Confirm
                button={
                  <Button
                    size="xs"
                    className="ml-2 mr-6"
                    color="primary"
                    style={{
                      minWidth: 'fit-content',
                      opacity: 100,
                    }}
                    variant="outlined"
                  >
                    {t('users.reset_password')}
                  </Button>
                }
                isLoading={resetPassword.isLoading}
                body={t('generic_short_confirm_message')}
                onConfirm={async () => {
                  await resetPassword.mutateAsync(user.email as string)
                }}
                title={t('users.reset_password')}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default UserRow
