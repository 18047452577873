import queryClient from 'configs/reactQuery/queryClient'
import { projectType } from 'features/shared/types/projectType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import patchMilestonesFromWorkTemplate from '../services/patchMilestonesFromWorkTemplate'
import { projectQueryKey } from './useProject'

const usePatchMilestonesFromWorkTemplate = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(patchMilestonesFromWorkTemplate, {
    onSuccess: (incomingProject: projectType) => {
      queryClient.setQueryData(
        [projectQueryKey, incomingProject.id],
        incomingProject
      )

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('work_templates.add_success'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default usePatchMilestonesFromWorkTemplate
