import { fetcherWithFile } from 'utils/fetcher'

async function getFunnelGeneralStatusReport(
  year: string,
  month: string,
  currency: string
) {
  return await fetcherWithFile.get<any>(
    `/reporter/reports/funnel_status/${year}/${month}/${currency}`
  )
}

export default getFunnelGeneralStatusReport
