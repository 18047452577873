import { useQuery } from 'react-query'
import getMoneys from '../services/getMoneys'
import { moneyType } from '../types/moneyType'

export const moneysQueryKey = 'moneys'

function useMoneys() {
  return useQuery<moneyType[]>(moneysQueryKey, getMoneys, {
    refetchOnMount: false,
  })
}

export default useMoneys
