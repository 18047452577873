import Categories from './views/Categories'
import { Route, Routes } from 'react-router-dom'
import CategorySummary from './views/CategorySummary'

const CategoryWrapper: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Categories />} />
        <Route index element={<CategorySummary />} />
    </Routes>
  )
}

export default CategoryWrapper
