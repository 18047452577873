import { userType } from 'features/shared/types/userType'
import { fetcher } from 'utils/fetcher'

export type updateUserProps = Pick<userType, 'email' | 'name'>

async function updateUser(user: updateUserProps) {
  return await fetcher.put<userType>('/landlord/users/me', user)
}

export default updateUser
