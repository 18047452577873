import { fetcher } from 'utils/fetcher'

type DeleteFileByIdProps = {
  projectId: string
  fileId: string
}

async function deleteFileById({ projectId, fileId }: DeleteFileByIdProps) {
  const result = await fetcher.delete(
    `/crm-clients/projects/${projectId}/files/${fileId}`
  )

  return result
}

export default deleteFileById
