import FormControl from 'components/FormControl'
import Input from 'components/Input'
import { Box, Button, useTheme, Banner } from 'lemon-system'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import useLogin from '../hooks/useLogin'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const { mutate, isLoading, mustUpdatePass } = useLogin()
  const tenant = useMemo(() => {
    const [subdomain] = window.location.hostname.split('.')

    return subdomain
  }, [])

  const { getThemeProp: theme } = useTheme()

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    mutate({ user, password, newPassword, tenant, mustUpdatePass })
  }

  return (
    <Box
      data-testid="Login"
      className="flex items-center justify-center w-screen h-screen bg-neutral-04"
    >
      <Box className="bg-neutral-01 rounded-lg p-8 w-full max-w-lg shadow-sm select-none">
        <Box as="h1" className="font-bold text-2xl text-center">
          LegalCRM
        </Box>
        <Banner className='mt-8' content={t('login.help')}></Banner>
        <Box as="form" className="mt-8 space-y-3" onSubmit={handleSubmit}>
          {!mustUpdatePass && (
            <FormControl isRequired>
              <FormControl.Label>{t('login.username')}</FormControl.Label>

              <Input
                size="md"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUser(e.target.value)
                }
                value={user}
                type="email"
                placeholder={t('login.username')}
              />
            </FormControl>
          )}

          <FormControl isRequired>
            <FormControl.Label>
              {mustUpdatePass
                ? t('login.please_update_password')
                : t('login.password')}
            </FormControl.Label>

            <Input
              size="md"
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                mustUpdatePass
                  ? setNewPassword(e.target.value)
                  : setPassword(e.target.value)
              }
              value={mustUpdatePass ? newPassword : password}
              type="password"
              placeholder="*******"
            />
          </FormControl>

          <Box className="flex justify-end">
            {isLoading ? (
              <Button isDisabled>
                <TailSpin
                  width={20}
                  height={20}
                  color={theme('colors.secondary.01')}
                />
              </Button>
            ) : (
              <Button type="submit">{t('login.signin')}</Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Login
