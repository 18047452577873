import { Box } from 'lemon-system'
import { ElementType, ReactNode } from 'react'

interface TaskCellProps {
  as?: ElementType<any>
  header?: ReactNode
  type?: 'fluid' | 'boxed'
  className?: string
}

const TaskCell: React.FC<TaskCellProps> = ({
  as = 'div',
  header,
  children,
  type = 'boxed',
  className = '',
  ...props
}) => {
  return (
    <Box
      as={as}
      className={`border-r border-neutral-02 flex  flex-col justify-center h-full last:border-none ${
        type === 'fluid'
          ? 'px-2 flex-1'
          : 'flex-0 py-2 px-4 w-[11rem] items-center'
      }`}
    >
      {header}
      <Box
        {...props}
        className={`px-2 font-semibold text-neutral-07 text-md override:(${className})`}
      >
        {children}
      </Box>
    </Box>
  )
}

export default TaskCell
