import TaskProgress from 'components/TaskProgress'
import { Menu } from 'components/Menu'
import { useUsers } from 'features/shared/hooks/useUsers'
import { Box, Icon } from 'lemon-system'
import React, { forwardRef, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLinkCleaner } from 'hooks/useLinkCleaner'
import ProjectOwner from 'components/ProjectOwner'
import MotionBox from 'components/MotionBox'
import { newUpdateColorTransition } from 'utils/newUpdateColorTransition'
import { deleteColorTransition } from 'utils/deleteColorTransition'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useUser } from 'features/shared/state/user/userContext'
import useTrackEvent from 'hooks/useTrackEvent'
import ProjectAreaLabel from 'components/ProjectAreaLabel'
import ProjectCategoryLabel from 'components/ProjectCategoryLabel'
import { clientsType } from 'features/shared/types/clientsType'
import Avatar from 'components/Avatar'
import Tooltip from 'components/Tooltip'
import { t } from 'i18next'
import { MdMoreVert, MdOutlineDelete } from 'react-icons/md'
import { projectType } from 'features/shared/types/projectType'
import DeleteResource from 'features/projects/components/DeleteResource'
import useProjectUpdater from 'features/projects/hooks/useUpdateProject'
import { serverProjectData } from 'features/projects/services/updateProject'

interface FunnelCardProps extends React.ComponentPropsWithoutRef<'div'> {
  isDragging?: boolean
  isDisabled?: boolean
  className?: string
  useAnimations?: boolean
  client?: clientsType
  project: projectType
  onUpdate?: (project: projectType) => void
}

const FunnelCard = forwardRef<HTMLDivElement, FunnelCardProps>(
  (
    {
      isDragging,
      isDisabled,
      className,
      useAnimations = false,
      client,
      project,
      onUpdate,
      ...rest
    },
    ref
  ) => {
    const {
      id,
      owner,
      name: title,
      area,
      category,
      active: isActive,
      summary: {
        tasks_completed: tasksCompleted = 0,
        tasks_total: tasksTotal = 0,
      },
    } = project
    const { user } = useUser()
    const location = useLocation()
    const navigate = useNavigate()
    const { mutateAsync: mutateDeleteAsync, isLoading: isDeleting } =
      useProjectUpdater()
    const { getUserByEmail } = useUsers()
    const projectLink = useLinkCleaner(`/project/${id}`)
    const ownerUsr = owner === user.email ? user : getUserByEmail(owner)
    const { policyValidation } = usePolicyValidation()
    const canReadProjects = useMemo(
      () => policyValidation('projects.read'),
      // eslint-disable-next-line
      []
    )
    const [showClientTooltip, setShowClientTooltip] = useState<boolean>(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
    const [referenceElement, setReferenceElement] =
      useState<HTMLElement | null>(null)
    const { track } = useTrackEvent()

    const handleClick = () => {
      if (!canReadProjects) return

      track({
        eventName: 'projects - detail',
        metadata: {
          triggerPath: location.pathname,
          projectPath: projectLink,
        },
      })

      navigate(projectLink)
    }

    const parseProjectData = () =>
      ({
        id: project.id,
        name: project.name,
        active: project.active,
        status: project.status,
        client_id: project.client_id,
        area_id: project.area?.id,
        category_id: project.category?.id,
        owner: project.owner,
        worth: {
          currency: project.worth?.currency,
          amount: project.worth?.amount,
          multiplier: project.worth?.multiplier,
        },
      } as serverProjectData)

    const handleProjectDelete = async () => {
      try {
        const updatedProject = await mutateDeleteAsync({
          ...parseProjectData(),
          active: !project.active,
        })
        onUpdate?.(updatedProject)
      } catch (error) {
        console.error(error)
      }
    }

    if (!title && !owner) return <Box ref={ref} {...rest} />

    return (
      <MotionBox
        className={`${className} bg-neutral-01 my-2 rounded-md border border-neutral-02 ${
          isDragging ? 'shadow-md' : ''
        }`}
        initial={useAnimations}
        animate={{
          opacity: 1,
          backgroundColor: newUpdateColorTransition(),
          transition: 3,
        }}
        exit={
          useAnimations
            ? {
                opacity: 0,
                backgroundColor: deleteColorTransition,
                transition: 3,
              }
            : null
        }
        ref={ref}
        {...rest}
      >
        <Box
          className={`block p-3 ${isDisabled ? 'cursor-not-allowed' : ''} ${
            canReadProjects ? 'cursor-pointer' : ''
          }`}
          onClick={handleClick}
        >
          <Box className="flex text-neutral-07 font-semibold text-md leading-5">
            <Box
              className={`${
                isActive ? '' : 'opacity-20'
              } flex flex-grow items-center`}
            >
              {title}
            </Box>

            <Menu>
              <Menu.Button
                className="flex rounded-full p-1 hover:bg-02 focus:outline-none"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                }}
              >
                <Icon size="sm" as={MdMoreVert} className="text-neutral-05" />
              </Menu.Button>

              <Menu.List>
                <Menu.Item
                  icon={<Icon as={MdOutlineDelete} />}
                  onClick={(e) => {
                    e.stopPropagation()
                    track({
                      eventName: 'project - delete',
                      metadata: {
                        projectId: id,
                      },
                    })
                    setIsDeleteOpen(true)
                  }}
                  className={isActive ? 'hover:bg-error-02 text-error-01' : ''}
                  iconClassName={isActive ? 'text-error-01' : ''}
                >
                  {isActive ? t('deactivate') : t('activate')}
                </Menu.Item>
              </Menu.List>
            </Menu>

            <DeleteResource
              title={t('projects.delete_project')}
              onClose={() => {
                setIsDeleteOpen(false)
                track({
                  eventName: 'project - delete - cancel',
                  metadata: {
                    projectId: id,
                  },
                })
              }}
              onConfirm={async () => {
                await handleProjectDelete()
                setIsDeleteOpen(false)
                track({
                  eventName: 'project - delete - done',
                  metadata: {
                    projectId: id,
                  },
                })
              }}
              isLoading={isDeleting}
              isOpen={isDeleteOpen}
            />
          </Box>

          <Box
            className={`${
              isActive ? '' : 'opacity-20'
            } flex flex-wrap items-center mt-4 mb-3`}
          >
            {client && (
              <>
                <Box
                  className="mr-2 mt-2"
                  ref={setReferenceElement}
                  onMouseEnter={() => {
                    setShowClientTooltip(true)
                  }}
                  onMouseLeave={() => setShowClientTooltip(false)}
                >
                  <Avatar id={client.id} name={client.name} size={7} />
                </Box>
                <Tooltip
                  classname="bg-neutral-01"
                  isOpen={showClientTooltip}
                  referenceElement={referenceElement}
                  placement="top-start"
                >
                  <Box className="flex items-center px-2">
                    <Avatar id={client.id} name={client.name} size={7} />
                    <Box className="ml-2">{client.name}</Box>
                  </Box>
                </Tooltip>
              </>
            )}
            <ProjectAreaLabel area={area} className="mr-2 mt-2" />
            <ProjectCategoryLabel category={category} className="mt-2" />
          </Box>

          <TaskProgress
            className={isActive ? '' : 'opacity-20'}
            tasksCompleted={tasksCompleted}
            tasksTotal={tasksTotal}
          />

          {ownerUsr && (
            <ProjectOwner
              className={`${isActive ? '' : 'children:opacity-20'} mt-6`}
              user={ownerUsr}
            />
          )}
        </Box>
      </MotionBox>
    )
  }
)

export default FunnelCard
