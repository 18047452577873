import { fetcher } from 'utils/fetcher'

type deleteMilestoneProps = {
  projectId: string
  milestoneId: string
}

async function deleteMilestone({
  projectId,
  milestoneId,
}: deleteMilestoneProps) {
  const result = await fetcher.delete(
    `/crm-clients/projects/${projectId}/milestones/${milestoneId}`
  )

  return result
}

export default deleteMilestone
