import LemonLoader from 'components/LemonLoader'
import getTenant from 'features/shared/services/getTenant'
import { tenantType } from 'features/shared/types/tenantType'
import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

type TenantContextType = {
  tenant: tenantType
}

const TenantContext = createContext<TenantContextType | undefined>(undefined)

export const TenantProvider: React.FC = ({ children }) => {
  const [tenantName] = window.location.hostname.split('.')
  const { isLoading, data: tenant } = useQuery('tenant', () =>
    getTenant(tenantName)
  )

  if (isLoading) {
    return <LemonLoader />
  }

  return (
    <TenantContext.Provider value={{ tenant: tenant as tenantType }}>
      {children}
    </TenantContext.Provider>
  )
}

export const useTenant = () => useContext(TenantContext) as TenantContextType
