import { useFormControl } from 'components/FormControl'
import { useTheme } from 'lemon-system'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RSelect, {
  CSSObjectWithLabel,
  GroupBase,
  StylesConfig,
} from 'react-select'
import RSSelectType from 'react-select/dist/declarations/src/Select'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'

export type SelectSizes = 'xs' | 'sm' | 'md' | 'lg'

export interface SelectProps extends StateManagerProps {
  isRequired?: boolean
  size?: SelectSizes
  isInvalid?: boolean
  isMulti?: boolean
  isMultiGrouped?: boolean
}

const Select = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  SelectProps
>(({ size = 'sm', isInvalid, isMulti, ...props }, ref) => {
  const { t } = useTranslation()
  const { getThemeProp: theme } = useTheme()
  const formControl = useFormControl()
  const conditionalInvalid = formControl.isInvalid || isInvalid

  const sizeStyles: { [key in SelectSizes]: { [key: string]: string } } =
    useMemo(
      () => ({
        xs: {
          height: theme('height.8'),
          paddingLeft: `calc(${theme('padding.3')} - 10px)`,
          fontSize: theme('fontSize.sm'),
        },
        sm: {
          height: theme('height.9'),
          paddingLeft: `calc(${theme('padding.4')} - 10px)`,
          fontSize: theme('fontSize.sm'),
        },
        md: {
          height: theme('height.10'),
          paddingLeft: `calc(${theme('padding.5')} - 10px)`,
          fontSize: theme('fontSize.md'),
        },
        lg: {
          height: theme('height.12'),
          paddingLeft: `calc(${theme('padding.6')} - 10px)`,
          fontSize: theme('fontSize.md'),
        },
      }),

      // eslint-disable-next-line
      []
    )

  const indicatorStyles = (styles: CSSObjectWithLabel) => ({
    ...styles,
    cursor: 'pointer',
    color: theme('colors.secondary.01'),
    '&:hover': {
      color: theme('colors.secondary.01'),
    },
  })

  const selectStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
    dropdownIndicator: indicatorStyles,
    clearIndicator: indicatorStyles,
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (styles) => ({
      ...styles,
      color: theme('colors.neutral.05'),
    }),
    control: (styles, state) => {
      let borderColor = theme('colors.secondary.01')

      if (conditionalInvalid) borderColor = theme('colors.error.01')
      if (state.isFocused) borderColor = theme('colors.brand.03')

      return {
        ...styles,
        ...sizeStyles[size],
        ...(isMulti && {
          height: 'auto',
          minHeight: sizeStyles[size].height,
        }),
        borderWidth: 1.5,
        borderRadius: theme('borderRadius.sm'),
        color: theme('colors.secondary.01'),
        borderColor,
        boxShadow: 'none',
        cursor: 'text',

        '&:hover': {
          boxShadow: theme('boxShadow.xs'),
          borderColor,
        },
      }
    },
    option: (styles, state) => {
      return {
        ...styles,
        color: state.isSelected
          ? theme('colors.neutral.01')
          : theme('colors.secondary.01'),
        fontSize: sizeStyles[size].fontSize,
        cursor: 'pointer',
      }
    },
  }

  return (
    <RSelect
      isMulti={isMulti}
      placeholder={t('select_placeholder')}
      {...props}
      ref={ref}
      styles={selectStyles}
      noOptionsMessage={() => t('select_no_options')}
    />
  )
})

export default Select
