import { Placement } from '@popperjs/core'
import { animationOrigins, customPlacement } from 'components/Menu'
import MotionBox from 'components/MotionBox'
import { AnimatePresence } from 'framer-motion'
import { ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

interface TooltipProps {
  isOpen: boolean
  children: ReactNode
  classname?: string
  placement?: customPlacement
  referenceElement: HTMLElement | null
}

const Tooltip: React.FC<TooltipProps> = ({
  placement = 'bottom-end',
  children,
  classname,
  isOpen,
  referenceElement,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const popper = usePopper(referenceElement, popperElement, {
    placement: placement as Placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          ref={setPopperElement as (element: HTMLElement | null) => void}
          style={popper.styles.popper}
          className="z-dropdown z-tooltip"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.1,
          }}
          {...popper.attributes.popper}
        >
          <MotionBox
            className={`${classname} shadow-sm rounded-md min-w-44 py-2 ${
              animationOrigins[placement as customPlacement]
            }`}
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            transition={{ duration: 0.1 }}
          >
            {children}
          </MotionBox>
        </MotionBox>
      )}
    </AnimatePresence>,
    document.querySelector('body') as HTMLElement
  )
}

export default Tooltip
