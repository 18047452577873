import { Box, BoxProps } from 'lemon-system'
import React, { useState } from 'react'

const AutoResizeTextArea: <E extends React.ElementType = 'textarea'>(
  props: BoxProps<E>
) => React.ReactElement | null = (props) => {
  const minRows = 2
  const maxRows = 5
  const [rows, setRows] = useState(2)

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    const textareaLineHeight = 24
    const target = event.target as HTMLTextAreaElement

    const previousRows = target.rows
    target.rows = minRows

    const currentRows = ~~(target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      target.rows = maxRows
      target.scrollTop = target.scrollHeight
    }

    setRows(currentRows < maxRows ? currentRows : maxRows)
  }

  return (
    <Box
      {...props}
      as="textarea"
      rows={rows}
      className="border flex-grow-1 flex-shrink-1 rounded-md mr-4 p-2 resize-none"
      onKeyDown={handleKeyDown}
    />
  )
}

export default AutoResizeTextArea
