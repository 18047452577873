import Avatar from 'components/Avatar'
import DatePicker from 'components/DatePicker'
import FormControl from 'components/FormControl'
import PlaceholderLoader from 'components/PlaceholderLoader'
import SelectIcon from 'components/SelectIcon'
import useFunnels from 'features/funnels/hooks/useFunnels'
import SelectCurrency from 'features/shared/components/SelectCurrency'
import { useCurrentTenant } from 'features/shared/hooks/useCurrentTenant'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button, useTheme } from 'lemon-system'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import { downloadFileFromBinary } from 'utils/downloadFileFromBinary'
import getFunnelByFunnelStatusReport from '../services/getFunnelByFunnelStatusReport'

const FunnelByFunnelStatusReport: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation()
  const [isDownloading, setIsDownloading] = useState(false)
  const { isLoading: isFunnelsLoading, data: funnels } = useFunnels()
  const { isLoading: isLoadingTenantData, data: currentTenant } =
    useCurrentTenant()

  const { getThemeProp: theme } = useTheme()

  const { track } = useTrackEvent({
    trackOnMount: {
      eventName: 'reports - funnel status',
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    const {
      year,
      funnel: { value: funnelId },
      currency,
    } = data
    setIsDownloading(true)
    const fullYear = year.getFullYear()
    const month = year.getMonth() + 1
    const res = await getFunnelByFunnelStatusReport(
      fullYear,
      month,
      funnelId,
      currency
    )
    const fileName = res.headers['content-disposition'].split('=')[1]
    setIsDownloading(false)
    await downloadFileFromBinary(res.data, fileName)

    track({
      eventName: 'reports - funnel status - download',
      metadata: {
        fullYear,
        month,
        funnelId,
        currency,
      },
    })
  })

  return (
    <Box className="flex h-full place-content-center place-items-center">
      <Box as="form" onSubmit={onSubmit} className="flex flex-col w-80">
        <Box className="flex w-full justify-center mb-6">
          <FormControl isInvalid={!!errors.year} className="w-full">
            <FormControl.Label>
              {t('reports.funnel_by_funnel_status.period')}
            </FormControl.Label>

            <Controller
              name="year"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  className="h-12"
                  selected={field.value}
                  dateFormat="MM/yyyy"
                  size="md"
                  showMonthYearPicker
                />
              )}
            />

            <Box className="absolute">
              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="flex w-full justify-center mb-6">
          <FormControl isInvalid={!!errors.funnel} className="w-full">
            <FormControl.Label>
              {t('reports.funnel_by_funnel_status.funnel')}
            </FormControl.Label>

            <Controller
              name="funnel"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                // TODO: Replace this with <SelectFunnels
                <SelectIcon
                  {...field}
                  isDisabled={isFunnelsLoading}
                  value={{
                    value: field.value?.value,
                    label: field.value?.label,
                    icon: field.value?.value && (
                      <Avatar
                        id={field.value?.value}
                        name={field.value?.label}
                        size={5}
                      />
                    ),
                  }}
                  isClearable
                  onChange={(newValue: any) => {
                    field.onChange(newValue)
                  }}
                  options={funnels?.map((funn: any) => ({
                    value: funn.id,
                    label: funn.name,
                    icon: <Avatar id={funn.id} name={funn.name} size={5} />,
                  }))}
                  size="lg"
                />
              )}
            />

            <Box className="absolute">
              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="flex w-full justify-center">
          <FormControl isInvalid={!!errors.currency} className="w-full">
            <FormControl.Label>
              {t('reports.funnel_general_status.currency')}
            </FormControl.Label>

            {isLoadingTenantData ? (
              <PlaceholderLoader className="w-full rounded-sm h-12" />
            ) : (
              <Controller
                name="currency"
                control={control}
                rules={{ required: true }}
                defaultValue={currentTenant?.currency}
                render={({ field }) => (
                  <SelectCurrency
                    {...field}
                    onChange={(newValue: any) => field.onChange(newValue.value)}
                    defaultValue={currentTenant?.currency}
                    size="lg"
                  />
                )}
              />
            )}

            <Box className="absolute">
              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>

        <Box className="flex justify-center mt-4">
          <Button
            type="submit"
            className="h-12 relative"
            isDisabled={isDownloading}
          >
            {t('download')}
            {isDownloading && (
              <Box className="absolute right-0 -mr-10">
                <TailSpin color={theme('colors.primary.01')} width={30} />
              </Box>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default FunnelByFunnelStatusReport
