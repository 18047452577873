import { Box, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'

const ReportNotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box className="flex flex-col h-full place-content-center">
      <Icon as={MdError} className="override:(w-full h-32)" />
      <Box as="span" className="flex justify-center text-lg">
        {t('reports.not_found')}
      </Box>
    </Box>
  )
}

export default ReportNotFound
