import { projectType } from 'features/shared/types/projectType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import updateMilestoneStatus from '../services/updateMilestoneStatus'
import { projectQueryKey } from './useProject'

function useUpdateMilestoneStatus(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(updateMilestoneStatus, {
    onMutate: async ({ milestoneId, status }) => {
      await queryClient.cancelQueries([projectQueryKey, projectId])

      const previousProject = queryClient.getQueryData([
        projectQueryKey,
        projectId,
      ])

      queryClient.setQueryData([projectQueryKey, projectId], (oldProject) => {
        const project = oldProject as projectType

        const newMilestones = project.milestones.map((milestone) =>
          milestone.id === milestoneId ? { ...milestone, status } : milestone
        )

        return { ...project, milestones: newMilestones }
      })

      return { previousProject }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([projectQueryKey, projectId])
    },

    onError: (_err, _, context: any) => {
      queryClient.setQueryData(
        [projectQueryKey, projectId],
        context.previousProject
      )

      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateMilestoneStatus
