import { projectTaskType } from './../../shared/types/projectType'
import { projectType } from 'features/shared/types/projectType'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import moveTask from '../services/moveTask'
import { projectQueryKey } from './useProject'
import { defaultActionContent } from 'features/shared/utils'

function useMoveTask(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(moveTask, {
    onMutate: async ({
      milestoneIdFrom,
      milestoneIdTo,
      position: newPosition,
      taskId,
    }) => {
      await queryClient.cancelQueries([projectQueryKey, projectId])

      const previousProject = queryClient.getQueryData([
        projectQueryKey,
        projectId,
      ])

      queryClient.setQueryData([projectQueryKey, projectId], (oldProject) => {
        const project = oldProject as projectType

        const taskToMove = project.milestones
          .find((milestone) => milestone.id === milestoneIdFrom)
          ?.tasks.find((task) => task.id === taskId) as projectTaskType

        const cleanMilestones = project.milestones.map((milestone) => {
          if (milestone.id !== milestoneIdFrom) return milestone

          return {
            ...milestone,
            tasks: milestone.tasks.filter((task) => task.id !== taskId),
          }
        })

        const newMilestones = cleanMilestones.map((milestone) => {
          if (milestone.id !== milestoneIdTo) return milestone

          const newTasks = [...milestone.tasks]

          newTasks.splice(newPosition, 0, taskToMove)

          return {
            ...milestone,
            tasks: newTasks,
          }
        })

        return { ...project, milestones: newMilestones }
      })

      return { previousProject }
    },

    onSuccess: (updatedProject: projectType) => {
      queryClient.setQueryData([projectQueryKey, projectId], updatedProject)
    },

    onError: (_err, _, context: any) => {
      queryClient.setQueryData(
        [projectQueryKey, projectId],
        context.previousProject
      )

      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useMoveTask
