import { contactType } from './../types/contactType'
import { fetcher } from 'utils/fetcher'

export type updateContactProps = {
  clientId: string
  contactId: string
  contactData: Omit<contactType, 'id'>
}

async function updateContact({
  clientId,
  contactId,
  contactData,
}: updateContactProps) {
  const response = await fetcher.put(
    `/crm-clients/clients/${clientId}/contacts/${contactId}`,
    contactData
  )

  return response
}

export default updateContact
