import FormControl from 'components/FormControl'
import Input from 'components/Input'
import { Modal } from 'components/Modal'
import PlaceholderLoader from 'components/PlaceholderLoader'
import usePolicies from 'features/roles/hooks/usePolicies'
import useSavePoliciesFromRole from 'features/roles/hooks/useSavePoliciesFromRole'
import parsePolicies from 'features/roles/utils/parsePolicies'
import policyStatus from 'features/roles/utils/policyStatus'
import useRoles, { rolesQueryKey } from 'features/shared/hooks/useRoles'
import { useDisclosure } from 'hooks/useDisclosure'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button } from 'lemon-system'
import { Fragment, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

const CreateRoleModal: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { isFetching } = useRoles({
    refetchOnMount: false,
  })
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm()
  const fields = useWatch({ control })
  const policies = usePolicies()
  const savePolicies = useSavePoliciesFromRole()
  const { t } = useTranslation()

  const { track } = useTrackEvent()

  const conditionalClose = () => {
    if (savePolicies.isLoading || isFetching) return

    track({
      eventName: 'roles - create - cancel',
    })

    onClose()
  }

  const onSubmit = handleSubmit(async (data) => {
    await savePolicies.mutateAsync({
      role: data.role,
      policies: parsePolicies(data),
    })

    await queryClient.invalidateQueries(rolesQueryKey)

    track({
      eventName: 'roles - create - done',
    })

    navigate(`./${encodeURIComponent(data.role)}`)
    onClose()
  })

  useEffect(() => {
    if (policies.data) {
      const read = '1'
      const defaultValues = policies.data.reduce(
        (initFormData, policyGroup) => {
          const [, policies] = Object.entries(policyGroup)[0]

          const policiesStatus = policies.reduce((initPoliciesData, policy) => {
            return {
              ...initPoliciesData,
              [policy]: read,
            }
          }, {})

          return { ...initFormData, ...policiesStatus }
        },
        {}
      )

      reset(defaultValues)
    }

    // eslint-disable-next-line
  }, [isOpen])

  const isEmptyPolicies = !Object.values(fields).some(
    (field) =>
      field === policyStatus.read.number || field === policyStatus.write.number
  )

  return (
    <>
      <Button
        id='menu-create-button'
        onClick={() => {
          track({
            eventName: 'roles - create',
          })
          onOpen()
        }}
      >
        {t('create')}
      </Button>
      <Modal onClose={conditionalClose} isOpen={isOpen} size="sm">
        <Modal.Overlay />
        <Modal.Content as="form" onSubmit={onSubmit}>
          <Modal.Header>{t('roles.create_role.title')}</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            {!policies.data && (
              <Box className="mb-5">
                <PlaceholderLoader className="h-6 mb-2 w-full" />
                <PlaceholderLoader className="h-6 mb-2 w-full" />
                <PlaceholderLoader className="h-6 mb-2 w-full" />
                <PlaceholderLoader className="h-6 mb-2 w-full" />
                <PlaceholderLoader className="h-6 mb-2 w-full" />
                <PlaceholderLoader className="h-6 mb-2 w-full" />
                <PlaceholderLoader className="h-6 mb-2 w-full" />
              </Box>
            )}

            <Box className="mb-6">
              <FormControl isRequired isInvalid={errors.role}>
                <FormControl.Label className="text-md">
                  {t('roles.create_role.name')}
                </FormControl.Label>
                <Input
                  {...register('role', {
                    required: true,
                  })}
                />

                <FormControl.ErrorMessage>
                  {t('field_required')}
                </FormControl.ErrorMessage>
              </FormControl>
            </Box>

            {policies.data?.map((policyGroup) => {
              const [policyGroupName, policies] = Object.entries(policyGroup)[0]

              return (
                <Box
                  key={`policyGroup-${policyGroupName}`}
                  className="grid grid-cols-4 items-center gap-x-4 gap-y-2 mb-10 last:mb-5"
                >
                  <Box as="h2" className="font-semibold capitalize leading-3">
                    {t(`roles.groups.${policyGroupName}`)}
                  </Box>
                  <Box
                    as="span"
                    className="font-semibold capitalize justify-self-start text-sm"
                  >
                    {t('roles.status.none')}
                  </Box>
                  <Box
                    as="span"
                    className="font-semibold capitalize justify-self-center text-sm"
                  >
                    {t('roles.status.read')}
                  </Box>
                  <Box
                    as="span"
                    className="font-semibold capitalize justify-self-end text-sm"
                  >
                    {t('roles.status.write')}
                  </Box>

                  {policies.map((policy) => (
                    <Fragment
                      key={`policyGroup-${policyGroupName}-policy-${policy}`}
                    >
                      <Box as="label">{t(`roles.policies.${policy}`)}</Box>
                      <Box
                        {...register(policy)}
                        as="input"
                        type="range"
                        step="1"
                        min="0"
                        max="2"
                        defaultValue="0"
                        className="col-span-3"
                      />
                    </Fragment>
                  ))}
                </Box>
              )
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="text"
              color="secondary"
              className="mr-auto"
              onClick={conditionalClose}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              isDisabled={
                savePolicies.isLoading ||
                isEmptyPolicies ||
                isFetching ||
                !isDirty
              }
            >
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default CreateRoleModal
