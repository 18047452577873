import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { policiesType } from 'features/shared/types/policiesType'
import { Navigate, useParams } from 'react-router-dom'
import ReportNotFound from './ReportNotFound'
import { availableReports } from './Reports'

const ReportDetail: React.FC = () => {
  const { reportName: urlReportName } = useParams()
  const { policyValidation } = usePolicyValidation()
  const { component: ReportComponent, allowedPolicy } =
    availableReports[urlReportName || '']

  if (policyValidation(allowedPolicy as policiesType))
    return <>{ReportComponent ? <ReportComponent /> : <ReportNotFound />}</>

  return <Navigate to="/" replace />
}

export default ReportDetail
