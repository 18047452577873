import { useTheme } from 'lemon-system'
import Select from 'react-select'

interface SelectorProps {
  items: Array<any> | undefined
  valueKey?: string
  labelKey: string
  [x: string]: any
}

export type itemType = {
  value: string
  label: string
}

const Selector: React.FC<SelectorProps> = ({
  items = [],
  valueKey = 'id',
  labelKey,
  ...other
}) => {
  const { getThemeProp: theme } = useTheme()

  return (
    <Select
      options={items.map((i) => ({ value: i[valueKey], label: i[labelKey] }))}
      styles={{
        option: (styles) => ({
          ...styles,
          cursor: 'pointer',
          color: theme('colors.secondary.01'),
        }),
        control: (styles) => ({
          ...styles,
          cursor: 'pointer',
        }),
        singleValue: (styles) => ({
          ...styles,
          color: theme('colors.secondary.01'),
        }),
      }}
      {...other}
    />
  )
}

export default Selector
