import { fetcher } from 'utils/fetcher'

type updateTaskProps = {
  projectId: string
  milestoneId: string
  taskId: string
  name: string
  deadline: Date | null
  assignees: string[] | null
}

async function updateTask({
  projectId,
  milestoneId,
  taskId,
  name,
  deadline,
  assignees,
}: updateTaskProps) {
  const result = await fetcher.put(
    `/crm-clients/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}`,
    { name, deadline, assignees }
  )
  return result
}

export default updateTask
