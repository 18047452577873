import Clients from './views/Clients'
import ClientDetail, { clientTabs } from './views/ClientDetail'
import routes from 'routes/routing'
import { Route, Routes } from 'react-router-dom'
import ClientProjects from './views/ClientProjects'
import ClientContacts from './views/ClientContacts'
import ProtectedRoute from 'features/shared/components/ProtectedRoute'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { policiesType } from 'features/shared/types/policiesType'

const ClientWrapper: React.FC = () => {
  const clientsHomeUrl = routes.clients.replace('/*', '')
  const { policyValidation: validator } = usePolicyValidation()
  const availablePath =
    clientTabs.find(
      (tab) => tab.path !== './' && validator(tab.allowedPolicy as policiesType)
    )?.path || `/${clientsHomeUrl}`

  return (
    <Routes>
      <Route index element={<Clients />} />
      <Route path={routes.client_detail} element={<ClientDetail />}>
        <Route
          index
          element={
            <ProtectedRoute
              allowedPolicy="projects.read"
              redirect={availablePath}
              showSnackbarMsg={false}
            >
              <ClientProjects />
            </ProtectedRoute>
          }
        />

        <Route
          path={routes.client_detail_contacts}
          element={
            <ProtectedRoute
              allowedPolicy="contacts.read"
              redirect={availablePath}
            >
              <ClientContacts />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  )
}

export default ClientWrapper
