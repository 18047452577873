import { useQuery } from 'react-query'
import getCategories from '../services/getCategories'
import { categoryType } from '../types/categoryType'

const useCategories = (options: any = {}) => {
  const { isLoading, data: categories } = useQuery<categoryType[]>(
    'categories',
    getCategories,
    options
  )
  return { isLoading, categories }
}

export default useCategories
