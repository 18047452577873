import { Route, Routes } from 'react-router-dom'
import WorkTemplates from './views/WorkTemplates'

const WorkTemplatesWrapper: React.FC = () => {
  return (
    <Routes>
      <Route index element={<WorkTemplates />} />
    </Routes>
  )
}

export default WorkTemplatesWrapper
