import RSSelectType from 'react-select/dist/declarations/src/Select'

import { forwardRef } from 'react'
import { useQuery } from 'react-query'
import { GroupBase } from 'react-select'

import Avatar from 'components/Avatar'
import PlaceholderLoader from 'components/PlaceholderLoader'
import getCurrentUser from 'features/shared/services/getCurrentUser'
import SelectIcon from 'components/SelectIcon'

import { SelectProps, SelectSizes } from 'components/Select'
import { useUsers } from 'features/shared/hooks/useUsers'
import { currentUserKey } from 'features/shared/state/user/userContext'

const sizeStyles: { [key in SelectSizes]: string } = {
  xs: 'h-8',
  sm: 'h-9',
  md: 'h-10',
  lg: 'h-12',
}

const SelectUser = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  Omit<SelectProps, 'options'>
>(({ size = 'sm', ...props }, ref) => {
  const { isLoading, data } = useUsers()
  const { isLoading: isCurrentUserLoading, data: currentUser } = useQuery(currentUserKey, getCurrentUser)

  if (isLoading || isCurrentUserLoading)
    return (
      <PlaceholderLoader className={`w-full rounded-sm ${sizeStyles[size]}`} />
    )

  const usersWithoutCurrentUser = data?.filter(u => u.id !== currentUser?.id) || []
  const options = [...[currentUser], ...usersWithoutCurrentUser]?.map((user) => ({
    label: user?.name,
    value: user?.email,
    icon: <Avatar id={user?.id} name={user?.name} src={user?.avatar} size={5} />,
    iconSelected: (
      <Avatar id={user?.id} name={user?.name} src={user?.avatar} size={7} />
    ),
  }))

  return <SelectIcon size={size} {...props} ref={ref} options={options} />
})

export default SelectUser
