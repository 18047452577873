import { fetcher } from 'utils/fetcher'

type savePoliciesFromRoleProps = {
  role: string
  policies: string[]
}

async function savePoliciesFromRole({
  role,
  policies,
}: savePoliciesFromRoleProps) {
  const response = await fetcher.post('/landlord/policies', {
    role,
    policies,
  })

  return response
}

export default savePoliciesFromRole
