import { TenantProvider } from 'features/shared/state/tenant/tenantContext'
import { UserProvider } from 'features/shared/state/user/userContext'
import { SocketProvider } from './socket/socketContext'

const AppProvider: React.FC = ({ children }) => {
  return (
    <TenantProvider>
      <UserProvider>
        <SocketProvider>{children}</SocketProvider>
      </UserProvider>
    </TenantProvider>
  )
}
export default AppProvider
