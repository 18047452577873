import TaskProgress from 'components/TaskProgress'
import { differenceInDays, format, parseISO } from 'date-fns'
import TaskCell from 'features/projects/components/TaskCell'
import UsersList from 'features/projects/components/UsersList'
import { taskType } from 'features/shared/types/taskType'
import { useLinkCleaner } from 'hooks/useLinkCleaner'
import { Box } from 'lemon-system'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

interface PendingTasksRowProps {
  task: taskType
  isLinkable: boolean
  displayColors?: boolean
}

const TasksRow: React.FC<PendingTasksRowProps> = ({
  task,
  isLinkable,
  displayColors,
}) => {
  const link: any = {
    as: Link,
    to: useLinkCleaner(`/project/${task.project_id}`),
  }
  const rowColor = useMemo(() => {
    if (!task.deadline || !displayColors) return ''

    const deadline = parseISO(task.deadline)
    const diff = differenceInDays(deadline, new Date())

    if (diff <= 1 && diff >= 0) return 'bg-warning-02'
    if (diff < 0) return 'bg-error-02'

    return ''
  }, [task.deadline, displayColors])

  return (
    <Box
      className={`flex py-6 mb-4 rounded-md border border-neutral-02 items-center text-neutral-07 ${rowColor}`}
      {...(isLinkable && link)}
    >
      <Box className="w-1/6 justify-center flex">
        {!task.deadline
          ? '--/--/----'
          : format(parseISO(task.deadline), 'dd/MM/yyyy')}
      </Box>
      <Box className="w-1/6 justify-center flex">{task.name}</Box>
      <Box className="w-2/6 justify-center flex">{task.project_name}</Box>
      <Box className="w-1/6 justify-center">
        <TaskProgress
          tasksCompleted={task.summary?.tasks_completed}
          tasksTotal={task.summary?.tasks_total}
        />
      </Box>
      <Box className="ml-auto justify-end flex">
        <TaskCell>
          <UsersList userEmails={task.assignees} />
        </TaskCell>
      </Box>
    </Box>
  )
}

export default TasksRow
