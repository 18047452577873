import PlaceholderLoader from 'components/PlaceholderLoader'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box } from 'lemon-system'
import { useParams } from 'react-router-dom'
import AddFile from '../components/AddFile'
import DocumentCard from '../components/DocumentCard'
import useFiles from '../hooks/useFiles'

const ProjectDocuments: React.FC = () => {
  const { projectId } = useParams()
  const { isLoading, data: files } = useFiles(projectId as string)
  const { policyValidation } = usePolicyValidation()

  useTrackEvent({
    trackOnMount: {
      eventName: 'projects - documents',
      metadata: {
        projectId,
      },
    },
  })

  if (isLoading) {
    return (
      <Box className="grid gap-4 my-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        <PlaceholderLoader className="rounded-sm h-22" />
        <PlaceholderLoader className="rounded-sm h-22" />
        <PlaceholderLoader className="rounded-sm h-22" />
        <PlaceholderLoader className="rounded-sm h-22" />
        <PlaceholderLoader className="rounded-sm h-22" />
      </Box>
    )
  }

  return (
    <Box className="grid gap-4 my-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {files?.map((file) => (
        <DocumentCard key={file.id} file={file} />
      ))}

      {policyValidation('projects_documents.write') && <AddFile />}
    </Box>
  )
}

export default ProjectDocuments
