import { Box } from 'lemon-system'
import { useMemo } from 'react'

interface TaskProgressProps {
  className?: string
  tasksCompleted?: number
  tasksTotal?: number
}

const TaskProgress: React.FC<TaskProgressProps> = ({
  className,
  tasksCompleted = 0,
  tasksTotal = 0,
}) => {
  const progressPercentage = useMemo(
    () => `${(tasksCompleted * 100) / tasksTotal}%`,
    [tasksTotal, tasksCompleted]
  )

  if (tasksTotal === 0) return null

  return (
    <Box className={`${className} flex items-center`}>
      <Box className="relative h-1.5 bg-01 rounded-full flex-grow-1 flex-shrink-1 mr-2">
        <Box
          className="absolute bottom-0 h-1.5 left-0 w-0 bg-primary-01 rounded-full"
          style={{ width: progressPercentage }}
        />
      </Box>
      <Box className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-neutral-06">
        {tasksCompleted}/{tasksTotal}
      </Box>
    </Box>
  )
}

export default TaskProgress
