import policyStatus from './policyStatus'

function parsePolicies(policies: { [key: string]: string }) {
  return Object.entries(policies).reduce(
    (newPolicies, [policyName, policyNumber]) => {
      if (policyNumber === policyStatus.read.number) {
        newPolicies.push(`${policyName}.${policyStatus.read.name}`)
      }

      if (policyNumber === policyStatus.write.number) {
        newPolicies.push(`${policyName}.${policyStatus.read.name}`)
        newPolicies.push(`${policyName}.${policyStatus.write.name}`)
      }

      return newPolicies
    },
    [] as string[]
  )
}

export default parsePolicies
