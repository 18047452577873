import { uniqId } from 'utils/uniqId'
import { useUser } from 'features/shared/state/user/userContext'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import createComment from '../services/createComment'
import { formatISO } from 'date-fns'
import { defaultActionContent } from 'features/shared/utils'

export const commentsQueryKey = 'project-comments'

function useCreateComment(projectId: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { user } = useUser()

  const queryKey = [commentsQueryKey, projectId]

  return useMutation(createComment, {
    onMutate: async ({ projectId, comment }) => {
      await queryClient.cancelQueries(queryKey)

      const previousComments = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (oldComments) => {
        const oldData = oldComments as any

        const newPages = [...oldData?.pages]?.map((data, index) => {
          return index === 0
            ? [
                ...data,
                {
                  author: user.email,
                  created_at: formatISO(new Date()),
                  id: uniqId(),
                  message: comment,
                  project_id: projectId,
                  tenant: user.tenant,
                },
              ]
            : data
        })

        return { ...oldData, pages: [...newPages] }
      })

      return { previousComments }
    },
    onError: (_err, _, context: any) => {
      queryClient.setQueryData(queryKey, context.previousComments)

      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useCreateComment
