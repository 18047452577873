import Avatar from 'components/Avatar'
import IconFile from 'components/IconFile'
import { Menu } from 'components/Menu'
import { Modal } from 'components/Modal'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import esLocale from 'date-fns/locale/es'
import useDeleteFile from 'features/projects/hooks/useDeleteFile'
import useDownloadFile from 'features/projects/hooks/useDownloadFile'
import { fileType } from 'features/projects/types/fileType'
import splitFileName from 'features/projects/utils/splitFileName'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useUsers } from 'features/shared/hooks/useUsers'
import { useDisclosure } from 'hooks/useDisclosure'
import useTrackEvent from 'hooks/useTrackEvent'
import { Box, Button, Icon } from 'lemon-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdMoreVert,
  MdOutlineDelete,
  MdOutlineFileDownload,
  MdOutlineVisibility,
} from 'react-icons/md'
import { useParams } from 'react-router-dom'
import DeleteResource from '../DeleteResource'
import FileCard from '../FileCard'

interface DocumentCardProps {
  file: fileType
}

const DocumentCard: React.FC<DocumentCardProps> = ({ file }) => {
  const { t } = useTranslation()
  const detailModal = useDisclosure()
  const confirmModal = useDisclosure()
  const { getUserByEmail } = useUsers()
  const { projectId } = useParams()
  const deleteFile = useDeleteFile(projectId as string)
  const downloadFile = useDownloadFile()
  const { policyValidation } = usePolicyValidation()

  const author = getUserByEmail(file.author)

  const { track } = useTrackEvent()

  const onDownload = () => {
    downloadFile.mutate({
      projectId: projectId as string,
      fileId: file.id,
    })
  }

  const onDelete = async () => {
    await deleteFile.mutateAsync({
      projectId: projectId as string,
      fileId: file.id,
    })

    track({
      eventName: 'projects - documents - delete - done',
      metadata: {
        projectId,
      },
    })
  }

  const { fileName, fileExt } = useMemo(() => {
    return splitFileName(file.name)
  }, [file])

  const bytesInMb = useMemo(
    () => `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
    [file]
  )

  const relativeDate = useMemo(() => {
    const formatedDate = formatDistanceToNowStrict(parseISO(file.created_at), {
      locale: esLocale,
    })

    return t('time_ago', {
      time: formatedDate,
    })

    // eslint-disable-next-line
  }, [file])

  const formatedDate = useMemo(() => {
    const date = parseISO(file.created_at)

    return format(date, 'dd/MM/yyyy', {
      locale: esLocale,
    })
  }, [file])

  return (
    <>
      <FileCard
        name={fileName}
        ext={fileExt}
        size={bytesInMb}
        date={relativeDate}
        actions={
          <Menu>
            <Menu.Button className="flex justify-center items-center rounded-full p-1 hover:bg-02 focus:outline-none">
              <Icon size="sm" as={MdMoreVert} className="text-neutral-05" />
            </Menu.Button>

            <Menu.List>
              <Menu.Item
                icon={<Icon as={MdOutlineVisibility} />}
                onClick={() => {
                  track({
                    eventName: 'projects - documents - detail',
                    metadata: {
                      projectId,
                    },
                  })
                  detailModal.onOpen()
                }}
              >
                {t('detail')}
              </Menu.Item>
              <Menu.Item
                icon={<Icon as={MdOutlineFileDownload} />}
                onClick={() => {
                  track({
                    eventName: 'projects - documents - download',
                    metadata: {
                      projectId,
                    },
                  })
                  onDownload()
                }}
              >
                {t('download')}
              </Menu.Item>

              {policyValidation('projects_documents.write') && (
                <Menu.Item
                  icon={<Icon as={MdOutlineDelete} />}
                  onClick={() => {
                    track({
                      eventName: 'projects - documents - delete',
                      metadata: {
                        projectId,
                      },
                    })

                    confirmModal.onOpen()
                  }}
                  className="hover:bg-error-02 text-error-01"
                  iconClassName="text-error-01"
                >
                  {t('delete')}
                </Menu.Item>
              )}
            </Menu.List>
          </Menu>
        }
      />

      <Modal
        onClose={detailModal.onClose}
        isOpen={detailModal.isOpen}
        size="sm"
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Box className="flex pr-12 items-end">
              <IconFile size={32} className="mr-2" ext={fileExt} />

              <Box as="span" className="flex overflow-hidden">
                <Box
                  as="span"
                  className="overflow-ellipsis overflow-hidden whitespace-nowrap flex-1"
                >
                  {fileName}
                </Box>
                <Box as="span" className="flex-0">
                  {fileExt}
                </Box>
              </Box>
            </Box>
          </Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <Box className="space-y-2">
              <Box className="flex text-sm">
                <Box className="text-neutral-08 font-semibold min-w-24">
                  {t('projects.documents.name')}
                </Box>
                <Box
                  as="span"
                  className="text-neutral-07 font-semibold flex overflow-hidden"
                >
                  <Box
                    as="span"
                    className="overflow-ellipsis overflow-hidden whitespace-nowrap flex-1"
                  >
                    {fileName}
                  </Box>
                  <Box as="span" className="flex-0">
                    {fileExt}
                  </Box>
                </Box>
              </Box>
              <Box className="flex text-sm">
                <Box className="text-neutral-08 font-semibold min-w-24">
                  {t('projects.documents.size')}
                </Box>
                <Box className="text-neutral-07 font-semibold">{bytesInMb}</Box>
              </Box>
              <Box className="flex text-sm">
                <Box className="text-neutral-08 font-semibold min-w-24">
                  {t('projects.documents.created_at')}
                </Box>
                <Box className="text-neutral-07 font-semibold">
                  {formatedDate}
                </Box>
              </Box>
              {policyValidation('users.read') && (
                <Box className="flex text-sm">
                  <Box className="text-neutral-08 font-semibold min-w-24">
                    {t('projects.documents.author')}
                  </Box>
                  <Box className="text-neutral-07 font-semibold flex items-center">
                    <Avatar
                      id={author?.id}
                      name={author?.name}
                      src={author?.avatar}
                      size={7}
                      className="mr-2 flex-grow-0 flex-shrink-0"
                    />
                    <Box className="flex flex-col">
                      {author?.name}
                      <Box
                        as="a"
                        href={`mailto:${author?.email}`}
                        target="_blank"
                      >
                        {author?.email}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                track({
                  eventName: 'projects - documents - download',
                  metadata: {
                    projectId,
                  },
                })
                onDownload()
              }}
            >
              {t('download')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <DeleteResource
        title={t('projects.documents.delete_file')}
        onClose={() => {
          track({
            eventName: 'projects - documents - delete - cancel',
            metadata: {
              projectId,
            },
          })

          confirmModal.onClose()
        }}
        onConfirm={onDelete}
        isLoading={deleteFile.isLoading}
        isOpen={confirmModal.isOpen}
      />
    </>
  )
}

export default DocumentCard
