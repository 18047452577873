import Table from 'components/Table'
import i18n from 'i18next'
import { userType } from 'features/shared/types/userType'
import { useState } from 'react'
import useCreateUser from 'features/users/hooks/useCreateUser'
import UserRow from './UserRow'
import useRoles from 'features/shared/hooks/useRoles'
import { createUserProps } from 'features/users/services/createUser'
import useUpdateUserRole from '../hooks/useUpdateUserRole'
import useInactivateUser from '../hooks/useInactivateUser'
import useActivateUser from '../hooks/useActivateUser'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useUser } from 'features/shared/state/user/userContext'

interface UsersTableProps {
  isCreating: boolean
  users: userType[] | undefined
  onCancel: () => void
  onCreated: () => void
}

const defaultUser = {
  id: null,
  name: null,
  email: null,
  role: null,
  tenant: null,
  avatar: '',
  active: true,
  dashboard: { active: [], inactive: [] },
  policies: [],
}

const headers = [
  { className: 'w-1/12' },
  {
    title: i18n.t('users.name'),
    className: 'py-2 text-neutral-01 text-left w-3/12',
    sortable: true,
    fieldName: 'name',
  },
  {
    title: i18n.t('users.email'),
    className: 'py-2 text-neutral-01 text-left w-3/12',
    sortable: true,
    fieldName: 'email',
  },
  {
    title: i18n.t('users.role'),
    className: 'py-2 text-neutral-01 text-left w-2/12',
    sortable: true,
    fieldName: 'role',
  },
  { className: 'py-2 text-neutral-01 text-left w-3/12' },
]

const UsersTable: React.FC<UsersTableProps> = ({
  isCreating,
  users = [],
  onCancel,
  onCreated,
}) => {
  const { user } = useUser()
  const { policyValidation } = usePolicyValidation()
  const [newUser, setNewUser] = useState<userType>(defaultUser)
  const { isLoading: onCreatingUser, mutateAsync: createMutateAsync } =
    useCreateUser()
  const { mutateAsync: updateRoleMutateAsync } = useUpdateUserRole()
  const { mutateAsync: inactivateMutateAsync } = useInactivateUser()
  const { mutateAsync: activateMutateAsync } = useActivateUser()
  const { isLoading: isRolesLoading, roles } = useRoles({
    refetchOnMount: false,
    enabled: policyValidation('roles.read'),
  })
  const filteredRoles =
    user.role === 'admin' ? roles : roles?.filter((r) => r !== 'admin')

  const userDataParsed = (user: userType) => {
    const { name, email, role } = user
    return { name, email, role } as createUserProps
  }

  const handleCreateUpdateUser = async (user: userType) => {
    try {
      user.id
        ? await updateRoleMutateAsync(user)
        : await createMutateAsync(userDataParsed(user))
      onCreated()
      setNewUser(defaultUser)
    } catch {
      onCancel()
    }
  }

  const handleIsDisabled = (user: userType) => !user.active

  const handleUserInactivation = async (active: boolean, user: userType) =>
    active ? await activateMutateAsync(user) : await inactivateMutateAsync(user)

  return (
    <Table
      headers={headers}
      sortItemsBy={{ field: 'name', asc: true }}
      isCreating={isCreating}
      newResource={newUser}
      resourceName="user"
      resources={users}
      rowComponent={UserRow}
      rowClassName="py-2 flex"
      isDisabled={handleIsDisabled}
      isLoading={onCreatingUser}
      onCancel={onCancel}
      onCreate={handleCreateUpdateUser}
      roles={filteredRoles?.map((r) => ({ name: r, label: r }))}
      isRolesLoading={isRolesLoading}
      onActivateUser={handleUserInactivation}
      canUpdateActivate={policyValidation('users.write')}
      currentUserIsAdmin={user.role === 'admin'}
    />
  )
}

export default UsersTable
