import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { policiesType } from 'features/shared/types/policiesType'
import { Box, Icon } from 'lemon-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdFilterAlt,
  MdOutlineFilterAlt,
  MdPeopleAlt,
  MdPerson,
} from 'react-icons/md'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import FunnelByFunnelStatusReport from './FunnelByFunnelStatusReport'
import FunnelByUserStatusReport from './FunnelByUserStatusReport'
import FunnelCurrentUserStatusReport from './FunnelCurrentUserStatusReport'
import FunnelGeneralStatusReport from './FunnelGeneralStatusReport'

export const availableReports: { [reportName: string]: any } = {
  funnel_general_status: {
    icon: MdOutlineFilterAlt,
    component: FunnelGeneralStatusReport,
    allowedPolicy: 'reports_status_general.read',
  },
  funnel_current_user_status: {
    icon: MdPerson,
    component: FunnelCurrentUserStatusReport,
    allowedPolicy: 'reports_status_personal.read',
  },
  funnel_by_user_status: {
    icon: MdPeopleAlt,
    component: FunnelByUserStatusReport,
    allowedPolicy: 'reports_status_user.read',
  },
  funnel_by_funnel_status: {
    icon: MdFilterAlt,
    component: FunnelByFunnelStatusReport,
    allowedPolicy: 'reports_status_funnel.read',
  },
}

const Reports: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { reportName: urlReportName } = useParams()
  const { policyValidation: reportValidator } = usePolicyValidation()
  const firstAllowedReportUrl = Object.keys(availableReports).find((a) =>
    reportValidator(availableReports[a].allowedPolicy)
  )

  useEffect(() => {
    if (!urlReportName) {
      firstAllowedReportUrl
        ? navigate(`./${firstAllowedReportUrl}`, { replace: true })
        : navigate('/', { replace: true })
    }
  }, [urlReportName, navigate, firstAllowedReportUrl])

  return (
    <Box className="flex h-full w-full">
      <Box className="w-full">
        <Outlet />
      </Box>
      <Box className="w-36 bg-secondary-02">
        {Object.keys(availableReports).map((reportName) => {
          if (
            reportValidator(
              availableReports[reportName].allowedPolicy as policiesType
            )
          )
            return (
              <Box
                as={Link}
                to={reportName}
                className={`flex flex-col w-full text-neutral-01 ${
                  urlReportName === reportName ? 'bg-neutral-06' : ''
                } hover:bg-neutral-06 px-4 pb-4`}
                key={`report-link-${reportName}`}
              >
                <Icon
                  as={availableReports[reportName].icon}
                  className="flex override:(h-auto w-full) justify-center"
                />
                <Box
                  as="span"
                  className="flex text-center font-semibold text-sm"
                >
                  {t(`reports.${reportName}.title`)}
                </Box>
              </Box>
            )

          return null
        })}
      </Box>
    </Box>
  )
}

export default Reports
