import { fetcherWithStatus } from 'utils/fetcher'

type loginProps = {
  user: string
  password: string
  newPassword: string
  tenant: string
  mustUpdatePass: boolean
}

async function login({
  user,
  password,
  newPassword,
  tenant,
  mustUpdatePass,
}: loginProps) {
  const response = await fetcherWithStatus.post(
    '/landlord/login',
    mustUpdatePass ? { password: newPassword } : {},
    {
      auth: {
        username: `${tenant}>${user}`,
        password,
      },
    }
  )

  return response
}

export default login
