import { fetcherWithAll } from 'utils/fetcher'
import { projectSearchResponseType, projectSearchResultType } from '../types/projectSearchResultType'
import { projectSearchParams } from '../types/projectSearchParams'

async function getFilteredProjects(params: projectSearchParams) {
  const response = await fetcherWithAll.get<any>('/crm-clients/projects', {
    params
  })

  return {
    pages: Number(response.headers['pages']),
    result: response.data as projectSearchResultType[]
  } as projectSearchResponseType
}

export default getFilteredProjects
