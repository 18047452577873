import MotionBox from 'components/MotionBox'
import PlaceholderLoader from 'components/PlaceholderLoader'
import TaskCell from 'features/projects/components/TaskCell'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import getWorkPending from 'features/shared/services/getWorkPending'
import { taskType } from 'features/shared/types/taskType'
import { Box } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import TasksRow from '../TasksRow'

const WorkPending: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, data: pendingTasks } = useQuery<taskType[]>(
    'work_pending',
    getWorkPending
  )
  const { policyValidation } = usePolicyValidation()

  return (
    <Box className="w-full mb-6">
      <Box as="h1" className="text-secondary-01 font-semibold text-lg">
        {t('dashboard.work_pending.title')}
      </Box>

      <Box className="flex flex-col mt-6 overflow-x-auto flex-grow-1 flex-shrink-1 overflow-hidden">
        {isLoading ? (
          <MotionBox
            key="placeholder-loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box className="flex flex-col mb-4">
              <PlaceholderLoader className="mr-4 h-20 mb-4 rounded-md" />
              <PlaceholderLoader className="mr-4 h-20 mb-4 rounded-md" />
              <PlaceholderLoader className="mr-4 h-20 mb-4 rounded-md" />
            </Box>
          </MotionBox>
        ) : (
          <>
            <Box className="flex bg-02 mb-4 rounded-md border border-neutral-02 items-center">
              <Box className="w-1/6 justify-center flex">
                {t('dashboard.work_pending.deadline')}
              </Box>
              <Box className="w-1/6 justify-center flex">
                {t('dashboard.work_pending.name')}
              </Box>
              <Box className="w-2/6 justify-center flex">
                {t('dashboard.work_pending.project_name')}
              </Box>
              <Box className="w-1/6 justify-center flex">
                {t('dashboard.work_pending.task_progress')}
              </Box>
              <Box className="ml-auto justify-end flex">
                <TaskCell>{t('dashboard.work_pending.assignees')}</TaskCell>
              </Box>
            </Box>

            {pendingTasks?.map((task, i) => (
              <MotionBox
                custom={i}
                animate="visible"
                variants={{
                  visible: (i: any) => ({
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: i * 0.15,
                    },
                  }),
                }}
                style={{ y: -30, opacity: 0 }}
                key={`PENDING-${task.id}`}
              >
                <TasksRow
                  displayColors
                  task={task}
                  isLinkable={policyValidation('projects.read')}
                />
              </MotionBox>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}

export default WorkPending
