import FloatingButton from 'components/FloatingButton'
import { formatISO } from 'date-fns'
import DraggableMilestones from 'features/projects/components/DraggableMilestones'
import DraggableTasks from 'features/projects/components/DraggableTasks'
import DroppableMilestones from 'features/projects/components/DroppableMilestones'
import DroppableTasks from 'features/projects/components/DroppableTasks'
import { saveProps } from 'features/projects/components/SaveResourceModal'
import TaskCell from 'features/projects/components/TaskCell'
import TaskCellHeader from 'features/projects/components/TaskCellHeader'
import UsersList from 'features/projects/components/UsersList'
import usePlaceholder from 'features/projects/hooks/usePlaceholder'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import {
  projectMilestoneType,
  projectTaskType,
} from 'features/shared/types/projectType'
import { workTemplates } from 'features/shared/types/workTemplates'
import { Box, Button, Icon } from 'lemon-system'
import { useEffect, useMemo, useState } from 'react'
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { MdDeleteOutline, MdPeople, MdSave } from 'react-icons/md'
import AddMilestone from '../components/AddMilestone'
import AddTask from '../components/AddTask'
import EditMilestone from '../components/EditMilestone'
import EditTask from '../components/EditTask'
import useCreateWorkTemplates from '../hooks/useCreateWorkTemplates'
import useUpdateWorkTemplates from '../hooks/useUpdateWorkTemplates'

interface WorkTemplatesPreviewProps {
  workTemplate: workTemplates
  onSaveNew: (workTemplate: workTemplates) => void
}

const WorkTemplatesPreview: React.FC<WorkTemplatesPreviewProps> = ({
  workTemplate,
  onSaveNew,
}) => {
  const { policyValidation } = usePolicyValidation()
  const [isDisabledDroppable, setisDisabledDroppable] = useState(false)
  const [milestones, setMilestones] = useState<projectMilestoneType[]>(
    workTemplate?.milestones || []
  )
  const { calculatePlaceholderProps, clearPlaceholder, placeholderProps } =
    usePlaceholder()
  const { t } = useTranslation()
  const { canUsersRead, canTasksWrite } = useMemo(
    () => ({
      canUsersRead: policyValidation('users.read'),
      canTasksWrite: policyValidation('projects_tasks.write'),
    }),

    // eslint-disable-next-line
    []
  )
  const { isLoading: isUpdating, mutateAsync: mutateAsyncUpdate } =
    useUpdateWorkTemplates()
  const { isLoading: isCreating, mutateAsync: mutateAsyncCreate } =
    useCreateWorkTemplates()
  const firstIndex = 0

  useEffect(() => {
    if (!workTemplate) return

    setMilestones(workTemplate?.milestones)
    // eslint-disable-next-line
  }, [workTemplate?.id])

  const handleDragStart = (event: DragStart) => {
    calculatePlaceholderProps({
      draggableId: event.draggableId,
      source: event.source,
    })
  }

  const handleDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return
    }

    setisDisabledDroppable(event.destination.index === firstIndex)

    calculatePlaceholderProps({
      draggableId: event.draggableId,
      source: event.source,
      destination: event.destination,
    })
  }

  const handleDragEnd = (result: DropResult) => {
    clearPlaceholder()
    setisDisabledDroppable(false)

    const { source, destination, type } = result
    const isOutsideTheList = !destination
    const isSamePlace =
      source.index === destination?.index &&
      source.droppableId === destination?.droppableId

    if (isOutsideTheList || isSamePlace) {
      return
    }
    if (!workTemplate) return

    if (type === 'milestones') {
      if (destination?.index === firstIndex) return

      const newMilestones = milestones.filter((_, idx) => source.index !== idx)
      newMilestones?.splice(destination.index, 0, milestones[source.index])

      setMilestones([...newMilestones])
    }

    if (type === 'tasks') {
      const milestone = milestones.find((m) => destination.droppableId)
      if (!milestone) return

      const newTasks = milestone.tasks.filter((_, idx) => source.index !== idx)
      newTasks?.splice(destination.index, 0, milestone.tasks[source.index])
      milestone.tasks = newTasks

      setMilestones([...milestones])
    }
  }

  const handleAddTask = (milestoneIdx: number, task: saveProps) => {
    const milestone = milestones?.[milestoneIdx]

    if (!milestone) {
      return
    }

    milestone.tasks = [...milestone.tasks, task] as projectTaskType[]
    milestones[milestoneIdx] = milestone

    setMilestones([...milestones])
  }

  const handleRemoveTask = (milestoneIdx: number, taskIdx: number) => {
    const milestone = milestones?.[milestoneIdx]

    if (!milestone) {
      return
    }

    milestone.tasks = milestone.tasks.filter((_, idx) => idx !== taskIdx)
    milestones[milestoneIdx] = milestone

    setMilestones([...milestones])
  }

  const handleEditTask = (
    milestoneIdx: number,
    taskIdx: number,
    task: saveProps
  ) => {
    const milestone = milestones?.[milestoneIdx]

    if (!milestone) {
      return
    }

    milestone.tasks[taskIdx] = task as projectTaskType
    milestones[milestoneIdx] = milestone

    setMilestones([...milestones])
  }

  const handleRemoveMilestone = (milestoneIdx: number) => {
    const milestone = milestones?.[milestoneIdx]

    if (!milestone) {
      return
    }

    const miles = milestones.filter((_, idx) => idx !== milestoneIdx) || []

    setMilestones([...miles])
  }

  const handleEditMilestone = (
    milestoneIdx: number,
    editedMilestone: saveProps
  ) => {
    const oldMilestone = milestones?.[milestoneIdx]

    if (!oldMilestone) {
      return
    }

    const milestone = {
      ...oldMilestone,
      ...editedMilestone,
    } as projectMilestoneType

    if (editedMilestone.deadline) {
      milestone.deadline = formatISO(editedMilestone.deadline)
    }

    milestones[milestoneIdx] = milestone
    setMilestones([...milestones])
  }

  const handleAddMilestone = (milestone: saveProps) => {
    const newmiles = [
      ...milestones,
      { ...milestone, tasks: [], assignees: milestone.assignees || [] },
    ] as projectMilestoneType[]

    setMilestones([...newmiles])
  }

  const handleSave = async () => {
    if (workTemplate?.id) {
      await mutateAsyncUpdate({ ...workTemplate, milestones })
    } else {
      const created = await mutateAsyncCreate({
        name: workTemplate?.name || null,
        milestones,
      })
      onSaveNew(created)
    }
  }

  return (
    <Box className="flex flex-col">
      <Box
        as="h1"
        className="text-secondary-01 font-semibold text-lg flex justify-center"
      >
        {t('work_templates.preview_title')}
      </Box>

      <Box className="p-2">
        {!workTemplate ? (
          <Box
            as="span"
            className="flex justify-center text-sm text-secondary-01 italic mt-10"
          >
            {t('work_templates.preview_no_selected')}
          </Box>
        ) : (
          <Box>
            <DragDropContext
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              onDragUpdate={handleDragUpdate}
            >
              <DroppableMilestones
                isDisabledDroppable={isDisabledDroppable}
                placeholderProps={placeholderProps}
                className={'mt-0'}
              >
                {milestones.map((milestone, milestoneIndex) => {
                  return (
                    <DraggableMilestones
                      isDisabled={!canTasksWrite}
                      key={milestone.name}
                      id={milestone.name}
                      index={milestoneIndex}
                      isDisabledDroppable={isDisabledDroppable}
                      milestoneActions={
                        canTasksWrite && (
                          <AddTask
                            onSave={(task) => {
                              handleAddTask(milestoneIndex, task)
                            }}
                          />
                        )
                      }
                      rowCells={
                        <>
                          <TaskCell as="label" type="fluid">
                            <Box className="text-lg flex flex-row items-center">
                              {milestone.name}
                            </Box>
                          </TaskCell>

                          {canUsersRead && (
                            <TaskCell
                              header={
                                <TaskCellHeader icon={MdPeople}>
                                  {t('projects.assignees')}
                                </TaskCellHeader>
                              }
                            >
                              <UsersList userEmails={milestone.assignees} />
                            </TaskCell>
                          )}

                          {canTasksWrite && (
                            <TaskCell>
                              <Box className="space-x-2 flex">
                                {/* Delete milestone */}
                                <Button
                                  size="xs"
                                  variant="outlined"
                                  style={{
                                    minWidth: 'fit-content',
                                  }}
                                  onClick={() =>
                                    handleRemoveMilestone(milestoneIndex)
                                  }
                                >
                                  <Icon as={MdDeleteOutline} />
                                </Button>
                                {/* Edit milestone */}
                                <EditMilestone
                                  onSave={(milestoneEdited) =>
                                    handleEditMilestone(
                                      milestoneIndex,
                                      milestoneEdited
                                    )
                                  }
                                  milestone={milestone}
                                />
                              </Box>
                            </TaskCell>
                          )}
                        </>
                      }
                    >
                      <DroppableTasks id={milestone.name}>
                        {milestone.tasks.map((task, taskIndex) => (
                          <DraggableTasks
                            isDisabled={!canTasksWrite}
                            id={task.name}
                            index={taskIndex}
                            key={task.name}
                          >
                            <TaskCell as="label" type="fluid">
                              <Box className="text-lg flex flex-row items-center">
                                {task.name}
                              </Box>
                            </TaskCell>

                            {canUsersRead && (
                              <TaskCell>
                                <UsersList userEmails={task.assignees} />
                              </TaskCell>
                            )}

                            {canTasksWrite && (
                              <TaskCell>
                                <Box className="space-x-2 flex">
                                  {/* Delete task */}
                                  <Button
                                    size="xs"
                                    variant="outlined"
                                    style={{
                                      minWidth: 'fit-content',
                                    }}
                                    onClick={() =>
                                      handleRemoveTask(
                                        milestoneIndex,
                                        taskIndex
                                      )
                                    }
                                  >
                                    <Icon as={MdDeleteOutline} />
                                  </Button>
                                  {/* Edit task */}
                                  <EditTask
                                    onSave={(task) =>
                                      handleEditTask(
                                        milestoneIndex,
                                        taskIndex,
                                        task
                                      )
                                    }
                                    task={task}
                                  />
                                </Box>
                              </TaskCell>
                            )}
                          </DraggableTasks>
                        ))}
                      </DroppableTasks>
                    </DraggableMilestones>
                  )
                })}
              </DroppableMilestones>
            </DragDropContext>

            <AddMilestone
              onSave={(milestone) => {
                handleAddMilestone(milestone)
              }}
            />
          </Box>
        )}

        {workTemplate && (
          <FloatingButton
            size={18}
            icon={MdSave}
            className="bg-primary-01 z-sticky"
            isDisabled={isCreating || isUpdating}
            onClick={() => handleSave()}
          />
        )}
      </Box>
    </Box>
  )
}

export default WorkTemplatesPreview
