import { useQuery } from 'react-query'
import getHoldings from '../services/getHoldings'
import { holdingType } from '../types/holdingType'

const useHoldings = (options: any = {}) => {
  const { isLoading, data: holdings } = useQuery<holdingType[]>(
    'holdings',
    getHoldings,
    options
  )
  return { isLoading, holdings }
}

export default useHoldings
