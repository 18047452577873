import { parseISO } from 'date-fns'
import { projectMilestoneType } from 'features/shared/types/projectType'
import { useDisclosure } from 'hooks/useDisclosure'
import { Button, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdOutlineEdit } from 'react-icons/md'
import SaveResourceModal, {
  saveProps,
} from 'features/projects/components/SaveResourceModal'

interface EditMilestoneProps {
  onSave: (values: saveProps) => void
  milestone: projectMilestoneType
}

const EditMilestone: React.FC<EditMilestoneProps> = ({ onSave, milestone }) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { t } = useTranslation()

  const handleSave = async (values: saveProps) => {
    onClose()
    onSave(values)
  }

  return (
    <>
      <Button
        size="xs"
        variant="outlined"
        style={{
          minWidth: 'fit-content',
        }}
        onClick={() => {
          onOpen()
        }}
      >
        <Icon as={MdOutlineEdit} />
      </Button>

      <SaveResourceModal
        onClose={() => {
          onClose()
        }}
        isOpen={isOpen}
        isLoading={false}
        onSave={handleSave}
        title={t('projects.edit_task')}
        defaultValues={{
          assignees: milestone.assignees,
          deadline: milestone.deadline ? parseISO(milestone.deadline) : null,
          name: milestone.name,
        }}
        showDeadline={false}
      />
    </>
  )
}

export default EditMilestone
