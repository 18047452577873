import FormControl from 'components/FormControl'
import MotionBox from 'components/MotionBox'
import SelectBoolean from 'features/shared/components/SelectBoolean'
import SelectClient from 'features/shared/components/SelectClient'
import SelectUser from 'features/shared/components/SelectUser'
import { Box, Button, Icon } from 'lemon-system'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineFilterAlt,
  MdSearch,
} from 'react-icons/md'
import SelectArea from 'features/shared/components/SelectArea'
import SelectFunnelStatus from '../SelectFunnelStatus'
import SelectCategory from 'features/shared/components/SelectCategory'
import SelectCurrency from 'features/shared/components/SelectCurrency'
import DatePicker from 'components/DatePicker'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import SelectFunnel from 'features/shared/components/SelectFunnel'
import parseStringBoolean from 'utils/parseStringBoolean'

interface ProjectFiltersProps {
  search: string | null
  onApply: (values: any) => void
  isDisabled: boolean
}

const ProjectFilters: React.FC<ProjectFiltersProps> = ({
  search,
  onApply,
  isDisabled,
}) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const { control, handleSubmit, register, setValue, getValues } = useForm({
    defaultValues: {
      name: search,
      active: undefined,
      status: undefined,
      funnel_status: parseStringBoolean(searchParams.get('funnel_status')),
      client_id: undefined,
      funnel_id: searchParams.get('funnel_id'),
      owner: undefined,
      area_id: undefined,
      category_id: undefined,
      currency: undefined,
      created_from: undefined,
      created_to: undefined,
      graduated_from: undefined,
      graduated_to: undefined,
      completed_from: undefined,
      completed_to: undefined,
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    onApply(formatDatesFilters(values))
  })

  const formatDatesFilters = (values: any) => {
    const {
      created_from,
      created_to,
      graduated_from,
      graduated_to,
      completed_from,
      completed_to,
    } = values
    return {
      ...values,
      created_from: created_from
        ? (format(created_from, 'dd/MM/yyyy') as any)
        : undefined,
      created_to: created_to
        ? (format(created_to, 'dd/MM/yyyy') as any)
        : undefined,
      graduated_from: graduated_from
        ? (format(graduated_from, 'dd/MM/yyyy') as any)
        : undefined,
      graduated_to: graduated_to
        ? (format(graduated_to, 'dd/MM/yyyy') as any)
        : undefined,
      completed_from: completed_from
        ? (format(completed_from, 'dd/MM/yyyy') as any)
        : undefined,
      completed_to: completed_to
        ? (format(completed_to, 'dd/MM/yyyy') as any)
        : undefined,
    }
  }

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsVisible(false)
    }
  }, [])

  useEffect(() => {
    setValue('name', search)
  }, [search, setValue])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <MotionBox
      initial={{ translateX: '0%' }}
      animate={{ translateX: isVisible ? '0%' : '100%' }}
      className="flex absolute h-full right-0"
      as="form"
      onSubmit={onSubmit}
    >
      <Button
        size="md"
        className="flex absolute top-0 right-full"
        onClick={() => setIsVisible(!isVisible)}
      >
        <Icon as={isVisible ? MdChevronRight : MdChevronLeft} size={28} />
        <Icon as={MdOutlineFilterAlt} size={28} />
      </Button>

      <Box className="w-56 h-full bg-01 overflow-y-scroll">
        <Box className="flex h-8 items-center rounded-sm m-2 bg-neutral-01">
          <Box
            {...register('name')}
            as="input"
            type="text"
            className="h-full w-full px-2 text-sm text-secondary-01 focus:outline-none"
            disabled={isDisabled}
          />
          <Icon className="mr-2" as={MdSearch} />
        </Box>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>{t('projects.filters.active')}</FormControl.Label>

          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <SelectBoolean
                {...field}
                onChange={({ value }: any) => setValue('active', value)}
                isDisabled={isDisabled}
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.on_funnel_status.title')}
          </FormControl.Label>

          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <SelectFunnelStatus
                {...field}
                onChange={({ value }: any) => {
                  setValue('funnel_status', value.funnel_status)
                  setValue('status', value.status)
                }}
                isDisabled={isDisabled}
                selectionDefault={{
                  funnel_status: getValues('funnel_status'),
                }}
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>{t('projects.filters.clients')}</FormControl.Label>

          <Controller
            name="client_id"
            control={control}
            render={({ field }) => (
              <SelectClient
                {...field}
                onChange={(value: any) =>
                  setValue(
                    'client_id',
                    value.map((v: any) => v.value).join(',') || undefined
                  )
                }
                isDisabled={isDisabled}
                isMultiGrouped
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>{t('projects.filters.owners')}</FormControl.Label>

          <Controller
            name="owner"
            control={control}
            render={({ field }) => (
              <SelectUser
                {...field}
                onChange={(value: any) =>
                  setValue(
                    'owner',
                    value.map((v: any) => v.value).join(',') || undefined
                  )
                }
                isDisabled={isDisabled}
                isMultiGrouped
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>{t('projects.filters.funnels')}</FormControl.Label>

          <Controller
            name="funnel_id"
            control={control}
            render={({ field }) => (
              <SelectFunnel
                {...field}
                onChange={(value: any) =>
                  setValue(
                    'funnel_id',
                    value.map((v: any) => v.value).join(',') || undefined
                  )
                }
                isDisabled={isDisabled}
                defaultValue={getValues('funnel_id')}
                isMultiGrouped
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>{t('projects.filters.areas')}</FormControl.Label>

          <Controller
            name="area_id"
            control={control}
            render={({ field }) => (
              <SelectArea
                {...field}
                onChange={(value: any) =>
                  setValue(
                    'area_id',
                    value.map((v: any) => v.value).join(',') || undefined
                  )
                }
                isDisabled={isDisabled}
                isMultiGrouped
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.categories')}
          </FormControl.Label>

          <Controller
            name="category_id"
            control={control}
            render={({ field }) => (
              <SelectCategory
                {...field}
                onChange={(value: any) =>
                  setValue(
                    'category_id',
                    value.map((v: any) => v.value).join(',') || undefined
                  )
                }
                isDisabled={isDisabled}
                isMultiGrouped
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.currencies')}
          </FormControl.Label>

          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <SelectCurrency
                {...field}
                onChange={(value: any) =>
                  setValue(
                    'currency',
                    value.map((v: any) => v.value).join(',') || undefined
                  )
                }
                isDisabled={isDisabled}
                isMultiGrouped
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.created_from')}
          </FormControl.Label>

          <Controller
            name="created_from"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                isDisabled={isDisabled}
                dateFormat="dd/MM/yyyy"
                size="sm"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.created_to')}
          </FormControl.Label>

          <Controller
            name="created_to"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                isDisabled={isDisabled}
                dateFormat="dd/MM/yyyy"
                size="sm"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.graduated_from')}
          </FormControl.Label>

          <Controller
            name="graduated_from"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                isDisabled={isDisabled}
                dateFormat="dd/MM/yyyy"
                size="sm"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.graduated_to')}
          </FormControl.Label>

          <Controller
            name="graduated_to"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                isDisabled={isDisabled}
                dateFormat="dd/MM/yyyy"
                size="sm"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-2">
          <FormControl.Label>
            {t('projects.filters.completed_from')}
          </FormControl.Label>

          <Controller
            name="completed_from"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                isDisabled={isDisabled}
                dateFormat="dd/MM/yyyy"
                size="sm"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </FormControl>

        <FormControl className="mx-2 mb-4">
          <FormControl.Label>
            {t('projects.filters.completed_to')}
          </FormControl.Label>

          <Controller
            name="completed_to"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                isDisabled={isDisabled}
                dateFormat="dd/MM/yyyy"
                size="sm"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </FormControl>

        <Box className="flex justify-center">
          <Button type="submit" isDisabled={isDisabled}>
            {t('apply')}
          </Button>
        </Box>
      </Box>
    </MotionBox>
  )
}

export default ProjectFilters
