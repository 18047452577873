import UserAvatar from 'components/UserAvatar'
import ListButton from 'components/ListButton'
import { default as LayoutSkeleton } from 'components/Layout'
import { Outlet, useLocation } from 'react-router-dom'
import {
  MdLogout,
  MdOutlineAdminPanelSettings,
  MdOutlineDashboard,
  MdOutlineManageAccounts,
  MdOutlineFilterAlt,
  MdOutlineGroups,
  MdPeopleOutline,
  MdTextRotationNone,
  MdCategory,
  MdBarChart,
  MdTaskAlt,
} from 'react-icons/md'
import { Box, Button, Icon } from 'lemon-system'
import routes from 'routes/routing'
import { useUser } from 'features/shared/state/user/userContext'
import { useTenant } from 'features/shared/state/tenant/tenantContext'
import useAuth from 'features/shared/hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { Confirm } from 'components/Dialog'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { availableReports } from 'features/reports/views/Reports'
import useTrackEvent from 'hooks/useTrackEvent'

const Layout: React.FC = () => {
  const location = useLocation()
  const { user } = useUser()
  const { tenant } = useTenant()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const { policyValidation } = usePolicyValidation()
  const reportValidator = policyValidation
  const isReportsAllowed = Object.values(availableReports).some((r) =>
    reportValidator(r.allowedPolicy)
  )

  const { track } = useTrackEvent()

  return (
    <LayoutSkeleton
      logo={tenant.logo}
      header={
        <Box className="flex">
          <UserAvatar
            avatar={user.avatar}
            name={user.name}
            email={user.email}
          />

          <Confirm
            title={t('login.logout_warning_confirm_title')}
            onConfirm={() => {
              track({
                eventName: 'logout - confirm',
                metadata: {
                  user,
                  location: location.pathname,
                },
              })
              logout()
            }}
            onOpen={() => {
              track({
                eventName: 'logout',
                metadata: {
                  user,
                  location: location.pathname,
                },
              })
            }}
            onCancel={() => {
              track({
                eventName: 'logout - cancel',
                metadata: {
                  user,
                  location: location.pathname,
                },
              })
            }}
            button={
              <Button
                variant="outlined"
                style={{
                  minWidth: 'fit-content',
                }}
              >
                <Icon as={MdLogout} size="xs" />
              </Button>
            }
            body={t('login.logout_warning_confirm_body')}
          />
        </Box>
      }
      sidebar={
        <Box className="space-y-2 p-4">
          <ListButton icon={MdOutlineDashboard} to={routes.dashboard}>
            {t('routes.dashboard')}
          </ListButton>
          {policyValidation('funnels.read') && (
            <ListButton icon={MdOutlineFilterAlt} to={routes.funnels}>
              {t('routes.funnels')}
            </ListButton>
          )}
          <ListButton icon={MdCategory} to={routes.projects}>
            {t('routes.projects')}
          </ListButton>
          <ListButton icon={MdTaskAlt} to={routes.work_templates}>
            {t('routes.work_templates')}
          </ListButton>
          {policyValidation('clients.read') && (
            <ListButton icon={MdPeopleOutline} to={routes.clients}>
              {t('routes.clients')}
            </ListButton>
          )}
          {policyValidation('holdings.read') && (
            <ListButton icon={MdOutlineGroups} to={routes.holdings}>
              {t('routes.holdings')}
            </ListButton>
          )}
          {policyValidation('areas.read') && (
            <ListButton icon={MdTextRotationNone} to={routes.areas}>
              {t('routes.areas')}
            </ListButton>
          )}
          {policyValidation('categories.read') && (
            <ListButton icon={MdCategory} to={routes.categories}>
              {t('routes.categories')}
            </ListButton>
          )}
          {policyValidation('users.read') && (
            <ListButton icon={MdOutlineManageAccounts} to={routes.users}>
              {t('routes.users')}
            </ListButton>
          )}

          {policyValidation('roles.read') && (
            <ListButton icon={MdOutlineAdminPanelSettings} to={routes.roles}>
              {t('routes.roles')}
            </ListButton>
          )}
          {isReportsAllowed && (
            <ListButton icon={MdBarChart} to={routes.reports}>
              {t('routes.reports')}
            </ListButton>
          )}
        </Box>
      }
    >
      <Outlet />
    </LayoutSkeleton>
  )
}

export default Layout
