import { useDisclosure } from 'hooks/useDisclosure'
import { Button, Icon } from 'lemon-system'

import { useTranslation } from 'react-i18next'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import SaveResourceModal, {
  saveProps,
} from 'features/projects/components/SaveResourceModal'

interface AddTaskProps {
  onSave: (values: saveProps) => void
}

const AddTask: React.FC<AddTaskProps> = ({ onSave }) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { t } = useTranslation()

  const handleSave = async (values: saveProps) => {
    onClose()
    values.assignees = values.assignees || []
    onSave(values)
  }

  return (
    <>
      <Button variant="outlined" onClick={() => onOpen()}>
        <Icon as={MdOutlineAddCircleOutline} className="mr-2" />{' '}
        {t('projects.add_task')}
      </Button>

      <SaveResourceModal
        onClose={() => onClose()}
        isOpen={isOpen}
        onSave={handleSave}
        title={t('projects.add_task')}
        isLoading={false}
        showDeadline={false}
      />
    </>
  )
}

export default AddTask
