import { fetcher } from 'utils/fetcher'

export type removeContactProps = {
  clientId: string
  contactId: string
}

async function removeContact({ clientId, contactId }: removeContactProps) {
  const response = await fetcher.delete(
    `/crm-clients/clients/${clientId}/contacts/${contactId}`
  )

  return response
}

export default removeContact
