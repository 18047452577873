import { useTenant } from 'features/shared/state/tenant/tenantContext'
import { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import trackEvent, { TrackEventProps } from 'utils/trackEvent'

interface TrackProps {
  trackOnMount?: TrackEventProps
}

function useTrackEvent(props?: TrackProps) {
  const { tenant } = useTenant()

  const track = ({ eventName, metadata }: TrackEventProps) => {
    trackEvent(eventName, {
      ...(metadata && { ...metadata }),
      ...(tenant && {
        tenant: tenant.name
      })
    })
  }

  const debouncedTrack = useDebouncedCallback(
    ({ eventName, metadata }: TrackEventProps) => {
      trackEvent(eventName, {
        ...(metadata && { ...metadata }),
        ...(tenant && {
          tenant: tenant.name
        })
      })
    },
    1000
  )

  useEffect(() => {
    props?.trackOnMount &&
      trackEvent(props.trackOnMount.eventName, {
        ...(props?.trackOnMount?.metadata && { ...props.trackOnMount.metadata }),
        ...(tenant && {
          tenant: tenant.name
        }),
      })

    // eslint-disable-next-line
  }, [])

  return { track, debouncedTrack }
}

export default useTrackEvent
