import { clientsType } from 'features/shared/types/clientsType'
import { defaultActionContent } from 'features/shared/utils'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import activateClient from '../services/activateClient'
import { clientsQueryKey } from '../views/Clients'

const useActivateUser = () => {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(activateClient, {
    onSuccess: (_: clientsType, updatedClient) => {
      updatedClient.active = true

      const updatedClients = (
        queryClient.getQueryData([clientsQueryKey]) as clientsType[]
      ).map((client) => (updatedClient.id === client.id ? updatedClient : client))
      queryClient.setQueryData(clientsQueryKey, updatedClients)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('clients.success_activate'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useActivateUser
