import MotionBox from 'components/MotionBox'
import { Box } from 'lemon-system'

interface PlaceholderLoaderProps {
  width?: number
  height?: number
  className?: string
}

const PlaceholderLoader: React.FC<PlaceholderLoaderProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <Box
      className={`bg-01 relative overflow-hidden ${className}`}
      style={{
        width,
        height,
      }}
    >
      <MotionBox
        className={`absolute -left-40 w-40 h-full top-0 bg-gradient-to-r from-transparent via-neutral-01 to-transparent opacity-50`}
        initial={{ left: -160 }}
        animate={{ left: '100%' }}
        transition={{
          repeat: Infinity,
          duration: 1,
        }}
        exit={{ left: 0 }}
      />
    </Box>
  )
}

export default PlaceholderLoader
