import Areas from './views/Areas'
import { Route, Routes } from 'react-router-dom'
import AreaSummary from './views/AreaSummary'

const AreaWrapper: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Areas />} />
        <Route index element={<AreaSummary />} />
    </Routes>
  )
}

export default AreaWrapper
