import { fetcher } from 'utils/fetcher'

type UploadFileProps = {
  fileName: string
  file: string
  projectId: string
}

async function uploadFile({ projectId, file, fileName }: UploadFileProps) {
  const result = await fetcher.post(
    `/crm-clients/projects/${projectId}/files`,
    {
      file,
      filename: fileName,
    }
  )

  return result
}

export default uploadFile
