import { projectMilestoneType } from 'features/shared/types/projectType'
import { fetcher } from 'utils/fetcher'

type updateMilestoneStatusProps = {
  status: boolean
  projectId: string
  milestoneId: string
}

async function updateMilestoneStatus({
  status,
  projectId,
  milestoneId,
}: updateMilestoneStatusProps) {
  const milestone = await fetcher.patch<projectMilestoneType>(
    `/crm-clients/projects/${projectId}/milestones/${milestoneId}`,
    {
      status,
    }
  )

  return milestone
}

export default updateMilestoneStatus
