import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Box, Button } from 'lemon-system'

import FormControl from 'components/FormControl'
import SelectIcon from 'components/SelectIcon'
import useProjectUpdater from '../hooks/useUpdateProject'
import SelectClients from 'features/shared/components/SelectClient'
import getAreas from 'features/shared/services/getAreas'
import Avatar from 'components/Avatar'
import getCategories from 'features/shared/services/getCategories'
import SelectUser from 'features/shared/components/SelectUser'
import FloatingButton from 'components/FloatingButton'
import getClient from 'features/shared/services/getClient'
import useProjects from '../hooks/useProject'
import DeleteResource from '../components/DeleteResource'
import MarkAsFinishedResource from '../components/MarkAsFinishedResource'
import PageLoader from 'components/PageLoader'

import { areaType } from 'features/shared/types/areaType'
import { areasQueryKey } from 'features/areas/views/Areas'
import { categoryType } from 'features/shared/types/categoryType'
import { categoriesQueryKey } from 'features/categories/views/Categories'
import { MdSave } from 'react-icons/md'
import { clientType } from 'features/shared/types/clientType'
import { projectType } from 'features/shared/types/projectType'
import { serverProjectData } from '../services/updateProject'
import { useTranslation } from 'react-i18next'
import { useCurrentTenant } from 'features/shared/hooks/useCurrentTenant'
import SelectCurrency from 'features/shared/components/SelectCurrency'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'

const ProjectProperties: React.FC = () => {
  const { policyValidation } = usePolicyValidation()
  const { projectId } = useParams()
  const showSaveButton = policyValidation('projects_properties.write')
  const { data: projectData } = useProjects(projectId || '')
  const useUpdateProject = useProjectUpdater()
  const { t } = useTranslation()
  const { isLoading: isLoadingDataTenant, data: currentTenant } =
    useCurrentTenant()

  const { isLoading: isLoadingClient, data: client } = useQuery<clientType>(
    `client-${projectData?.client_id}`,
    () => getClient(projectData?.client_id),
    { enabled: policyValidation('clients.read') }
  )

  const [projectProperties, setProjectProperties] = useState<projectType>(
    projectData || ({} as projectType)
  )

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
  const [isDeletingLoading, setIsDeletingLoading] = useState<boolean>(false)
  const [isMarkAsFinishedOpen, setIsMarkAsFinishedOpen] =
    useState<boolean>(false)
  const [isMarkAsFinishedLoading, setIsMarkAsFinishedLoading] =
    useState<boolean>(false)
  const [isSaveChangesLoading, setIsSaveChangesLoading] =
    useState<boolean>(false)

  const { data: areas } = useQuery<areaType[]>(areasQueryKey, getAreas, {
    enabled: policyValidation('areas.read'),
  })

  const { data: categories } = useQuery<categoryType[]>(
    categoriesQueryKey,
    getCategories,
    { enabled: policyValidation('categories.read') }
  )

  const { track } = useTrackEvent({
    trackOnMount: {
      eventName: 'projects - properties',
      metadata: {
        projectId,
      },
    },
  })

  const createParsedProjectData = (currentProjectData: projectType) => {
    const isValidAmount = !isNaN(Number(currentProjectData?.worth?.amount))
    const amount = isValidAmount
      ? Number(currentProjectData?.worth?.amount)
      : null

    const isValidMultiplier = !isNaN(
      Number(currentProjectData?.worth?.multiplier)
    )
    const multiplier = isValidMultiplier
      ? Number(currentProjectData?.worth?.multiplier)
      : null

    return {
      id: currentProjectData.id,
      name: currentProjectData.name,
      active: currentProjectData.active,
      status: currentProjectData.status,
      client_id: currentProjectData.client_id,
      area_id: currentProjectData.area.id,
      category_id: currentProjectData.category.id,
      owner: currentProjectData.owner,
      worth: {
        currency: currentProjectData?.worth?.currency,
        amount: amount,
        multiplier: multiplier,
      },
    } as serverProjectData
  }

  const handleDeletePropertiesProject = async () => {
    try {
      await useUpdateProject.mutateAsync(
        createParsedProjectData({
          ...projectData,
          active: !projectData?.active,
        } as projectType)
      )
    } catch {}
  }

  const handlemarkAsFinishedPropertiesProject = async () => {
    try {
      await useUpdateProject.mutateAsync(
        createParsedProjectData({ ...projectData, status: true } as projectType)
      )
    } catch {}
  }

  const handleSaveChangePropertiesProject = async () => {
    try {
      await useUpdateProject.mutateAsync(
        createParsedProjectData({ ...projectProperties } as projectType)
      )
    } catch {}
  }

  const handleProjectPoperty = (key: string, value: any) =>
    setProjectProperties({ ...projectProperties, [key]: value })

  useEffect(() => {
    const currentCurrency = projectProperties?.worth?.currency
    const isValidCurrentCurrency = !!currentCurrency

    if (!isValidCurrentCurrency) {
      handleProjectPoperty('worth', {
        ...projectProperties.worth,
        currency: currentTenant?.currency,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectProperties, currentTenant])

  if (isSaveChangesLoading) return <PageLoader />

  return (
    <>
      <Box className="font-semibold">
        <br />

        <Box as="div" className="py-1">
          {t('projects.properties.identification')}
        </Box>

        <FormControl>
          <FormControl.Label>{t('name')}</FormControl.Label>
          <Box className="h-8 w-1/2 ring-1 ring-secondary-01 flex items-center pl-2 mb-4">
            <Box
              as="input"
              type="text"
              name="name"
              placeholder={t('name')}
              required
              value={projectProperties?.name || ''}
              className="h-full w-full focus:outline-none"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleProjectPoperty(
                  'name',
                  (e.target as HTMLInputElement).value
                )
              }
            />
          </Box>
        </FormControl>

        {policyValidation('clients.read') && (
          <>
            <FormControl className="w-1/2 flex justify-center">
              <FormControl.Label>
                {t('projects.properties.client_label')}
              </FormControl.Label>
              {!!projectProperties && !isLoadingClient && (
                <FormControl>
                  <SelectClients
                    {...(projectProperties?.client_id && {
                      defaultValue: {
                        label: client?.name,
                        value: client?.id,
                        icon: (
                          <Avatar
                            id={`${client?.name}`}
                            name={client?.name}
                            size={5}
                          />
                        ),
                      },
                    })}
                    onChange={(newValue: any) => {
                      handleProjectPoperty('client_id', newValue.value)
                    }}
                    size="md"
                  />

                  <FormControl.ErrorMessage>
                    {t('field_required')}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            </FormControl>
            <br />
          </>
        )}
        <FormControl className="w-1/2 flex justify-center">
          <FormControl.Label>
            {t('projects.properties.area_label')}
          </FormControl.Label>
          <SelectIcon
            {...(projectData?.area?.name && {
              defaultValue: {
                value: projectData?.area?.id,
                label: projectData?.area?.name,
                icon: (
                  <Avatar
                    id={`${projectData?.area?.name}`}
                    name={projectData?.area?.name}
                    size={5}
                  />
                ),
              },
            })}
            onChange={(newValue: any) => {
              const newArea = areas?.find((a) => a.id === newValue.value)
              if (newArea)
                setProjectProperties({ ...projectProperties, area: newArea })
            }}
            options={
              areas
                ? areas.map((area: any) => ({
                    value: area.id,
                    label: area.name,
                    icon: (
                      <Avatar id={`${area.name}`} name={area.name} size={5} />
                    ),
                  }))
                : [
                    {
                      value: projectData?.area?.id,
                      label: projectData?.area?.name,
                      icon: (
                        <Avatar
                          id={`${projectData?.area?.name}`}
                          name={projectData?.area?.name}
                          size={5}
                        />
                      ),
                    },
                  ]
            }
            value={projectData?.area?.name}
            size="md"
          />
          <FormControl.ErrorMessage>
            {t('field_required')}
          </FormControl.ErrorMessage>
        </FormControl>
        <br />
        <FormControl className="w-1/2 flex justify-center">
          <FormControl.Label>
            {t('projects.properties.category_label')}
          </FormControl.Label>
          <SelectIcon
            {...(projectData?.category?.name && {
              defaultValue: {
                value: projectData?.category?.id,
                label: projectData?.category?.name,
                icon: (
                  <Avatar
                    id={`${projectData?.category?.name}`}
                    name={projectData?.category?.name}
                    size={5}
                  />
                ),
              },
            })}
            onChange={(newValue: any) => {
              const newCategory = categories?.find(
                (c) => c.id === newValue.value
              )
              if (newCategory)
                setProjectProperties({
                  ...projectProperties,
                  category: newCategory,
                })
            }}
            options={
              categories
                ? categories.map((cat: any) => ({
                    value: cat.id,
                    label: cat.name,
                    icon: (
                      <Avatar id={`${cat.name}`} name={cat.name} size={5} />
                    ),
                  }))
                : [
                    {
                      value: projectData?.category?.id,
                      label: projectData?.category?.name,
                      icon: (
                        <Avatar
                          id={`${projectData?.category?.name}`}
                          name={projectData?.category?.name}
                          size={5}
                        />
                      ),
                    },
                  ]
            }
            size="md"
          />
          <FormControl.ErrorMessage>
            {t('field_required')}
          </FormControl.ErrorMessage>
        </FormControl>

        {policyValidation('users.read') && (
          <>
            <br />
            <FormControl className="w-1/2 flex justify-center">
              <FormControl.Label>
                {t('projects.properties.owner_label')}
              </FormControl.Label>
              <FormControl>
                <SelectUser
                  {...(projectData?.owner && {
                    defaultValue: {
                      value: projectData?.owner,
                    },
                  })}
                  onChange={(newValue: any) => {
                    handleProjectPoperty('owner', newValue.value)
                  }}
                  size="md"
                />
                <FormControl.ErrorMessage>
                  {t('field_required')}
                </FormControl.ErrorMessage>
              </FormControl>
            </FormControl>
          </>
        )}

        <Box as="div" className="py-3 border-b-2 border-neutral-02" />
        {!isLoadingDataTenant && (
          <>
            <Box as="div" className="py-3">
              {t('projects.properties.value_label')}
            </Box>
            <FormControl className="w-1/2 flex justify-center">
              <FormControl.Label>
                {t('projects.properties.money_label')}
              </FormControl.Label>
              <SelectCurrency
                onChange={(newValue: any) => {
                  handleProjectPoperty('worth', {
                    ...projectProperties.worth,
                    currency: newValue.value,
                  })

                  track({
                    eventName: 'projects - properties - currency update',
                    metadata: {
                      projectId,
                      currency: newValue.value,
                    },
                  })
                }}
                defaultValue={
                  projectProperties?.worth?.currency
                    ? projectProperties?.worth?.currency
                    : currentTenant?.currency
                }
                size="md"
              />
              <FormControl.ErrorMessage>
                {t('field_required')}
              </FormControl.ErrorMessage>
            </FormControl>
          </>
        )}
        <br />
        <FormControl>
          <FormControl.Label>
            {t('projects.properties.amount_label')}
          </FormControl.Label>
          <Box className="h-8 w-1/2 ring-1 ring-secondary-01 flex items-center pl-2 mb-4">
            <Box
              as="input"
              type="text"
              name="amount"
              value={projectProperties?.worth?.amount}
              placeholder={t('projects.properties.amount_label')}
              className="h-full w-full focus:outline-none"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleProjectPoperty('worth', {
                  ...projectProperties.worth,
                  amount: (e.target as HTMLInputElement).value,
                })
              }
            />
          </Box>
        </FormControl>
        <FormControl className='hidden'>
          <FormControl.Label>
            {t('projects.properties.multiplier_label')}
          </FormControl.Label>
          <Box className="h-8 w-1/2 ring-1 ring-secondary-01 flex items-center pl-2 mb-4">
            <Box
              as="input"
              type="text"
              name="multiplier"
              value={projectProperties?.worth?.multiplier}
              placeholder={t('projects.properties.multiplier_label')}
              className="h-full w-full focus:outline-none cursor-not-allowed"
              disabled
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                const val = (e.target as HTMLInputElement).value

                if (parseInt(val) > 100) {
                  return
                }

                handleProjectPoperty('worth', {
                  ...projectProperties.worth,
                  multiplier: val,
                })
              }}
              onKeyPress={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  return
                }

                if (!/\d+/g.test(e.key)) {
                  e.preventDefault()
                }
              }}
            />
          </Box>
        </FormControl>

        {projectData?.pipeline?.funnel_id && (
          <>
            <Box as="div" className="py-3 border-b-2 border-neutral-02" />
            <Box as="div" className="py-2">
              {t('projects.properties.funnel_label')}
            </Box>
            <FormControl>
              <FormControl.Label>
                {t('projects.properties.funnel_name')}
              </FormControl.Label>
              <Box className="h-8 w-1/2 ring-1 ring-secondary-01 flex items-center pl-2 mb-4">
                <Box
                  as="input"
                  type="text"
                  name="funnel-name"
                  value={projectData?.pipeline?.funnel_name || ''}
                  placeholder={t('projects.properties.funnel_name')}
                  disabled
                  className="h-full w-full focus:outline-none"
                />
              </Box>
            </FormControl>
            <FormControl>
              <FormControl.Label>
                {t('projects.properties.funnel_status')}
              </FormControl.Label>
              <Box className="h-8 w-1/2 ring-1 ring-secondary-01 flex items-center pl-2 mb-4">
                <Box
                  as="input"
                  type="text"
                  name="funnel-status"
                  placeholder={t('projects.properties.funnel_status')}
                  value={
                    projectData.pipeline.status
                      ? t('projects.properties.business_started_status')
                      : t('projects.properties.business_creation_status')
                  }
                  disabled
                  className="h-full w-full focus:outline-none"
                />
              </Box>
            </FormControl>
          </>
        )}
        <Box as="div" className="py-2" />

        {showSaveButton && (
          <>
            <Button
              title={t('projects.properties.mark_as_finished')}
              className={`$absolute top-14 right-0 bottom-50 rounded-full`}
              onClick={() => {
                setIsMarkAsFinishedOpen(true)

                track({
                  eventName: 'projects - properties - complete',
                  metadata: {
                    projectId,
                  },
                })
              }}
            >
              {t('projects.properties.mark_as_finished')}
            </Button>

            <Box as="button" className="border-l-2 border-neutral-01" />

            <Button
              title={t('delete')}
              className={`$absolute top-14 right-0 bottom-50 rounded-full`}
              onClick={() => {
                setIsDeleteOpen(true)

                track({
                  eventName: 'projects - properties - delete',
                  metadata: {
                    projectId,
                  },
                })
              }}
            >
              {projectData?.active ? t('deactivate') : t('activate')}
            </Button>

            <FloatingButton
              size={18}
              icon={MdSave}
              className="bg-primary-01"
              onClick={async () => {
                setIsSaveChangesLoading(true)
                await handleSaveChangePropertiesProject?.()
                setIsSaveChangesLoading(false)

                track({
                  eventName: 'projects - properties - edit',
                  metadata: {
                    projectId,
                  },
                })
              }}
            />
          </>
        )}
      </Box>

      <DeleteResource
        title={t('projects.delete_project')}
        onClose={() => {
          setIsDeleteOpen(false)
          setIsDeletingLoading(false)
          track({
            eventName: 'projects - properties - delete - cancel',
            metadata: {
              projectId,
            },
          })
        }}
        onConfirm={async () => {
          setIsDeletingLoading(true)
          await handleDeletePropertiesProject?.()
          setIsDeleteOpen(false)
          setIsDeletingLoading(false)

          track({
            eventName: 'projects - properties - delete - done',
            metadata: {
              projectId,
            },
          })
        }}
        isLoading={isDeletingLoading}
        isOpen={isDeleteOpen}
      />

      <MarkAsFinishedResource
        title={t('projects.mark_as_finished')}
        onClose={() => {
          setIsMarkAsFinishedOpen(false)

          track({
            eventName: 'projects - properties - complete - cancel',
            metadata: {
              projectId,
            },
          })
        }}
        onConfirm={async () => {
          setIsMarkAsFinishedLoading(true)
          await handlemarkAsFinishedPropertiesProject?.()
          setIsMarkAsFinishedOpen(false)
          setIsMarkAsFinishedLoading(false)

          track({
            eventName: 'projects - properties - complete - done',
            metadata: {
              projectId,
            },
          })
        }}
        isLoading={isMarkAsFinishedLoading}
        isOpen={isMarkAsFinishedOpen}
      />
    </>
  )
}

export default ProjectProperties
