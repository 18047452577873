import { useState } from 'react'
import { useQuery } from 'react-query'

import useRemoveContact from 'features/clients/hooks/useRemoveContact'
import useUpdateContact from 'features/clients/hooks/useUpdateContact'
import useUpdateFavouriteContact from 'features/clients/hooks/useUpdateFavouriteContact'
import getClient from 'features/shared/services/getClient'

import { updateContactProps } from 'features/clients/services/updateContact'
import { clientType } from 'features/shared/types/clientType'
import ContactCard, { ContactCardProps } from '../ContactCard'

interface ContactWrapperProps extends Omit<ContactCardProps, 'id'> {
  id: string
  clientId?: string
  isFavourite?: boolean | null
}

const ContactCardWrapper: React.FC<ContactWrapperProps> = ({
  id: contactId,
  clientId,
  isFavourite,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const update = useUpdateContact()
  const remove = useRemoveContact()
  const updateFavouriteContact = useUpdateFavouriteContact()
  const { refetch: refetchClient } = useQuery<clientType>(`client-${clientId}`, () =>
    getClient(clientId)
  )

  const handleEdit = async (contactData: updateContactProps['contactData']) => {
    try {
      await update.mutateAsync({
        clientId: clientId as string,
        contactId,
        contactData,
      })
      setIsEditing(false)
    } catch {
      setIsEditing(true)
    }
  }

  const handleFavourite = async (params: any) => {
    try {
      await updateFavouriteContact.mutateAsync({
        clientId: clientId as string,
        contactId,
      })
      await refetchClient()
    } catch {
    }
  }

  const handleRemove = async () => {
    try {
      await remove.mutateAsync({
        clientId: clientId as string,
        contactId,
      })
    } catch {
      setIsRemoving(true)
    }
  }

  return (
    <ContactCard
      className="m-4"
      isLoading={update.isLoading || remove.isLoading}
      onEdit={handleEdit}
      onFavourite={handleFavourite}
      id={contactId}
      onEditing={setIsEditing}
      onRemoving={setIsRemoving}
      isEditing={isEditing}
      isRemoving={isRemoving}
      onRemove={handleRemove}
      isFavourite={isFavourite}
      {...rest}
    />
  )
}

export default ContactCardWrapper
