import RSSelectType from 'react-select/dist/declarations/src/Select'
import { SelectProps } from 'components/Select'
import SelectIcon from 'components/SelectIcon'
import { GroupBase } from 'react-select'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

interface SelectAscDescProps extends Omit<SelectProps, 'options'> {
  sortingDefault?: string
}

const SelectAscDesc = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  SelectAscDescProps
>(({ size = 'sm', sortingDefault = '', value, ...props }, ref) => {
  const { t } = useTranslation()
  const defaultValue = []
  const valueItems = []
  const options = [
    { label: t('asc'), value: 'asc' },
    { label: t('desc'), value: 'desc' },
  ]

  if (sortingDefault) {
    const found = options.find((o) => o.value === sortingDefault)
    if (found) defaultValue.push(found)
  }

  if (value) {
    const found = options.find((o) => o.value === value)
    if (found) valueItems.push(found)
  }

  return (
    <SelectIcon
      size={size}
      ref={ref}
      options={options}
      defaultValue={defaultValue}
      {...props}
      value={valueItems}
    />
  )
})

export default SelectAscDesc
