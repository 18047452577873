import { format, parseISO } from 'date-fns'
import Avatar from 'components/Avatar'
import { TableRowProps } from 'components/TableRow'
import { projectHistoryType } from 'features/shared/types/projectHistoryType'
import { userType } from 'features/shared/types/userType'
import { Box } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { exists } from 'i18next'
import parseProjectHistoryChanges from 'features/projects/utils/parseProjectHistoryChanges'
import { useMemo } from 'react'

interface HistoryRowProps extends TableRowProps {
  project_history: projectHistoryType
  users: userType[]
}

const HistoryRow: React.FC<HistoryRowProps> = ({ project_history, users }) => {
  const { t } = useTranslation()
  const { summary, date, action, resource, subject } = project_history
  const author = useMemo(
    () => users.find((u) => u.email === project_history.author),
    [users, project_history.author]
  )

  return (
    <>
      <Box as="td" className="w-1/6 items-center py-6">
        <Box className="flex items-center">
          <Box className="flex justify-center w-1/2">
            <Avatar size={9} id={author?.id} name={author?.name} />
          </Box>
          <Box className="flex text-left w-1/2">
            <Box as="span">{author?.name}</Box>
          </Box>
        </Box>
      </Box>
      <Box as="td" className="w-1/6 justify-center items-center">
        {format(parseISO(date), 'dd/MM/yyyy - HH:mm:ss')}
      </Box>
      <Box as="td" className="w-2/6">
        <Box as="span" className="flex justify-center w-full">
          {t(`projects.history.actions.${action}`)}{' '}
          {exists(`projects.history.resource.${resource}`)
            ? t(`projects.history.resource.${resource}`)
            : resource}
          :
        </Box>
        <Box as="span" className="ml-1 font-bold flex justify-center w-full">
          {subject}
        </Box>
      </Box>

      <Box as="td" className="w-2/6 max-w-[0] text-left">
        <Box as="ul" className="w-full flex flex-col overflow-hidden">
          {summary &&
            Object.keys(summary).map((key, idx) => (
              <Box
                as="li"
                className="flex overflow-hidden w-full"
                key={`sum-${idx}`}
              >
                <Box className="flex min-w-[13ch] flex-grow-0">
                  <Box as="span" className="font-bold">
                    {key}:
                  </Box>
                </Box>

                <Box className="flex flex-wrap items-center overflow-hidden">
                  <Box
                    as="span"
                    className="text-error-01 ml-2 line-through overflow-ellipsis overflow-hidden whitespace-nowrap"
                  >
                    {parseProjectHistoryChanges(summary[key].from)}
                  </Box>

                  <Box className="ml-2">|</Box>

                  <Box
                    as="span"
                    className="text-tertiary-01 ml-2 overflow-ellipsis overflow-hidden whitespace-nowrap"
                  >
                    {parseProjectHistoryChanges(summary[key].to)}
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  )
}

export default HistoryRow
