import MotionBox from 'components/MotionBox'
import { useMemo } from 'react'
import { createPortal } from 'react-dom'
import { uniqId } from 'utils/uniqId'

interface OverlayProps {
  isOpen?: boolean
}

const Overlay: React.FC<OverlayProps> = ({ isOpen }) => {
  const overlayId = useMemo(() => uniqId(), [])

  if (!isOpen) return null

  return createPortal(
    <MotionBox
      key={`ContactCardOverlay-${overlayId}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.6 }}
      transition={{
        duration: 0.3,
      }}
      exit={{ opacity: 0 }}
      className="fixed w-screen h-screen bg-neutral-09 opacity-0 top-0 left-0 z-modal"
    />,
    document.querySelector('body') as HTMLBodyElement
  )
}

export default Overlay
