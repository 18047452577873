import Avatar from 'components/Avatar'
import FormControl from 'components/FormControl'
import { useUser } from 'features/shared/state/user/userContext'
import { Box, Button } from 'lemon-system'
import Input from 'components/Input'
import FloatingButton from 'components/FloatingButton'
import { MdSave } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import UserAvatarEdit from './UserAvatarEdit'
import useUpdateUser from '../hooks/useUpdateUser'
import UserDashboardSetting from './UserDashboardSetting'
import { userType } from 'features/shared/types/userType'
import useTrackEvent from 'hooks/useTrackEvent'

const UserProfile: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { register, handleSubmit, setValue } = useForm()
  const [isAvatarEditing, setIsAvatarEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const update = useUpdateUser()

  const { track } = useTrackEvent({
    trackOnMount: {
      eventName: 'profile',
      metadata: {
        user,
      },
    },
  })

  const handleUserUpdate = async (values: userType) => {
    setIsUpdating(true)
    try {
      await update.mutateAsync({ ...user, ...values })
    } catch {}
    setIsUpdating(false)
  }

  const onSubmit = handleSubmit(async (values) => {
    handleUserUpdate(values as userType)

    track({
      eventName: 'profile - update',
      metadata: {
        user,
      },
    })
  })

  const handleDashboardSettingChanges = (
    dashboardSettings: userType['dashboard']
  ) => {
    setValue('dashboard', dashboardSettings)
  }

  return (
    <Box as="form" onSubmit={onSubmit}>
      <Box className="flex w-full h-72 p-6">
        <Box className="flex flex-col h-full">
          <Box className="flex justify-center mb-4">
            <Avatar src={user.avatar} size={60} />
          </Box>
          <Box className="flex justify-center">
            <Button
              title="Cambiar"
              className="flex rounded-full w-min p-4"
              onClick={() => {
                setIsAvatarEditing(true)

                track({
                  eventName: 'profile - update avatar',
                  metadata: {
                    user,
                  },
                })
              }}
            >
              {t('user_profile.change_avatar_btn')}
            </Button>
          </Box>
        </Box>
        <Box className="flex flex-col h-full w-72 ml-8">
          <FormControl className="mb-4">
            <FormControl.Label>{t('user_profile.name')}</FormControl.Label>

            <Input
              size="md"
              autoComplete="off"
              defaultValue={user.name || ''}
              {...register('name', { required: true })}
            />
          </FormControl>

          <FormControl>
            <FormControl.Label>{t('user_profile.email')}</FormControl.Label>

            <Input
              size="md"
              autoComplete="off"
              isDisabled
              value={user.email || ''}
            />
          </FormControl>
        </Box>

        <FloatingButton
          size={18}
          className="bg-primary-01"
          icon={MdSave}
          isDisabled={isUpdating}
          type="submit"
        />

        {isAvatarEditing && (
          <UserAvatarEdit
            isOpen={isAvatarEditing}
            onClose={() => setIsAvatarEditing(false)}
            user={user}
          />
        )}
      </Box>

      <Box className="flex w-full h-96 mt-4 ml-4">
        <UserDashboardSetting
          user={user}
          onChange={handleDashboardSettingChanges}
        />
      </Box>
    </Box>
  )
}

export default UserProfile
