import { Box } from 'lemon-system'
import { Droppable, DroppableProvided } from 'react-beautiful-dnd'

interface DroppableTasksProps {
  id: string
}

const DroppableTasks: React.FC<DroppableTasksProps> = ({ id, children }) => {
  return (
    <Droppable droppableId={id} type="tasks">
      {(droppableProvided: DroppableProvided) => (
        <Box
          style={{
            minHeight: '1px',
          }}
          {...droppableProvided.droppableProps}
          ref={droppableProvided.innerRef}
        >
          {children}
          {droppableProvided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

export default DroppableTasks
