import { Route, Routes } from 'react-router-dom'
import routes from 'routes/routing'
import ProjectDocuments from './views/ProjectDocuments'
import ProjectHistory from './views/ProjectHistory'
import ProjectComments from './views/ProjectComments'
import ProjectProperties from './views/ProjectProperties'
import ProjectsDetail, { projectsRoutes } from './views/ProjectsDetail'
import ProjectsTasks from './views/ProjectsTasks'
import ProtectedRoute from 'features/shared/components/ProtectedRoute'
import { useMemo } from 'react'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'

const ProjectsWrapper: React.FC = () => {
  const { policyValidation } = usePolicyValidation()

  const availableRoute = useMemo(
    () =>
      projectsRoutes.find((route) => policyValidation(route.allowedPolicy))
        ?.path || '/',

    // eslint-disable-next-line
    []
  )

  return (
    <Routes>
      <Route path=":projectId" element={<ProjectsDetail />}>
        <Route
          index
          element={
            <ProtectedRoute
              allowedPolicy="projects_tasks.read"
              redirect={availableRoute}
              showSnackbarMsg={false}
            >
              <ProjectsTasks />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.project_comments}
          element={
            <ProtectedRoute allowedPolicy="projects_comments.read">
              <ProjectComments />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.projects_documents}
          element={
            <ProtectedRoute allowedPolicy="projects_documents.read">
              <ProjectDocuments />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.project_history}
          element={
            <ProtectedRoute allowedPolicy="projects_history.read">
              <ProjectHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.project_properties}
          element={
            <ProtectedRoute allowedPolicy="projects_properties.read">
              <ProjectProperties />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProjectsWrapper
