import Holdings from './views/Holdings'
import { Route, Routes } from 'react-router-dom'
import HoldingSummary from './views/HoldingSummary'

const HoldingWrapper: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Holdings />} />
        <Route index element={<HoldingSummary />} />
    </Routes>
  )
}

export default HoldingWrapper
