import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'lemon-system'
import { useQuery } from 'react-query'
import getFunnels from '../services/getFunnels'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { defaultActionContent } from 'features/shared/utils'

export const funnelsQueryKey = 'funnels'

function useFunnels(includeInactives: boolean = false) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const { policyValidation } = usePolicyValidation()

  return useQuery(
    [funnelsQueryKey, includeInactives],
    () => getFunnels(includeInactives),
    {
      refetchOnWindowFocus: true,
      enabled: policyValidation('funnels.read'),
      onError: () =>
        snackbar.add({
          duration: 3000,
          content: t('unexpected_error'),
          status: 'error',
          actionContent: defaultActionContent,
        }),
    }
  )
}

export default useFunnels
