import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'lemon-system'
import { useQuery } from 'react-query'
import getPoliciesFromRole from '../services/getPoliciesFromRole'
import { defaultActionContent } from 'features/shared/utils'

export const policiesRoleQueryKey = 'policiesRole'

function usePoliciesFromRole(role: string) {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useQuery<string[]>(
    [policiesRoleQueryKey, role],
    () => getPoliciesFromRole(role),
    {
      onError: () =>
        snackbar.add({
          duration: 3000,
          content: t('unexpected_error'),
          status: 'error',
          actionContent: defaultActionContent,
        }),
    }
  )
}

export default usePoliciesFromRole
