import * as Sentry from '@sentry/react'
import axios from 'axios'
import constants from 'configs/constants'

const tokenStorage = localStorage.getItem('app.auth.token')
const token = tokenStorage && JSON.parse(tokenStorage)

export const fetcher = axios.create({
  baseURL: constants.API_URL,
})

export const fetcherWithStatus = axios.create({
  baseURL: constants.API_URL,
})

export const fetcherWithAll = axios.create({
  baseURL: constants.API_URL,
})

export const fetcherWithFile = axios.create({
  baseURL: constants.API_URL,
  responseType: 'blob',
})

fetcher.interceptors.response.use(
  (response) => {
    return response?.data
  },
  (error) => {
    if (error.request.status >= 500) {
      Sentry.setExtra('request_payload', JSON.parse(error.config.data))
      Sentry.captureException(error)
    }
    return Promise.reject(error)
  }
)

fetcherWithStatus.interceptors.response.use(
  (response) => {
    return { ...response?.data, http_status: response?.status }
  },
  (error) => {
    if (error.request.status >= 500) {
      Sentry.setExtra('request_payload', JSON.parse(error.config.data))
      Sentry.captureException(error)
    }
    return Promise.reject(error)
  }
)

fetcherWithFile.interceptors.response.use(
  (response) => {
    return {
      data: response?.data,
      headers: response?.headers,
    }
  },
  (error) => {
    if (error.request.status >= 500) {
      Sentry.captureException(error)
    }
    return Promise.reject(error)
  }
)

fetcherWithAll.interceptors.response.use(
  (response) => {
    return {
      data: response?.data,
      headers: response?.headers,
    }
  },
  (error) => {
    if (error.request.status >= 500) {
      Sentry.captureException(error)
    }
    return Promise.reject(error)
  }
)

export const setHeaders = (token: string) => {
  fetcher.defaults.headers.common['token'] = token
  fetcherWithFile.defaults.headers.common['token'] = token
  fetcherWithAll.defaults.headers.common['token'] = token
}

export const resetHeaders = () => {
  delete fetcher.defaults.headers.common['token']
}

if (token) setHeaders(token)

