import { userType } from 'features/shared/types/userType'
import { fetcher } from 'utils/fetcher'

export type updateUserAvatarProps = {
  filename: string | null
  file: string | null
}

async function updateUserAvatar({ filename, file }: updateUserAvatarProps) {
  return await fetcher.post<userType>('landlord/users/me/avatars', {
    filename,
    file,
  })
}

export default updateUserAvatar
