import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { Box, Button, Icon } from 'lemon-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'

interface MenuProjectButtonProps {
  size: number
  icon: IconType
  onCreateProject?: () => void
}

const MenuProjectButton: React.FC<MenuProjectButtonProps> = ({
  size,
  icon,
  onCreateProject,
}) => {
  const { policyValidation } = usePolicyValidation()
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  const onClick = () => {
    setShow(!show)
  }

  useEffect(() => {
    const menuIdPattern = 'menu-projects'
    const handleClickOutside = (event: MouseEvent) => {
      const isMenuClick = (event.target as HTMLElement).id.includes(
        menuIdPattern
      )
      if (!isMenuClick && show) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [show])

  return (
    <Box className="fixed right-6 bottom-5">
      <Button
        id="menu-projects-create-button"
        className={`bg-primary-01 override:(rounded-full min-w-0 w-${size} h-${size})`}
        onClick={onClick}
      >
        <Icon as={icon} size={size * 1.5} id="menu-projects-svg-create-button" />
      </Button>

      <Box
        className={`absolute bottom-full right-0 flex flex-col items-end space-y-5 mb-5 ${
          !show ? 'hidden' : ''
        }`}
      >
        {policyValidation('projects.write') && (
          <Button
            id="menu-projects-create-new-button"
            title={t('clients.create_project.title')}
            isDisabled={false}
            className="w-max"
            onClick={() => {
              setShow(false)
              onCreateProject?.()
            }}
          >
            <Box className="text-xs pointer-events-none">
              {t('clients.create_project.title')}
            </Box>
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default MenuProjectButton
