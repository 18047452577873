import PlaceholderLoader from 'components/PlaceholderLoader'
import DeleteResource from 'features/projects/components/DeleteResource'
import { workTemplates } from 'features/shared/types/workTemplates'
import { useDisclosure } from 'hooks/useDisclosure'
import { Box, Button, IconButton } from 'lemon-system'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import useDeleteWorkTemplates from '../hooks/useDeleteWorkTemplates'
import useWorkTemplates from '../hooks/useWorkTemplates'
import WorkTemplatesPreview from './WorkTemplatesPreview'

const WorkTemplates: React.FC = () => {
  const tenant = useMemo(() => {
    const [subdomain] = window.location.hostname.split('.')

    return subdomain
  }, [])

  const emptyWorkTemplate = () => {
    return {
      id: null,
      created_at: null,
      name: null,
      tenant,
      milestones: [],
    } as workTemplates
  }
  const { t } = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [wTSelected, setWTSelected] = useState<workTemplates>(null)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const workTemplateDelete = useRef<workTemplates>(null)
  const { mutateAsync, isLoading: isDeleting } = useDeleteWorkTemplates()
  const { isLoading, data } = useWorkTemplates()
  const handleDelete = async () => {
    const workTemplate = workTemplateDelete.current
    await mutateAsync(workTemplate)

    if (workTemplate?.id === wTSelected?.id) {
      setWTSelected(null)
    }

    workTemplateDelete.current = null
  }

  return (
    <Box className="flex w-full p-2">
      <Box className="flex-col w-1/4">
        <Box
          as="h1"
          className="flex justify-center w-full text-secondary-01 font-semibold text-lg mb-2"
        >
          {t('work_templates.title')}
        </Box>

        {isLoading ? (
          <>
            <PlaceholderLoader className="h-14 mb-2 rounded-md" />
            <PlaceholderLoader className="h-14 mb-2 rounded-md" />
            <PlaceholderLoader className="h-14 mb-2 rounded-md" />
          </>
        ) : (
          <>
            {data?.map((workTemplate: workTemplates) => {
              if (!workTemplate) return null

              return (
                <Box
                  className={`flex relative cursor-pointer rounded-md border border-neutral-02 font-semibold text-sm justify-center items-center p-4 mb-2 ${
                    wTSelected?.id === workTemplate.id
                      ? 'bg-primary-01 text-neutral-01'
                      : 'text-neutral-07 hover:bg-neutral-03'
                  }`}
                  key={`workTemplate-${workTemplate.id}`}
                  onClick={() => {
                    setWTSelected(workTemplate)
                    setIsCreating(false)
                  }}
                >
                  {workTemplate.name}

                  <Box className="flex absolute left-0 ml-2">
                    <IconButton
                      icon={MdDelete}
                      className="override:(bg-neutral-01 hover:bg-error-02)"
                      onClick={(e) => {
                        e.stopPropagation()
                        onOpen()
                        workTemplateDelete.current = workTemplate
                      }}
                      color="error"
                    />
                  </Box>
                </Box>
              )
            })}

            {isCreating && (
              <Box className="flex justify-center cursor-pointer rounded-md border border-neutral-02 p-4 mb-2 bg-primary-01 text-neutral-01">
                <Box
                  as="input"
                  type="text"
                  placeholder={t('work_templates.name_placeholder')}
                  className="h-full w-full bg-primary-01 placeholder-neutral-01 font-semibold px-2 text-sm text-neutral-01 focus:outline-none"
                  value={wTSelected?.name || ''}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    if (!wTSelected) {
                      return
                    }
                    const value = (e.target as HTMLInputElement).value
                    setWTSelected({ ...wTSelected, name: value })
                  }}
                />
              </Box>
            )}

            <Box className="flex justify-center">
              {isCreating ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsCreating(false)
                    setWTSelected(null)
                  }}
                >
                  {t('cancel')}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsCreating(true)
                    setWTSelected(emptyWorkTemplate())
                  }}
                >
                  {t('work_templates.add')}
                </Button>
              )}
            </Box>
          </>
        )}

        <DeleteResource
          title={t('work_templates.delete_confirmation_title')}
          onClose={() => onClose()}
          onConfirm={async () => {
            handleDelete()
            onClose()
          }}
          isLoading={isDeleting}
          isOpen={isOpen}
        />
      </Box>

      <Box className="w-3/4">
        <WorkTemplatesPreview
          workTemplate={wTSelected}
          onSaveNew={(created: workTemplates) => {
            setIsCreating(false)
            setWTSelected(created)
          }}
        />
      </Box>
    </Box>
  )
}

export default WorkTemplates
