import { Box } from 'lemon-system'
import { useState } from 'react'
import { MdPersonAdd } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { LayoutGroup } from 'framer-motion'

import FloatingButton from 'components/FloatingButton'
import PlaceholderLoader from 'components/PlaceholderLoader'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import getClient from 'features/shared/services/getClient'
import useTrackEvent from 'hooks/useTrackEvent'
import ContactCardWrapper from '../components/ContactCardWrapper'
import NewContactCard from '../components/NewContactCard'
import useClientContacts from '../hooks/useClientContacts'

import { clientType } from 'features/shared/types/clientType'

const ClientContacts: React.FC = () => {
  const { clientId } = useParams()
  const { data: clientData } = useQuery<clientType>(`client-${clientId}`, () =>
    getClient(clientId)
  )
  const { data, isLoading } = useClientContacts(clientId as string)
  const [isCreatingContact, setIsCreatingContact] = useState(false)
  const { policyValidation } = usePolicyValidation()
  const showCreatingButton = policyValidation('contacts.write')

  const hideNewContact = () => setIsCreatingContact(false)

  useTrackEvent({
    trackOnMount: {
      eventName: 'clients - contacts',
      metadata: {
        clientId: clientId as string,
      },
    },
  })

  if (isLoading) {
    return (
      <Box className="py-6 flex flex-wrap">
        <PlaceholderLoader className="min-w-96 h-60 m-4 rounded-md" />
        <PlaceholderLoader className="min-w-96 h-60 m-4 rounded-md" />
        <PlaceholderLoader className="min-w-96 h-60 m-4 rounded-md" />
      </Box>
    )
  }

  return (
    <Box className="py-6">
      {showCreatingButton && (
        <FloatingButton
          onClick={() => setIsCreatingContact(true)}
          size={18}
          icon={MdPersonAdd}
          className="bg-primary-01"
        />
      )}

      <Box className="flex flex-wrap justify-start align-start">
        <LayoutGroup>
          {isCreatingContact && (
            <NewContactCard
              onCreated={hideNewContact}
              onCancel={hideNewContact}
            />
          )}

          {clientData && data?.map(({ id, name, email, phone }) => (
            <ContactCardWrapper
              key={id}
              clientId={clientId}
              id={id}
              name={name}
              email={email}
              phone={phone}
              isFavourite={clientData?.favourite?.id === id}
            />
          ))}
        </LayoutGroup>
      </Box>
    </Box>
  )
}

export default ClientContacts
