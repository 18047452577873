const readInputFile = (inputFile: File) => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject('Problem read file')
    }

    reader.onloadend = () => {
      resolve(reader.result)
    }

    reader.readAsDataURL(inputFile)
  })
}

export default readInputFile
