import useCreateContact from 'features/clients/hooks/useCreateContact'
import { createContactProps } from 'features/clients/services/createContact'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ContactCard from '../ContactCard'

interface NewContactCardProps {
  onCreated: () => void
  onCancel: () => void
}

const NewContactCard: React.FC<NewContactCardProps> = ({
  onCreated,
  onCancel,
}) => {
  const [isEditing, setIsEditing] = useState(true)
  const { isLoading, mutateAsync } = useCreateContact()
  const { clientId } = useParams()

  const handleEdit = async (contactData: createContactProps['contactData']) => {
    try {
      await mutateAsync({
        clientId: clientId as string,
        contactData,
      })
      onCreated()
    } catch {
      setIsEditing(true)
    }
  }

  const handleEditing = (flag: boolean) => {
    if (!flag) onCancel()
  }

  return (
    <ContactCard
      className="m-4"
      isLoading={isLoading}
      onEdit={handleEdit}
      onEditing={handleEditing}
      isEditing={isEditing}
    />
  )
}

export default NewContactCard
