import RSSelectType from 'react-select/dist/declarations/src/Select'
import { SelectProps } from 'components/Select'
import SelectIcon from 'components/SelectIcon'
import { GroupBase } from 'react-select'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

const SelectBoolean = forwardRef<
  RSSelectType<unknown, boolean, GroupBase<unknown>>,
  Omit<SelectProps, 'options'>
>(({ size = 'sm', ...props }, ref) => {
  const { t } = useTranslation()
  const options = [
    { label: t('active'), value: true },
    { label: t('inactive'), value: false },
  ]

  return <SelectIcon size={size} ref={ref} options={options} {...props} />
})

export default SelectBoolean

