import { fetcher } from 'utils/fetcher'

export type activateFunnelProps = {
  funnelId: string
}

async function activateFunnel({ funnelId }: activateFunnelProps) {
  const response = await fetcher.patch(
    `/crm-clients/funnels/${funnelId}`
  )

  return response
}

export default activateFunnel
