import { useState } from 'react'
import { Box, Checkbox, Icon } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { MdPersonAdd, MdSearch } from 'react-icons/md'

import FloatingButton from 'components/FloatingButton'
import PageLoader from 'components/PageLoader'
import UsersTable from './UsersTable'

import { filterRowBuilder } from 'utils'
import { useUsers } from 'features/shared/hooks/useUsers'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'

const Users: React.FC = () => {
  const { t } = useTranslation()
  const [onlyActives, setOnlyActives] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [search, setSearch] = useState('')
  const { isLoading, data: users } = useUsers()
  const { policyValidation } = usePolicyValidation()
  const showCreatingButton = policyValidation('users.write')

  const filterUsers = filterRowBuilder()

  const { debouncedTrack, track } = useTrackEvent({
    trackOnMount: {
      eventName: 'users',
    },
  })

  const handleChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value
    setSearch(value)
    debouncedTrack({
      eventName: 'users - search',
      metadata: {
        searchValue: value,
      },
    })
  }

  const handleToggleActives = () => {
    const flag = !onlyActives

    setOnlyActives(flag)
    debouncedTrack({
      eventName: 'users - toggle actives',
      metadata: {
        onlyActives: flag,
      },
    })
  }

  if (isLoading) return <PageLoader />

  return (
    <Box className="p-6">
      <Box as="h1" className="text-secondary-01 font-semibold text-lg pb-8">
        {t('users.title')}
      </Box>

      <Box as="h3" className="text-secondary-01 font-semibold text-sm pb-2">
        {t('users.search_title')}
      </Box>

      <Box className="h-8 w-1/2 ring-1 ring-secondary-01 flex items-center pl-2 mb-4 rounded-sm">
        <Box
          as="input"
          type="text"
          placeholder={t('users.search_placeholder')}
          className="h-full w-full placeholder-neutral-05 px-2 text-sm text-secondary-01 focus:outline-none"
          value={search}
          onChange={handleChangeSearch}
        />
        <Icon className="mr-2" as={MdSearch} />
      </Box>

      <Box className="mb-4 flex">
        <Checkbox isChecked={onlyActives} onChange={handleToggleActives}>
          <Box as="span" className="hover:cursor-pointer">
            {t('users.only_actives_checkbox')}
          </Box>
        </Checkbox>
      </Box>

      <UsersTable
        users={filterUsers(users, search).filter((u: { active: boolean }) =>
          onlyActives ? u.active : true
        )}
        isCreating={isCreating}
        onCancel={() => {
          setIsCreating(false)
        }}
        onCreated={() => {
          setIsCreating(false)
        }}
      />

      {showCreatingButton && (
        <FloatingButton
          size={18}
          icon={MdPersonAdd}
          className="bg-primary-01"
          onClick={() => {
            track({
              eventName: 'users - create',
            })
            setIsCreating(true)
          }}
        />
      )}
    </Box>
  )
}

export default Users
