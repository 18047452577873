import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'lemon-system'
import { useQuery } from 'react-query'
import getProjectsByFunnelId from '../services/getProjectsByFunnelId'
import { defaultActionContent } from 'features/shared/utils'

export const projectsInFunnelQueryKey = 'projectsInFunnel'

function useProjects(funnelId: string) {
  const { policyValidation } = usePolicyValidation()
  const canRead = policyValidation('projects.read')
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useQuery(
    [projectsInFunnelQueryKey, funnelId],
    () => getProjectsByFunnelId(funnelId),
    {
      onError: () =>
        snackbar.add({
          duration: 3000,
          content: t('unexpected_error'),
          status: 'error',
          actionContent: defaultActionContent,
        }),
      enabled: !!funnelId && canRead,
    }
  )
}

export default useProjects
