import { fetcher } from 'utils/fetcher'

export type funnelStep = {
  name: string
}

export type createFunnelProps = {
  name: string
  active?: boolean,
  steps: funnelStep[],
}


async function createFunnel(funnelData: createFunnelProps) {
  const response = await fetcher.post(`/crm-clients/funnels`, funnelData)

  return response
}

export default createFunnel
