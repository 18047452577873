import { funnelType } from './../types/funnelType'
import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import createFunnel from '../services/createFunnel'
import { defaultActionContent } from 'features/shared/utils'

const useCreateFunnel = () => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return useMutation(createFunnel, {
    onSuccess: (newFunnel: funnelType) => {
      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('funnels.success_create'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useCreateFunnel
