import { useDisclosure } from 'hooks/useDisclosure'
import SaveResourceModal, {
  saveProps,
} from 'features/projects/components/SaveResourceModal'
import { useTranslation } from 'react-i18next'
import { Button } from 'lemon-system'

interface AddMilestoneProps {
  onSave: (values: saveProps) => void
}

const AddMilestone: React.FC<AddMilestoneProps> = ({ onSave }) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { t } = useTranslation()

  const handleSave = async (values: saveProps) => {
    onClose()
    onSave(values)
  }

  return (
    <>
      <Button onClick={() => onOpen()}>
        {t('work_templates.add_milestone')}
      </Button>

      <SaveResourceModal
        onClose={() => {
          onClose()
        }}
        isOpen={isOpen}
        isLoading={false}
        onSave={handleSave}
        title={t('projects.add_milestone')}
        showDeadline={false}
      />
    </>
  )
}

export default AddMilestone
