import { useSnackbar } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import updateClient from '../services/updateClient'

import { clientsType } from 'features/shared/types/clientsType'
import { defaultActionContent } from 'features/shared/utils'
import { clientsQueryKey } from '../views/Clients'

const useUpdateClient = () => {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(updateClient, {
    onSuccess: (updatedClient: clientsType) => {
      const updatedClients = (
        queryClient.getQueryData([clientsQueryKey]) as clientsType[]
      ).map((client) => {
        if (updatedClient.id === client.id) {
          return {
            ...updatedClient,
            name: updatedClient.name,
            holding: {
              ...client.holding,
              id: updatedClient.holding?.id,
              name: updatedClient.holding?.name,
            }
          }
        } else {
          return client
        }
      })
      queryClient.setQueryData(clientsQueryKey, updatedClients)

      snackbar.add({
        duration: 5000,
        status: 'success',
        content: t('clients.success_update'),
        actionContent: defaultActionContent,
      })
    },
    onError: () => {
      snackbar.add({
        duration: 3000,
        status: 'error',
        content: t('unexpected_error'),
        actionContent: defaultActionContent,
      })
    },
  })
}

export default useUpdateClient
