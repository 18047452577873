import { workTemplates } from 'features/shared/types/workTemplates'
import { fetcher } from 'utils/fetcher'

async function updateWorkTemplates(workTemplate: workTemplates) {
  return await fetcher.put<workTemplates>(
    `/work-templates/${workTemplate?.id}`,
    {
      ...workTemplate,
    }
  )
}

export default updateWorkTemplates
