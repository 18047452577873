import PageLoader from 'components/PageLoader'
import Tabs, { Tab } from 'components/Tabs'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useRoles from 'features/shared/hooks/useRoles'
import { Box } from 'lemon-system'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import CreateRoleModal from '../components/CreateRoleModal'
import DeleteRole from '../components/DeleteRole'
import MenuButton from '../components/MenuButton'

const Roles: React.FC = () => {
  const { t } = useTranslation()
  const { policyValidation } = usePolicyValidation()
  const { isLoading, roles } = useRoles()
  const adminRoleIndex = 0

  if (isLoading) return <PageLoader />

  return (
    <Box className="p-6">
      <Box
        as="h1"
        className="text-secondary-01 font-semibold text-lg flex items-center mb-8"
      >
        {t('roles.title')}
      </Box>

      <Tabs>
        {roles?.map((role, index) => (
          <Tab
            key={`roleTab-${role}`}
            to={
              index === adminRoleIndex ? './' : `./${encodeURIComponent(role)}`
            }
          >
            {role}
          </Tab>
        ))}
      </Tabs>

      <Outlet />

      {policyValidation('roles.write') && (
        <MenuButton>
          <CreateRoleModal />
          <DeleteRole />
        </MenuButton>
      )}
    </Box>
  )
}

export default Roles
