import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import TaskDragButton from '../TaskDragButton'
import TaskRow from '../TaskRow'

interface DraggableTasksProps {
  id: string
  index: number
  isDisabled: boolean
}

const DraggableTasks: React.FC<DraggableTasksProps> = ({
  children,
  id,
  index,
  isDisabled,
}) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDisabled}>
      {(
        draggableProvidedTasks: DraggableProvided,
        draggableSnapshotTasks: DraggableStateSnapshot
      ) => (
        <TaskRow
          isNested
          className={`${draggableSnapshotTasks.isDragging ? 'shadow-md' : ''}`}
          ref={draggableProvidedTasks.innerRef}
          {...draggableProvidedTasks.draggableProps}
        >
          {!isDisabled && (
            <TaskDragButton {...draggableProvidedTasks.dragHandleProps} />
          )}
          {children}
        </TaskRow>
      )}
    </Draggable>
  )
}

export default DraggableTasks
