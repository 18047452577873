import { Box, Icon } from 'lemon-system'
import { createContext, ElementType, ReactNode, useContext } from 'react'
import { MdErrorOutline } from 'react-icons/md'

interface FormControlProps {
  isInvalid?: boolean
  isRequired?: boolean
  children: ReactNode
  className?: string
  as?: ElementType
}

interface FormControlLabelProps {
  className?: string
}

type FormControlContextType = Omit<FormControlProps, 'children' | 'className'>

const FormControlContext = createContext<FormControlContextType | undefined>(
  undefined
)

export function useFormControl() {
  return useContext(FormControlContext) as FormControlContextType
}

const FormControl = ({
  children,
  isInvalid,
  isRequired,
  as = 'label',
  className = '',
}: FormControlProps) => {
  return (
    <FormControlContext.Provider value={{ isInvalid, isRequired }}>
      <Box as={as} className={`block ${className}`}>
        {children}
      </Box>
    </FormControlContext.Provider>
  )
}

const FormControlLabel: React.FC<FormControlLabelProps> = ({
  children,
  className = '',
}) => {
  const { isRequired } = useFormControl()

  return (
    <Box
      as="span"
      className={`block text-secondary-01 font-semibold text-xs mb-0.5 ${className}`}
    >
      {children} {isRequired && '*'}
    </Box>
  )
}

const FormControlErrorMessage: React.FC = ({ children }) => {
  const { isInvalid } = useFormControl()

  if (!isInvalid) return null

  return (
    <Box
      as="span"
      className="text-error-01 text-xs flex items-center mt-0.5 h-4.5"
    >
      <Icon as={MdErrorOutline} size="xs" className="mr-1.5" />
      {children}
    </Box>
  )
}

FormControl.Label = FormControlLabel
FormControl.ErrorMessage = FormControlErrorMessage

export default FormControl
