import FloatingButton from 'components/FloatingButton'
import FunnelCard from 'features/shared/components/FunnelCard'
import PlaceholderLoader from 'components/PlaceholderLoader'
import useFunnels from 'features/funnels/hooks/useFunnels'
import CreateProject from 'features/funnels/views/CreateProject'
import getClient from 'features/shared/services/getClient'
import getClientProjects from 'features/shared/services/getClientProjects'
import { clientType } from 'features/shared/types/clientType'
import { projectType } from 'features/shared/types/projectType'
import { useDisclosure } from 'hooks/useDisclosure'
import { Box } from 'lemon-system'
import { useState } from 'react'
import { MdNoteAdd } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import usePolicyValidation from 'features/shared/hooks/usePolicyValidation'
import useTrackEvent from 'hooks/useTrackEvent'
import queryClient from 'configs/reactQuery/queryClient'

const ClientProjects: React.FC = () => {
  const { clientId } = useParams()
  const { policyValidation } = usePolicyValidation()
  const [isCreatingProject, setIsCreatingProject] = useState(false)
  const { onClose, onOpen } = useDisclosure()
  const { data: funnels } = useFunnels()

  const clientProjectsKey = (clientId?: string) =>
    `client-${clientId || ''}-projects`

  const { isLoading, data: projects } = useQuery<projectType[]>(
    clientProjectsKey(clientId),
    () => getClientProjects(clientId)
  )

  const { data: client } = useQuery<clientType>(`client-${clientId}`, () =>
    getClient(clientId)
  )

  const handleOnCloseCreateProjectModal = async () => {
    setIsCreatingProject(false)
    onClose()
  }

  const handleProjectUpdate = (updatedProject: projectType) => {
    const previousProjects = queryClient.getQueryData([
      clientProjectsKey(clientId),
    ]) as projectType[]

    const updatedProjects = previousProjects.map((project) =>
      project.id === updatedProject.id ? updatedProject : project
    )

    queryClient.setQueryData(clientProjectsKey(clientId), updatedProjects)
  }

  useTrackEvent({
    trackOnMount: {
      eventName: 'clients - projects',
      metadata: {
        clientId: clientId as string,
      },
    },
  })

  return (
    <Box className="w-full">
      <Box className="flex flex-wrap mt-6 overflow-x-auto flex-grow-1 flex-shrink-1">
        {isLoading ? (
          <Box className="flex mr-4 w-72 h-28">
            <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
            <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
            <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
            <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
            <PlaceholderLoader className="mr-4 w-72 flex-grow-0 flex-shrink-0 rounded-md" />
          </Box>
        ) : (
          projects?.map((p) => (
            <Box className="flex mr-4 w-72" key={`FunnelCard-${p.id}`}>
              <FunnelCard
                className="w-full"
                client={client}
                project={p}
                onUpdate={handleProjectUpdate}
              />
            </Box>
          ))
        )}
      </Box>

      {policyValidation('projects.write') && (
        <FloatingButton
          size={18}
          icon={MdNoteAdd}
          className="bg-primary-01"
          onClick={() => setIsCreatingProject(true)}
        />
      )}

      {isCreatingProject && (
        <CreateProject
          isOpen={isCreatingProject}
          onOpen={onOpen}
          onClose={handleOnCloseCreateProjectModal}
          currentClient={client}
          funnels={funnels}
        />
      )}
    </Box>
  )
}

export default ClientProjects
