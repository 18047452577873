import { useUser } from 'features/shared/state/user/userContext'
import constants from 'configs/constants'
import { useEffect } from 'react'

const useInitAppcues = () => {
  const { user } = useUser()
  const { APPCUES_KEY } = constants
  useEffect(() => {
    if (user && APPCUES_KEY) {
      //@ts-ignore
      window.Appcues?.identify(user.id as string, {
        id: user.id as string,
        name: user.name as string,
        email: user.email as string,
        tenant: user.tenant as string,
        role: user.role as string,
        status: user.status as string,
      })
    }
  }, [user, APPCUES_KEY])
}

export default useInitAppcues
