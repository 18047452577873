import { userType } from 'features/shared/types/userType'
import { fetcher } from 'utils/fetcher'

async function updatePredefinedUserAvatar(avatar: string | null) {
  return await fetcher.put<userType>('landlord/users/me/avatars', {
    option: avatar,
  })
}

export default updatePredefinedUserAvatar
