import { Box, Button, Icon } from 'lemon-system'
import {
  MdCheck,
  MdClose,
  MdDeleteOutline,
  MdOutlineEdit,
} from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { RefObject, useEffect, useState } from 'react'

import Avatar from 'components/Avatar'

import { categoryType } from 'features/shared/types/categoryType'
import { Confirm } from 'components/Dialog'
import useTrackEvent from 'hooks/useTrackEvent'

interface CategoryRowProps {
  category: categoryType
  isNew: boolean
  isEditable: boolean
  onEdit?: (key: string, value: string) => void
  onCreate?: (category: categoryType) => void
  onEditable: () => void
  onCancelEdit?: () => void
  onCancel?: () => void
  isDisabled?: boolean
  onDeleteCategory?: (category: categoryType) => void
  newIsValid?: boolean
  firstInputRef?: RefObject<HTMLInputElement> | null
  isLoading?: boolean
  canUpdateDelete: boolean
}

const CategoryRow: React.FC<CategoryRowProps> = ({
  category,
  isEditable = false,
  onCreate,
  onEditable,
  onEdit,
  onCancel,
  isDisabled = false,
  onDeleteCategory,
  firstInputRef,
  isLoading,
  canUpdateDelete,
}) => {
  const { t } = useTranslation()
  const [categoryEditable, setCategoryEditable] =
    useState<categoryType>(category)
  const [isValid, setIsValid] = useState(false)

  const handleCategoryEditable = (key: string, value: string) =>
    setCategoryEditable({ ...categoryEditable, [key]: value })

  const { track } = useTrackEvent()

  useEffect(() => {
    setCategoryEditable(category)

    // eslint-disable-next-line
  }, [isEditable])

  useEffect(() => {
    setIsValid(!!(categoryEditable.name?.trim() || '').length)
  }, [categoryEditable.name])

  return (
    <>
      <Box as="td" className="w-1/3 flex justify-center">
        <Avatar
          size={7}
          id={category.id}
          name={categoryEditable.name}
          className={'inline-flex'}
        />
      </Box>
      <Box as="td" className={`text-left w-1/3`}>
        {isEditable ? (
          <Box
            as="input"
            value={categoryEditable.name || ''}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleCategoryEditable?.(
                'name',
                (e.target as HTMLInputElement).value
              )
            }
            placeholder={t('categories.edit_fields.name')}
            className="border-b border-transparent focus:(border-b outline-none) w-full"
            ref={firstInputRef}
            disabled={isLoading}
            required
          />
        ) : (
          category.name
        )}
      </Box>
      <Box as="td" className="w-1/3">
        {isEditable ? (
          <Box className="text-center">
            <Button
              size="xs"
              className="mr-2"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                onCancel?.()
                track({
                  eventName: `categories - ${
                    category.id ? 'edit' : 'create'
                  } - cancel`,
                  ...(category.id && {
                    metadata: {
                      category: categoryEditable,
                    },
                  }),
                })
              }}
              variant="outlined"
            >
              <Icon as={MdClose} />
            </Button>

            <Button
              size="xs"
              style={{
                minWidth: 'fit-content',
              }}
              onClick={() => {
                onCreate?.(categoryEditable)
                track({
                  eventName: `categories - ${
                    category.id ? 'edit' : 'create'
                  } - done`,
                  ...(category.id && {
                    metadata: {
                      category: categoryEditable,
                    },
                  }),
                })
              }}
              variant="outlined"
              isDisabled={!isValid}
              type="submit"
            >
              <Icon as={MdCheck} />
            </Button>
          </Box>
        ) : (
          <Box as="td" className="w-3/12">
            <Box className="flex justify-center">
              {canUpdateDelete && (
                <>
                  <Button
                    size="xs"
                    style={{
                      minWidth: 'fit-content',
                    }}
                    onClick={() => {
                      onEditable()
                      track({
                        eventName: 'categories - edit',
                        metadata: {
                          category,
                        },
                      })
                    }}
                    variant="outlined"
                    isDisabled={isDisabled}
                  >
                    <Icon as={MdOutlineEdit} />
                  </Button>
                  <Confirm
                    title={`Eliminar categoría`}
                    onConfirm={async () => {
                      await onDeleteCategory?.(category)
                      track({
                        eventName: 'categories - delete - done',
                        metadata: {
                          category,
                        },
                      })
                    }}
                    onOpen={() => {
                      track({
                        eventName: 'categories - delete',
                        metadata: {
                          category,
                        },
                      })
                    }}
                    onCancel={() => {
                      track({
                        eventName: 'categories - delete - cancel',
                        metadata: {
                          category,
                        },
                      })
                    }}
                    isLoading={isLoading}
                    button={
                      <Button
                        size="xs"
                        className="ml-2"
                        color="primary"
                        style={{
                          minWidth: 'fit-content',
                          opacity: 100,
                        }}
                        variant="outlined"
                      >
                        <Icon as={MdDeleteOutline} />
                      </Button>
                    }
                    body={t('generic_short_confirm_message')}
                  />
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default CategoryRow
