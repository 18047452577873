import { fetcher } from 'utils/fetcher'
import { projectType } from '../types/projectType'

async function getClientProjects(clientId?: string) {
  return await fetcher.get<projectType[]>(
    `/crm-clients/clients/${clientId}/projects`
  )
}

export default getClientProjects
