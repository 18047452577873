import { useState } from 'react'
import { DraggableLocation } from 'react-beautiful-dnd'

const queryAttr = 'data-rbd-draggable-id'

function usePlaceholder() {
  const [placeholderProps, setPlaceholderProps] = useState<{
    [index: string]: number
  } | null>({})

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`
    const draggedDOM = document.querySelector(domQuery)

    return draggedDOM
  }

  const calculatePlaceholderProps = ({
    source,
    destination,
    draggableId,
  }: {
    source: DraggableLocation
    destination?: DraggableLocation
    draggableId: string
  }) => {
    const draggedDOM = getDraggedDom(draggableId)

    if (!draggedDOM) return

    const { clientHeight, clientWidth } = draggedDOM
    const sourceIndex = source.index

    const parentPaddingTop = parseFloat(
      window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
    )

    const childrenArray = Array.from(
      draggedDOM.parentNode?.children as HTMLCollectionOf<HTMLElement>
    )

    const sumElementsHeight = (elements: HTMLElement[]) => {
      return elements.reduce((total, curr) => {
        const style = window.getComputedStyle(curr)
        const marginBottom = parseFloat(style.marginBottom)
        return total + curr.clientHeight + marginBottom
      }, 0)
    }

    let clientY

    if (destination) {
      const destinationIndex = destination.index
      const movedItem = childrenArray[sourceIndex]
      childrenArray.splice(sourceIndex, 1)

      const updatedArray = [
        ...childrenArray.slice(0, destinationIndex),
        movedItem,
        ...childrenArray.slice(destinationIndex + 1),
      ]

      clientY =
        parentPaddingTop +
        sumElementsHeight(updatedArray.slice(0, destinationIndex))
    } else {
      clientY =
        parentPaddingTop +
        sumElementsHeight(childrenArray.slice(0, sourceIndex))
    }

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
      ),
    })
  }

  const clearPlaceholder = () => {
    setPlaceholderProps(null)
  }

  return {
    getDraggedDom,
    placeholderProps,
    calculatePlaceholderProps,
    clearPlaceholder,
  }
}

export default usePlaceholder
